import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainSideBar from '../../components/MainsideBar';
import ScreenTemplate from '../../components/ScreenTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAsync } from '../../store/slices/userSlice';
import useNotificationsApi from '../../api/notificationsApis';
import useLocation from '../../common/hooks/useLocation';
import { GOOGLE_API_KEY } from '../../config/keys';
import Mainheader from '../../components/headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const WelcomePage = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);

  const userData = useSelector((state) => state?.user?.userDetails);

  const { fetchNotifications } = useNotificationsApi();
  useEffect(() => {
    if (userData) {
      fetchNotifications(userData?._id, dispatch);
    }
  }, []);
  const handleLogout = async () => {
    try {
      dispatch(logoutUserAsync());
      //navigate("/");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
    >
      <Typography variant="h4" gutterBottom>
        Welcome to Amealio!
      </Typography>
      <PrimaryButton variant="contained" onClick={handleLogout}>
        Logout
      </PrimaryButton>
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <Mainheader
          showLocation={true}
          disabled={false}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
};

export default WelcomePage;

// import React, { useEffect } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import PropTypes from 'prop-types';
// import { Box, Button, Typography } from '@mui/material';
// import useWindowWidth from '../../common/hooks/useWindowWidth';
// import useLocation from '../../common/hooks/useLocation';
// import ScreenTemplate from '../../components/ScreenTemplate';
// import Mainheader from '../../components/headers/Mainheader';
// import MainSideBar from '../../components/MainsideBar';
// import CommunityComponent from './HomeComponents/CommunityComponent';
// import AutoplaySlider from './HomeComponents/AutoplaySlider';
// import OndcCardsInfo from './HomeComponents/OndcCardsInfo';
// import OffersCardInfo from './HomeComponents/OffersCardInfo';
// import {
//   COMMUNITY_ARR,
//   CURATION_ARR,
//   NEW_EXPERIENCE,
//   OFFER_ARR,
//   ONDC_ARR,
//   RESTAURANT_CAFE,
//   WHATS_MOOD_TODAY,
// } from './HomeConstant';

// import { useNavigate } from 'react-router-dom';
// import { GOOGLE_API_KEY } from '../../config/keys';
// import { useDispatch, useSelector } from 'react-redux';
// import { logoutUserAsync } from '../../store/slices/userSlice';
// import CurationCardInfo from './HomeComponents/CurationCardInfo';
// import RestaurantAndCafeInfo from './HomeComponents/RestaurantAndCafeInfo';
// import ExperienceInfoCard from './HomeComponents/ExperienceInfoCard';
// import WhatMoodTodayInfoCard from './HomeComponents/WhatMoodTodayInfoCard';
// import HomeTextWithButton from '../../components/reusable/HomeTextWithButton';
// import MainTabComponent from './HomeTabComponent/MainTabComponent';

// const MainHomeScreen = () => {
//   const windowWidth = useWindowWidth();
//   const isDesktop = windowWidth > 1024;
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const {
//     coordinates,
//     currentLocation,
//     updateLocation,
//     setCurrentLocation,
//     location,
//     setLocationState,
//     selectedSavedAddress,
//   } = useLocation(GOOGLE_API_KEY);

//   const userData = useSelector((state) => state?.user?.userDetails);

//   //   const { fetchNotifications } = useNotificationsApi();
//   //   useEffect(() => {
//   //     if (userData) {
//   //       fetchNotifications(userData?._id, dispatch);
//   //     }
//   //   }, []);
//   const handleLogout = async () => {
//     try {
//       dispatch(logoutUserAsync());
//       //navigate("/");
//     } catch (error) {
//       console.error('Logout failed:', error);
//     }
//   };

//   const content = (
//     <Box
//       display="flex"
//       flexDirection="column"
//       alignItems="flex-start"
//       justifyContent="flex-start"
//       minHeight="100vh"
//       paddingY={2}
//     >
//       <Typography variant="h4" gutterBottom>
//         Welcome to Amealio!
//       </Typography>

//       {/****** @DESC WHAT MOOD TODAY VIEW ******/}
//       <Row className="w-full h-auto mb-4">
//         <Col xs={12}>
//           <HomeTextWithButton text={'What’s your mood like today ?'} />
//         </Col>
//         <AutoplaySlider autoplayInterval={2000}>
//           {WHATS_MOOD_TODAY.map((item, index) => {
//             return (
//               <React.Fragment key={item?.id + index}>
//                 <WhatMoodTodayInfoCard
//                   title={item?.title}
//                   image={item?.image}
//                 />
//               </React.Fragment>
//             );
//           })}
//         </AutoplaySlider>
//       </Row>
//       {/****** @DESC TAB VIEW ******/}
//       <MainTabComponent />
//       {/****** @DESC TRY NEW EXPERIENCE VIEW ******/}
//       <Row className="w-full h-auto mb-4">
//         <Col xs={12}>
//           <HomeTextWithButton
//             text={'Turn Moments into Memories – Try New Experiences'}
//           />
//         </Col>
//         <AutoplaySlider autoplayInterval={2000}>
//           {NEW_EXPERIENCE.map((item, index) => {
//             return (
//               <Col xs={12} md={3} key={item?.id + index}>
//                 <ExperienceInfoCard
//                   title={item?.title}
//                   desc={item?.desc}
//                   image={item?.image}
//                 />
//               </Col>
//             );
//           })}
//         </AutoplaySlider>
//       </Row>
//       {/****** @DESC RESTAURANT & CAFE VIEW ******/}
//       <Row className="w-full h-auto mb-4">
//         <Col xs={12}>
//           <HomeTextWithButton
//             text={'Restaurants & Cafes you visited'}
//             btnText={'View All'}
//             onClickAction={() => {}}
//             displayBtn={true}
//           />
//         </Col>
//         <AutoplaySlider autoplayInterval={2000}>
//           {RESTAURANT_CAFE.map((item, index) => {
//             return (
//               <Col xs={12} md={3} key={item?.id + index}>
//                 <RestaurantAndCafeInfo
//                   title={item?.title}
//                   desc={item?.desc}
//                   offerDesc={item?.offer_desc}
//                   image={item?.image}
//                 />
//               </Col>
//             );
//           })}
//         </AutoplaySlider>
//       </Row>
//       {/****** @DESC CURATION RESTAURANT VIEW ******/}
//       <Row className="w-full h-auto mb-4">
//         <Col xs={12}>
//           <HomeTextWithButton
//             text={'Curations in Pune'}
//             btnText={'All collections in pune'}
//             onClickAction={() => {}}
//             displayBtn={true}
//           />
//         </Col>
//         <AutoplaySlider autoplayInterval={2000}>
//           {CURATION_ARR.map((item, index) => {
//             return (
//               <Col xs={12} md={3} key={item?.id + index}>
//                 <CurationCardInfo
//                   title={item?.title}
//                   places={item?.places}
//                   image={item?.image}
//                 />
//               </Col>
//             );
//           })}
//         </AutoplaySlider>
//       </Row>
//       {/****** @DESC SUPER ADMIN OR MERCHANT OFFERS VIEW ******/}
//       <div className="w-full h-auto my-3">
//         <Col
//           xs={12}
//           className="w-full min-h-56 rounded-2xl flex items-center justify-start py-6"
//           style={{
//             background: 'linear-gradient(92.09deg, #FFA9C7 0%, #FDD6A3 100%)',
//           }}
//         >
//           <AutoplaySlider autoplayInterval={2000}>
//             {OFFER_ARR.map((item, index) => {
//               return (
//                 <React.Fragment key={item?.id + index}>
//                   {/* // <Col xs={12} md={3} key={item?.id + index}> */}
//                   <OffersCardInfo title={item?.title} image={item?.image} />
//                   {/* </Col> */}
//                 </React.Fragment>
//               );
//             })}
//           </AutoplaySlider>
//         </Col>
//       </div>
//       {/****** @DESC ONDC RESTAURANT VIEW ******/}
//       <div className="w-full h-auto">
//         <div className="w-full">
//           <HomeTextWithButton
//             text={'ONDC Restaurants'}
//             btnText={'Explore'}
//             onClickAction={() => {}}
//             displayBtn={true}
//           />
//         </div>
//         <AutoplaySlider autoplayInterval={2000}>
//           {ONDC_ARR.map((item, index) => {
//             return (
//               <React.Fragment key={item?.id + index}>
//                 <OndcCardsInfo
//                   restName={item?.rest_name}
//                   offerName={item?.offer_desc}
//                   offerPrice={item?.upto_price}
//                   rating={item?.rating}
//                   restTime={item?.time}
//                   restIsLike={item?.isLike}
//                   restPremium={item?.isPremium}
//                   image={item?.image}
//                 />
//               </React.Fragment>
//             );
//           })}
//         </AutoplaySlider>
//       </div>
//       {/****** @DESC COMMUNITY VIEW ******/}
//       <Row className="w-full h-auto">
//         <Col xs={12}>
//           <HomeTextWithButton
//             text={'Explore Community of like minded people'}
//           />
//         </Col>
//         <AutoplaySlider autoplayInterval={2000}>
//           {COMMUNITY_ARR.map((item, index) => {
//             return (
//               <Col xs={12} md={5} key={item?.id + index}>
//                 <CommunityComponent
//                   title={item?.title}
//                   desc={item?.desc}
//                   rating={item?.rating}
//                   members={item?.members}
//                   image={item?.image}
//                 />
//               </Col>
//             );
//           })}
//         </AutoplaySlider>
//       </Row>
//     </Box>
//   );

//   return (
//     <ScreenTemplate
//       MainSideBarComponent={MainSideBar}
//       HeaderComponent={() => (
//         <Mainheader
//           showLocation={true}
//           disabled={false}
//           currentLocation={location}
//           updateLocation={updateLocation}
//           isDesktop={isDesktop}
//           sidebarWidth={240}
//           setCurrentLocation={setCurrentLocation}
//           setLocationState={setLocationState}
//           selectedSavedAddress={selectedSavedAddress}
//         />
//       )}
//       ContentComponent={content}
//     />
//   );
// };

// MainHomeScreen.propTypes = {};

// export default MainHomeScreen;
