import React from "react";
import { Grid, Box, BottomNavigation } from "@mui/material";
import useWindowWidth from "../common/hooks/useWindowWidth";

const ScreenTemplate = ({
  MainSideBarComponent,
  HeaderComponent,
  ContentComponent,
  FooterComponent = null,
  sidebarWidth = 240,
  actionBy,
}) => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  const headerHeight = isDesktop ? 64 : 56;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {HeaderComponent && <HeaderComponent />}
      <Grid container sx={{ flex: 1 }}>
        {MainSideBarComponent && (
          <MainSideBarComponent windowWidth={windowWidth} />
        )}
        <Grid
          item
          sx={{
            paddingBottom: isDesktop ? 0 : "60px",
            width: "100%",
          }}
        >
          {actionBy === "REELS_PAGE" ? (
            <Box className={`bytes-content`}>{ContentComponent}</Box>
          ) : (
            <Box
              className={`main-content`}
              sx={{
                padding: "12px",
                marginTop: `${headerHeight}px`,
              }}
            >
              {ContentComponent}
            </Box>
          )}
        </Grid>
      </Grid>

      {FooterComponent && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            // left: 0,
            right: 0,
            height: `${60}px`,
            backgroundColor: "background.paper",
            borderTop: "1px solid",
            borderColor: "divider",
            zIndex: 1200,
            width: isDesktop ? `calc(100% - ${sidebarWidth}px)` : "100%",
          }}
        >
          <FooterComponent />
        </Box>
      )}
    </Box>
  );
};

export default ScreenTemplate;
