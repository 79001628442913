import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Sidebar = ({ isOpen, onClose, title, children }) => {
  return (
    <div
      className={`offcanvas offcanvas-end ${isOpen ? 'show' : ''}`}
      tabIndex="-1"
      style={{ visibility: isOpen ? 'visible' : 'hidden' }}
    >
      <div className="offcanvas-header">
        <h2 className="offcanvas-title">{title}</h2>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={onClose}
        ></button>
      </div>
      <div className="offcanvas-body">{children}</div>
    </div>
  );
};

export default Sidebar;
