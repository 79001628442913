import React from "react";
import { Card } from "react-bootstrap";
import { Users } from "lucide-react";
import { assets } from "../../../assets/assets";
import Icons from "../../../components/Icons";
import QuantityCounter from "./QuantityCounter";

const ExperiencePackage = ({
  hasQuantity = false,
  packageData = {},
  data = {},
  formik = {},
  onIncrementHandler = () => {},
  onDecrementHandler = () => {},
}) => {
  // console.log("🚀 ~ packageData:", packageData);

  return (
    <div className="col-lg-5 col-md-12">
      <Card
        className="col-12 w-10 rounded-3xl p-6  rounded-4"
        style={{
          borderColor: assets.colors.exp_card_border,
          backgroundColor: "#FFFBFA",
        }}
      >
        <div className="p-0 space-y-4">
          <div className="d-flex align-items-center  gap-3">
            <Icons type="PackageGroup" />
            <h2 className="text-xl fw-bold">{packageData?.name}</h2>
          </div>

          <div className="space-y-2 mt-2">
            <p className="text-lg">
              No of people included in the package : {packageData?.adults}{" "}
              Adults, {packageData?.kids} kids
            </p>
          </div>

          <div className="space-y-1 text-right">
            {hasQuantity ? (
              <div className="d-flex align-items-end justify-content-between">
                <div className="w-100 d-flex flex-column justify-content-start">
                  {packageData?.discountedPrice && (
                    <div className="d-flex items-center justify-content-start">
                      <span
                        style={{ textDecoration: "line-through", opacity: 0.5 }}
                      >
                        ₹ {packageData?.price} /per person
                      </span>
                    </div>
                  )}

                  <div className="d-flex items-center justify-content-start">
                    <span className="text-lg font-medium text-gray-400">
                      ₹{" "}
                      {packageData?.discountedPrice
                        ? packageData?.discountedPrice
                        : packageData?.price}{" "}
                    </span>
                    <span className="text-sm text-gray-400 ml-1">
                      /per person
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <QuantityCounter
                    onIncrementHandler={onIncrementHandler}
                    onDecrementHandler={onDecrementHandler}
                    formik={formik}
                  />
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex flex-column justify-content-end">
                {packageData?.discountedPrice && (
                  <div className="d-flex items-center justify-content-end">
                    <span
                      style={{ textDecoration: "line-through", opacity: 0.5 }}
                    >
                      ₹ {packageData?.price} /per person
                    </span>
                  </div>
                )}

                <div className="d-flex items-center justify-content-end">
                  <span className="text-lg font-medium  text-black-500">
                    ₹{" "}
                    {packageData?.discountedPrice
                      ? packageData?.discountedPrice
                      : packageData?.price}{" "}
                  </span>
                  <span className="text-sm text-black-500 ml-1">
                    / per person
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>

      {formik.errors?.adultCount && (
        <div className="f12-500 mt-2" style={{ color: assets.colors.primary }}>
          {formik.errors?.adultCount}
        </div>
      )}
    </div>
  );
};

export default ExperiencePackage;
