import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from '@react-google-maps/api';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TextField, Typography } from '@mui/material';
import { GOOGLE_API_KEY } from '../../config/keys';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const AddAddress = () => {
  const [mapLocation, setMapLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMapLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          // Fallback to default location if permission is denied
          setMapLocation({
            lat: 17.4278,
            lng: 78.5483,
          });
        }
      );
    } else {
      // Fallback to default location if geolocation is not supported
      setMapLocation({
        lat: 17.4278,
        lng: 78.5483,
      });
    }
  }, []);

  const onLoad = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newLocation = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setMapLocation(newLocation);
      }
    }
  };

  const onMapClick = (event) => {
    setMapLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  return (
    <Container fluid style={{ padding: '20px' }}>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Typography className="f24-800" align="center" gutterBottom>
            Add Address
          </Typography>
          <Typography className="f16-500" align="center" gutterBottom>
            We provide door to door service through Amealio
          </Typography>
          {/* <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={['places']}> */}
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type the location to search"
              style={{ marginBottom: '20px' }}
            />
          </Autocomplete>
          {mapLocation && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapLocation}
              zoom={15}
              onClick={onMapClick}
            >
              <Marker
                position={mapLocation}
                icon={{
                  url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                }}
              />
            </GoogleMap>
          )}

        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: '20px' }}>
        <Col xs={12} className="text-center">
          <PrimaryButton>Save Address</PrimaryButton>
        </Col>
      </Row>
    </Container>
  );
};

export default AddAddress;
