import React from "react";
import PropTypes from "prop-types";
import { Col, Image, Row } from "react-bootstrap";
import { TruncatedText, extractTimingData } from "../../ondcHelperFuction.ts";
import { NOTAVAILABLEIMAGE } from "../../../../common/enums/GlobalEnums.js";
import AddButton from "../../../ReusableComponent/Buttons.js";
import AddToCartOndcButton from "./AddToCartOndcButton.jsx";
import OndcCustomizationModal from "./OndcCustomizationModal.jsx";

const OnceMenuItemCards = (props) => {
  const {
    viewType,
    item,
    menuData,
    // ondcOrderInfo,
    // ondcCartItems,
    restData,
    // setPriceAlertModal,
    // openModal,
    // openModalAction,
    // closedModalAction,
    actionBy,
    currenCartData,
  } = props;
  return (
    <div className={`menu-card relative ${viewType === 2 && "p-0"}`}>
      {viewType === 2 ? (
        <div className="border-b h-44 rounded-b-2xl rounded-lg">
          <Image
            src={item?.descriptor?.images?.[0] || NOTAVAILABLEIMAGE}
            alt={item?.descriptor?.name}
            className="h-full w-full object-fill"
          />
        </div>
      ) : (
        <div className="d-flex justify-content-between px-2 py-2">
          <div className="h-5 flex items-center px-1">
            {item?.recommended && (
              <span className="py-1 px-1 text-[10px] leading-3 border-[0.5px] border-[#4CAF50] text-[#4CAF50] bg-[#4CAF5026] rounded-xl">
                Recommended
              </span>
            )}
          </div>
        </div>
      )}
      <div className="row m-0">
        {viewType !== 2 && (
          <div className="col-4" style={{ textAlign: "center" }}>
            <Image
              src={item?.descriptor?.images?.[0] || NOTAVAILABLEIMAGE}
              alt={item?.descriptor?.name}
              className="h-24 md:h-32 w-full object-fill border shadow-md rounded-xl"
            />
          </div>
        )}
        <div
          className={`d-flex align-items-center ${
            viewType === 2 ? "col-12 p-3" : "col-8 px-2"
          }`}
        >
          <div className="col-12">
            <div
              className="f16-800"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Image
                src={require(`../../../../assets/images/IconsandImages/${
                  // extractTimingData(item?.tags, "veg_nonveg", "veg") === "yes"
                  //   ? "vegindicator.png"
                  //   :
                  "nonveg_indicator.png"
                }`)}
                alt="Veg-Nog Veg"
                className="h-4 w-4 mr-3 object-fill"
              />
              <span className="f18-1000">{item?.descriptor?.name}</span>
              {viewType === 2 && (
                <div className="h-5 flex items-center px-2">
                  {item?.recommended && (
                    <span className="py-1 px-1 text-[10px] leading-3 border-[0.5px] border-[#4CAF50] text-[#4CAF50] bg-[#4CAF5026] rounded-xl">
                      Recommended
                    </span>
                  )}
                </div>
              )}
            </div>
            <p className="mb-0 text-sm font-Mulish font-medium">
              {TruncatedText(item?.descriptor?.long_desc, 75)}
            </p>
            <div className="w-full h-auto mt-2">
              <Row>
                <Col xs={6} md={6}>
                  <p className="mb-0 text-sm font-Mulish font-semibold flex items-center">
                    COD:{" "}
                    <span
                      className={`px-1 text-sm font-mulish font-bold
                        ${
                          item["@ondc/org/available_on_cod"] ||
                          item?.available_on_cod
                            ? "text-green-600"
                            : "text-red-600"
                        }
                      `}
                    >
                      {item["@ondc/org/available_on_cod"] ||
                      item?.available_on_cod
                        ? "Yes"
                        : "No"}
                    </span>
                  </p>
                </Col>
                <Col xs={6} md={6}>
                  <p className="mb-0 text-sm font-Mulish font-semibold flex items-center">
                    Returnable:{" "}
                    <span
                      className={`px-1 text-sm font-mulish font-bold
                        ${
                          item["@ondc/org/returnable"] || item?.returnable
                            ? "text-green-600"
                            : "text-red-600"
                        }
                      `}
                    >
                      {item["@ondc/org/returnable"] || item?.returnable
                        ? "Yes"
                        : "No"}
                    </span>
                  </p>
                </Col>
                <Col xs={12} md={12}>
                  <p className="mb-0 text-sm font-Mulish font-semibold flex items-center">
                    Cancellation Available:{" "}
                    <span
                      className={`px-1 text-sm font-mulish font-bold
                        ${
                          item["@ondc/org/cancellable"] || item?.cancellable
                            ? "text-green-600"
                            : "text-red-600"
                        }
                      `}
                    >
                      {item["@ondc/org/cancellable"] || item?.cancellable
                        ? "Yes"
                        : "No"}
                    </span>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="f16-700 d-flex align-items-center justify-content-between pt-2">
              <div className="block md:flex align-items-center">
                {/* Order Count Section */}
                {item?.quantity && (
                  <span className="d-flex align-items-center mr-3">
                    <span className="f14-500">Available Item:</span>
                    <span className="f16-700 mx-1">
                      {item?.quantity?.available?.count}
                    </span>
                  </span>
                )}
                <p className="text-lg font-Mulish font-bold mb-0 block md:hidden">
                  {item?.price?.currency === "INR" ? "₹ " : ""}
                  {item?.price?.value || 0}
                </p>
              </div>

              <div className="align-items-center justify-content-end">
                <p className="text-lg font-Mulish font-bold mb-0 hidden md:block text-center">
                  {item?.price?.currency === "INR" ? "₹ " : ""}
                  {item?.price?.value || 0}
                </p>
                {/* {item?.has_customization ? (
                  <OndcCustomizationModal
                    modalOpen={openModal}
                    onClickOpenModal={openModalAction}
                    onClickClosedModal={closedModalAction}
                    item={item}
                    restData={restData}
                    disabledButton={false}
                    actionBy={"ONDC_CUSTOMIZE"}
                  />
                ) : ( */}
                <AddToCartOndcButton
                  item={item}
                  menuData={menuData}
                  restData={restData}
                  actionPerform={actionBy}
                  currenItemCartData={currenCartData}
                />
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OnceMenuItemCards.propTypes = {
  viewType: PropTypes.string || PropTypes.number,
  item: PropTypes.object,
  // ondcOrderInfo: PropTypes.object,
  // ondcCartItems: PropTypes.array,
  restData: PropTypes.object,
  actionBy: PropTypes.string,
  currenCartData: PropTypes.object,
  // setPriceAlertModal: PropTypes.func,
};

export default OnceMenuItemCards;
