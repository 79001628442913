import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../config/keys";

const libraries = ["places"];

const ReusableMap = ({
  defaultCenter = { lat: 18.5204303, lng: 73.8567437 },
  defaultZoom,
  inputStyle,
  mapContainerStyle = { height: "300px", width: "100%" },
  autocompleteOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: "in" },
  },
  customMarkerIcon,
  onMarkerPositionChange,
  onPlaceDetailsChange,
}) => {
  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: GOOGLE_API_KEY,
  //   libraries,
  // });

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [markerPosition, setMarkerPosition] = useState(null);
  const autocompleteRef = useRef(null);
  const [placeDetails, setPlaceDetails] = useState(null);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onMapClick = useCallback(
    (event) => {
      const latLng = event.latLng;
      setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() });
      reverseGeocode(latLng.lat(), latLng.lng());
      if (onMarkerPositionChange) {
        onMarkerPositionChange({ lat: latLng.lat(), lng: latLng.lng() });
      }
    },
    [onMarkerPositionChange]
  );

  const onMarkerDragEnd = useCallback(
    (event) => {
      const latLng = event.latLng;
      setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() });
      if (map) {
        map.panTo(latLng);
      }
      reverseGeocode(latLng.lat(), latLng.lng());
      if (onMarkerPositionChange) {
        onMarkerPositionChange({ lat: latLng.lat(), lng: latLng.lng() });
      }
    },
    [map, onMarkerPositionChange]
  );

  const onAutocompleteLoad = useCallback((autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        setCenter({ lat: location.lat(), lng: location.lng() });
        setMarkerPosition({ lat: location.lat(), lng: location.lng() });
        map?.panTo(location);
        updatePlaceDetails(place);
      } else {
        console.error("Place details not found.");
      }
    }
  }, [map, onPlaceDetailsChange]);

  const updatePlaceDetails = useCallback(
    (place) => {
      const addressComponents = place.address_components || [];
      const formattedAddress = place.formatted_address || "";
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const placeId = place.place_id;

      //   console.log("addressComponents===>94", addressComponents);
      //   console.log("formattedAddress===>95", formattedAddress);

      let locality = "";
      let country = "";
      let city = "";
      let state = "";
      let postalCode = "";
      let building = "";
      let street = "";
      let sublocalityLevel2 = "";
      let sublocalityLevel1 = "";

      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("sublocality_level_1")) {
          locality = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        } else if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        } else if (component.types.includes("premise")) {
          building = component.long_name;
        } else if (component.types.includes("route")) {
          street = component.long_name;
        } else if (component.types.includes("street_number")) {
          street = component.short_name + " " + street;
        }
        if (component.types.includes("sublocality_level_2")) {
          sublocalityLevel2 = component.long_name;
        }
        if (component.types.includes("sublocality_level_1")) {
          sublocalityLevel1 = component.long_name;
        }
      });
      let combinedSublocality = "";
      if (sublocalityLevel2 && sublocalityLevel1) {
        combinedSublocality = `${sublocalityLevel2}, ${sublocalityLevel1}`;
      } else if (sublocalityLevel2) {
        combinedSublocality = sublocalityLevel2;
      } else if (sublocalityLevel1) {
        combinedSublocality = sublocalityLevel1;
      }

      const newPlaceDetails = {
        placeId,
        city,
        state,
        locality,
        country,
        postalCode,
        building,
        street,
        address: formattedAddress,
        lat,
        lng,
        combinedSublocality,
      };

      setPlaceDetails(newPlaceDetails);
      if (onPlaceDetailsChange) {
        onPlaceDetailsChange(newPlaceDetails);
      }
    },
    [onPlaceDetailsChange]
  );

  const reverseGeocode = useCallback(
    (lat, lng) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          updatePlaceDetails(results[0]);
        } else {
          console.error("Reverse geocoding failed:", status);
        }
      });
    },
    [updatePlaceDetails]
  );

  // if (loadError) return <div>Error loading maps!</div>;
  // if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div style={{ height: "auto", width: "100%" }}>
      {/* <div className="absolute z-[1001]"> */}
      <Autocomplete
        onLoad={onAutocompleteLoad}
        libraries={libraries}
        options={autocompleteOptions}
        onPlaceChanged={onPlaceChanged}
      >
        <input
          type="text"
          placeholder="Search places..."
          className={`border w-full h-10 p-2 bg-white rounded-t-md shadow-lg ${inputStyle}`}
        />
      </Autocomplete>
      {/* </div> */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={defaultZoom}
        onLoad={onMapLoad}
        onClick={onMapClick}
      >
        {markerPosition && (
          <Marker
            position={markerPosition}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
            icon={customMarkerIcon}
          />
        )}
      </GoogleMap>
    </div>
  );
};

ReusableMap.propTypes = {
  defaultCenter: PropTypes.object,
  defaultZoom: PropTypes.number,
  inputStyle: PropTypes.object || PropTypes.array || PropTypes.string,
  mapContainerStyle: PropTypes.object || PropTypes.array || PropTypes.string,
  autocompleteOptions: PropTypes.object || PropTypes.array || PropTypes.string,
  customMarkerIcon: PropTypes.object || PropTypes.array || PropTypes.string,
  onMarkerPositionChange: PropTypes.func,
  onPlaceDetailsChange: PropTypes.func,
};

export default ReusableMap;
