import { toast } from "react-toastify";

export function ShowSuccessMessage(message){
    toast.success(message ??"This is a success message! 🎉", {
        position: "top-right",
        autoClose: 2000, // Closes in 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
}

export function ShowErrorMessage(message){
    toast.error(message ??"This is a success message! 🎉", {
        position: "top-right",
        autoClose: 2000, // Closes in 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
}