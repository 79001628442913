import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Col, Row, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReusableTextField from "../../../../components/inputFields/ResuableTextField";
import ReusableMap from "../../../../components/locations/MapComponent/ReusableMap";
import { GOOGLE_API_KEY } from "../../../../config/keys";
import useLocation from "../../../../common/hooks/useLocation";
import { isEmpty } from "../../../../common/utility/helperFunc";

const ColorButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#FFFFFF",
  border: "1px solid #FFFFFF",
  backgroundColor: "#EE3A23",
  "&:hover": {
    color: "#EE3A23",
    backgroundColor: "#FFFFFF",
    border: "1px solid #EE3A23",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#EE3A23",
  border: "1px solid #EE3A23",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#EE3A23",
    border: "1px solid #FFFFFF",
  },
}));

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, "must be at least 3 characters")
    .max(150, "must be less than 150 characters")
    .required("Address name field is required"),
  street: Yup.string()
    .min(3, "must be at least 3 characters")
    .max(150, "must be less than 150 characters")
    .required("Street field is required"),
  locality: Yup.string()
    .min(3, "must be at least 3 characters")
    .max(150, "must be less than 150 characters")
    .required("Locality field is required"),
  city: Yup.string()
    .min(3, "must be at least 3 characters")
    .required("City field is required"),
  state: Yup.string()
    .min(3, "must be at least 3 characters")
    .max(45, "must be less than 45 characters")
    .required("State field is required"),
  country: Yup.string()
    .min(3, "must be at least 3 characters")
    .required("Street field is required"),
  area_code: Yup.string().required("Pin Code field is required"),
});

const DeliveryModals = (props) => {
  const { visible, closedModal, restData, onClickSubmit } = props;

  const formik = useFormik({
    initialValues: {
      gps: { lat: 18.5204303, lng: 73.8567437 },
      name: "",
      building: "",
      street: "",
      locality: "",
      city: "",
      state: "",
      country: "",
      area_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const obj = {
        ...values,
        gps: `${values.gps?.lat}, ${values.gps?.lng}`,
      };
      onClickSubmit(obj);
    },
  });

  // useEffect(() => {
  //   if (!isEmpty(restData) && !isEmpty(restData?.fulfillment?.address)) {
  //     formik.setValues({
  //       gps: restData?.fulfillment?.address?.gps,
  //       name: restData?.fulfillment?.address?.name || "",
  //       building: restData?.fulfillment?.address?.building || "",
  //       street: restData?.fulfillment?.address?.street || "",
  //       locality: restData?.fulfillment?.address?.locality || "",
  //       city: restData?.fulfillment?.address?.city || "",
  //       state: restData?.fulfillment?.address?.state || "",
  //       country: restData?.fulfillment?.address?.country || "",
  //       area_code: restData?.fulfillment?.address?.postalCode || "",
  //     });
  //   }
  // }, [restData]);

  const handlePlaceDetailsChange = (details) => {
    let locationSelected = {
      lat: details?.lat,
      lng: details?.lng,
    };
    formik.setValues({
      gps: locationSelected,
      name: formik.values.name || "",
      building: details?.building || "",
      street: details?.street || "",
      locality: details?.locality || "",
      city: details?.city || "",
      state: details?.state || "",
      country: details?.country || "",
      area_code: details?.postalCode || "",
    });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={closedModal}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      className="switch-delivery-map-modal"
    >
      <Modal.Header className={"sm:px-2 md:px-4 sm:py-3 md:py-3 border"}>
        <div className="w-full flex items-center justify-between">
          <h4 className="text-base font-Mulish font-semibold text-black mb-0">
            Select Delivery Address
          </h4>
          <button className="w-5 h-5" onClick={closedModal}>
            <img
              src={require("../../../../assets/images/IconsandImages/cantsay_icon.png")}
              className="w-full h-auto"
              alt={"Closed"}
              title={"Closed"}
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="h-auto pt-0 border-0 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
        <Form className="py-3" onSubmit={formik.handleSubmit}>
          <Row>
            {/*** REUSABLE MAP COMPONENT ***/}
            <Col xm={12} sm={12} md={12}>
              <ReusableMap
                defaultZoom={15}
                onPlaceDetailsChange={handlePlaceDetailsChange}
              />
            </Col>
            {/*** STREET ***/}
            <Col xm={12} sm={12} md={12} className="mt-2">
              <ReusableTextField
                label="Address name*"
                type="text"
                placeholder={"Enter address name here"}
                size="small"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Col>
            {/*** Landmark + Locality ***/}
            <Col xm={12} sm={6}>
              <ReusableTextField
                label="Street*"
                type="text"
                placeholder={"Enter street"}
                size="small"
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.street && Boolean(formik.errors.street)}
                helperText={formik.touched.street && formik.errors.street}
              />
            </Col>
            <Col xm={12} sm={6}>
              <ReusableTextField
                label="Locality*"
                type="text"
                placeholder={"Enter locality"}
                size="small"
                name="locality"
                value={formik.values.locality}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.locality && Boolean(formik.errors.locality)
                }
                helperText={formik.touched.locality && formik.errors.locality}
              />
            </Col>
            {/*** BUILDING + PIN CODE ***/}
            <Col xm={12} sm={6}>
              <ReusableTextField
                label="Building"
                type="text"
                placeholder={"Enter building information"}
                size="small"
                name="building"
                value={formik.values.building}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.building && Boolean(formik.errors.building)
                }
                helperText={formik.touched.building && formik.errors.building}
              />
            </Col>
            <Col xm={12} sm={6}>
              <ReusableTextField
                label="Pin Code*"
                type="text"
                placeholder={"Enter pin code"}
                size="small"
                name="area_code"
                value={formik.values.area_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.area_code && Boolean(formik.errors.area_code)
                }
                helperText={formik.touched.area_code && formik.errors.area_code}
              />
            </Col>
            {/*** CITY + STATE ***/}
            <Col xm={12} sm={6}>
              <ReusableTextField
                label="city*"
                type="text"
                placeholder={"Enter city"}
                size="small"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Col>
            <Col xm={12} sm={6}>
              <ReusableTextField
                label="State*"
                type="text"
                placeholder={"Enter state"}
                size="small"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Col>
          </Row>
          <div className="sm:w-full md:w-1/2 md:float-end justify-end items-end py-4">
            <Stack spacing={2} direction="row">
              <OutlinedButton
                variant="outlined"
                // style={{ width: "50%" }}
                onClick={closedModal}
              >
                Cancel
              </OutlinedButton>
              <ColorButton variant="contained" type="submit">
                Next
              </ColorButton>
            </Stack>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

DeliveryModals.propTypes = {
  visible: PropTypes.bool.isRequired,
  closedModal: PropTypes.func.isRequired,
  restData: PropTypes.object,
  onClickSubmit: PropTypes.func.isRequired,
};

export default DeliveryModals;
