import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Col, Row } from "react-bootstrap";
import { isEmpty } from "../../../../common/utility/helperFunc";

const ColorButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#FFFFFF",
  border: "1px solid #FFFFFF",
  backgroundColor: "#EE3A23",
  "&:hover": {
    color: "#EE3A23",
    backgroundColor: "#FFFFFF",
    border: "1px solid #EE3A23",
  },
}));

const DeliveryTypeButton = [
  {
    id: "F0",
    type: "Delivery",
    contact: {
      phone: "9876543210",
      email: "ss_seller_1@ss.com",
    },
  },
  {
    id: "F1",
    type: "Self-Pickup",
    contact: {
      phone: "9876543210",
      email: "ss_seller_1@ss.com",
    },
  },
  {
    id: "F2",
    type: "Buyer-Delivery",
    contact: {
      phone: "9876543210",
      email: "ss_seller_1@ss.com",
    },
  },
];

const SelectDeliveryType = (props) => {
  const {
    visible,
    closedModal,
    restData,
    onClickAction,
    selectedId,
    onClickSubmit,
  } = props;

  return (
    <Modal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={closedModal}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      className="switch-delivery-type-modal"
    >
      <Modal.Header className={"sm:px-2 md:px-4 sm:py-3 md:py-3 border"}>
        <div className="w-full flex items-center justify-between">
          <h4 className="text-base font-Mulish font-semibold text-black mb-0">
            Select Delivery Type
          </h4>
          <button className="w-5 h-5" onClick={closedModal}>
            <img
              src={require("../../../../assets/images/IconsandImages/cantsay_icon.png")}
              className="w-full h-auto"
              alt={"Closed"}
              title={"Closed"}
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="h-auto pt-0 border-0 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
        <Row className="py-2">
          {!isEmpty(restData) &&
            !isEmpty(restData?.fulfillments) &&
            restData?.fulfillments
              .filter(
                (item) =>
                  !item.type.toString().toLowerCase().includes("buyer-delivery")
              )
              .map((item, index) => {
                return (
                  <Col xs={6} sm={6} key={item?.type}>
                    <button
                      className={`border-2 rounded-md shadow-md w-full my-2 py-2 ${
                        item.id === selectedId
                          ? " bg-[#EE3A23] text-white"
                          : " bg-white text-black"
                      }`}
                      onClick={onClickAction(item)}
                    >
                      <p className="mb-0 text-sm">{item?.type}</p>
                    </button>
                  </Col>
                );
              })}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="sm:w-full md:w-1/2 md:float-end justify-end items-end">
          <Stack spacing={2} direction="row">
            <ColorButton
              variant="contained"
              onClick={onClickSubmit}
              disabled={!isEmpty(selectedId) ? false : true}
            >
              Submit
            </ColorButton>
          </Stack>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

SelectDeliveryType.propTypes = {
  visible: PropTypes.bool.isRequired,
  closedModal: PropTypes.func.isRequired,
  restData: PropTypes.array,
  onClickAction: PropTypes.func.isRequired,
  selectedId: PropTypes.string || PropTypes.number,
  onClickSubmit: PropTypes.func.isRequired,
};

export default SelectDeliveryType;
