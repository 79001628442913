import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const TruncatedText = (props) => {
  const { textString, textCount, textColorType } = props;
  const originalText = textString;
  const maxLength = textCount;

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  const truncatedText =
    originalText.length > maxLength
      ? originalText.substring(0, maxLength) + ' ...'
      : originalText;

  return (
    <Button onClick={toggleText} className="text-left">
      <p
        className={`mb-0 font-Mulish font-normal text-xs capitalize ${textColorType}`}
      >
        {isExpanded ? originalText : truncatedText}
      </p>
    </Button>
  );
};

TruncatedText.propTypes = {
  textColorType: PropTypes.string,
  textString: PropTypes.string,
  textCount: PropTypes.number,
};

export default TruncatedText;
