import RestaurantHome from '../../screens/restaurant_home';
import '../../../src/index.scss';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import Experiences from '../../screens/experience/Experiences';
import ExperiencesDetails from '../../screens/experience-details/ExperiencesDetails';
import RestaurantDetails from '../../screens/restaurentDetails/RestaurantDetails';
import HealthAndsanitation from '../../screens/healthsanitation/healthsection';
import ViewMenu from '../../screens/viewmenu/ViewMenu';
import SeatingUserInfo from '../../screens/seating/SeatingUserInfo';
import MenuFilter from '../../screens/viewmenu/MenuFilter';
import TrackScreen from '../../screens/seating/TracksSreen';
import WriteReview from '../../screens/ReusableComponent/WriteReview';
import PrivacyPolicy from '../../screens/privacypolicy/PrivacyPolicy';
import CartPage from '../../screens/ordering/cartPage/CartPage';
import CheckOutPage from '../../screens/ordering/checkoutPage/CheckOutPage';
import OrderTrackScreen from '../../screens/ordering/trackScreen/OrderTrackScreen';
import CurbSideDetails from '../../screens/ordering/modals/CurbSideDetails';
import BookExperience from '../../screens/bookexperience/BookExperience';
import ExperienceCheckout from '../../screens/experience/ExperienceCheckout';
import ExpTrackScreen from '../../screens/bookexperience/ExpTrackScreen';
import Login from '../../screens/login/Login';
import SignUp from '../../screens/signup/SignUp';
import HomePage from '../../screens/homePage/HomePage';
import ProfilePage from '../../screens/profilePage/ProfilePage';
import OtpScreen from '../../screens/signup/EnterOtp';
import UserLogin from '../../screens/login/UserLogin';
import LoginOtpScreen from '../../screens/login/LoginOtpScreen';
import Apopup from '../../screens/srinath/Apopup';
import OnboardingSlider from '../../screens/signup/SplashScreens';
import WelcomePage from '../../components/singups/WelcomePage';
import UserLocation from '../../screens/userProfile/UserLocation';
import UserPreferences from '../../screens/userProfile/UserPreferences';
import AddAddress from '../../screens/userProfile/Mapsetup';
import FirstImpressions from '../../screens/profileSetup/FirstImpressions';
import ProfileSetupMenu from '../../screens/profileSetupMenu/ProfileSetupMenu';
import DietaryPreferences from '../../screens/healthPreferences/DietaryPreferences';
import FoodieDays from '../../screens/healthPreferences/FoodieDays';
import FavoriteCuisines from '../../screens/healthPreferences/FavoriteCuisines';
import Allergies from '../../screens/healthRestrictions/Allergies';
import DietaryRestrictions from '../../screens/healthRestrictions/DietaryRestricitons';
import Places from '../../screens/healthPreferences/PreferredPlaces';
import ExperiencePreference from '../../screens/experiencePreference/ExperiencePreference';
import SocialPreferences from '../../screens/socialPreference/SocialPreference';
import Restaurantmenu from '../../screens/viewmenu/RestaurantMenu';
import ExperienceNew from '../../screens/experience/experienceWeb';
import ExperienceDetailsWeb from '../../screens/experience-details/ExperienceDetailsWeb';
import CommunityMain from '../../screens/community/Community';
import ProtectedLayer from './ProtectedLayer';
import ExpRestaurantDetails from '../../screens/orderStatus/ExpRestaurantDetails';
import OrderStatus from '../../screens/orderStatus/OrderStatus';
import ExperienceBooking from '../../screens/ExperienceBooking/ExperienceBooking';
import ExpBookingConfirm from '../../screens/ExperienceBookingConfirm/ExperienceBookingConfirm';
import ExpMainCheckout from '../../screens/ExperienceCheckout/ExperienceMainCheckout';
import ExperiencePayamentSuccess from '../../screens/ExpPaymentSuccess/ExpPaymentSuccess';
import WaitlistBooking from '../../screens/seating/booking/WaitlistBooking';
import FoodPayamentSuccess from '../../screens/foodSuccessFailurePage/FoodSuccessPage';
import FoodFailurePage from '../../screens/foodSuccessFailurePage/FoodFailurePage';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../common/utility/helperFunc';
import OndcRestaurantsMain from '../../screens/ONDC/ondcRestaurntsMain';
import OndcRestaurantMenu from '../../screens/ONDC/ondcRestaurantMenu';
import PageNotFound from '../../screens/PageNotFound';
import OndcCartPage from '../../screens/ordering/cartPage/OndcCartPage';
import OndcTrackScreen from '../../screens/ordering/trackScreen/OndcTrackScreen';
import { Favorite } from '@mui/icons-material';
import ProfileFavoritesPage from '../../screens/profilePage/ProfileFavoritesPage';
import TrackOrders from '../../screens/profilePage/TrackOrders';
import SavedAddresses from '../../screens/profilePage/SavedAddresses';
import MainBytesScreen from '../../screens/BytesScreen/MainBytesScreen';
import RestaurantDetailsPage from '../../screens/restaurentDetails/RestaurantDetailsPage';
import MainHomeScreen from '../../screens/AmealioHome/MainHomeScreen';

/**
 *
 * @returns component for the specific route w.r.tp the app base url
 */
const Routes = () => {
  // const userDetails = useSelector((state) => state.user.userDetails);
  const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));

  return (
    <ReactRoutes>
      <Route
        path="/login"
        element={
          !isEmpty(storedUserDetails) &&
          !isEmpty(storedUserDetails?.accessToken) ? (
            <Navigate to="/home" replace />
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/userlogin"
        element={
          !isEmpty(storedUserDetails) &&
          !isEmpty(storedUserDetails?.accessToken) ? (
            <Navigate to="/home" replace />
          ) : (
            <UserLogin />
          )
        }
      />
      {/* <Route path="/" element={<UserLogin />} /> */}
      <Route
        path="/"
        element={
          !isEmpty(storedUserDetails) &&
          !isEmpty(storedUserDetails?.accessToken) ? (
            <Navigate to="/home" replace />
          ) : (
            <UserLogin />
          )
        }
      />
      <Route
        path="/signup"
        element={
          !isEmpty(storedUserDetails) &&
          !isEmpty(storedUserDetails?.accessToken) ? (
            <Navigate to="/home" replace />
          ) : (
            <SignUp />
          )
        }
      />
      <Route
        path="/enterotp"
        element={
          !isEmpty(storedUserDetails) &&
          !isEmpty(storedUserDetails?.accessToken) ? (
            <Navigate to="/home" replace />
          ) : (
            <OtpScreen />
          )
        }
      />
      <Route path="/enterloginotp" element={<LoginOtpScreen />} />
      <Route
        path="/splash"
        element={
          !isEmpty(storedUserDetails) &&
          !isEmpty(storedUserDetails?.accessToken) ? (
            <Navigate to="/home" replace />
          ) : (
            <OnboardingSlider />
          )
        }
      />
      {/* <Route
        path="/home"
        element={
          !isEmpty(storedUserDetails) &&
            !isEmpty(storedUserDetails?.accessToken) ? (
            <Navigate to="/welcome" replace />
          ) : (
            <HomePage />
          )
        }
      /> */}

      {/* Protected Routes */}
      <Route element={<ProtectedLayer />}>
        <Route path="/home" element={<MainHomeScreen />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/address" element={<AddAddress />} />
        <Route path="/food" element={<HomePage />} />
        <Route
          path="/Profile/favoritesPage"
          element={<ProfileFavoritesPage />}
        />
        <Route path="/Profile/track-order" element={<TrackOrders />} />
        <Route path="/Profile/saved-addresses" element={<SavedAddresses />} />

        {/* Community route */}
        <Route path="/community" element={<CommunityMain />} />

        {/* Experience */}
        <Route path="/experience" element={<ExperienceNew />} />
        <Route
          path="/restaurant/:restaurantId/experience/:experienceId"
          element={<ExperiencesDetails />}
        />
        <Route
          path="/experience/:restaurantId/:experienceId/booking"
          element={<ExperienceBooking />}
        />
        <Route
          path="/experience/:restaurantId/:experienceId/booking-confirm"
          element={<ExpBookingConfirm />}
        />
        <Route
          path="/experience/:restaurantId/:experienceId/checkout"
          element={<ExpMainCheckout />}
        />
        <Route
          path="/experience/:restaurantId/:experienceId/payment-success"
          element={<ExperiencePayamentSuccess />}
        />

        <Route
          path="/experience/:restaurantId/:experienceId/orderstatus"
          element={<OrderStatus />}
        />
        {/* // user profile and preferences // */}
        <Route path="/userlocation" element={<UserLocation />} />
        <Route path="/userpreferences" element={<UserPreferences />} />

        {/* Restaurant Routes */}
        <Route path="/restaurant/:ID" element={<RestaurantHome />} />
        <Route path="/restaurant/:ID/viewmenu" element={<ViewMenu />} />

        {/* // user profile and preferences // */}
        <Route path="/userlocation" element={<UserLocation />} />
        <Route path="/userpreferences" element={<UserPreferences />} />

        {/* Restaurant Routes */}
        <Route path="/restaurant/:ID" element={<RestaurantHome />} />
        <Route path="/restaurant/:ID/viewmenu" element={<ViewMenu />} />

        {/* <Route
          path="/restaurant/:ID/restaurantdetails"
          element={<RestaurantDetails />}
        /> */}

        <Route
          path="/restaurant/:ID/restaurant/details"
          element={<RestaurantDetailsPage />}
        />

        <Route
          path="/restaurant/:ID/viewmenu/menufilter"
          element={<MenuFilter />}
        />
        <Route
          path="/restaurant/:ID/healthandsanitation"
          element={<HealthAndsanitation />}
        />

        {/* Experience Routes */}
        <Route
          path="/restaurant/:restaurantId/experience"
          element={<Experiences />}
        />
        <Route
          path="/restaurant/:restaurantId/experienceweb/:experienceId"
          element={<ExperienceDetailsWeb />}
        />
        <Route
          path="/restaurant/:restaurantId/experience/:experienceId/bookexp"
          element={<BookExperience />}
        />
        <Route
          path="/restaurant/:restaurantId/experience/:experienceId/paymentSummary"
          element={<ExperienceCheckout />}
        />
        <Route
          path="/restaurant/:restaurantId/trackexp/:trackId"
          element={<ExpTrackScreen />}
        />

        {/* Seating Routes */}
        <Route
          path="/restaurant/:restaurantId/seating"
          element={<SeatingUserInfo />}
        />
        <Route
          path="/restaurant/:restaurantId/reservation"
          element={<SeatingUserInfo />}
        />
        <Route
          path="/restaurant/:restaurantId/seating/writereview"
          element={<WriteReview />}
        />
        <Route
          path="/restaurant/:restaurantId/seating/track/:dinerId"
          element={<TrackScreen />}
        />
        <Route
          path="/restaurant/:restaurantId/seating/waitlist"
          element={<WaitlistBooking />}
        />

        {/* ordering Routes */}
        <Route path="/restaurant/:ID/food/menu" element={<Restaurantmenu />} />
        <Route path="/restaurant/:ID/food/cartpage" element={<CartPage />} />
        <Route
          path="/restaurant/:ID/food/checkout/:order_id/payment-success"
          element={<FoodPayamentSuccess />}
        />
        <Route
          path="/restaurant/:ID/food/checkout/:order_id/payment-failure"
          element={<FoodFailurePage />}
        />
        <Route
          path="/restaurant/:ID/food/checkout/:order_id"
          element={<CheckOutPage />}
        />
        <Route
          path="/restaurant/:ID/food/ordertrack/:order_id"
          element={<OrderTrackScreen />}
        />
        <Route
          path="/restaurant/:ID/curbside-details"
          element={<CurbSideDetails />}
        />

        {/* ONDC */}
        <Route path="/ondc" element={<OndcRestaurantsMain />} />
        <Route path="/ondc/:ID/menu" element={<OndcRestaurantMenu />} />
        <Route path="/ondc/:ID/ondccart" element={<OndcCartPage />} />
        <Route path="/ondc/:ID/checkout/:order_id" element={<CheckOutPage />} />
        <Route
          path="/ondc/:ID/ondctrack/:order_id"
          element={<OndcTrackScreen />}
        />

        {/* BYTES  */}
        <Route path="/bytes" element={<MainBytesScreen />} />
      </Route>

      {/* privacy-policy */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/profile" element={<FirstImpressions />} />
      <Route path="/profileSetupMenu" element={<ProfileSetupMenu />} />
      <Route path="/dietarypreference" element={<DietaryPreferences />} />
      <Route path="/foodiedays" element={<FoodieDays />} />
      <Route path="/cuisines" element={<FavoriteCuisines />} />
      <Route path="/allergies" element={<Allergies />} />
      <Route path="/dietaryRestrictions" element={<DietaryRestrictions />} />
      <Route path="/places" element={<Places />} />
      <Route path="/expPreference" element={<ExperiencePreference />} />
      <Route path="/social" element={<SocialPreferences />} />
      <Route path="*" element={<PageNotFound />} />
    </ReactRoutes>
  );
};

export default Routes;
