import React, { useState, useCallback, useEffect } from 'react';
import HomePageSearchBar from '../../components/SearchBar/HomeSearchBar';
import HomeFilter from './HomeFilter';
import RestaurantCard from '../ReusableComponent/restaurantCard';
import ExperienceBanner from './ExperienceBanner';
import ReferEarnBanner from '../ReusableComponent/ReferAndEarn';
import ScreenTemplate from '../../components/ScreenTemplate';
import MainSideBar from '../../components/MainsideBar';
import MainHeader from '../../components/headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import useLocation from '../../common/hooks/useLocation';
import useApi from '../../common/api/hooks/useApi';
import { GOOGLE_API_KEY } from '../../config/keys';
import { URL } from '../../common/api/urls';
import OrderingServices from '../../components/food/OrderingServices';
import { Col, Row, Image } from 'react-bootstrap';
import Cuisine from './cuisine';
import { useDispatch, useSelector } from 'react-redux';
import useNotificationsApi from '../../api/notificationsApis';
import WithAmealioLove from '../../components/reusable/WithAmealioLove';

const ALL_CATEGORY_KEYS = {
  RESTAURANT_TYPE: '5f562c9094ac7fdf47ea1d0a',
  FOOD_TYPE: '5f562c9094ac7fdf47ea1d1d',
  LIQUORS: '5f562c9094ac7fdf47ea1d1c',
};

const HomePage = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);

  //console.log("coordinates", coordinates);
  const { handleRequest } = useApi();
  const [restaurants, setRestaurants] = useState([]);
  const [subcategories, setSubcategories] = useState({
    RESTAURANT_TYPE: [],
    FOOD_TYPE: [],
    LIQUORS: [],
  });

  const [selectedFilters, setSelectedFilters] = useState({
    RESTAURANT_TYPE: [],
    FOOD_TYPE: [],
    LIQUORS: [],
    SORT_BY: [],
    subscribedServices: [],
  });
  const [favorites, setFavorites] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.userDetails);
  const { fetchNotifications } = useNotificationsApi();
  useEffect(() => {
    if (userData) {
      fetchNotifications(userData?._id, dispatch);
    }
  }, []);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const fetchRestaurants = useCallback(
    //async (lat, long, filters, sortBy = 0) => {
    async (lat, long, filters, sortBy = 0, pageNumber = 1, reset = false) => {
      const payload = {
        lat,
        long,
        subscribedServices: [filters.subscribedServices],
        popularSelect: 0,
        sortBy,
        costForTwo: { minPrice: 0, maxPrice: 10000 },
        foodType: filters.FOOD_TYPE,
        restaurantType: filters.RESTAURANT_TYPE,
        liquorAvailable: filters.LIQUORS,
        distance: 10,
      };
      try {
        setIsLoading(true);
        const response = await handleRequest(
          'post',
          `/${URL.LISTRESTAURANT}?page=${pageNumber}&limit=10`,
          payload
        );

        if (response.data && response.data.length > 0) {
          setRestaurants((prev) =>
            reset ? response.data : [...prev, ...response.data]
          );
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleRequest]
  );

  const handleSelectedRestaurant = ([selectedRestaurant]) => {
    setRestaurants([selectedRestaurant]);
  };

  // FAVORITE BUTTON
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails?.favourites) {
      setFavorites(userDetails.favourites);
    }
  }, []);
  const toggleFavorite = async (restaurantId) => {
    const updatedFavorites = favorites.includes(restaurantId)
      ? favorites.filter((id) => id !== restaurantId)
      : [...favorites, restaurantId];

    setFavorites(updatedFavorites);

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
      userDetails.favourites = updatedFavorites;
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
    }
    let formData = {
      restaurant_id: restaurantId,
    };
    const isFav = userDetails.favourites.includes(restaurantId);
    const favType = !isFav ? 'remove' : 'add';
    await handleRequest('post', `${URL.FAVORITES}?${favType}=true`, formData, {
      headers: { Authorization: userDetails.accessToken },
      successMessage: 'Successfully updated favorites',
      errorMessage: 'Favorites update failed',
      onSuccess: async (response) => {
        // fetch all communities from this location
      },
      onError: (error) => {},
    });
  };

  useEffect(() => {
    if (location.lat && location.lng) {
      //console.log("Location updated:", location);
      setPage(1);
      setHasMoreData(true);
      setRestaurants([]);
      fetchRestaurants(
        location.lat,
        location.lng,
        selectedFilters,
        selectedFilters.SORT_BY[0] || 0,
        1,
        true
      );
    }
  }, [coordinates, location, selectedFilters, fetchRestaurants]);

  const handleFilterChange = (category, values) => {
    setSelectedFilters((prev) => ({ ...prev, [category]: values }));
  };

  useEffect(() => {
    sessionStorage.removeItem('cartItems');
    sessionStorage.removeItem('cartOrderType');
    sessionStorage.removeItem('defaultOrderType');
    sessionStorage.removeItem('orderPostData');
    sessionStorage.removeItem('order_details');
    sessionStorage.removeItem('defaultOrderType');
  }, []);

  const handleApplyFilters = () => {
    const sortBy = selectedFilters.SORT_BY[0] || 0;
    setPage(1);
    setHasMoreData(true);
    setRestaurants([]);
    fetchRestaurants(
      location.lat,
      location.lng,
      selectedFilters,
      //selectedFilters.SORT_BY || 0,
      sortBy,
      1,
      true
    );
  };
  const handleSortChange = (sortByEnum) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      SORT_BY: [sortByEnum], // Ensure only one sorting option is selected
    }));
  };
  const handleScroll = useCallback(() => {
    const scrollable = document.documentElement;
    if (
      scrollable.offsetHeight + scrollable.scrollTop >=
      scrollable.scrollHeight
    ) {
      if (!isLoading && hasMoreData && location.lat && location.lng) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  }, [isLoading, hasMoreData]);

  useEffect(() => {
    if (page > 1) {
      fetchRestaurants(
        location.lat,
        location.lng,
        selectedFilters,
        selectedFilters.SORT_BY[0] || 0,
        page
      );
    }
  }, [page, location, selectedFilters, fetchRestaurants]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, hasMoreData]);

  const content = (
    <>
      <div style={{ padding: '10px', fontFamily: 'Arial, sans-serif' }}>
        <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'red' }}>
          Food
        </div>
        <div style={{ fontSize: '14px', color: '#000', marginTop: '5px' }}>
          Explore
        </div>
      </div>
      <OrderingServices
        title={
          <span className="text-black/200">What would you like to do?</span>
        }
        hanldeClick={handleApplyFilters}
        handleFilterChang={handleFilterChange}
        selectedFilters={selectedFilters}
      />
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <HomeFilter
          subcategories={subcategories}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          onApplyFilters={handleApplyFilters}
          onSortChange={handleSortChange}
        />
      </div>
      <HomePageSearchBar
        setRestaurants={handleSelectedRestaurant}
        coordinates={location}
      />
      {/* <ExperienceBanner /> */}
      {/* <VegNonvegFilter /> */}
      {/* <ExperienceBanner /> */}
      {/* <div>Cuisines You’ll Like</div> */}
      <Cuisine />
      <div
        className={`d-flex ${isDesktop ? 'flex-row' : 'flex-column'} ${
          isDesktop ? '' : 'justify-content-center align-items-center'
        }`}
        style={{
          gap: '20px',
          marginTop: '15px',
        }}
      >
        <div>
          <div className="f22-700 ml-1 mb-4">Neighboring Restaurants</div>
          <RestaurantCard
            restaurants={restaurants}
            favorites={favorites} // Pass favorites to RestaurantCard
            onToggleFavorite={toggleFavorite} // Pass toggleFavorite function
          />
          {isLoading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>

      {/* <HomeImageSlider /> */}
      {/* {isDesktop ? <RestaurantListWeb /> : <RestaurantListMobile />} */}

      {/* <ExperienceBanner /> */}
      {/* <ReferEarnBanner /> */}
      <WithAmealioLove />
    </>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
};

export default HomePage;
