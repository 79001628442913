// src/components/GoogleSignIn.js
import React from 'react';
import app from '../../firebaseConfig';
import { Image } from 'react-bootstrap';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const GoogleSignIn = ({ onSuccess }) => {
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // This gives you a Google Access Token. You can use it to access the Google API if needed.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info
      const user = result.user;
      onSuccess(user, true);
      console.log('User signed in:', user, credential, token);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  return (
    <Image
      src={require('../../assets/images/socialmedia/google.png')}
      width={'44px'}
      className="grey-round-border mr-2"
      style={{ maxWidth: '100%' }}
      onClick={handleGoogleSignIn}
    />
  );
};

export default GoogleSignIn;
