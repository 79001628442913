import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useApi from "../../common/api/hooks/useApi";
import { URL } from "../../common/api/urls";
export const ExperienceGridWeb = ({ data }) => {
  // console.log("🚀 ~ ExperienceGridWeb ~ data:", data);
  //console.log("ExperienceGrid", data);
  const navigate = useNavigate();
  const { handleRequest } = useApi();
  /* Fav Icon Functionality */
  const [favorites, setFavorites] = useState([]);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
    if (userDetails?.eventFav) {
      setFavorites(userDetails.eventFav);
    }
  }, []);
  const [showFullDescription, setShowFullDescription] = useState(false);
  // Function to toggle favorite status
  const toggleFavorite = (categoryId) => {
    const updatedFavorites = favorites.includes(categoryId)
      ? favorites.filter((id) => id !== categoryId)
      : [...favorites, categoryId];

    setFavorites(updatedFavorites);

    // Update localStorage
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      userDetails.eventFav = updatedFavorites;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    }
    //console.log("Favorite updated for experience:", categoryId);
  };
  const isFavorite = favorites.includes(data?.category);
  return (
    <div className="f20-500 h-100 w-100 mt-4 position-relative  px-2 px-md-0">
      <div
        className="experience-card overlay shadow-sm"
        style={{
          borderRadius: "19px",
          background: `url('${data?.photo || "url"}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "300px",
        }}
      ></div>

      {/* Experience Details */}
      <div className="experience-details d-flex flex-column p-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="f24-700">{data?.title || "Experience"}</div>
          <div className="d-flex align-items-center gap-2">
            <Image
              src={require("../../assets/images/ExperiencePageAssets/heartexpcard.png")}
              alt="Heart"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => toggleFavorite(data?.category)}
            />
            <span className="ms-1 f14-400">{data?.explike || "0"}</span>
            <Image
              src={require("../../assets/images/ExperiencePageAssets/eye.png")}
              alt="Views"
              style={{ width: 24, height: 16 }}
            />
            <span className="ms-1 f14-400">
              {data?.expview ? data.expview : "0"}
            </span>
          </div>
        </div>

        {/* Second Row: Description & Explore Button */}
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div
            className="f14-400 "
            style={{
              maxWidth: "55%",
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            {data?.description}
          </div>
          <button
            className="btn  py-2 text-white f16-500"
            style={{
              borderRadius: "10px",
              backgroundColor: "#F85032",
              padding: "4px 8px",
            }}
            onClick={() => {
              sessionStorage.setItem("experienceData", JSON.stringify(data));
              navigate(
                `/restaurant/${data?.restaurantIds}/experienceweb/${data?._id}`
              );
            }}
          >
            Explore Now
          </button>
        </div>
      </div>
    </div>
  );
};
