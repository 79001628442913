import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { Image } from 'react-bootstrap';

function AppleSignIn() {
  const handleSuccess = (response) => {
    console.log('Apple Sign-In Success:', response);
    // Process the response and send the token to your backend for verification
  };

  const handleError = (error) => {
    console.error('Apple Sign-In Error:', error);
  };

  return (
    <AppleSignin
      authOptions={{
        clientId:
          '859203423831-0lhu7t0q8obqbm8gl8negaf3debd2ufo.apps.googleusercontent.com',
        redirectURI: 'http://localhost:3000/auth/apple/callback',
        scope: 'email name',
        responseMode: 'form_post',
        usePopup: true, // optional, defaults to false
      }}
      uiType="dark"
      onSuccess={handleSuccess}
      onError={handleError}
      render={(props) => (
        // Custom UI component
        <div onClick={props.onClick} style={{ cursor: 'pointer' }}>
          <Image
            src={require('../../assets/images/socialmedia/apple.png')}
            width={44}
            className="grey-round-border mx-2"
            style={{ maxWidth: '100%' }}
            alt="Apple Sign-In"
          />
        </div>
      )}
    />
  );
}

export default AppleSignIn;
