import React, { useEffect, useState } from 'react';
import MainSideBar from '../../components/MainsideBar';
import MainHeader from '../../components/headers/Mainheader';
import ScreenTemplate from '../../components/ScreenTemplate';
import useLocation from '../../common/hooks/useLocation';
import { GOOGLE_API_KEY } from '../../config/keys';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import { SearchWithFilterAndSort } from './SearchWithFilterAndSort';
import { Col, Row, Image } from 'react-bootstrap';
import { ExperienceGridWeb } from './ExperienceGridWeb';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useApi from '../../common/api/hooks/useApi';
import { HorizontalScrollMenu } from './HorizontalScrollMenu';
import { useDispatch, useSelector } from 'react-redux';
import useNotificationsApi from '../../api/notificationsApis';
import Typography from '@mui/material/Typography';
import WithAmealioLove from '../../components/reusable/WithAmealioLove';

const ExperienceNew = () => {
  const userAuth = JSON.parse(sessionStorage.getItem('userInformation'));

  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  // console.log("currentLocation:", currentLocation);
  // console.log("🚀:", coordinates);
  const [search, setSearch] = useState('');

  const [activeExperience, setActiveExperience] = useState('Events');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [selectedCelebration, setSelectedCelebration] = useState('');
  const [selectedExperience, setSelectedExperience] = useState('Events');
  const [sourceExperiences, setSourceExperiences] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //const [dateFilter, setDateFilter] = useState(moment());
  const [dateFilter, setDateFilter] = useState(moment().format('YYYY-MM-DD'));

  const handleExperienceSelect = (selectedType) => {
    //console.log("🟢 Selected Experience Type:", selectedType);
    setSelectedExperience(selectedType);
    setActiveExperience(selectedType);

    const filteredExperiences = sourceExperiences.filter(
      (item) => item.category === selectedType
    );
    // console.log("🔵 Filtered Experiences:", filteredExperiences);
    setExperiences(filteredExperiences);
  };
  const handleCelebrationSelect = (selected) => {
    // console.log("🟠 Selected Celebration:", selected);
    setSelectedCelebration(selected);
  };

  // API integration
  const [cancel, responseData, apiError, loaded, reset, executeAPI] =
    useAxios();
  const [
    cancelQL,
    responseDataQL,
    errorQL,
    loadedQL,
    resetQL,
    executeQuickLinksAPI,
  ] = useAxios();
  const [
    cancelExpiApi,
    useExperiences,
    expApiError,
    ExperiencesLoading,
    resetExp,
    executeUserExpApi,
  ] = useAxios();

  const { handleRequest, isDataLoading } = useApi();

  useEffect(() => {
    if (error) {
      console.error('API Error:', error);
    }
  }, [error]);

  useEffect(() => {
    if (experiences.length > 0) {
      // console.log("Experiences Data:", JSON.stringify(experiences, null, 2));
    }
  }, [experiences]);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.userDetails);
  const { fetchNotifications } = useNotificationsApi();
  useEffect(() => {
    if (userData) {
      fetchNotifications(userData?._id, dispatch);
    }
    executeQuickLinksAPI(`${URL.QUICK_LINKS}?type=QUICK_LINKS`, 'GET');
  }, []);
  useEffect(() => {
    if (loadedQL && responseDataQL) {
      //console.log("-<>- Quick Links API Response:", responseDataQL);
      setCategories(responseDataQL?.categories || []);
      setSubCategories(responseDataQL?.subCategories || []);
      setFilteredSubCategories(responseDataQL?.subCategories || []);
    }
  }, [loadedQL, responseDataQL]);
  useEffect(() => {
    (async () => {
      if (location.lat) {
        // `${URL.EXPERIENCE}?long=${location.lng}&lat=${location.lat}`,
        await handleRequest(
          'get',
          `${URL.EXPERIENCE}?pageType=subCategory&long=${location.lng}&lat=${location.lat}&dateFilter=${dateFilter}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              version: 'V1', // Adding the header as per the screenshot
            },
            onSuccess: async (data) => {
              //console.log("🔵 API Response Data:", data);
              if (data.length > 0) {
                // setExperiences(data);
                //console.log("🔵 Experience Item:", data[0]);
                const extractedExperiences = data
                  .map((item) => ({
                    title: item.title,
                    photo: item.photo,
                    description: item.description,
                    category: item.category,
                    subCategory: item.subCategoryId,
                    experienceIds: item.experience,
                    restaurantId: item.restaurants,
                    explike: item.expLikes,
                    expview: item.expViews,
                  }))
                  .sort((a, b) => a.title.localeCompare(b.title));
                setSourceExperiences(extractedExperiences);
                setExperiences(extractedExperiences);
              } else {
                setSourceExperiences([]);
                setExperiences([]);
              }
              setError(null);
            },
            onError: (error) => {
              console.error(
                'API Error:',
                error.response ? error.response.data : error
              );
              setError('Failed to load experiences. Please try again later.');
            },
          }
        );
        setLoading(false);
      }
    })();
  }, [location, dateFilter]);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    // console.log("Search input value:", searchValue);
    // console.log("Experiences before filtering:", experiences);

    if (searchValue && experiences.length) {
      const filteredData = experiences.filter((item) => {
        //console.log("Checking item:", item);
        return item.title?.trim().toLowerCase().includes(searchValue);
      });
      //console.log("Filtered experiences:", filteredData);
      setExperiences(filteredData);
    } else {
      setExperiences(sourceExperiences);
    }
  };

  const handleCategorySelect = (selected) => {
    // console.log("🟢 Selected Category:", selected);
    setSelectedCategory(selected);

    if (!selected) {
      setFilteredSubCategories(subCategories);
      setExperiences(sourceExperiences); // Display all experiences
    } else {
      // Filter subcategories and experiences by the selected category
      const filteredSubCategories = subCategories.filter(
        (item) => item.selected_category === selected
      );
      // console.log("🟠 Filtered Subcategories:", filteredSubCategories);
      setFilteredSubCategories(filteredSubCategories);

      const filteredExperiences = sourceExperiences.filter(
        (item) => item.category === selected
      );
      // console.log("🔵 Filtered Experiences by Category:", filteredExperiences);
      setExperiences(filteredExperiences);
    }
  };

  // Handle subcategory selection and filtering
  const handleSubCategorySelect = (selected) => {
    //console.log("==> Selected Subcategory:", selected);
    setSelectedSubCategory(selected);
    // Filter experiences by subcategory
    const filteredExperiences = sourceExperiences.filter(
      (item) => item.subCategory === selected
    );
    //console.log(
    //   "-<>- Filtered Experiences by Subcategory:",
    //   filteredExperiences
    // ); // Log filtered experiences by subcategory
    setExperiences(filteredExperiences);
  };
  const handleChange = (newValue) => {
    setDateFilter(moment(newValue).format('YYYY-MM-DD'));
  };
  const content = (
    <>
      {/* Header Section */}
      <div style={{ marginBottom: '2px' }}>
        <span style={{ color: '#EE3A23', fontSize: '34px', fontWeight: '800' }}>
          Special Experiences
        </span>
        <br />
        <span className="font-bold">
          Create your own events & dining experiences
        </span>
      </div>
      <div
        className="search-filter-container"
        style={{ padding: '8px', marginBottom: '8px' }}
      >
        <SearchWithFilterAndSort
          onChange={handleSearchChange}
          search={search}
          placeHolderText={'Search for experiences'}
          micIcon={true}
        />
      </div>
      <div className="mt-3">
        <h2 className="f28-700">Types of Experiences</h2>
      </div>
      <HorizontalScrollMenu
        data={categories}
        onClick={handleCategorySelect}
        selected={selectedCategory}
      />
      <div className=" mt-3">
        <h2 className="f28-700 mt-4">Celebration</h2>
      </div>
      <HorizontalScrollMenu
        data={filteredSubCategories}
        onClick={handleSubCategorySelect}
        selected={selectedSubCategory}
      />

      <div>
        <div className="f28-700 py-2 mt-3">
          Great Experiences - Curated for the Occassion
        </div>

        {loading ? (
          <p>...</p>
        ) : error ? (
          <p>{error}</p>
        ) : experiences.length > 0 ? (
          <Row>
            {experiences.map((experience) => (
              <Col key={experience.title} md={6} className="mb-4">
                {/* <ExperienceGrid data={experience} /> */}
                <ExperienceGridWeb data={experience} />
              </Col>
            ))}
          </Row>
        ) : (
          <p>No experiences available.</p>
        )}
      </div>
      <WithAmealioLove />
    </>
  );
  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
};

export default ExperienceNew;
