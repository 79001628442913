import React from 'react'

function DeliveryAddress(props) {
    function address () {
    }
  return (
    <div className="w-full max-w-xl p-4 md:p-6">
      <h1 className="text-2xl font-bold mb-6">Delivery Address</h1>
      
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3">
        <div className="flex items-center">
          <div className="sm:w-12 sm:h-12 sm:mr-2 mb-3 sm:mb-0 rounded-full bg-gray-100 flex items-center justify-center mr-7 w-9 h-9">
            <img
              src={require("../../assets/images/IconsandImages/AddressHouseLogo.png")}
              alt="House"
              className="w-5 h-5 sm:w-7 sm:h-7"
            />
          </div>
          <div>
            <h2 className="text-lg font-bold sm:text-lg">{props.address}</h2>
            <p className="text-gray-500 text-xs sm:text-sm">{props.location}</p>
          </div>
          {(props.default === true) && (
          <div className="bg-amber-500 text-black font-bold py-1 px-3 rounded-full mr-4 text-xs sm:text-sm ml-2 mb-10">
            DEFAULT
          </div>
          )}
          <button onClick={address()}>
            <div className="sm:w-12 sm:h-12 rounded-full bg-gray-100 flex items-center justify-center  w-9 h-9 ml-3">
            <img
              src={require("../../assets/images/IconsandImages/EditAddressIcon.png")}
              alt="Edit"
              className="w-5 h-5 sm:w-7 sm:h-7"
            />
          </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeliveryAddress