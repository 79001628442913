import {useState} from 'react'
import Popper from '@mui/material/Popper';
import language from "../../assets/images/IconsandImages/language.png";
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { assets } from "../../assets/assets";
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import "../../../src/assets/scss/login.scss";
import { languages } from "../../common/constants/Global.js";  

function LanguagePicker() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState("English");

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
            <Typography
              variant="body1"
              color="initial"
              sx={{ display: { xs: "none", sm: "none", md: "inline" }, fontWeight: "bold", fontFamily: "Mulish"}}
              className="font-extrabold font-[mulish]">
              Language{" "}
            <button aria-describedby={id} type="button" onClick={handleClick}>
            <span
                className="f18-700"
                style={{ color: assets.colors.primary, fontWeight: "bold", fontFamily: "Mulish" }}>
                {" "}
                {selectedLanguage.slice(0,2)}
            </span>
            </button>
            <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom"
                className='language-popper'>
                <Box sx={{display : 'flex', flexDirection : 'row', alignItems : 'start'}}>
                    <img src = {language} alt="Language Icon" className='mt-1 mr-2'/>
                    <Typography sx={{fontSize : '16px'}} className="f16-500">Choose App Display language</Typography>
                </Box>
                <Grid container spacing={2} mt={0.5} justifyContent="center">
                  {languages.map((lang) => (
                  <Grid item key={lang}>
                  <Button
                    onClick={() => setSelectedLanguage("English")} // Change this to lang when the functionality is implemented and prefered lang is stored in selectedLanguage
                    className={`language-button ${selectedLanguage === lang ? 'selected' : ''}`}
                  >
                    {lang}
                  </Button>
                </Grid>
                  ))}
                </Grid>
              </Popper>
            </Typography>
    </div>
  )
}

export default LanguagePicker;
