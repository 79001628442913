import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScreenTemplate from '../../components/ScreenTemplate';
import Mainheader from '../../components/headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import { useNavigate } from 'react-router-dom';
import useApi from '../../common/api/hooks/useApi';
import useLocation from '../../common/hooks/useLocation';
import { GOOGLE_API_KEY } from '../../config/keys';
import MainSideBar from '../../components/MainsideBar';
import ReelsComponent from './ReelsComponent';
import { useSelector } from 'react-redux';
import { URL } from '../../common/api/urls';
import { isEmpty } from '../../common/utility/helperFunc';
import { useSnackbar } from 'notistack';
import { CALL_TO_ACTION_ENUMS } from '../../common/enums/GlobalEnums';
import Loader from '../../components/loader';
import OffersModal from '../OffersScreen/OffersModal';

const MainBytesScreen = (props) => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  const { handleRequest, loading } = useApi();

  const userAuth = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userDetails
  );

  const [reels, setReels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [totalReelsCount, setTotalReelsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [currentReelIndex, setCurrentReelIndex] = useState(0);
  const touchStartY = useRef(null);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [muted, setMuted] = useState(true);
  const [isOpenOffersModal, setIsOpenOffersModal] = useState(false);
  const [currentOfferData, setCurrentOfferData] = useState({});
  const [currentReelsInfo, setCurrentReelsInfo] = useState({});

  const onClickOpenOfferModal = () => {
    setIsOpenOffersModal(false);
  };

  const reelContainerRef = useRef(null);

  /***** @DESC GET REELS DATA *****/
  const fetchReels = useCallback(async (page, parsedLocation) => {
    setIsLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      authorization: userAuth?.accessToken,
      version: 'v1',
    };
    await handleRequest(
      'get',
      `${URL.GET_REELS}?page=${page}&limit=${limit}&lat=${parsedLocation?.lat}&long=${parsedLocation?.lng}`,
      null,
      {
        headers: headers,
        onSuccess: async (response) => {
          if (page === 1) {
            setReels(response?.records);
            setTotalReelsCount(response?.paginationInfo?.totalCount);
          } else {
            let newData = response?.records;
            setReels((prevReels) => [...prevReels, ...newData]);
            setTotalReelsCount(response?.paginationInfo?.totalCount);
          }
          setIsLoading(false);
        },
        onError: (error) => {
          showNotification(
            `${error?.response ? error?.response?.data : error}`,
            'error'
          );
          setIsLoading(false);
        },
      }
    );
    // try {
    //   const response = await axios.get(
    //     `https://uatmerchantapi.amealio.com/user/reels?page=1&limit=100&lat=18.518430099284075&long=73.93499492590843`
    //   );
    //   if (page === 1) {
    //     setReels(response.data);
    //   } else {
    //     setReels((prevReels) => [...prevReels, ...response.data]);
    //   }
    // } catch (error) {
    //   console.error("Error fetching reels:", error);
    // } finally {
    //   setIsLoading(false);
    // }
  }, []);

  useEffect(() => {
    const storedLocation = localStorage.getItem('userLocation');
    if (storedLocation) {
      const parsedLocation = JSON.parse(storedLocation);
      if (currentPage !== 1) {
        fetchReels(currentPage, parsedLocation);
      }
    }
  }, [fetchReels, currentPage]);

  useEffect(() => {
    const storedLocation = localStorage.getItem('userLocation');
    if (storedLocation) {
      const parsedLocation = JSON.parse(storedLocation);
      if (
        location?.lat === parsedLocation?.lat &&
        location?.lng === parsedLocation?.lng
      ) {
        touchStartY.current = null;
        setCurrentReelIndex(0);
        setCurrentPage(1);
        setReels([]);
        fetchReels(1, parsedLocation);
      }
    }
  }, [location]);

  /***** @DESC REELS REFRESH HANDLER *****/
  const onClickHandleReelsRefresh = () => {
    const storedLocation = localStorage.getItem('userLocation');
    if (storedLocation) {
      const parsedLocation = JSON.parse(storedLocation);
      if (
        location?.lat === parsedLocation?.lat &&
        location?.lng === parsedLocation?.lng
      ) {
        touchStartY.current = null;
        setCurrentReelIndex(0);
        setCurrentPage(1);
        setReels([]);
        fetchReels(1, parsedLocation);
      }
    }
  };

  /***** @DESC USER HANDLE TOUCH START HANDLER *****/
  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
  };

  /***** @DESC USER HANDLE TOUCH MOVE HANDLER *****/
  const handleTouchMove = (e) => {
    if (!touchStartY.current) return;
    const touchEndY = e.touches[0].clientY;
    const diffY = touchStartY.current - touchEndY;
    if (Math.abs(diffY) > 50) {
      if (diffY > 0) {
        if (currentReelIndex < reels?.length - 1) {
          setCurrentReelIndex((prevIndex) => prevIndex + 1);
        } else if (
          !isLoading &&
          reels?.length > 0 &&
          reels?.length % 10 === 0
        ) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      } else {
        if (currentReelIndex > 0) {
          setCurrentReelIndex((prevIndex) => prevIndex - 1);
        }
      }
      touchStartY.current = null;
    }
  };

  /***** @DESC USER HANDLE TOUCH END HANDLER *****/
  const handleTouchEnd = () => {
    touchStartY.current = null;
  };

  useEffect(() => {
    !isEmpty(reels) &&
      reels.forEach((reel, index) => {
        if (index === currentReelIndex) {
        }
      });
  }, [currentReelIndex, reels]);

  /***** @DESC USER VIDEO ERROR HANDLER *****/
  const handleVideoError = (error) => {
    console.error('Video playback error:', error);
    if (error.nativeEvent) {
      console.error('Native video error:', error.nativeEvent);
      console.error('Video error code', error.nativeEvent.target.error.code);
    }
  };

  /**** @DESC FULL SCREEN ACTION ****/
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (reelContainerRef.current.requestFullscreen) {
        reelContainerRef.current.requestFullscreen();
      } else if (reelContainerRef.current.mozRequestFullScreen) {
        reelContainerRef.current.mozRequestFullScreen();
      } else if (reelContainerRef.current.webkitRequestFullscreen) {
        reelContainerRef.current.webkitRequestFullscreen();
      } else if (reelContainerRef.current.msRequestFullscreen) {
        reelContainerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  /**** @DESC TOGGLE MUTE/UNMUTE ACTION ****/
  const handleMuteUnmute = () => {
    setMuted(!muted);
  };

  /**** @DESC ONCLICK PLAY PAUSE ACTION ****/
  const onPlayPausedVideo = () => {
    setIsPlaying(!isPlaying);
  };

  /***** @DESC USER SLIDE UP HANDLER *****/
  const slideUp = () => {
    if (currentReelIndex < reels?.length - 1) {
      setCurrentReelIndex((prevIndex) => prevIndex + 1);
    } else if (!isLoading && reels?.length > 0 && reels?.length % 10 === 0) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  /***** @DESC USER SLIDE DOWN HANDLER *****/
  const slideDown = () => {
    if (currentReelIndex > 0) {
      setCurrentReelIndex((prevIndex) => prevIndex - 1);
    }
  };

  /***** @DESC USER FAVORITE ONCLICK HANDLER *****/
  const onClickFavActionClick = (favData, index) => () => {
    const retrievedItem = localStorage.getItem('userDetails');
    if (retrievedItem) {
      const userDetailsInfo = JSON.parse(retrievedItem);
      if (
        !isEmpty(userDetailsInfo) &&
        !isEmpty(userDetailsInfo?.accessToken)
        //  ||
        // (!isEmpty(guestInfoValue) && guestInfoValue?.userType !== "GUEST")
      ) {
        const indexOne = reels.findIndex((obj) => obj._id === favData?._id);
        const updatedReelsData = [...reels];
        const reel = updatedReelsData[indexOne];
        reel.likes = reel?.isLiked ? reel.likes - 1 : reel.likes + 1;
        reel.isLiked = !reel?.isLiked;
        setCurrentReelsInfo(reel);
        setReels(updatedReelsData);
        setIsLoading(false);
        const formData = {
          like: true,
        };
        patchReelsApi(favData?._id, formData);
      } else {
        // LoginModal();
      }
    }
    // const guestInfo: any = await getGuestUserInfoStorage();
    // const guestInfoValue = JSON.parse(guestInfo);
    // const jsonValue = JSON.parse(retrievedItem);
  };

  /***** @DESC USER FAVORITE UPDATE CALL *****/
  const patchReelsApi = async (reelsId, formData) => {
    setIsLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      authorization: userAuth?.accessToken,
    };
    await handleRequest(
      'patch',
      `${URL.GET_REELS}/${reelsId}`,
      JSON.stringify(formData),
      {
        headers: headers,
        onSuccess: async (response) => {
          setIsLoading(false);
        },
        onError: (error) => {
          showNotification(
            `${error?.response ? error?.response?.data : error}`,
            'error'
          );
          setIsLoading(false);
        },
      }
    );
  };

  /***** @DESC USER BUTTON HANDLER BOOK, VIEW, ITEM, OFFER *****/
  const onHandleBookViewClick = (item, index) => () => {
    const retrievedItem = localStorage.getItem('userDetails');
    if (retrievedItem) {
      const userDetailsInfo = JSON.parse(retrievedItem);
      if (
        !isEmpty(userDetailsInfo) &&
        !isEmpty(userDetailsInfo?.accessToken)
        //  ||
        // (!isEmpty(guestInfoValue) && guestInfoValue?.userType !== "GUEST")
      ) {
        if (
          CALL_TO_ACTION_ENUMS[0]?.toString() === item?.callToAction?.toString()
        ) {
          onClickRestaurantPage(item, index);
        } else if (
          CALL_TO_ACTION_ENUMS[1]?.toString() === item?.callToAction?.toString()
        ) {
          onItemBookNow(item);
        } else if (
          CALL_TO_ACTION_ENUMS[2]?.toString() === item?.callToAction?.toString()
        ) {
          onExperienceBookNow(item);
        } else {
          onClickGetOffers(item);
        }
      } else {
        // LoginModal();
      }
    }
  };

  /***** @DESC USER CLICK ON ITEM MEN *****/
  const onItemBookNow = (item) => {
    console.log('itemInfo===>352', item);
    setIsPageLoader(true);
    const timer = setTimeout(() => {
      navigate(`/restaurant/${item.restaurant._id}/food/menu`);
      setIsPageLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  /***** @DESC USER CLICK ON BOOK EXPERIENCE *****/
  const onExperienceBookNow = (item) => {
    setIsPageLoader(true);
    const timer = setTimeout(() => {
      navigate(
        `/experience/${item.restaurant._id}/${item.experience._id}/booking`
      );
      setIsPageLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  /***** @DESC USER CLICK ON VIEW RESTAURANT *****/
  const onClickRestaurantPage = (item, index) => {
   
    setIsPageLoader(true);
    const timer = setTimeout(() => {
      navigate(
        // `/experience/${item.restaurant._id}/${item.experience._id}/booking`
        `/restaurant/${item.restaurant._id}/restaurant/details`
      );
      setIsPageLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onClickGetOffers = async (item) => {
    let headers = {
      'Content-Type': 'application/json',
      authorization: userAuth?.accessToken,
      // version: 'v1',
    };
    await handleRequest('get', `${URL.GET_OFFERS}/${item?.offer?._id}`, null, {
      headers: headers,
      onSuccess: async (response) => {
        setCurrentReelsInfo(item);
        setCurrentOfferData(response);
        setIsLoading(false);
        if (Object.keys(response).length > 0) {
          setIsOpenOffersModal(true);
        } else {
          showNotification(`Offers details are not available`, 'error');
        }
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          'error'
        );
        setIsLoading(false);
      },
    });
  };

  /***** @DESC USER HANDLE TOUCH START/MOUSE DOWN HANDLER *****/
  const handleStart = (e) => {
    touchStartY.current = e.touches ? e.touches[0].clientY : e.clientY;
  };

  /***** @DESC USER HANDLE TOUCH MOVE/MOUSE MOVE HANDLER *****/
  const handleMove = (e) => {
    if (touchStartY.current === null) return;

    const endY = e.touches ? e.touches[0].clientY : e.clientY;
    const diffY = touchStartY.current - endY;

    if (Math.abs(diffY) > 50) {
      if (diffY > 0) {
        if (currentReelIndex < reels?.length - 1) {
          setCurrentReelIndex((prevIndex) => prevIndex + 1);
        } else if (
          !isLoading &&
          reels?.length > 0 &&
          reels?.length % 10 === 0
        ) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      } else {
        if (currentReelIndex > 0) {
          setCurrentReelIndex((prevIndex) => prevIndex - 1);
        }
      }
      touchStartY.current = null;
    }
  };

  /***** @DESC USER HANDLE TOUCH END/MOUSE UP HANDLER *****/
  const handleEnd = () => {
    touchStartY.current = null;
  };

  const content = isPageLoader ? (
    <Loader />
  ) : (
    <div
      // display={"flex"}
      // alignItems={"center"}
      // justifyContent={"center"}
      // h-[87vh] md:h-[92vh]
      className={
        'h-full w-full xs:w-full md:w-[23%]' //rounded-none md:rounded-2xl
      }
    >
      {reels?.length === 0 && isLoading ? (
        <div className="w-full h-full rounded-xl border flex items-center justify-center text-white bg-black text-lg font-semibold shadow-md p-4 text-center capitalize">
          Loading...
        </div>
      ) : reels?.length === 0 && !isLoading ? (
        <div className="w-full h-full rounded-xl border flex items-center justify-center text-white bg-black text-lg font-semibold shadow-md p-4 text-center capitalize">
          Sorry, there are no reels available at this location right now. Please
          check back later or try switching your location.
        </div>
      ) : (
        <div
          ref={reelContainerRef}
          className="w-full h-full"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleStart}
          onMouseMove={handleMove}
          onMouseUp={handleEnd}
        >
          {!isEmpty(reels) &&
            reels.map((reel, index) => (
              <ReelsComponent
                key={reel._id}
                indexValue={index}
                reel={reel}
                isCurrent={index === currentReelIndex}
                isPlaying={isPlaying}
                isFullscreen={isFullscreen}
                muted={muted}
                playbackRate={playbackRate}
                totalReelsCount={totalReelsCount}
                favBoolValue={
                  !isEmpty(userAuth?.accessToken) ? reel?.isLiked : false
                }
                isOpenOffersModal={isOpenOffersModal}
                onClickOpenOfferModal={onClickOpenOfferModal}
                onFavAction={(dataInfo) =>
                  onClickFavActionClick(dataInfo, index)
                }
                onClickActionButton={onHandleBookViewClick(reel, index)}
                handleVideoError={handleVideoError}
                onFullscreenView={toggleFullscreen}
                handleMuteUnmute={handleMuteUnmute}
                onPlayPausedVideo={onPlayPausedVideo}
                handlePrevious={slideDown}
                handleNext={slideUp}
                handleRefreshReels={onClickHandleReelsRefresh}
              />
            ))}
          {isLoading && currentReelIndex === reels?.length - 1 && (
            <div
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              Loading more...
            </div>
          )}
          {isOpenOffersModal && (
            <OffersModal
              openModal={isOpenOffersModal}
              onClosedModal={onClickOpenOfferModal}
              offerData={!isEmpty(currentOfferData) ? currentOfferData : {}}
              currentReelsInfo={currentReelsInfo}
              onActionFavClick={onClickFavActionClick(currentReelsInfo)}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
      actionBy={'REELS_PAGE'}
      HeaderComponent={() => (
        <Mainheader
          showLocation={true}
          disabled={false}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
    />
  );
};

MainBytesScreen.propTypes = {};

export default MainBytesScreen;
