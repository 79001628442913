import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import MainSideBar from '../../components/MainsideBar';
import ScreenTemplate from '../../components/ScreenTemplate';
import useLocation from '../../common/hooks/useLocation';
import { GOOGLE_API_KEY } from '../../config/keys';
import useAuth from '../../common/hooks/useAuth';
import { useState } from 'react';
import Loader from '../../components/loader';
import { URL } from '../../common/api/urls';
import useApi from '../../common/api/hooks/useApi';
import MainHeader from '../../components/headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import ExpRestaurantCard from '../ExperienceBooking/Components/ExpRestaurantCard';
import EditExpBookingDetails from './Components/EditExpBookingDetails';

import ExperiencePackage from '../ExperienceBooking/Components/ExpPackage';
import AboutRestaurantCard from '../ExperienceBooking/Components/AboutRestaurnatCard';
import Icons from '../../components/Icons';
import SpecialOccasions from './Components/SpecialOccoassions';

// import ExpFooter from './Exp_Footer.svg';
import { useAxios } from '../../common/api/hooks/useAxios';
import moment from 'moment';
import { useFormik } from 'formik';
import { getExperienceTaxes } from '../../common/utility/expFunctions';
import { isEmpty } from '../../common/utility/helperFunc';
import { useSelector } from 'react-redux';
import WithAmealioLove from '../../components/reusable/WithAmealioLove';

const errorMessage =
  'Sorry! Unable to proceed with your booking as there are no time slots available for this day. Please try to book for another day.';
const errorMessageTwo = 'Maximum limit exceeded!';
// const errorMessageThree = "Seats are not available";
const errorMessageFour = 'No more seats are available';

const ExpBookingConfirm = () => {
  const userAuth = useSelector((state) => state.user.userDetails);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { auth, persist } = useAuth();
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  const { handleRequest, loading } = useApi();

  const { restaurantId, experienceId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [communityRes, setCommunityRes] = useState();

  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  const [cancelTS, TSData, TSerror, TSloaded, TSreset, executeTSAPI] =
    useAxios();

  const [activeDateData, setActiveDateData] = useState();
  const [visible, setVisible] = React.useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTimeInfo, setActiveTimeInfo] = useState('');
  const [loginModal, showLoginModal] = useState(false);
  const [extKids, setExtKids] = useState(0);
  const [extAdult, setExtAdult] = useState(0);
  const [displayPackage, setDisplayPackage] = useState(false);
  const [dialogBoxText, setDialogBoxText] = useState('');

  // const { state } = useLocation();

  let todayDate =
    moment().format('YYYY-MM-DD') <
    moment(responseData?.startDate).format('YYYY-MM-DD')
      ? moment(responseData?.startDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
  const [currantDate, setCurrentDate] = useState(
    todayDate
    // state?.actionBy === 'PAYMENT_SUMMARY'
    //   ? getFilledExpData.reservationDate
    //   : todayDate
  );

  const showDialog = () => setVisible(true);

  const handleLogout = () => {
    sessionStorage.removeItem('userInformation');
    sessionStorage.removeItem('userOtpDetails');
    sessionStorage.removeItem('userOtpPayload');
    navigate('/');
  };

  const getTimeSlotsAction = (date, expID) => {
    executeTSAPI(`${URL.TIMESLOTS}?date=${date}&expId=${expID}`, 'GET');
  };

  const timeSlotsAction = (date) => {
    if (responseData) {
      getTimeSlotsAction(date, responseData?._id);
    }
  };
  useEffect(() => {
    let dateChangeFormat = moment(currantDate).format('DD-MM-YYYY');
    if (responseData) {
      timeSlotsAction(currantDate);
      onClickPressDate(dateChangeFormat);
    }
  }, [currantDate, responseData]);

  useEffect(() => {
    if (experienceId !== undefined && experienceId !== null) {
      executeAPI(`${URL.EXPERIENCE}/${experienceId}`, 'GET');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId]);

  useEffect(() => {
    (async () => {
      if (location.lat) {
        // validate the token first
        await handleRequest('get', `${URL.VALIDATE_TOKEN}`, null, {
          headers: { Authorization: userAuth.accessToken },
          successMessage: 'Successfully validated the user authentication',
          errorMessage: 'User authentication failed',
          onSuccess: async (response) => {
            // fetch all communities from this location
            setIsLoading(false);
          },
          onError: (error) => {
            console.log('🚀 ~ error:', error);

            setIsLoading(false);
            handleLogout();
          },
        });
      }
    })();
  }, [location]);

  const onClickPressDate = (data) => {
    let timingsData = responseData?.timings;
    let currentDate = timingsData[data];
    if (currentDate?.open) {
      setActiveDateData(currentDate?.multipleHours);
      setActiveTimeInfo(currentDate?.multipleHours?.[0]?.openingTime);
      let currentTime = moment(
        currentDate?.multipleHours?.[0]?.openingTime
      ).format('hh:mm A');
      formik.setFieldValue('reservationTime', currentTime);
    } else if (isEmpty(currentDate)) {
      let formData = {
        open: true,
        description: 'Not Available',
        closingTime: new Date(),
        openingTime: new Date(),
      };
      let formDataTwo = {
        open: false,
        multipleHours: [formData],
      };
      setActiveTimeInfo('Not Available');
      setActiveDateData(formDataTwo?.multipleHours);
    } else {
      let formData = {
        open: currentDate?.multipleHours?.[0]?.open,
        description: 'Not Available',
        closingTime: currentDate?.multipleHours?.[0]?.closingTime,
        openingTime: currentDate?.multipleHours?.[0]?.openingTime,
      };
      setActiveTimeInfo('Not Available');
      currentDate.multipleHours = [formData];
      setActiveDateData(currentDate?.multipleHours);
    }
  };

  const validate = (values) => {
    let minValue = responseData?.minSeats;
    let totalAdultKidsCount =
      formik.values.adultCount + formik.values.kidsCount;
    const errors = {};
    if (values.reservationTime === '') {
      errors.reservationTime = 'Select time is required';
    } else if (values.reservationTime === 'Select time is required') {
      errors.reservationTime = 'Select time is required';
    }
    if (values.isPackagesActive) {
      if (responseData?.expType === 'SPECIAL') {
        if (values.reservationTime === '') {
          errors.reservationTime = 'Select time is required';
        } else if (values.reservationTime === 'Select time is required') {
          errors.reservationTime = 'Select time is required';
        }
      }
      if (values.package.count === 0) {
        errors.count = 'At least one package is required';
      } else {
        formik.setTouched({}, false, false);
      }
    } else {
      if (values.adultCount === 0) {
        errors.adultCount = 'Adult pass is required';
      } else if (totalAdultKidsCount < minValue) {
        errors.adultCount = `Minimum pass limit is ${minValue}`;
      } else {
        formik.setTouched({}, false);
        // formik.setFieldTouched('date', false, false)
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      reservationDate: '',
      reservationTime: '',
      expType: '',
      adultCount: 0,
      adultPrice: 0,
      adultPriceChanged: false,
      kidsCount: 0,
      kidsPrice: 0,
      kidsPriceChanged: false,
      isPackagesActive: false,
      package: {
        name: '',
        count: 0,
        price: 0,
        adults: 0,
        kids: 0,
      },
      birthPkg: 0,
      birthPkgHandicap: 0,
      birthPkgHighchair: 0,
      highchairAccess: false,
      highchairCount: 0,
      handicapCount: 0,
      seatingPreference: '',
      occasion: '',
      subTotal: 0,
      error: '',
    },
    validate,
    onSubmit: (values) => {
      const formData = {
        experienceId: responseData?._id,
        vendorId: responseData?.vendorId,
        restaurantId: responseData?.restaurantId?._id,
        userId: userAuth?.User?._id,
        expType: responseData?.expType,
        requestId: '',
        userDetails: {
          mobileNumber: userAuth?.User?.mobile_number,
          countryCode: userAuth?.User?.country_code,
          name: userAuth?.User?.first_name + ' ' + userAuth?.User?.last_name,
          fcmToken: userAuth?.User?.FCMtoken,
          email: userAuth?.User?.email,
        },
        reservationDate: currantDate,
        reservationTime: values.reservationTime,
        adultCount: values.isPackagesActive ? extAdult : values.adultCount,
        adultPrice: responseData?.adultPrice,
        adultPriceChanged: values.adultPriceChanged,
        kidsCount: values.isPackagesActive ? extKids : values.kidsCount,
        kidsPrice: responseData?.kidsPrice,
        kidsPriceChanged: values.kidsPriceChanged,
        isPackagesActive: values.isPackagesActive,
        package: values.package,
        highchairAccess: values.highchairAccess,
        highchairCount: values.highchairCount,
        handicapCount: values.handicapCount,
        seatingPreference: values.seatingPreference,
        occasion: values.occasion,
        donation: 0,
        tableNo: '',
        paymentData: {
          paymentMethod: 6,
          totalAmount: 0,
          amount: 0,
          donationAmount: 0,
        },
        taxes: {},
        taxesTotal: 0,
        surCharges: {},
        surChargesTotal: 0,
        pointOfEntry: 'WEBAPP',
        trackLink:
          window.location.protocol +
          '//' +
          window.location.hostname +
          `/restaurant/${restaurantId}/trackexp`,
      };

      if (
        responseData?.expType === 'CURATED' &&
        activeTimeInfo === 'Not Available'
      ) {
        setDialogBoxText(errorMessage);
        showDialog();
      } else if (
        responseData?.expType === 'SPECIAL' &&
        formik.values.reservationTime === ''
      ) {
        formik.setErrors({ reservationTime: 'Select time is required' });
      } else {
        if (userAuth) {
          onFinalSubmitAction(formData);
        } else {
          showLoginModal(true);
        }
      }
    },
  });

  const onFinalSubmitAction = (data) => {
    let formData = data;
    let taxesSurcharges = getExperienceTaxes(
      onSubTotalValue(formData),
      responseData
    );
    formData.paymentData.amount = onSubTotalValue(formData);
    formData.paymentData.totalAmount =
      onSubTotalValue(formData) +
      +taxesSurcharges?.taxesTotal +
      +taxesSurcharges?.surChargesTotal;
    formData.taxes = taxesSurcharges?.taxes;
    formData.taxesTotal = +taxesSurcharges?.taxesTotal;
    formData.surCharges = taxesSurcharges?.surCharges;
    formData.surChargesTotal = +taxesSurcharges?.surChargesTotal;
    sessionStorage.setItem('expBookData', JSON.stringify(formData));
    navigate(`/experience/${restaurantId}/${experienceId}/checkout`);
  };

  const onSubTotalValue = (data) => {
    let formData = data;
    let subTotalAmount = 0;
    let totalAdultCount = formData.adultCount;
    let totalKidsCount = formData.kidsCount;
    let totalPkgCount = formData.package?.count;
    let pkgAmount = formData.package?.price;
    let tAdultAmount = totalAdultCount * responseData?.adultPrice;
    let tKidsAmount = totalKidsCount * responseData?.kidsPrice;
    let finalAmount = tAdultAmount + tKidsAmount;
    if (!formData.isPackagesActive) {
      subTotalAmount = finalAmount;
    } else {
      let getTotalPackageAmount = pkgAmount * totalPkgCount;
      subTotalAmount = finalAmount + getTotalPackageAmount;
    }
    return subTotalAmount;
  };

  const onIncrementHandler = (actionType, value, pkgData) => {
    let formikValues = value;
    let adultKidsValue = formikValues?.adultCount + formikValues?.kidsCount;
    let pkgAdultKids =
      +formik.values?.package?.adults +
      +formik.values?.package?.kids +
      +extAdult +
      +extKids;
    let currentCount = 0;
    let newPackData = {
      name: '',
      count: 0,
      price: 0,
      adults: 0,
      kids: 0,
    };
    if (actionType === 'Adult') {
      if (adultKidsValue < responseData?.maxSeats) {
        if (adultKidsValue >= 0 && adultKidsValue < responseData?.seatsLeft) {
          if (formik.values.adultCount === 0) {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              adultCount: formikValues.adultCount + 1,
              isPackagesActive: false,
              handicapCount: 0,
              highchairCount: 0,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          } else {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              adultCount: formikValues.adultCount + 1,
              isPackagesActive: false,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          }
        } else {
          // Alert.alert("Alert", "Seats not available");
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      } else {
        setDialogBoxText(errorMessageTwo);
        showDialog();
      }
    }
    if (actionType === 'Kids') {
      if (adultKidsValue < responseData?.maxSeats) {
        if (adultKidsValue >= 0 && adultKidsValue < responseData?.seatsLeft) {
          if (formik.values.kidsCount === 0) {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              kidsCount: formikValues.kidsCount + 1,
              isPackagesActive: false,
              handicapCount: 0,
              highchairCount: 0,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          } else {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              kidsCount: formikValues.kidsCount + 1,
              isPackagesActive: false,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          }
        } else {
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      } else {
        setDialogBoxText(errorMessageTwo);
        showDialog();
      }
    }

    if (actionType === 'package') {
      if (
        formikValues?.package?.count >= 0 &&
        formikValues?.package?.count < 1000
      ) {
        let newPack = {
          name: pkgData?.name,
          count: 0,
          price: pkgData?.discountedPrice || pkgData?.price,
          adults: 0,
          kids: 0,
        };
        if (
          +pkgAdultKids <= +responseData?.seatsLeft &&
          pkgData?.name === formikValues.package.name
        ) {
          let totalCount = formik.values.package.count + 1;
          let adultCount = pkgData?.adults * totalCount;
          let kidsCount = pkgData?.kids * totalCount;
          currentCount = adultCount + kidsCount + +extAdult + +extKids;
          if (currentCount > +responseData?.seatsLeft) {
            // let remainingSeats = +currentCount - +responseData?.seatsLeft;
            let remainingSeats = +responseData?.seatsLeft - pkgAdultKids;
            if (+responseData?.seatsLeft === +pkgAdultKids) {
              setDialogBoxText(errorMessageFour);
              showDialog();
            } else {
              setDialogBoxText(
                `${remainingSeats} more ${
                  remainingSeats === 1
                    ? 'seat is available'
                    : 'seats are available'
                }, Please select "Add More people to the package" option`
              );
              showDialog();
            }
          } else {
            newPack.count = totalCount;
            newPack.adults = pkgData?.adults * totalCount;
            newPack.kids = pkgData?.kids * totalCount;
            formik.setValues({
              ...formikValues,
              package: newPack,
              adultCount: 0,
              kidsCount: 0,
              isPackagesActive: true,
            });
          }
        } else if (
          +pkgAdultKids <= +responseData?.seatsLeft ||
          pkgData?.name !== formikValues.package.name
        ) {
          newPack.count = 1;
          newPack.adults = pkgData?.adults;
          newPack.kids = pkgData?.kids;
          setExtAdult(0);
          setExtKids(0);
          formik.setValues({
            ...formikValues,
            package: newPack,
            adultCount: 0,
            kidsCount: 0,
            isPackagesActive: true,
          });
          setDisplayPackage(false);
        } else {
          setDialogBoxText(errorMessageTwo);
          showDialog();
        }
        if (
          formikValues?.package?.count <= 0 ||
          pkgData?.name !== formikValues.package.name
        ) {
          formik.setFieldValue('handicapCount', 0);
          formik.setFieldValue('highchairCount', 0);
        }
        formik.setFieldValue('errors', {});
        setActiveTab(1);
      }
      //
    }
    if (actionType === 'PkgAdult') {
      if (
        formikValues?.package?.adults >= 0 &&
        formikValues?.package?.adults < 1000
      ) {
        if (+pkgAdultKids < +responseData?.seatsLeft) {
          let adultCount = formik.values.package?.adults;
          let kidsCount = formik.values.package?.kids;
          setExtAdult(extAdult + 1);
          formik.setValues({
            ...formikValues,
            isPackagesActive: true,
            // handicapCount: 0,
          });
          currentCount = adultCount + kidsCount + extAdult;
        } else {
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      }
    }
    if (actionType === 'PkgKids') {
      if (
        formikValues?.package?.kids >= 0 &&
        formikValues?.package?.kids < 1000
      ) {
        if (+pkgAdultKids < +responseData?.seatsLeft) {
          let adultCount = formik.values.package?.adults;
          let kidsCount = formik.values.package?.kids;
          setExtKids(extKids + 1);
          formik.setValues({
            ...formikValues,
            isPackagesActive: true,
            // highchairCount: 0,
          });
          currentCount = adultCount + kidsCount + extKids;
        } else {
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      }
    }
    if (actionType === 'Handicap') {
      let totalAdultCount = formikValues?.package?.adults + extAdult;
      if (
        formikValues?.handicapCount >= 0 &&
        formikValues?.handicapCount < 1000
      ) {
        if (
          formikValues?.handicapCount < formikValues?.adultCount ||
          formikValues?.handicapCount < totalAdultCount
        ) {
          formik.setFieldValue(
            'handicapCount',
            formikValues?.handicapCount + 1
          );
        }
      }
    }
    if (actionType === 'Highchair') {
      let totalKidsCount = formikValues?.package?.kids + extKids;
      if (
        formikValues?.highchairCount >= 0 &&
        formikValues?.highchairCount < 1000
      ) {
        if (
          formikValues?.highchairCount < formikValues?.kidsCount ||
          formikValues?.highchairCount < totalKidsCount
        ) {
          formik.setFieldValue(
            'highchairCount',
            formikValues.highchairCount + 1
          );
        }
      }
    }
  };

  const onDecrementHandler = (actionType, value, pkgData) => {
    let formikValues = value;

    if (actionType === 'Adult') {
      if (formikValues.adultCount > 0) {
        formik.setFieldValue('adultCount', formikValues.adultCount - 1);
        formik.setFieldValue('handicapCount', 0);
      }
    }
    if (actionType === 'Kids') {
      if (formikValues.kidsCount > 0) {
        formik.setFieldValue('kidsCount', formikValues.kidsCount - 1);
        formik.setFieldValue('highchairCount', 0);
      }
    }
    if (actionType === 'package') {
      let newPack = {
        name: pkgData.name,
        count: formik.values?.package?.count,
        price: pkgData?.discountedPrice || pkgData?.price,
        adults: formik.values?.package?.adults,
        kids: formik.values?.package?.kids,
      };
      if (formikValues?.package?.count >= 2) {
        let totalCount = formik.values.package.count - 1;
        newPack.count = totalCount;
        newPack.adults = pkgData?.adults * totalCount;
        newPack.kids = pkgData?.kids * totalCount;
      } else if (formikValues?.package?.count === 1) {
        let totalCount = formik.values.package.count - 1;
        newPack.count = totalCount;
        newPack.adults = pkgData?.adults * totalCount;
        newPack.kids = pkgData?.kids * totalCount;
        setDisplayPackage(false);
        setExtAdult(0);
        setExtKids(0);
        formik.setFieldValue('handicapCount', 0);
        formik.setFieldValue('highchairCount', 0);
      }
      if (newPack.count === 0) {
        newPack.name = '';
        newPack.adults = 0;
        newPack.kids = 0;
        formik.setValues({
          ...formik.values,
          package: newPack,
          adultCount: 0,
          adultPrice: responseData?.adultPrice,
          kidsCount: 0,
          kidsPrice: responseData?.kidsPrice,
          handicapCount: 0,
          highchairCount: 0,
        });
      } else {
        formik.setFieldValue('package', newPack);
        formik.setFieldValue('handicapCount', 0);
        formik.setFieldValue('highchairCount', 0);
      }
    }
    if (actionType === 'PkgAdult') {
      if (extAdult > 0 && extAdult < 100) {
        setExtAdult(extAdult - 1);
        formik.setFieldValue('handicapCount', 0);
      }
    }
    if (actionType === 'PkgKids') {
      if (extKids > 0 && extKids < 100) {
        setExtKids(extKids - 1);
        formik.setFieldValue('highchairCount', 0);
      }
    }
    if (actionType === 'Handicap') {
      if (formikValues.handicapCount > 0) {
        formik.setFieldValue('handicapCount', formikValues.handicapCount - 1);
      }
    }
    if (actionType === 'Highchair') {
      if (formikValues.highchairCount > 0) {
        formik.setFieldValue('highchairCount', formikValues.highchairCount - 1);
      }
    }
  };
  console.log('response data l 688', responseData);
  const content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
    >
      {isLoading ? (
        <Loader />
      ) : responseData ? (
        <div className="d-flex flex-column w-100">
          <ExpRestaurantCard data={responseData} />
          <EditExpBookingDetails TSData={TSData} formik={formik} />

          {/* Packages */}
          <>
            <div className="d-flex flex-column py-8">
              {/* <h1 className="text-3xl font-bold mb-8">Discounted Group Bookings</h1> */}

              <div className="d-flex align-items-center justify-content-start">
                <Icons type="packages" />
                <h5
                  className="mx-2"
                  style={{ fontWeight: 'bold', color: '#000' }}
                >
                  Packages
                </h5>
              </div>

              {/* Responsive Grid for ExperiencePackage components */}
              <div className="container justify-content-start mx-0 p-0 py-3">
                <div className="row gap-3 w-100">
                  {responseData.isPackagesProvided &&
                    responseData.packages.map((packageData) => (
                      <ExperiencePackage
                        packageData={packageData}
                        hasQuantity={true}
                        onIncrementHandler={onIncrementHandler}
                        onDecrementHandler={onDecrementHandler}
                        formik={formik}
                      />
                    ))}

                  {/* <ExperiencePackage 
                            data={responseData} hasQuantity={true}
                            onIncrementHandler={onIncrementHandler}
                            onDecrementHandler={onDecrementHandler}
                            formik={formik}
                          /> */}
                </div>
              </div>
            </div>
          </>

          {/* Divider */}
          <hr
            style={{ border: '0.5px solid grey', margin: '10px 0' }}
            className="mt-4"
          />

          {/* Special Occassions */}
          <SpecialOccasions
            formik={formik}
            onCancel={() => {
              navigate(`/experience/${restaurantId}/${experienceId}/booking`);
            }}
          />
          <WithAmealioLove />
          {/* <div className="w-full">
            <img
              src={ExpFooter}
              alt="Footer"
              className="w-full h-full object-cover"
            />
          </div> */}
        </div>
      ) : (
        <>{/* <WebViewComponent accessToken={userAuth.accessToken}/> */}</>
      )}
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
    />
  );
};
export default ExpBookingConfirm;
