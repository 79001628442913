import React, { useCallback, useEffect, useState } from "react";
import moment from "moment/moment";
import { Button, Col, Image, Row } from "react-bootstrap";
import {
  useNavigate,
  useParams,
  useLocation as use_location,
} from "react-router-dom";
import useLocation from "../../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../../config/keys";
import ScreenTemplate from "../../../components/ScreenTemplate";
import Mainheader from "../../../components/headers/Mainheader";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import MainSideBar from "../../../components/MainsideBar";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DottedHr from "../../reusableUiComponents/DottedHr";
import EastIcon from "@mui/icons-material/East";

import { isEmpty } from "../../../common/utility/helperFunc";
import { useDispatch, useSelector } from "react-redux";
import CartBar from "../../ReusableComponent/CartBar";
import {
  saveOndcCartItems,
  saveOndcOrderInfo,
  saveOndcViewCartBottomInfo,
  saveOndcApiGetOndc,
  saveOndcApiGetCartItemsOndc,
  updateOndcCheckItemAvailability,
} from "../../../store/slices/ondcSlice";
import { IconButton, TextField, Drawer, Alert } from "@mui/material";
import OnceMenuItemCards from "../../ONDC/Components/MenuItemCards/OnceMenuItemCards";
import { setViewCartInfo } from "../../ONDC/ondcHelperFuction.ts";
import SocketContext from "../../../setup/socket/SocketContext.js";
import { ONDC_SOCKET_DATA } from "../../../common/enums/GlobalEnums.js";
import useApi from "../../../common/api/hooks/useApi.js";
import { URL } from "../../../common/api/urls.js";
import SelectDeliveryType from "../../ONDC/Components/Modals/SelectDeliveryType.jsx";
import DeliveryModals from "../../ONDC/Components/Modals/DeliveryModals.jsx";
import { useSnackbar } from "notistack";
//
const OndcCartPageComponent = (props) => {
  const navigate = useNavigate();
  const { ID } = useParams();
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const locationData = use_location();
  const dispatch = useDispatch();
  const { handleRequest, loading } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [ondcOrderInfo, setOndcOrderInfo] = useState({});
  const [ondcCartItems, setOndcCartItems] = useState([]);
  const [bottomViewCartInfo, setBottomViewCartInfo] = useState({});
  const [apiOndcOrderCartInfo, setApiOndcOrderCartInfo] = useState({});
  const [showNotesAlert, setShowNotesAlert] = useState(false);
  const [selectApiResponse, setSelectApiResponse] = useState({});
  const [openDeliveryMapModal, setOpenDeliveryMapModal] = useState(false);
  const [openDeliveryModalType, setOpenDeliveryModalType] = useState(false);

  const [onSelect, setOnSelect] = useState("");

  const [checkItemAvailability, setCheckItemAvailability] = useState(false);

  const userAuth = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userDetails
  );
  const getOndcSavedOrderInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcOrderInfo
  );
  const getOndcSavedCartItems = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcCartItems
  );
  const getOndcBottomViewCartInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcViewCartBottomInfo
  );
  const getOndcApiOrderCartData = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcApiGetOndcData
  );

  const itemAvailability = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.checkAvailability
  );

  useEffect(() => {
    if (props?.socket) {
      props.socket.authentication.app.authentication.setAccessToken(
        userAuth.accessToken
      );
      props.socket.authentication.app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log("Socket Re-Authenticated CART");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    getOndcCartDataFromApiRequest(userAuth.accessToken);
    if (!isEmpty(itemAvailability) && itemAvailability) {
      setCheckItemAvailability(true);
      onClickAvailabilityCheck();
    } else {
      setCheckItemAvailability(false);
      onClickAvailabilityCheck();
    }
    const ondcSocket = props.socket.service("ondc");
    ondcSocket.on(ONDC_SOCKET_DATA[1], (data) => {
      setSelectApiResponse(data);
    });
  }, []);

  useEffect(() => {
    if (ID) {
      if (!isEmpty(locationData) && !isEmpty(locationData?.state)) {
        const storedOndcOrderDetails = JSON.parse(
          localStorage.getItem("ondcOrderDetails")
        );
        const storedOndcCartDetails = JSON.parse(
          localStorage.getItem("ondcCartDetails")
        );
        const storedOndcViewCartBottomInfo = JSON.parse(
          localStorage.getItem("ondcViewCartBottomInfo")
        );
        if (
          !isEmpty(storedOndcOrderDetails) &&
          !isEmpty(storedOndcCartDetails)
        ) {
          dispatch(saveOndcOrderInfo(storedOndcOrderDetails));
          dispatch(saveOndcCartItems(storedOndcCartDetails));
          dispatch(saveOndcViewCartBottomInfo(storedOndcViewCartBottomInfo));
        } else {
          dispatch(saveOndcOrderInfo({}));
          dispatch(saveOndcCartItems([]));
          dispatch(saveOndcViewCartBottomInfo({}));
        }
      }
    }
  }, [locationData]);

  useEffect(() => {
    if (!isEmpty(getOndcSavedOrderInfo)) {
      setOndcOrderInfo(getOndcSavedOrderInfo);
    } else {
      setOndcOrderInfo({});
    }
    if (!isEmpty(getOndcSavedCartItems)) {
      let blankArr = [];
      for (const element of getOndcSavedCartItems) {
        if (!("parent_item_id" in element)) {
          blankArr.push(element);
        }
      }
      setOndcCartItems(blankArr);
    } else {
      setOndcCartItems([]);
    }
    if (!isEmpty(getOndcBottomViewCartInfo)) {
      setBottomViewCartInfo(getOndcBottomViewCartInfo);
    } else {
      setBottomViewCartInfo({});
    }
    if (!isEmpty(getOndcApiOrderCartData)) {
      setApiOndcOrderCartInfo(getOndcApiOrderCartData);
    } else {
      setApiOndcOrderCartInfo({});
    }
  }, [
    getOndcSavedOrderInfo,
    getOndcSavedCartItems,
    getOndcBottomViewCartInfo,
    getOndcApiOrderCartData,
  ]);

  useEffect(() => {
    if (!isEmpty(itemAvailability) && itemAvailability) {
      setCheckItemAvailability(true);
    } else {
      setCheckItemAvailability(false);
    }
  }, [itemAvailability]);

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);

  const navigateToViewMenu = () => {
    navigate(`/restaurant/${ID}/viewmenu`);
  };

  const [notes, setNotes] = useState(
    ondcOrderInfo?.message?.order?.notes || ""
  );
  const submitNotes = () => {
    alert("Currently, these features are unavailable");
    // let orderInfo = ondcOrderInfo;
    // let newData = {};
    // if (!isEmpty(notes)) {
    //   if (Object.isExtensible(orderInfo?.message?.order)) {
    //     orderInfo.message.order.notes = notes;
    //   } else {
    //     newData = Object.assign({}, orderInfo, {
    //       message: Object.assign({}, orderInfo.message, {
    //         order: Object.assign({}, orderInfo.message.order, {
    //           notes: notes,
    //         }),
    //       }),
    //     });
    //   }
    //   localStorage.setItem("ondcOrderDetails", JSON.stringify(newData));
    //   dispatch(saveOndcOrderInfo(newData));
    //   setNotes("");
    // } else {
    //   setShowNotesAlert(true);
    // }
  };

  const intialOrderCall = async () => {
    // navigate(
    //   `/ondc/${ID}/ondctrack/${ondcOrderInfo?.message?.order?.provider?.id}`
    // );
    const order_id = Math.floor(100000 + Math.random() * 900000);
    const formData = ondcOrderInfo;
    let newData = {};
    if (Object.isExtensible(formData?.message?.order)) {
      formData.message.order.order_id = order_id;
    } else {
      newData = Object.assign({}, formData, {
        message: Object.assign({}, formData.message, {
          order: Object.assign({}, formData.message.order, {
            order_id: order_id,
          }),
        }),
      });
    }
    localStorage.setItem("ondcOrderDetails", JSON.stringify(newData));
    dispatch(saveOndcOrderInfo(newData));

    if (
      !isEmpty(getOndcApiOrderCartData) &&
      !isEmpty(getOndcApiOrderCartData?.fulfillment) &&
      !isEmpty(getOndcApiOrderCartData?.fulfillment?.address) &&
      Object.keys(getOndcApiOrderCartData?.fulfillment?.address).length > 1
    ) {
      onClickInitializePayment(getOndcApiOrderCartData);
    } else {
      setOpenDeliveryMapModal(true);
    }
    // onClickSelectApiCall(formData);
  };

  /**
   * GET ONDC ADDED CART DATA
   */
  const getOndcCartDataFromApiRequest = async (token) => {
    let headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
    await handleRequest("get", `${URL.ONDC_USER_CART}`, null, {
      headers: headers,
      onSuccess: async (data) => {
        dispatch(saveOndcApiGetOndc(data?.data));
        dispatch(saveOndcApiGetCartItemsOndc(data?.data?.cart_items));
      },
      onError: (error) => {
        console.error(
          "API Error:",
          error.response ? error.response.data : error
        );
      },
    });
  };

  const getBaseAmount = (data) => {
    let baseAmount = 0;
    let deliveryCharges = 0;
    let convenienceFee = 0;
    let totalAmount = 0;
    const finalAmount = data?.message?.order?.quote?.breakup;
    for (let i = 0; i < finalAmount?.length; i++) {
      // BASE AMOUNT
      if (
        finalAmount?.[i]?.title.replace(/\s+/g, "").toLowerCase() !==
          "deliverycharges" &&
        finalAmount?.[i]?.title.replace(/\s+/g, "").toLowerCase() !==
          "conveniencefee"
      ) {
        baseAmount += +finalAmount?.[i]?.price?.value;
      } else {
        baseAmount += 0;
      }
      // DELIVERY CHARGES AMOUNT
      if (
        finalAmount?.[i]?.title.replace(/\s+/g, "").toLowerCase() ===
        "deliverycharges"
      ) {
        deliveryCharges += +finalAmount?.[i]?.price?.value;
      } else {
        deliveryCharges += 0;
      }
      // CONVENIENCE FEES AMOUNT
      if (
        finalAmount?.[i]?.title.replace(/\s+/g, "").toLowerCase() ===
        "conveniencefee"
      ) {
        convenienceFee += +finalAmount?.[i]?.price?.value;
      } else {
        convenienceFee += 0;
      }
      // FINAL TOTAL AMOUNT
      totalAmount += +finalAmount?.[i]?.price?.value;
    }
    return {
      baseAmount: baseAmount,
      deliveryCharges: deliveryCharges,
      convenienceFee: convenienceFee,
      totalAmount: totalAmount,
    };
  };

  /**
   * ON CLICK AVAILABILITY CHECK
   */
  const onClickAvailabilityCheck = useCallback(async () => {
    await handleRequest("get", `${URL.ONDC_USER_CART_DELIVERY}`, null, {
      headers: { Authorization: userAuth.accessToken },
      successMessage: "",
      errorMessage: "Something went wrong! Please try again",
      onSuccess: async (response) => {
        // fetch all communities from this location
        if (response.status) {
          dispatch(updateOndcCheckItemAvailability(true));
        } else {
          dispatch(updateOndcCheckItemAvailability(false));
        }
      },
      onError: (error) => {
        console.log("error Info=>", error);
      },
    });
  }, [dispatch, itemAvailability]);

  const onClickSelectDeliveryType = (data) => () => {
    setOnSelect(data.id);
  };

  /**
   * ON CLICK SELECT DELIVERY TYPE
   */
  const onClickDeliveryTypeSubmit = async () => {
    let formData = {
      id: onSelect,
    };
    let headers = {
      "Content-Type": "application/json",
      authorization: userAuth.accessToken,
    };
    await handleRequest("patch", `${URL.ONDC_USER_CART_DELIVERY}`, formData, {
      headers: headers,
      onSuccess: async (response) => {
        if (response?.status) {
          showNotification(`${response?.message}`, "success");
          setOpenDeliveryMapModal(false);
          setOpenDeliveryModalType(false);
          onClickAvailabilityCheck();
          getOndcCartDataFromApiRequest(userAuth.accessToken);
        }
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
        // console.error(
        //   "API Error:",
        //   error.response ? error.response.data : error
        // );
        // alert(error.response ? error.response.data : error);
      },
    });
  };

  /**
   * ON CLICK ADD DELIVERY ADDRESS
   */
  const onClickDeliveryAddressSubmit = async (data) => {
    await handleRequest("post", `${URL.ONDC_USER_CART_DELIVERY}`, data, {
      headers: { Authorization: userAuth.accessToken },
      successMessage: "Address was successfully Added",
      errorMessage: "Something went wrong! Please try again",
      onSuccess: async (response) => {
        // fetch all communities from this location
        if (response.status) {
          showNotification(`${response?.message}`, "success");
          setOpenDeliveryMapModal(false);
          setOpenDeliveryModalType(true);
        }
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
      },
    });
  };

  /**
   * ON CLICK ADD DELIVERY ADDRESS
   */
  const onClickInitializePayment = async (data) => {
    await handleRequest("post", `${URL.ONDC_USER_CART_ORDER}`, data, {
      headers: { Authorization: userAuth.accessToken },
      successMessage: "",
      errorMessage: "Something went wrong! Please try again",
      onSuccess: async (response) => {
        // fetch all communities from this location
        if (response.status) {
          navigate(
            `/ondc/${data?.restaurant_details?._id}/checkout/${response?.data?.order_id}`,
            {
              state: {
                orderData: { orderID: response?.data },
                ondcCheckAvailability: selectApiResponse,
                from: "ondc",
                fromOndc: true,
              },
            }
          );
        }
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
      },
    });
  };

  const refineDeliveryAddress = (address) => {
    const completeAddress = [
      address?.building,
      address?.name,
      address?.locality,
      address?.street,
      address?.city,
      address?.state,
      address?.country,
      address?.area_code,
    ];
    return completeAddress;
  };

  const content = (
    <React.Fragment>
      <div
        className={
          "bg-[#80808014] px-2 py-3 rounded-md flex items-center justify-between"
        }
      >
        <div className="w-full">
          <p className="text-sm text-lightTextColor font-Mulish font-bold">
            Order References :{" "}
            <span className="text-base text-black font-bold">
              {getOndcApiOrderCartData?.fulfillment?.type
                ?.toString()
                ?.toUpperCase() || "N/A"}
            </span>
          </p>
          {!isEmpty(getOndcApiOrderCartData) &&
            !isEmpty(getOndcApiOrderCartData?.fulfillment) && (
              <div className="w-full flex items-start mb-3">
                <p className="text-sm text-lightTextColor font-Mulish font-bold mb-0">
                  Delivery Address :
                </p>
                <span className="text-sm font-Mulish text-black font-semibold mb-0 ml-1">
                  {refineDeliveryAddress(
                    getOndcApiOrderCartData?.fulfillment?.address
                  ).map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index === 3 && <br />}
                    </React.Fragment>
                  ))}
                </span>
                <button
                  onClick={() => {
                    setOpenDeliveryMapModal(true);
                    setOpenDeliveryModalType(false);
                  }}
                  type="button"
                  className="ml-4"
                >
                  <Image
                    src={require("../../../assets/images/IconsandImages/edit.png")}
                    className="w-4 h-4 bg-cover"
                  />
                </button>
              </div>
            )}

          <Row>
            <Col xs={4} md={2} className="flex items-center justify-start">
              <Image
                src={require("../../../assets/images/IconsandImages/calexp.png")}
                alt="icon"
                className="object-contain w-6 h-6"
                style={{ maxWidth: "100%" }}
              />
              <div className="f14-700 ml-2">
                {moment().format("DD MMM YYYY")}
              </div>
            </Col>
            <Col xs={4} md={2} className="flex items-center justify-start">
              <Image
                src={require("../../../assets/images/IconsandImages/clockexp2.png")}
                alt="icon"
                className="object-contain w-6 h-6"
                style={{ maxWidth: "100%" }}
              />
              <div className="f14-700 ml-2">{moment().format("hh:mm A")}</div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="d-flex justify-content-between px-2 py-3">
        <div className="f18-700">Order Details</div>
        <button
          className="text-sm font-Mulish font-semibold"
          style={{ color: "#2196f3" }}
          onClick={navigateToViewMenu}
        >
          Add More Items
        </button>
      </div>
      <Row>
        {!isEmpty(apiOndcOrderCartInfo) &&
          !isEmpty(apiOndcOrderCartInfo?.cart_items) &&
          // !isEmpty(apiOndcOrderCartInfo)
          apiOndcOrderCartInfo?.cart_items?.length > 0 &&
          apiOndcOrderCartInfo?.cart_items?.map((items, index) => {
            return (
              <Col sm={12} md={6} key={items?.id}>
                <OnceMenuItemCards
                  viewType={1}
                  item={items?.itemDetails}
                  restData={
                    (!isEmpty(ondcOrderInfo) &&
                      !isEmpty(ondcOrderInfo?.message) &&
                      !isEmpty(ondcOrderInfo?.message?.order) &&
                      ondcOrderInfo?.message?.order?.provider) ||
                    {}
                  }
                  actionBy={"ONDC_VIEW_CART"}
                  currenCartData={getOndcApiOrderCartData}
                />
              </Col>
            );
          })}
      </Row>
      {!isEmpty(apiOndcOrderCartInfo) &&
      !isEmpty(apiOndcOrderCartInfo?.cart_items) &&
      apiOndcOrderCartInfo?.cart_items?.length > 0 ? (
        <>
          <div className="p-2">
            <div className="f14-700 d-flex align-items-center">
              {" "}
              <DescriptionOutlinedIcon mx={4} />
              Write Notes For Kitchen
            </div>
            <div className="my-2">
              <div className="w-full">
                <TextField
                  id="outlined-basic-kitchen"
                  placeholder="I want less sugar."
                  variant="standard"
                  size="small"
                  className="border bg-white shadow-sm rounded-lg"
                  fullWidth
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  sx={{ border: "none" }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="send"
                        onClick={() => submitNotes()}
                      >
                        <EastIcon
                          sx={{
                            color: "whitesmoke",
                            backgroundColor: "#96ade1",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>
            {showNotesAlert && (
              <Alert severity="error" onClose={() => setShowNotesAlert(false)}>
                Please Enter Valid instruction
              </Alert>
            )}
          </div>
          <div className="pb-5">
            <div className="p-3 f14-700">Order Summary</div>
            <DottedHr />
            <div className="p-3 f14-700">
              <div className="flex-between my-2">
                <div>Original Item Value</div>
                <div>
                  ₹
                  {(getBaseAmount(selectApiResponse).baseAmount || 0).toFixed(
                    2
                  )}
                </div>
              </div>
              <div className="flex-between my-2">
                <div>Delivery Charges</div>
                <div>
                  ₹
                  {(
                    getBaseAmount(selectApiResponse).deliveryCharges || 0
                  ).toFixed(2)}
                </div>
              </div>
              <div className="flex-between my-2">
                <div>Convenience Fee</div>
                <div>
                  ₹
                  {(
                    getBaseAmount(selectApiResponse).convenienceFee || 0
                  ).toFixed(2)}
                </div>
              </div>
              <AddTip
                OrderData={selectApiResponse}
                setOrderData={setSelectApiResponse}
              />
              <DonateUdbhav
                OrderData={selectApiResponse}
                setOrderData={setSelectApiResponse}
              />
              <hr />
              <div className="flex-between f16-700">
                <div>Grand Total</div>
                <div>
                  ₹
                  {(getBaseAmount(selectApiResponse).totalAmount || 0).toFixed(
                    2
                  )}
                </div>
              </div>
              <hr />
            </div>
          </div>
        </>
      ) : (
        <div className="p-2 text-center">
          <div style={{ height: "30vh" }}></div>
          <div className="f18-700 mt-4">Your Cart is empty</div>
          <div className="f16-500 text-grey my-4">
            Please add something from menu to enjoy the delicious food and our
            services
          </div>
        </div>
      )}
      <div className="bg-white fixed bottom-14 md:bottom-0 left-0 md:left-[17%] w-full z-[100]">
        {/* <div className="row w-100 m-0">
          <div className="col-10  p-0"> */}
        {/* <div className="col-12 p-0">
          {!isEmpty(ondcOrderInfo) &&
            !isEmpty(ondcCartItems) &&
            ondcCartItems?.length > 0 && (
              <CartBar
                setshowOtpModal={() => {}}
                intialOrderCall={intialOrderCall}
                from="ONDC"
                fromPage="ONDC_CHECKOUT"
                viewCartInfo={bottomViewCartInfo}
              />
            )}
        </div> */}
        {!isEmpty(getOndcApiOrderCartData) &&
          getOndcApiOrderCartData?.cart_items?.length > 0 && (
            <CartBar
              // setshowOtpModal={setshowOtpModal}
              setshowOtpModal={() => {}}
              intialOrderCall={intialOrderCall}
              from="ONDC"
              fromPage="ONDC_CHECKOUT"
              viewCartInfo={getOndcApiOrderCartData}
              ondcFinalOrderData={(
                getBaseAmount(selectApiResponse).totalAmount || 0
              ).toFixed(2)}
              ondcCheckAvailability={checkItemAvailability}
              onClickCheckAvailabilityAction={onClickAvailabilityCheck}
            />
          )}
        {/* </div>
        </div> */}
      </div>
      {openDeliveryMapModal && (
        <DeliveryModals
          visible={openDeliveryMapModal}
          closedModal={() => setOpenDeliveryMapModal(false)}
          restData={getOndcApiOrderCartData}
          onClickSubmit={onClickDeliveryAddressSubmit}
        />
      )}
      {openDeliveryModalType && (
        <SelectDeliveryType
          visible={openDeliveryModalType}
          closedModal={() => setOpenDeliveryModalType(false)}
          restData={getOndcApiOrderCartData?.restaurant_details}
          onClickAction={onClickSelectDeliveryType}
          selectedId={onSelect}
          onClickSubmit={onClickDeliveryTypeSubmit}
        />
      )}
    </React.Fragment>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <Mainheader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
};

OndcCartPageComponent.propTypes = {};

const OndcCartPage = (props) => (
  <SocketContext.Consumer>
    {(socket) => <OndcCartPageComponent {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default OndcCartPage;

function AddTip({ OrderData, setOrderData }) {
  const dispatch = useDispatch();
  const [showTipDrawer, setShowTipDrawer] = useState(false);
  const [custom, setcustom] = useState(false);
  const [tiPAmount, setTipAmount] = useState(
    OrderData?.message?.order?.add_tip?.amount || 0
  );
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const onClickCommonAddRemoveAmount = async (value, actionBy) => {
    let orderInfoData = OrderData;
    let newData = {};
    let finalData;
    if (Object.isExtensible(orderInfoData?.message?.order)) {
      orderInfoData.message.order.add_tip.amount = +value;
    } else {
      newData = Object.assign({}, orderInfoData, {
        message: Object.assign({}, orderInfoData.message, {
          order: Object.assign({}, orderInfoData.message.order, {
            add_tip: Object.assign({}, orderInfoData.message.order.add_tip, {
              amount: +value,
            }),
          }),
        }),
      });
      finalData = setViewCartInfo(newData?.message?.order?.items, newData);
      newData.message.order.total_amount = finalData?.totalAmount;
    }
    setTipAmount(+value);
    dispatch(saveOndcOrderInfo(newData));
    dispatch(saveOndcViewCartBottomInfo(finalData));
    await Promise.all([
      localStorage.setItem("ondcOrderDetails", JSON.stringify(newData)),
      localStorage.setItem("ondcViewCartBottomInfo", JSON.stringify(finalData)),
    ]);
  };

  const handleAddTip = async (e) => {
    onClickCommonAddRemoveAmount(e.target.value, "ADD_TIP");
    setShowTips(false);
  };
  const handleTipLearnMore = () => {
    setShowTipDrawer(true);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = async () => {
    alert("Currently, these features are unavailable");
    // if (tiPAmount > 0) {
    //   onClickCommonAddRemoveAmount(0, "REMOVE_TIP");
    //   setShowTips(!showTips);
    // } else {
    //   setShowTips(true);
    // }
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    onClickCommonAddRemoveAmount(customAmount, "CUSTOM_TIP");
    setShowTips(false);
    setcustom(false);
  };

  return (
    <>
      <div className="add-tip">
        <div>
          Add Tip{" "}
          <button
            className="f12-500 ml-2"
            style={{ color: "#2196f3", cursor: "pointer" }}
            onClick={handleTipLearnMore}
          >
            Learn More
          </button>
        </div>
        <button className="primary-color f12-700" onClick={handleChange}>
          {tiPAmount > 0 ? "Remove Amount" : "Change Amount"}
        </button>
        <div>₹ {tiPAmount}</div>
      </div>
      {/* Tip Modal */}
      <Drawer
        anchor="bottom"
        open={showTipDrawer}
        onClose={() => setShowTipDrawer(false)}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            marginBottom: "50px",
            borderRadius: "16px ",
            backgroundColor: "white",
          },
        }}
      >
        <div
          style={{
            padding: "20px",
            borderRadius: "16px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 style={{ fontWeight: "bold" }}> Add Tip</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../assets/images/IconsandImages/addtipNEW.png")}
              alt="Udbhav"
              style={{ maxWidth: "300px", marginBottom: "15px" }}
            />
          </div>
          <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.6" }}>
            A Customer may at it's sole discretion add a certain amount as "Tip"
            for the services provided through the Amealio Platform.Any amount
            recieved towards Tips shall be non-refundable except in the
            following circumstances: A. Order is auto-cancelled before the
            acceptance of the order bythe Merchant. b. If the Order is cancelled
            by the Merchant due to such as non-availability of food items
            ordered by the customer, non-availability of the service, or any
            other reasons attributable to the Merchant. However, in case of
            partial cncellation or modifications in the order/ Booking, the
            amount contributed towards Tips shall be non-refundableAmealio
            reserves the right, at its sole discretion, to refuse the refund of
            the amount pay towards Tips for any reason what so ever, without
            assigning any reason to the customer and the customer accepts and
            undertakes to not raise any dispute on the same.
          </p>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#fc5a47",
              color: "white",
              borderRadius: "10px",
              textTransform: "capitalize",
              width: "50%",
            }}
            onClick={() => {
              setShowTipDrawer(false);
              setShowTips(true);
            }}
          >
            Add Tip
          </Button>
        </div>
      </Drawer>

      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

export function DonateUdbhav({ OrderData, setOrderData }) {
  const dispatch = useDispatch();
  const [showDonateDrawer, setShowDonateDrawer] = useState(false);
  const [custom, setcustom] = useState(false);
  const [tiPAmount, setTipAmount] = useState(
    OrderData?.message?.order?.donate_amount?.amount || 0
  );
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const onClickCommonAddRemoveAmount = async (value, actionBy) => {
    let orderInfoData = OrderData;
    let newData = {};
    let finalData;
    if (Object.isExtensible(orderInfoData?.message?.order)) {
      orderInfoData.message.order.donate_amount.amount = +value;
    } else {
      newData = Object.assign({}, orderInfoData, {
        message: Object.assign({}, orderInfoData.message, {
          order: Object.assign({}, orderInfoData.message.order, {
            donate_amount: Object.assign(
              {},
              orderInfoData.message.order.donate_amount,
              {
                amount: +value,
              }
            ),
          }),
        }),
      });
      finalData = setViewCartInfo(newData?.message?.order?.items, newData);
      newData.message.order.total_amount = finalData?.totalAmount;
    }
    setTipAmount(+value);
    dispatch(saveOndcOrderInfo(newData));
    dispatch(saveOndcViewCartBottomInfo(finalData));
    await Promise.all([
      localStorage.setItem("ondcOrderDetails", JSON.stringify(newData)),
      localStorage.setItem("ondcViewCartBottomInfo", JSON.stringify(finalData)),
    ]);
  };

  const handleAddTip = (e) => {
    onClickCommonAddRemoveAmount(e.target.value, "ADD_DONATE");
    setShowTips(false);
  };

  const handleDonateLearnMore = () => {
    setShowDonateDrawer(true);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = () => {
    alert("Currently, these features are unavailable");
    // if (tiPAmount > 0) {
    //   onClickCommonAddRemoveAmount(0, "REMOVE_DONATE");
    //   setShowTips(!showTips);
    // } else {
    //   setShowTips(true);
    // }
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    onClickCommonAddRemoveAmount(customAmount, "CUSTOM_DONATE");
    setShowTips(false);
    setcustom(false);
  };

  return (
    <>
      <div className="add-tip">
        <div>
          Donate to Udbhav{" "}
          {/* <span className="f12-500" style={{ color: "#2196f3" }}>
            Learn More
          </span> */}
          <button
            className="f12-500"
            style={{ color: "#2196f3", cursor: "pointer", fontWeight: "500" }}
            onClick={handleDonateLearnMore}
          >
            Learn More
          </button>
        </div>
        <button
          className="primary-color f12-700"
          onClick={handleChange}
          style={{ marginRight: "70px" }}
        >
          {tiPAmount > 0 ? "Remove Donation" : "Change Amount"}
        </button>
        <div>₹ {tiPAmount}</div>
      </div>
      {/* Udbhav Modal */}
      <Drawer
        anchor="bottom"
        open={showDonateDrawer}
        onClose={() => setShowDonateDrawer(false)}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            marginBottom: "50px",
            borderRadius: "16px ",
            backgroundColor: "white",
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          <h2 style={{ fontWeight: "bold" }}>Donate To Udbhav</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../assets/images/IconsandImages/udbhav.png")}
              alt="Udbhav"
              style={{ maxWidth: "150px", marginBottom: "15px" }}
            />
          </div>
          <p style={{ fontSize: "16px", color: "#333", lineHeight: "1.6" }}>
            Udbhav is a foundation focused on providing resources to the
            underprivileged sectors while creating a lasting impact that
            empowers communities. We cater to families in need of bare
            necessities and motivate children to study by developing specialized
            programs to improve their quality of life. Driven by the youth,
            Udbhav willingly partners up with similar organizations to help
            create change. We believe in creating a sustainable world by
            participating in drives that address environmental concerns. A
            recent partnership with the Akshaya Patra organization led to 500
            food packets delivered to the families and people most affected by
            the pandemic. A host of partnerships and events that give back to
            society are on the horizon. Be a part of this and help us to change
            the world!
          </p>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#fc5a47",
              color: "white",
              borderRadius: "25px",
              textTransform: "capitalize",
              width: "100%",
            }}
            onClick={() => window.open("https://visionudbhav.org/", "_blank")}
          >
            Visit Udbhav
          </Button>
        </div>
      </Drawer>

      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}
