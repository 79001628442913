import React, { useEffect, useState } from "react";
import TitleSection from "../../ReusableComponent/TitleSection";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../../common/api/urls";
import { useAxios } from "../../../common/api/hooks/useAxios";
import { Image, Table } from "react-bootstrap";
import moment from "moment";
import DottedHr from "../../reusableUiComponents/DottedHr";
import {
  calcFinalTotal,
  calcTotalOriginal,
} from "../calculations/OrderingCalculations";
import {
  NEW_PAYMENT_TYPE_METHOD,
  ORDER_TYPES,
  ORDER_TYPE_NAME,
} from "../../../common/enums/OrderEnums";
import SuccessModals from "../modals/SuccessModals";
import UserRegistration from "../../ReusableComponent/UserRegistration";
import DineInModal from "../modals/dineInModal";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { isEmpty } from "../../../common/utility/helperFunc";
import NotificationAlert from "../../ReusableComponent/Alert";
import { Checkbox, CircularProgress } from "@mui/material";
import { assets } from "./../../../assets/assets";
import {
  ORDER_RAZORPAY_KEY,
  PAY_LATER_ARR,
  PAY_WITH_ARR,
  UPI_ARR,
} from "../../../common/constants/Payments";
import axios from "axios";

import useAlerts from "../../../components/AlertMessages";
import useLocation from "../../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../../config/keys";
import ScreenTemplate from "../../../components/ScreenTemplate";
import MainSideBar from "../../../components/MainsideBar";
import MainHeader from "../../../components/headers/Mainheader";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import { useSelector } from "react-redux";
import { useLocation as use_location } from "react-router-dom";
import useApi from "../../../common/api/hooks/useApi";
import { User } from "lucide-react";

function CheckOutPage() {
  const { ID, order_id } = useParams();

  const navigate = useNavigate();
  const propsData = use_location();
  const { handleRequest } = useApi();

  const windowWidth = useWindowWidth();

  const isDesktop = windowWidth > 1024;

  const UserData = useSelector((state) => state.user.userDetails);
  const ExperienceDetails = useSelector(
    (state) => state.experience.expTrackDetails
  );
  const getOndcApiOrderCartData = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcApiGetOndcData
  );

  const { showSuccessAlert, showErrorAlert, AlertComponent } = useAlerts();

  const orderData = JSON.parse(sessionStorage.getItem("orderPostData"));
  const orderType = sessionStorage.getItem("cartOrderType");
  const [visible, setVisible] = useState(false);
  const [dialogBoxText, setDialogBoxText] = useState("");
  const [token, setToken] = useState(sessionStorage.getItem("accessToken"));
  const [getLocation, setGetLocation] = useState("");
  const [useWalletAmount, setUseWalletAmount] = useState(false);
  const [openAddMoneyModal, setOpenAddMoneyModal] = useState("");
  const [ondcCheckAvailability, setOndcCheckAvailability] = useState({});

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);

  const [disable, setDisable] = useState(false);

  const [selectedSplitPaymentMethod, setSelectedSplitPaymentMethod] =
    useState(false);
  const [selectedSplitPaymentMethodText, setSelectedSplitPaymentMethodText] =
    useState("");

  const [walletCheck, setWalletCheck] = useState(false);
  const expRequestDetails = useSelector(
    (state) => state.experience?.expTrackDetails
  );

  const [state, setState] = useState({
    isLoading: true,
    restData: undefined,
    restId: ID,
    openBankingModal: false,
    walletDetailsFinal: {},
    savedCardListArr: [],
    savedUpiListArr: [],
    currentOrderData: {},
    currentRestaurantInfo: {},
    currentTotalAmount: 0,
    currentOrderType: "",
    currentOrderId: "",
    currentActionBy: "",
    //************//
    setCustomerId: "",
    selectedUsedIdState: "",
    cardCvv: "",
    selectedPaymentMethod: "",
    netBankingPaymentMethod: "",
    netBankingPaymentKey: "",
  });

  const {
    isLoading,
    restId,
    openBankingModal,
    currentOrderData,
    walletDetailsFinal,
    savedCardListArr,
    currentRestaurantInfo,
    currentTotalAmount,
    currentOrderType,
    currentOrderId,
    currentActionBy,
    setCustomerId,
    selectedUsedIdState,
    cardCvv,
    selectedPaymentMethod,
    netBankingPaymentMethod,
    netBankingPaymentKey,
  } = state;

  const [storedItems, setStoredItems] = useState(
    JSON.parse(sessionStorage.getItem("cartItems")) || []
  );

  const [successModal, showSuccessModal] = useState(false);

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  // eslint-disable-next-line
  const [
    orderPostcancel,
    orderPostresponseData,
    orderPosterror,
    orderPostloaded,
    orderPostreset,
    orderPostexecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    ordercancel,
    orderresponseData,
    ordererror,
    orderloaded,
    orderreset,
    orderexecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    orderTrackcancel,
    ordertrackresponseData,
    orderTrackerror,
    orderTrackloaded,
    orderTrackreset,
    orderTrackexecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    transactioncancel,
    transactionresponseData,
    transactionerror,
    transactionloaded,
    transactionreset,
    transactionexecuteAPI,
  ] = useAxios();

  //razor pay order records //
  const [
    razorpayOrderRecordCancel,
    razorpayOrderRecordresponseData,
    razorpayOrderRecorderror,
    razorpayOrderRecordloaded,
    razorpayOrderRecordreset,
    razorpayOrderRecordexecuteAPI,
  ] = useAxios();

  //razor pay customer records //
  const [
    razorpayPaymentCancel,
    razorpayPaymentresponseData,
    razorpayPaymenterror,
    razorpayPaymentloaded,
    razorpayPaymentreset,
    razorpayPaymentexecuteAPI,
  ] = useAxios();

  //wallet payments //
  const [
    walletPaymentCancel,
    walletPaymentresponseData,
    walletPaymenterror,
    walletPaymentloaded,
    walletPaymentreset,
    walletPaymentexecuteAPI,
  ] = useAxios();

  //wallet payments //
  const [
    walletAmountCancel,
    walletAmountresponseData,
    walletAmounterror,
    walletAmountloaded,
    walletAmountreset,
    walletAmountexecuteAPI,
  ] = useAxios();

  //let UserData = JSON.parse(sessionStorage.getItem('userInformation'));

  // let OrderData = JSON.parse(sessionStorage.getItem("order_details"));

  const subscriptionData = state?.restData?.subscription?.casual_dining
    ? state?.restData?.subscription?.casual_dining_status
    : state?.restData?.subscription?.fast_food_dining
      ? state?.restData?.subscription?.fast_food_dining_status
      : state?.restData?.subscription?.multi_service_business
        ? state?.restData?.subscription?.multi_service_business_status
        : {};

  // let summary = calcFinalTotal(storedItems, UserData, '', subscriptionData);

  const [showOtpModal, setshowOtpModal] = useState(false);
  const [showDineInModal, setshowDineInModal] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  useEffect(() => {
    const fetchRestaurantDetails = async () => {
      if (restId !== undefined && restId !== null) {
        try {
          const restaurantResponse = await executeAPI(
            `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,
            "GET",
            null
          );
          setRestaurantDetails(restaurantResponse);
          walletAmountexecuteAPI(
            `${URL.WALLETAMOUNT}/${UserData?.wallet_id}`,
            "GET",
            null,
            {
              Authorization: UserData.accessToken,
            }
          );
        } catch (error) {
          console.log("error", error);
        }
      }
    };
    fetchRestaurantDetails();
  }, [restId]);
  //console.log("restaurantDetails", restaurantDetails);
  // if (!sessionStorage.getItem("accessToken")) setshowOtpModal(true);
  // else if (!JSON.parse(sessionStorage.getItem("order_details"))?.proceed) {
  //   setshowDineInModal(true);
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(dialogBoxText)) {
      const timeoutId = setTimeout(() => {
        setDialogBoxText("");
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [dialogBoxText]);

  useEffect(() => {
    if (propsData?.state?.from !== "ondc") {
      if (isEmpty(orderData)) {
        navigate(`/restaurant/${restId}/food/menu`);
      }
    }
  }, [orderData]);

  useEffect(() => {
    if (propsData?.state?.from === "ondc") {
      setOndcCheckAvailability(propsData?.state?.ondcCheckAvailability);
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  useEffect(() => {
    if (orderresponseData) {
      // if we place order from experience, navigate to experience track screen else show success model
      // add experience id if we order food items from experience

      orderTrackexecuteAPI(
        `${URL.USER_ORDER}/${orderresponseData?._id}`,
        "GET",
        null,
        {
          Authorization: UserData.accessToken,
        },
        (res) => {
          if (propsData?.state?.from == "EXPERIENCE") {
            // orderData.expe_request_id = ExperienceDetails?._id;
            navigate(
              `/experience/${expRequestDetails?.restaurantId?._id}/${expRequestDetails?.experienceId?._id}/orderstatus`
            );
          } else {
            showSuccessModal(true);
          }
        }
      );
    }
  }, [orderresponseData]);

  const naviageViewMenu = () => {
    navigate(`/restaurant/${ID}/viewmenu`);
  };

  const handleProceed = () => {
    if (UserData.accessToken) {
      let formData = JSON.parse(sessionStorage.getItem("orderPostData"));
      const order_details = JSON.parse(sessionStorage.getItem("order_details"));
      let user_details = JSON.parse(sessionStorage.getItem("userInformation"));
      formData = { ...formData, ...order_details };
      formData.user_details = {
        first_name: user_details?.first_name,
        last_name: user_details?.last_name,
        country_code: user_details?.country_code,
        mobile_number: user_details?.mobile_number,
        user_id: user_details?._id,
        email: user_details?.email,
        token: user_details?.FCMtoken,
      };
      formData.total_amount = orderData?.total_amount;
      formData.token = user_details?.FCMtoken;
      formData.user_id = user_details?._id;
      formData.payment_method = 6;
      formData.payment_status = 0;
      formData.user_location = {
        lat: user_details?.lastLocation.coordinates[1],
        long: user_details?.lastLocation.coordinates[0],
      };
      formData.totalDinePax = order_details?.totalDinePax;

      formData["trackLink"] =
        window.location.protocol +
        "//" +
        window.location.hostname +
        `/restaurant/${ID}/ordertrack`;
      orderPostexecuteAPI(`${URL.USER_ORDER}`, "POST", formData, {
        Authorization: UserData.accessToken,
      });
    }
  };

  const getOrderData = () => {
    if (UserData.accessToken) {
      let formData = JSON.parse(sessionStorage.getItem("orderPostData"));
      const order_details = JSON.parse(sessionStorage.getItem("order_details"));
      let user_details = UserData;
      formData = { ...formData, ...order_details };
      formData.user_details = {
        first_name: user_details?.first_name,
        last_name: user_details?.last_name,
        country_code: user_details?.country_code,
        mobile_number: user_details?.mobile_number,
        user_id: user_details?._id,
        email: user_details?.email,
        token: user_details?.FCMtoken,
      };
      formData.user_id = user_details?._id;

      formData.total_amount = orderData?.total_amount;
      formData.token = user_details?.FCMtoken;
      formData.totalDinePax = order_details?.totalDinePax;
      formData["trackLink"] =
        window.location.protocol +
        "//" +
        window.location.hostname +
        `/restaurant/${ID}/ordertrack`;
      formData.user_location = {
        lat: !isEmpty(location)
          ? location?.lat
          : UserData?.lastLocation?.coordinates[1],
        long: !isEmpty(location)
          ? location?.lng
          : UserData?.lastLocation?.coordinates[0],
      };
      return formData;
    }
    return null;
  };

  const walletSelected = () => {
    if (!walletCheck) {
      setState({
        ...state,
        selectedPaymentMethod: "WALLET",
      });
    } else if (walletCheck) {
      setState({
        ...state,
        selectedPaymentMethod: "",
      });
    }
    setWalletCheck(!walletCheck);
  };

  // useEffect(() => {
  //   if (orderPostresponseData?._id) {
  //     orderexecuteAPI(
  //       `${URL.USER_ORDER}/${orderPostresponseData?._id}`,
  //       'PATCH',
  //       {
  //         order_status: 0,
  //         currentStatusText: 'Pending',
  //       },
  //       {
  //         Authorization: UserData.accessToken,
  //       }
  //     );
  //     transactionexecuteAPI(
  //       `${URL.UPDATE_TRANSACTION}`,
  //       'POST',
  //       {
  //         paid: false,
  //         method: 'PAYLATER',
  //         amount: orderPostresponseData?.base_amount,
  //         type: 'PAYLATER',
  //         date: moment().valueOf(),
  //         paymentType: 6,
  //         id: orderPostresponseData?._id,
  //       },
  //       {
  //         Authorization: UserData.accessToken,
  //       }
  //     );
  //   }
  // }, [orderPostresponseData]);

  const backButton = () => {
    if (propsData?.state?.fromOndc) {
      navigate(`/ondc/${ID}/cartpage`, {
        state: {
          from: state?.from,
          fromOndc: true,
        },
      });
    } else {
      navigate(`/restaurant/${ID}/food/cartpage`);
    }
  };

  /**************************************
   * @DESC PAYMENT METHOD SELECTION FUNC
   *************************************/
  const onButtonClickAction = (infoData) => {
    let upperTitle = infoData.title.split(" ").join("").trim().toUpperCase();
    if (
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) >=
      +currentTotalAmount.toFixed(2) &&
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) + +currentTotalAmount.toFixed(2) >
      10000
    ) {
      setVisible(true);
      setDialogBoxText(
        "You have sufficient wallet balance for this order, Please use your wallet for placing this order"
      );
    } else if (
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) <
      +currentTotalAmount.toFixed(2) &&
      selectedPaymentMethod.toLowerCase() === "wallet" &&
      (infoData?.title.toLowerCase() === "bhim upi" ||
        infoData?.title.toLowerCase() === "debit card" ||
        infoData?.title.toLowerCase() === "credit card" ||
        upperTitle.toLowerCase() === "netbanking" ||
        // infoData?.title.toLowerCase() === "pay at site" ||
        infoData?.title.toLowerCase() === "upi_default")
    ) {
      if (!isEmpty(UserData.accessToken)) {
        setSelectedSplitPaymentMethod(true);
        onClickCheckSplitPayment(infoData);
      } else {
        // props.navigation.navigate('Login', {
        //   fromAppFlow: true,
        // });
      }
    } else {
      if (UserData.accessToken)
        if (infoData.isOpenModal && infoData.linkType === "MODAL") {
          setUseWalletAmount(false);
          if (upperTitle === "NETBANKING") {
            setState({
              ...state,
              selectedPaymentMethod: upperTitle,
              openBankingModal: true,
            });
          } else {
            setState({
              ...state,
              selectedPaymentMethod: upperTitle,
              openBankingModal: false,
            });
          }
        } else {
          setUseWalletAmount(false);
          if (selectedPaymentMethod === infoData?.title) {
            setState({
              ...state,
              selectedPaymentMethod: "",
              netBankingPaymentMethod: "",
              netBankingPaymentKey: "",
            });
          } else {
            setState({
              ...state,
              selectedPaymentMethod: infoData?.title,
              netBankingPaymentMethod: "",
              netBankingPaymentKey: "",
            });
          }
        }
      else {
      }
    }
  };

  ///check split payment //

  const onClickCheckSplitPayment = (selectedInfo) => {
    let upperTitle = selectedInfo.title
      .split(" ")
      .join("")
      .trim()
      .toUpperCase();
    if (
      selectedPaymentMethod === "WALLET" &&
      selectedSplitPaymentMethodText.toLowerCase() !==
      selectedInfo?.title.toLowerCase()
    ) {
      setSelectedSplitPaymentMethod(true);
      if (upperTitle.toLowerCase() === "netbanking") {
        setSelectedSplitPaymentMethodText("NETBANKING");
      } else {
        setSelectedSplitPaymentMethodText(selectedInfo?.title);
      }
    } else {
      setSelectedSplitPaymentMethod(false);
      setSelectedSplitPaymentMethodText("");
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Function to handle input change
  const setDisableBtn = (e) => {
    setDisable(false);
  };

  const debouncedHandleInputChange = debounce(setDisableBtn, 4000);

  /******************************************************
   * @DESC ON CLICK PROCEED ACTION CALL
   *****************************************************/
  const onClickProceedAction = () => {
    setDisable(true);
    debouncedHandleInputChange();
    if (selectedPaymentMethod === "WALLET") {
      if (walletAmountresponseData?.balance > orderData?.total_amount) {
        onClickPayByWalletAmount();
      } else {
        showErrorAlert(
          "Insufficient wallet amount, please select different payment method"
        );
      }
    } else if (
      selectedPaymentMethod === "Debit Card" ||
      selectedPaymentMethod === "Credit Card" ||
      selectedPaymentMethod === "NETBANKING" ||
      selectedPaymentMethod === "Pay At Site" ||
      selectedPaymentMethod === "BHIM UPI" ||
      selectedPaymentMethod === "UPI_DEFAULT"
    ) {
      onClickPayByWalletAmount();
    } else {
      setVisible(true);
      setDialogBoxText("Select at least one payment method");
    }
  };

  /******************************************************
   * @DESC PAYMENT STEP 1: CREATE ORDER
   ******************************************************/
  const onClickPayByWalletAmount = () => {
    setState({
      ...state,
      isLoading: true,
    });
    if (selectedPaymentMethod === "Pay At Site") {
      let formData = getOrderData();
      formData.payment_method = 6;
      formData.payment_pending = false;
      formData.payment_status = 0;
      // add experience id if we order food items from experience
      if (propsData?.state?.from == "EXPERIENCE") {
        formData.expe_request_id = ExperienceDetails?._id;
      }
      orderPostexecuteAPI(
        `${URL.USER_ORDER}`,
        "POST",
        formData,
        {
          Authorization: UserData.accessToken,
        },
        (resData) => razorPayPaymentSuccess(resData)
      );
      // dispatch(
      //   create_User_Order_Records(
      //     formData,
      //     (resData) => orderPayLaterSuccess(resData),
      //     (errData) => orderCallbackError(errData)
      //   )
      // );
    } else {
      let orderData = getOrderData();
      orderData.payment_method = NEW_PAYMENT_TYPE_METHOD.indexOf(
        selectedPaymentMethod
      );
      orderData.payment_status = 0;
      // add experience id if we order food items from experience
      if (propsData?.state?.from == "EXPERIENCE") {
        orderData.expe_request_id = ExperienceDetails?._id;
      }
      if (propsData?.state?.from === "ondc") {
        onClickOndcPaymentThrowRazorpay(
          propsData?.state,
          getOndcApiOrderCartData
        );
      } else {
        if (orderData) {
          orderPostexecuteAPI(
            `${URL.USER_ORDER}`,
            "POST",
            orderData,
            {
              Authorization: UserData.accessToken,
            },
            (resData) => orderCallbackSuccess(resData.data)
          );
        }
      }
      // dispatch(
      //   create_User_Order_Records(
      //     currentOrderData,
      //     (resData) => orderCallbackSuccess(resData),
      //     (errData) => orderCallbackError(errData)
      //   )
      // );
    }
  };

  /******************************************************
   * @DESC PAYMENT STEP 2: CREATE RAZOR PAY ORDER RECORDS
   *****************************************************/
  const orderCallbackSuccess = (orderSuccess) => {
    let orderID = orderSuccess?._id;
    sessionStorage.setItem("currentOrderID", orderID);
    let formData = {
      amount: selectedSplitPaymentMethod
        ? +orderSuccess?.pendingAmount
        : +orderSuccess?.total_amount,
      user_id: orderSuccess?.user_id,
      transaction_type: "RAZORPAY",
      refModel: "User",
      message: "",
      currency: "INR",
      isEvent: false,
      restaurant_id: orderSuccess?.restaurant_id?._id,
      type: "razorpay",
    };
    if (!isEmpty(orderID)) {
      razorpayOrderRecordexecuteAPI(
        `${URL.RAZORPAY_ORDER_RECORD}`,
        "POST",
        formData,
        {
          Authorization: UserData.accessToken,
        },
        (resData) => razorpayCallbackSuccess(resData.data)
      );
      // dispatch(
      //   razorpayOrderRecordexecuteAPI(
      //     formData,
      //     orderID,
      //     (resData, orderID) => razorpayCallbackSuccess(resData, orderID),
      //     (errData) => orderCallbackError(errData)
      //   )
      // );
    }
  };

  /******************************************************
   * @DESC PAYMENT STEP 3: CREATE RAZOR PAY CUSTOMER RECORDS
   *****************************************************/
  const razorpayCallbackSuccess = (prevApiResponse) => {
    sessionStorage.setItem(
      "razorpayOrderDetails",
      JSON.stringify(prevApiResponse)
    );
    let encodedString =
      "Basic cnpwX2xpdmVfM210WVZPOG5ST2I1VXE6QWVibE1ac2xWTjdhVjg5SXRnZXpEWGxB";
    if (selectedPaymentMethod === "WALLET" && !selectedSplitPaymentMethod) {
      let formData = {
        user_id: prevApiResponse?.transactional?.user_id,
        transaction_type: "WALLET",
        amount: prevApiResponse?.transactional?.amount,
        restaurant_id: prevApiResponse?.transactional?.restaurant_id,
        orderId: sessionStorage.getItem("currentOrderID"),
        transactional_id: prevApiResponse?.transactional?._id,
      };
      walletPaymentexecuteAPI(
        `${URL.WALLET_PAYMENT}`,
        "POST",
        formData,
        {
          Authorization: UserData.accessToken,
        },
        (resData) => razorPayPaymentSuccess(resData)
      );
    } else {
      let formData = {
        name: UserData?.first_name + " " + UserData?.last_name,
        contact: UserData?.mobile_number,
        email: UserData?.email,
        fail_existing: "0",
        notes: {
          notes_key_1: "",
          notes_key_2: "",
        },
      };

      // create_Razorpay_Customer_Records(
      //   formData,
      //   prevApiResponse,
      //   orderId,
      //   encodedString,
      //   (resData, prevApiResponse, orderID) =>
      //     razorpayCustomerSuccess(resData, prevApiResponse, orderID),
      //   (errData) => orderCallbackError(errData)
      // );
      razorpayCustomerSuccess(
        "cust_N3Ms9PumHZ9Gn2",
        prevApiResponse,
        sessionStorage.getItem("currentOrderID")
      );
    }
  };

  const orderCallbackError = (err) => {
    console.log(err);
  };

  // const razorpayCustomerSuccess = () => {};

  /******************************************************
   * @DESC PAYMENT STEP 4: WALLET PAYMENT API SUCCESS
   *****************************************************/
  const paymentApiSuccess = (resData) => {
    let formData = {
      payment_status: 1,
      payment_method: 7,
      transactional_id: resData?._id,
      razorpay_payment_id: "",
      transactional: {
        payment_captured: true,
      },
    };
    razorPayPaymentSuccess();
  };

  /******************************************************
   * @DESC PAYMENT STEP 4: OPEN RAZOR PAY MODAL
   *****************************************************/
  const razorpayCustomerSuccess = (
    customerInfo,
    prevResponse,
    orderID,
    razorPayPaymentApiSuccess,
    orderCallbackError
  ) => {
    const customerID = customerInfo;

    // Define options for the Razorpay modal
    const options = {
      key: process.env.RAZORPAY_KEY, // Replace with your Razorpay API key
      amount: prevResponse?.amount, // Amount in smallest currency unit (e.g., paise for INR)
      currency: "INR", // Currency code (e.g., 'INR')
      name: "Amealio",
      description: "Amealio Ordering Payment",
      image: "https://your-image-url.com/logo.png", // Optional: logo or image URL
      order_id: prevResponse?.transactional?.order_id,
      // customer_id: customerID,
      prefill: {
        name: UserData?.firstName,
        email: UserData?.email,
        contact: `${UserData?.country_code} ${UserData?.mobile_number}`,
      },
      method: {
        netbanking: selectedPaymentMethod === "NETBANKING",
        card:
          selectedPaymentMethod === "Debit Card" ||
          selectedPaymentMethod === "Credit Card",
        wallet: false,
        upi: selectedPaymentMethod === "BHIM UPI",
      },
      notes: {
        order_id: orderID,
      },

      handler: (response) => {
        if (response?.razorpay_payment_id) {
          const paymentDetails = {
            transactional_id: prevResponse?.transactional?._id,
            razorpay_payment_id: response?.razorpay_payment_id,
            isEvent: false,
            order_id: sessionStorage.getItem("currentOrderID"),
          };
          razorpayPaymentexecuteAPI(
            `${URL.RAZORpAY_PAYMENT_RECORD}`,
            "POST",
            paymentDetails,
            {
              Authorization: UserData.accessToken,
            },
            (resData) => razorPayPaymentSuccess(resData)
          );
          // Call the success callback function directly
        }
      },
      prefill: {
        name: UserData?.firstName,
        email: UserData?.email,
        contact: `${UserData?.country_code}${UserData?.mobile_number}`,
      },
    };

    // Additional options for payment methods
    if (
      selectedPaymentMethod.toUpperCase() === "WALLET" &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText.toLowerCase() === "netbanking") {
        options.bank = netBankingPaymentKey;
      }
    } else if (selectedPaymentMethod.toLowerCase() === "netbanking") {
      options.bank = netBankingPaymentKey;
    }

    if (
      selectedPaymentMethod.toUpperCase() === "WALLET" &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText === "UPI_DEFAULT") {
        options.vpa = selectedUsedIdState;
      }
    } else if (selectedPaymentMethod === "UPI_DEFAULT") {
      options.vpa = selectedUsedIdState;
    }
    displayRazorpay(options);

    // const rzp1 = new Razorpay(options);

    // rzp1.on('payment.failed', function (response) {
    //   alert(response.error.code);
    //   alert(response.error.description);
    //   alert(response.error.source);
    //   alert(response.error.step);
    //   alert(response.error.reason);
    //   alert(response.error.metadata.order_id);
    //   alert(response.error.metadata.payment_id);
    // });

    // rzp1.open();

    // Create a new Razorpay instance
    // const rzp1 = new Razorpay(options);

    // Open the Razorpay modal
    // rzp1
    //   .open()
    //   .then((response) => {
    //     if (response?.razorpay_payment_id) {
    //       const paymentDetails = {
    //         transactional_id: prevResponse?.transactional?._id,
    //         razorpay_payment_id: response?.razorpay_payment_id,
    //         isEvent: false,
    //         order_id: orderId,
    //       };
    //       // Call the success callback function directly
    //       console.log('called modal success');
    //     }
    //   })
    //   .catch((error) => {
    //     setState({
    //       ...state,
    //       loading: false,
    //     });

    //     // Parse error message
    //     const errorMessage = JSON.parse(error?.description);

    //     // Handle error case
    //     orderCallbackError(
    //       errorMessage?.error?.description || error?.data?.message
    //     );
    //   });
  };

  /******************************************************
   * @DESC PAYMENT STEP 5: OPEN RAZOR PAY AFTER SUCCESSFULL PAYMENT
   *****************************************************/
  const razorPayPaymentSuccess = (data) => {
    try {
      if (data?.status === 201 || data?.status === 200) {
        if (selectedPaymentMethod === "Pay At Site") {
          orderexecuteAPI(
            `${URL.USER_ORDER}/${data?.data?._id}`,
            "PATCH",
            {
              order_status: 0,
              currentStatusText: "Pending",
            },
            {
              Authorization: UserData.accessToken,
            }
          );
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            "POST",
            {
              paid: false,
              method: "PAYLATER",
              amount: data?.data?.base_amount,
              type: "PAYLATER",
              date: moment().valueOf(),
              paymentType: 6,
              id: data?.data?._id,
            },
            {
              Authorization: UserData.accessToken,
            }
          );
        } else if (
          selectedPaymentMethod === "Debit Card" ||
          selectedPaymentMethod === "Credit Card" ||
          selectedPaymentMethod === "NETBANKING" ||
          selectedPaymentMethod === "BHIM UPI" ||
          selectedPaymentMethod === "UPI_DEFAULT"
        ) {
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            "POST",
            {
              paid: true,
              method: "RAZORPAY",
              amount: orderPostresponseData?.base_amount,
              type: "PAYMENT",
              date: moment().valueOf(),
              paymentType: NEW_PAYMENT_TYPE_METHOD.indexOf(
                selectedPaymentMethod
              ),
              id: sessionStorage.getItem("currentOrderID"),
              transactionId:
                razorpayOrderRecordresponseData?.transactional?._id,
              txnId:
                razorpayOrderRecordresponseData?.transactional
                  ?.transactionNumber,
            },
            {
              Authorization: UserData.accessToken,
            },
            () => { }
          );

          orderexecuteAPI(
            `${URL.USER_ORDER}/${sessionStorage.getItem("currentOrderID")}`,
            "PATCH",
            {
              order_status: 0,
              currentStatusText: "Pending",
              payment_status: 1,
              transactional_id: JSON.parse(
                sessionStorage.getItem("razorpayOrderDetails")
              ).transactional?._id,
            },
            {
              Authorization: UserData.accessToken,
            },
            () => { }
          );
        } else if (selectedPaymentMethod === "WALLET") {
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            "POST",
            {
              paid: true,
              method: "WALLET",
              amount: orderPostresponseData?.base_amount,
              type: "PAYMENT",
              date: moment().valueOf(),
              paymentType: NEW_PAYMENT_TYPE_METHOD.indexOf(
                selectedPaymentMethod
              ),
              id: sessionStorage.getItem("currentOrderID"),
              transactionId: walletPaymentresponseData?._id,
              txnId: "",
            },
            {
              Authorization: UserData.accessToken,
            },
            () => { }
          );
          orderexecuteAPI(
            `${URL.USER_ORDER}/${sessionStorage.getItem("currentOrderID")}`,
            "PATCH",
            {
              order_status: 0,
              currentStatusText: "Pending",
              payment_status: 1,
              transactional_id: JSON.parse(
                sessionStorage.getItem("razorpayOrderDetails")
              ).transactional?._id,
            },
            {
              Authorization: UserData.accessToken,
            },
            () => { }
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * @DESC ONDC PAYMENT PROCESS
   */
  const onClickOndcPaymentThrowRazorpay = (loctionData, getOndcOrderCart) => {
    const options = {
      key: "rzp_live_3mtYVO8nROb5Uq", // Replace with your Razorpay API key
      // key: process.env.RAZORPAY_KEY, // Replace with your Razorpay API key
      amount: "1.00",
      // loctionData?.ondcCheckAvailability?.message?.order?.quote?.price?.value, // Amount in smallest currency unit (e.g., paise for INR)
      currency: "INR", // Currency code (e.g., 'INR')
      name: "Amealio",
      description: "Amealio Ordering Payment",
      image: "https://your-image-url.com/logo.png", // Optional: logo or image URL
      order_id: loctionData?.orderData?.orderID?.razorpay_order_id,
      // customer_id: customerID,
      prefill: {
        name: UserData?.firstName,
        email: UserData?.email,
        contact: `${UserData?.country_code} ${UserData?.mobile_number}`,
      },
      method: {
        netbanking: selectedPaymentMethod === "NETBANKING",
        card:
          selectedPaymentMethod === "Debit Card" ||
          selectedPaymentMethod === "Credit Card",
        wallet: false,
        upi: selectedPaymentMethod === "BHIM UPI",
      },
      notes: {
        order_id: loctionData?.orderData?.orderID?.razorpay_order_id,
        reciept_id: loctionData?.orderData?.orderID?.razorpay_reciept_id,
      },

      handler: (response) => {
        if (response?.razorpay_payment_id) {
          if (propsData?.state?.from == "ondc") {
            razorpayOndcCallbackSuccess(response?.razorpay_payment_id);

            return true;
          }

          const paymentDetails = {
            transactional_id:
              loctionData?.orderData?.orderID?.payment_transaction_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            isEvent: false,
            order_id: loctionData?.orderData?.orderID?.order_id,
          };
          razorpayPaymentexecuteAPI(
            `${URL.RAZORpAY_PAYMENT_RECORD}`,
            "POST",
            paymentDetails,
            {
              Authorization: UserData.accessToken,
            },
            (resData) => razorpayOndcCallbackSuccess(resData)
          );
        }
      },
      prefill: {
        name: UserData?.first_name + " " + UserData?.last_name,
        email: UserData?.email,
        contact: `${UserData?.country_code}${UserData?.mobile_number}`,
      },
    };

    // Additional options for payment methods
    if (
      selectedPaymentMethod.toUpperCase() === "WALLET" &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText.toLowerCase() === "netbanking") {
        options.bank = netBankingPaymentKey;
      }
    } else if (selectedPaymentMethod.toLowerCase() === "netbanking") {
      options.bank = netBankingPaymentKey;
    }
    if (
      selectedPaymentMethod.toUpperCase() === "WALLET" &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText === "UPI_DEFAULT") {
        options.vpa = selectedUsedIdState;
      }
    } else if (selectedPaymentMethod === "UPI_DEFAULT") {
      options.vpa = selectedUsedIdState;
    }
    displayRazorpay(options);
  };

  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      // paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      // paymentId.current = response.error.metadata.payment_id;
      console.log("payment failed ", response);
    });

    // to open razorpay checkout modal.
  };

  const loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        console.log("razorpay loaded successfully");
        resolve(true);
      };
      script.onerror = () => {
        console.log("error in loading razorpay");
        resolve(false);
      };
      document.body.appendChild(script);
    });

  /******************************************************
   * @DESC PAYMENT STEP 3: CREATE RAZOR PAY CUSTOMER RECORDS
   *****************************************************/
  const razorpayOndcCallbackSuccess = async (payment_id) => {
    let formData = {
      payment_type: selectedPaymentMethod === "WALLET" ? "WALLET" : "RAZORPAY", // RAZORPAY, WALLET
      order_id: propsData?.state?.orderData?.orderID?.order_id,
      payment_id: payment_id,
    };
    await handleRequest("patch", `${URL.ONDC_USER_CART_ORDER}`, formData, {
      headers: { Authorization: UserData.accessToken },
      successMessage: "",
      errorMessage: "Something went wrong! Please try again",
      onSuccess: async (response) => {
        // fetch all communities from this location
        if (response.status) {
          // "add redirection rote";
          navigate(
            `/ondc/${ID}/ondctrack/${propsData?.state?.orderData?.orderID?.order_id}`
          );
        }
      },
      onError: (error) => {
        console.log("error Info=>", error);
      },
    });
  };

  const content = (
    <>
      {!isEmpty(dialogBoxText) ? (
        <NotificationAlert alert={dialogBoxText} variant={"error"} />
      ) : (
        <NotificationAlert
          alert={orderPosterror?.response?.data?.message}
          variant={"error"}
        />
      )}
      <div className="p-2" style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        <div className=" mb-3" style={{ color: "#EE3A23", fontSize: "30px" }}>
          <strong>
            {propsData?.state?.from === "ondc"
              ? getOndcApiOrderCartData?.restaurant_details?.descriptor?.name
              : restaurantDetails?.data?.restaurant_name || ""}{" "}
          </strong>
        </div>
        <div
          className="d-flex flex-column flex-md-row justify-content-between mb-4"
          style={{
            gap: "-10px",
          }}
        >
          <div
            className="p-3 rounded w-100 w-md-50 mb-4 mb-md-0 mr-md-2"
            style={{ backgroundColor: "#F0F3FC" }}
          >
            <div>
              <span
                className="f14-700"
                style={{ fontSize: "40px !important", fontWeight: "bold" }}
              >
                Total Amount To Be Paid
              </span>

              {/* <span className="f16-700 order_bg">
                {propsData?.state?.from === "ondc"
                  ? ondcCheckAvailability?.message?.order?.fulfillments?.[0]
                    ?.type
                  : ORDER_TYPE_NAME[ORDER_TYPES.indexOf(orderType)]}
              </span> */}
            </div>
            <div className="d-flex justify-content-between align-items-end mt-2">
              <span className="f24-700">{`₹ ${propsData?.state?.from === "ondc"
                ? ondcCheckAvailability?.message?.order?.quote?.price?.value
                : orderData?.total_amount || 0
                }`}</span>
              <span className="f12-700"
                style={{ marginTop: "-10px" }}>
                Order ID:{" "}
                {propsData?.state?.from === "ondc"
                  ? propsData?.state?.orderData?.orderID?.order_id
                  : orderData?.order_id}
              </span>
            </div>
          </div>

          <div
            className="bg-light p-3 rounded w-100 w-md-50"
            style={{ backgroundColor: "#80808014", borderRadius: "20px" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={walletCheck}
                  onChange={() => walletSelected(walletCheck)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span className="f24-700">Wallet</span>
              </div>
              <button
                className="btn btn-danger btn-lg"
                style={{
                  minWidth: "30px",
                  borderRadius: "10px",
                  marginBottom: "-100px",
                  fontSize: "13px",
                }}
              >
                Add Money
              </button>
            </div>
            <div>
              <span
                className="f24-700"
                style={{ color: assets.colors.primary }}
              >
                {`₹ ${walletAmountresponseData?.balance || 0}`}
              </span>
              <div className="f12-700 text-grey mt-1">Current Balance</div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="w-100 w-md-50 mb-4 mb-md-0"></div>

          <div className="w-100 w-md-50">
            {/* <div className="f16-700 my-1 mt-2 pl-2">UPI</div>
            <div className="shadow-md border-radius mb-3">
              {UPI_ARR.map((data) => (
                <React.Fragment key={data.title}>
                  <button
                    className={`p-2 border-radius flex-between cursor-pointer w-full ${
                      selectedPaymentMethod
                        .split(" ")
                        .join("")
                        .trim()
                        .toUpperCase() ===
                      data.title.split(" ").join("").trim().toUpperCase()
                        ? "border-active"
                        : ""
                    }`}
                    onClick={() => onButtonClickAction(data)}
                  >
                    <div className="d-flex">
                      <div>
                        <Image
                          src={require("../../../assets/images/IconsandImages/paylater.jpeg")}
                          width={24}
                        />
                      </div>
                      <div className="f14-700 flexCenter px-3">
                        {data.title}
                      </div>
                    </div>
                    <div>
                      <KeyboardArrowRightRoundedIcon />
                    </div>
                  </button>
                </React.Fragment>
              ))}
            </div> */}
            <div className="f16-700 mb-2 pl-2">Pay With</div>
            <div className="shadow-md border-radius">
              <div className=" border-radius ">
                {UPI_ARR.map((data) => (
                  <React.Fragment key={data.title}>
                    <button
                      className={`p-2 border-radius flex-between cursor-pointer w-full ${selectedPaymentMethod
                        .split(" ")
                        .join("")
                        .trim()
                        .toUpperCase() ===
                        data.title.split(" ").join("").trim().toUpperCase()
                        ? "border-active"
                        : ""
                        }`}
                      onClick={() => onButtonClickAction(data)}
                    >
                      <div className="d-flex">
                        <div>
                          <Image
                            src={require("../../../assets/images/IconsandImages/bhimup.png")}
                            width={24}
                          />
                        </div>
                        <div className="f14-700 flexCenter px-3">
                          {data.title}
                        </div>
                      </div>
                      <div>
                        <KeyboardArrowRightRoundedIcon />
                      </div>
                    </button>
                  </React.Fragment>
                ))}
              </div>
              {PAY_WITH_ARR.map((data) => (
                <button
                  key={data.title}
                  className={`p-2 my-2 border-radius flex-between w-full ${selectedPaymentMethod
                    .split(" ")
                    .join("")
                    .trim()
                    .toUpperCase() ===
                    data.title.split(" ").join("").trim().toUpperCase()
                    ? "border-active"
                    : ""
                    }`}
                  onClick={() => onButtonClickAction(data)}
                >
                  <div className="d-flex">
                    <Image
                      src={require("../../../assets/images/IconsandImages/debit.png")}
                      width={22}
                    />
                    <div className="f14-700 flexCenter px-3">{data.title}</div>
                  </div>
                  <KeyboardArrowRightRoundedIcon />
                </button>
              ))}
              {propsData?.state?.from !== "ondc" && (
                <div className="shadow-md border-radius">
                  {PAY_LATER_ARR.map((data) => (
                    <button
                      key={data.title}
                      className={`p-2 border-radius flex-between w-full ${selectedPaymentMethod
                        .split(" ")
                        .join("")
                        .trim()
                        .toUpperCase() ===
                        data.title.split(" ").join("").trim().toUpperCase()
                        ? "border-active"
                        : ""
                        }`}
                      onClick={() => onButtonClickAction(data)}
                    >
                      <div className="d-flex">
                        <Image
                          src={require("../../../assets/images/IconsandImages/paylater.jpeg")}
                          width={24}
                        />
                        <div className="f14-700 flexCenter px-3">
                          {data.title}
                        </div>
                      </div>
                      <KeyboardArrowRightRoundedIcon />
                    </button>
                  ))}
                </div>
              )}

            </div>

            {/* <div className="f16-700 my-2 pl-2 mt-3">Pay Later</div>
            <div className="shadow-md border-radius">
              {PAY_LATER_ARR.map((data) => (
                <button
                  key={data.title}
                  className={`p-2 border-radius flex-between w-full ${
                    selectedPaymentMethod
                      .split(" ")
                      .join("")
                      .trim()
                      .toUpperCase() ===
                    data.title.split(" ").join("").trim().toUpperCase()
                      ? "border-active"
                      : ""
                  }`}
                  onClick={() => onButtonClickAction(data)}
                >
                  <div className="d-flex">
                    <Image
                      src={require("../../../assets/images/IconsandImages/paylater.jpeg")}
                      width={24}
                    />
                    <div className="f14-700 flexCenter px-3">{data.title}</div>
                  </div>
                  <KeyboardArrowRightRoundedIcon />
                </button>
              ))}
            </div> */}
          </div>
        </div>
      </div>
      {/* Bottom Buttons */}
      <div
        style={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: "100",
          padding: "10px 0",
        }}
      >
        <div className="row w-100 m-0">
          <hr className="m-0 p-0" />
          <div className="col-12 col-md-6 col-lg-4 p-0 mb-[50px]">
            <div className="flex justify-center md:justify-end items-center gap-[15px] mt-2 lg:mr-[-700px]">
              <button
                className="btn btn-outline-secondary btn-lg hover:bg-white"
                style={{
                  minWidth: "140px",
                  height: "45px",
                  borderRadius: "14px",
                  borderColor: "red",
                  color: "red",
                  padding: "6px 12px",
                }}
                onClick={backButton}
              >
                Cancel
              </button>

              <button
                className="btn btn-danger btn-lg"
                style={{
                  minWidth: "140px",
                  height: "45px",
                  borderRadius: "14px",
                  padding: "6px 12px",
                }}
                disabled={disable}
                onClick={onClickProceedAction}
              >
                Proceed
              </button>

            </div>
          </div>
        </div>
      </div>


      <SuccessModals
        order_id={orderPostresponseData?._id}
        successModal={successModal}
        showSuccessModal={(e) => showSuccessModal(e)}
      />
    </>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
}

export default CheckOutPage;

const create_Razorpay_Customer_Records = async (
  formData,
  previousResponse,
  orderID,
  orderToken,
  callback,
  callback2
) => {
  let headers = {
    "Content-Type": "application/json",
  };

  if (orderToken) {
    headers.Authorization = orderToken;
  }

  try {
    let response = await axios({
      url: `https://api.razorpay.com/v1/customers`,
      method: "POST",
      data: formData,
      headers,
      timeout: 120000,
    });

    // Check response status and data
    if (response.status === 201 || response.status === 200) {
      // Call the success callback function
      callback(response.data, previousResponse, orderID);
    }
  } catch (error) {
    // Call the error callback function
    callback2(error);
  }
};
