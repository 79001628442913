import React from 'react';
import { Image } from 'react-bootstrap';
import { LoginSocialFacebook } from 'reactjs-social-login';

const FacebookLoginButton = () => {
  const handleFacebookResponse = (response) => {
    if (response.accessToken) {
      console.log('Facebook OAuth Success:', response);

      // Fetch user details from Facebook Graph API
      fetchFacebookUserInfo(response.accessToken);
    } else {
      console.log('Facebook OAuth Failed');
    }
  };

  const fetchFacebookUserInfo = async (accessToken) => {
    try {
      const response = await fetch(
        `https://graph.facebook.com/me?fields=id,name,email&access_token=${accessToken}`
      );
      const userInfo = await response.json();
      console.log('User Info:', userInfo);

      // Store user details in session storage
      sessionStorage.setItem('facebookUser', JSON.stringify(userInfo));

      // Store access token in session storage (optional)
      sessionStorage.setItem('facebookAccessToken', accessToken);
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  };
  const handleFacebookLoginSuccess = (data) => {
    console.log(data);
  };
  const handleLoginFailure = (data) => {
    console.log(data);
  };

  return (
    <LoginSocialFacebook
      appId="1818822588490915"
      onResolve={handleFacebookLoginSuccess}
      onReject={handleLoginFailure}
    >
      <Image
        src={require('../../assets/images/socialmedia/Facebook.png')}
        width={'44px'}
        className="grey-round-border mx-2"
        style={{ maxWidth: '100%' }}
      />
    </LoginSocialFacebook>
  );
};

export default FacebookLoginButton;
