import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../buttons/PrimaryButton';
import MainSideBar from '../MainsideBar';
import ScreenTemplate from '../ScreenTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAsync } from '../../store/slices/userSlice';
import useNotificationsApi from '../../api/notificationsApis';
import useLocation from '../../common/hooks/useLocation';
import { GOOGLE_API_KEY } from '../../config/keys';
import Mainheader from '../headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';

const WelcomePage = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);

  const userData = useSelector((state) => state?.user?.userDetails);

  const { fetchNotifications } = useNotificationsApi();
  useEffect(() => {
    if (userData) {
      fetchNotifications(userData?._id, dispatch);
    }
  }, []);
  const handleLogout = async () => {
    try {
      dispatch(logoutUserAsync());
      //navigate("/");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      minHeight="100vh"
      padding={2}
    >
      <Typography variant="h4" gutterBottom>
        Welcome to Amealio!
      </Typography>
      <PrimaryButton variant="contained" onClick={handleLogout}>
        Logout
      </PrimaryButton>
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <Mainheader
          showLocation={true}
          disabled={false}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
};

export default WelcomePage;
