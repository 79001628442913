import React, { useState, useEffect, memo, useRef } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Avatar,
  Modal,
  TextField,
  Badge,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import assets from "../../assets/assets";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setLocation } from "../../store/slices/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import NotificationsDrawer from "../notifications/MainNotificationsDrawer";
import { selectUnreadCount } from "../../store/slices/notificationSlice";
import useNotificationsApi from "../../api/notificationsApis";
import { NavLink } from "react-router-dom";
import { Navigate } from "react-router-dom";
import ProfilePage from "../../screens/profilePage/ProfilePage";
import MapLocationDrawer from "../locations/LocationMap/MapLocationDrawer";

const MainHeader = ({
  currentLocation,
  disabled,
  updateLocation,
  showLocation = false,
  isDesktop,
  sidebarWidth = 240,
  setCurrentLocation,
  setLocationState,
  selectedSavedAddress,
}) => {
  const [isLocationModalOpen, setLocationModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mapDrawerOpen, setMapDrawerOpen] = useState(false);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userDetails);
  const { fetchNotifications } = useNotificationsApi();
  const unreadCount = useSelector(selectUnreadCount);

  const TruncatedText = ({ text, textLength }) => {
    const maxLength = textLength;
    const truncatedText =
      text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    return truncatedText;
  };
  const [open, setOpen] = React.useState(false);

  const ProfiletoggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  useEffect(() => {
    if (!searchQuery || !window.google) return;
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: searchQuery },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setLocationSuggestions(predictions || []);
        } else {
          setLocationSuggestions([]);
          console.error("AutocompleteService Error:", status);
        }
      }
    );
  }, [searchQuery]);

  const handleLocationSelect = (location) => {
    setCurrentLocation(location.description);
    setLocationModalOpen(false);
    const placeService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    placeService.getDetails({ placeId: location.place_id }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        // saveLocationToLocalStorage(lat, lng, location.description);
        updateLocation(lat, lng, location.description);
        setLocationState({
          lat: lat,
          lng: lng,
          locationName: location.description,
        });
        dispatch(
          setLocation({
            lat: lat,
            lng: lng,
            locationName: location.description,
          })
        );
        localStorage.setItem(
          "userLocation",
          JSON.stringify({
            lat: lat,
            lng: lng,
            locationName: location.description,
          })
        );
      } else {
        console.error("Place Details Error:", status);
      }
    });
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const mapToggleDrawer = () => {
    setMapDrawerOpen((prevState) => !prevState);
  };

  const intervalRef = useRef(null); // Store interval reference

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  useEffect(() => {
    if (userData && !intervalRef.current) {
      const fetchAndSetInterval = () => {
        fetchNotifications(userData?._id, dispatch);
      };

      // Initial fetch
      if (!notifications) {
        // fetchAndSetInterval();
      }
      // Set interval only if it hasn't been set already
      intervalRef.current = setInterval(fetchAndSetInterval, 60000);

      // Cleanup function to clear interval when userData changes or component unmounts
      return () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      };
    }
  }, [userData]);

  const getUserLatLng = (cords) => {
    updateLocation(cords.lat, cords.lng);
    mapToggleDrawer();
  };

  const refineDeliveryAddress = (address) => {
    const completeAddress = [
      address?.name,
      address?.address1,
      address?.address2,
      address?.area,
      address?.city,
      address?.state,
      address?.country,
      address?.pincode,
    ]
      .filter(Boolean)
      .join(" ");
    return completeAddress;
  };

  /**** @DESC SAVED ADDRESS LOCATION ACTION ****/
  const handleSavedLocationSelect = (userSavedAddressData) => {
    const mainText = userSavedAddressData?.locality;
    const formatted_address = refineDeliveryAddress(userSavedAddressData);
    let selectedPlace = {
      lat: userSavedAddressData?.location?.latitude,
      lng: userSavedAddressData?.location?.longitude,
      place_id: userSavedAddressData?.place_id || "",
      locationName: mainText,
      locationFullAddress: formatted_address,
    };
    selectedSavedAddress(selectedPlace);
    mapToggleDrawer();
  };

  /**** @DESC RECENT SEARCH ACTION ****/
  const handleRecentSearch = (recentSearch) => {
    selectedSavedAddress(recentSearch);
    mapToggleDrawer();
  };
  const data = useSelector((state) => state.user) || {};

  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{
        width: isDesktop ? `calc(100% - ${sidebarWidth}px)` : "100%",
        margin: 0,
        ml: isDesktop ? `${sidebarWidth}px` : 0,
        zIndex: 1100,
        backgroundColor: "white",
      }}
    >
      <Toolbar>
        {showLocation && (
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              cursor: "pointer",
              flexDirection: "column",
            }}
            onClick={
              mapToggleDrawer
              // () => !disabled && setLocationModalOpen(true)
            }
          >
            {/* Location Section */}
            <Box sx={{ display: "flex", alignItems: "start", gap: 1 }}>
              <FmdGoodIcon
                sx={{ color: !disabled ? "#EE3A23" : "#8A8A8A" }}
                className="mt-1"
              />
              <div className="w-full">
                <div className="hidden md:block">
                  <p
                    className={`flex items-start justify-center text-sm font-Mulish font-semibold mb-0 ${!disabled ? "text-[#000000]" : "text-[#8A8A8A]"
                      }`}
                  >
                    <div className="border-b-2 border-black py-1 mr-2 font-bold capitalize">
                      {currentLocation?.locationName?.slice(0, 16)}
                    </div>
                    <span
                      className={`flex items-start justify-center text-sm font-Mulish font-semibold mb-0 text-lightTextColor capitalize py-1`}
                    >
                      <TruncatedText
                        text={currentLocation?.locationFullAddress || ""}
                        textLength={32}
                      />
                      <ExpandMoreIcon
                        sx={{ color: !disabled ? "#00000" : "#8A8A8A" }}
                      />
                    </span>
                  </p>
                </div>
                <div className="block md:hidden">
                  <p
                    className={`flex items-start justify-start text-sm font-Mulish font-semibold mb-0 ${!disabled ? "text-[#000000]" : "text-[#8A8A8A]"
                      }`}
                  >
                    <div className="border-b-2 border-black py-1 mr-2 font-bold capitalize">
                      {currentLocation?.locationName?.slice(0, 16)}
                    </div>
                  </p>
                  <span
                    className={`flex items-start justify-start text-sm font-Mulish font-semibold mb-0 text-lightTextColor capitalize py-1`}
                  >
                    <TruncatedText
                      text={currentLocation?.locationFullAddress || ""}
                      textLength={24}
                    />
                    <ExpandMoreIcon
                      sx={{ color: !disabled ? "#EE3A23" : "#8A8A8A" }}
                    />
                  </span>
                </div>
                {/* <span className="text-[10px] md:text-sm  font-Mulish font-semibold text-[#8A8A8A]">
                  Set or change your location
                </span> */}
              </div>
            </Box>
          </Box>
        )}

        <Box display="flex" alignItems="center" sx={{ ml: "auto" }}>
          <Box className={"mr-6 md:mr-2"}>
            <IconButton
              color="inherit"
              className="w-6 md:w-12 h-6 md:h-12 ml-10"
              sx={{
                border: {
                  xs: "none",
                  sm: "none",
                  md: "1px solid #EFEFEF",
                  lg: "1px solid #EFEFEF",
                },
              }}
              onClick={toggleDrawer}
            >
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Box>

          <Box>
            <IconButton
              color="inherit"
              className="w-6 md:w-12 h-6 md:h-12 ml-10"
              sx={{
                border: {
                  xs: "none",
                  sm: "none",
                  md: "1px solid #EFEFEF",
                  lg: "1px solid #EFEFEF",

                },
              }}
              onClick={ProfiletoggleDrawer(true)}


            >
              {/* DrawerComponent Opens from Right */}
              <Avatar alt="Profile Picture" src={data.userDetails.profile_photo[0]} />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
      {open && (
        <ProfilePage
          anchor="right"
          open={open}
          toggleDrawer={ProfiletoggleDrawer}
        />
      )}
      {drawerOpen && (
        <NotificationsDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          isDesktop={isDesktop}
        />
      )}
      {mapDrawerOpen && (
        <MapLocationDrawer
          mapDrawerOpen={mapDrawerOpen}
          toggleDrawer={mapToggleDrawer}
          isDesktop={isDesktop}
          handleSavedLocationSelect={(location) =>
            handleSavedLocationSelect(location)
          }
          handleRecentSearch={(recentSearch) =>
            handleRecentSearch(recentSearch)
          }
          getUserLatLngInfo={(userLatLng) => getUserLatLng(userLatLng)}
        />
      )}

      {showLocation && (
        <Modal
          open={isLocationModalOpen}
          onClose={() => setLocationModalOpen(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: "80%",
              maxWidth: 400,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Search Location
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter location"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Box>
              {locationSuggestions.length > 0 ? (
                locationSuggestions.map((location, index) => (
                  <Typography
                    key={index}
                    sx={{ cursor: "pointer", mb: 1 }}
                    onClick={() => handleLocationSelect(location)}
                  >
                    {location.description}
                  </Typography>
                ))
              ) : (
                <Typography sx={{ color: "gray", textAlign: "center" }}>
                  No suggestions available
                </Typography>
              )}
            </Box>
          </Box>
        </Modal>
      )}
    </AppBar>
  );
};

MainHeader.propTypes = {
  currentLocation: PropTypes.object,
  disabled: PropTypes.bool,
  updateLocation: PropTypes.func,
  showLocation: PropTypes.bool,
  isDesktop: PropTypes.bool,
  sidebarWidth: PropTypes.number,
  setCurrentLocation: PropTypes.func,
  setLocationState: PropTypes.func,
  selectedSavedAddress: PropTypes.func,
};

export default memo(MainHeader);
