import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RoomIcon from '@mui/icons-material/Room';
import RefreshIcon from '@mui/icons-material/Refresh';

import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { isEmpty } from '../../common/utility/helperFunc';
import { Avatar, Button, IconButton } from '@mui/material';
import TruncatedText from './TruncatedText';
import { formatNumber } from '../ONDC/ondcHelperFuction.ts';
import { Col } from 'react-bootstrap';
import shareIcon from '../../assets/images/IconsandImages/shareIcon.svg';
import activeFavIcon from '../../assets/images/IconsandImages/activeFav.svg';
import inActiveFavIcon from '../../assets/images/IconsandImages/lightFav.svg';
import inActiveWhiteFavIcon from '../../assets/images/IconsandImages/lightWhiteFav.svg';
import inActiveWhiteShareIcon from '../../assets/images/IconsandImages/lightWhiteShare.svg';
import dropArrowIcon from '../../assets/images/IconsandImages/dropDown.svg';
import upArrowIcon from '../../assets/images/IconsandImages/upArrow.svg';
import BottomSheetModal from './BottomSheetModal.jsx';
import { CALL_TO_ACTION_ENUMS } from '../../common/enums/GlobalEnums.js';
import OffersModal from '../OffersScreen/OffersModal.jsx';

const ReelsComponent = (props) => {
  const {
    indexValue,
    isPlaying,
    isFullscreen,
    muted,
    handleMuteUnmute,
    onClickActionButton,
    onPlayPausedVideo,
    reel,
    isCurrent,
    playbackRate,
    handleVideoError,
    onFullscreenView,
    handlePrevious,
    handleNext,
    onFavAction,
    favBoolValue,
    totalReelsCount,
    handleRefreshReels,
    isOpenOffersModal,
    onClickOpenOfferModal,
  } = props;

  const [openBottomSheet, setOpenBottomSheet] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const shareDataInfo = {
    title:
      'Hey! Just wanted to share this awesome Vibe with you on Amealio. Check it out!',
    message: `Hey! Just wanted to share this awesome Vibe with you on Amealio. Check it out! 😄${reel?.shareLink?.shortLink}\n#Amealio\n#Enjoy your moments.`,
    subject:
      'Hey! Just wanted to share this awesome Vibe with you on Amealio. Check it out! ',
    url: reel?.shareLink?.shortLink,
  };

  const onClickShareAction = () => {
    setOpenBottomSheet(true);
  };

  return (
    <div
      ref={ref}
      key={reel._id}
      style={{
        position: 'relative',
        top: 0,
        left: 0,
        display: isCurrent ? 'block' : 'none',
        aspectRatio: 16 / 9,
      }}
      className="w-full h-full relative rounded-none md:rounded-2xl bg-black border-2 border-black "
    >
      {/* rounded-none md:rounded-2xl */}
      <div className="absolute top-0 w-full z-10 h-auto flex items-center justify-between ">
        {/**** @DESC FULL SCREEN VIEW ***/}
        <IconButton
          aria-label="fullscreen"
          onClick={onFullscreenView}
          className="text-white"
        >
          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
        {/**** @DESC MUTE AND UNMUTE ***/}
        <IconButton
          aria-label="mute_unmute"
          onClick={handleMuteUnmute}
          className="text-white"
        >
          {!muted ? (
            <VolumeUpIcon fontSize="medium" />
          ) : (
            <VolumeOffIcon fontSize="medium" />
          )}
        </IconButton>
      </div>
      {!isCurrent && (
        <img
          src={reel?.thumbnailUrl}
          alt="Reel Thumbnail"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      )}
      {inView && (
        <ReactPlayer
          key={reel._id}
          url={reel?.videoUrl}
          width="100%"
          height="100%"
          style={{
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            // padding: "10px",
          }}
          className={'rounded-none md:rounded-2xl overflow-hidden'}
          playing={isCurrent && isPlaying}
          loop={true}
          muted={muted}
          playbackRate={playbackRate}
          onError={handleVideoError}
        />
      )}
      {/**** @DESC PAUSED AND PLAY ***/}
      <IconButton
        aria-label="play_pause"
        onClick={onPlayPausedVideo}
        className="absolute top-0 border-none w-full h-[85vh] !rounded-none text-white"
      >
        {!isPlaying && (
          <img
            src={require('../../../src/assets/images/IconsandImages/PausePlay.png')}
            alt="Pause Play"
            className="object-cover w-24 h-24 rounded-full"
          />
        )}
      </IconButton>
      {/**** @DESC BOX SECTION ***/}
      <div className="flex items-center justify-between absolute bottom-0 w-full rounded-none md:rounded-2xl">
        {/**** @DESC BOX LEFT SECTION ***/}
        <div className={'w-[85%] min-h-28 p-3 block text-left'}>
          {/**** @DESC REELS PROFILE NAME SECTION ***/}
          <div className="w-full flex items-center justify-start">
            <Avatar
              alt="Profile Img"
              src={
                reel?.restaurant?.logo_url_thumbnails ||
                require('../../assets/images/IconsandImages/ProfilePicture.png')
              }
            />
            <p className="mb-0 font-Mulish font-normal text-white text-base capitalize mx-2">
              {reel?.restaurant?.name}
            </p>
            <Button
              variant="outlined"
              className={
                'text-white border-white font-Mulish font-semibold !text-sm !rounded-2xl w-20 h-7 !capitalize'
              }
            >
              Follow
            </Button>
          </div>
          {/**** @DESC REELS DESCRIPTION SECTION ***/}
          <div className="w-full flex items-center justify-start my-3">
            <TruncatedText
              textString={reel?.description}
              textCount={75}
              textColorType={'text-white'}
            />
          </div>
          {/**** @DESC LOCATION SECTION ***/}
          <div className="w-full flex items-center justify-start text-white">
            <RoomIcon fontSize="small" />
            <p className="mb-0 font-Mulish font-normal text-xs ml-2">
              {`${reel?.restaurant?.area + ', ' + reel?.restaurant?.city}`}
            </p>
          </div>
        </div>
        {/**** @DESC BOX RIGHT SECTION ***/}
        <div
          className={
            'w-[15%] min-h-28 p-1 block text-center text-white md:text-black md:hidden'
          }
        >
          <Button
            className="!min-w-2 !h-6 !border !border-white !md:border-black !rounded-lg bg-white !text-black flex items-center justify-center !font-Mulish !font-semibold !text-sm !md:text-base mb-4 !capitalize"
            onClick={onClickActionButton}
          >
            {reel?.callToAction === CALL_TO_ACTION_ENUMS[2]
              ? 'Book'
              : reel?.callToAction === CALL_TO_ACTION_ENUMS[1]
              ? 'Item'
              : 'View'}
          </Button>
          <ReuseButtonIconText
            iconType={!favBoolValue ? 'NOT_ACTIVE_FAV' : 'ACTIVE_FAV'}
            colorType={'white'}
            onClickPress={onFavAction(reel)}
            btnText={reel?.likes}
          />
          <ReuseButtonIconText
            iconType={'ACTIVE_SHARE'}
            colorType={'white'}
            onClickPress={onClickShareAction}
            btnText={reel?.shares}
          />
        </div>
      </div>
      {/**** @DESC BOX SECTION FOR DESKTOP ***/}
      <div
        className={
          'w-[16%] min-h-28 p-1 text-center md:text-black hidden md:block absolute -right-20 bottom-0'
        }
      >
        <Button
          variant="outlined"
          onClick={onClickActionButton}
          className={
            ' !text-black !bg-white !border-black font-Mulish font-semibold !text-sm !rounded-lg !min-w-8 !h-7 !capitalize mb-3'
          }
        >
          {reel?.callToAction === CALL_TO_ACTION_ENUMS[2]
            ? 'Book'
            : reel?.callToAction === CALL_TO_ACTION_ENUMS[1]
            ? 'Item'
            : 'View'}
        </Button>
        <ReuseButtonIconText
          iconType={!favBoolValue ? 'NOT_ACTIVE_FAV' : 'ACTIVE_FAV'}
          colorType={'black'}
          onClickPress={onFavAction(reel)}
          btnText={reel?.likes}
        />
        <ReuseButtonIconText
          iconType={'ACTIVE_SHARE'}
          colorType={'black'}
          onClickPress={onClickShareAction}
          btnText={reel?.shares}
        />
      </div>
      {/****** DESKTOP UP AND DOWN BUTTON CONTROLLER *******/}
      <div className="absolute -right-[100%] hidden md:block top-[40%] bottom-[40%]">
        {indexValue !== 0 && (
          <Col xs={12} className="mb-5 flex items-end justify-end">
            <Button size="small" onClick={handlePrevious}>
              <img
                src={upArrowIcon}
                alt="Up Arrow"
                className="object-cover w-12 h-12 rounded-full"
              />
            </Button>
          </Col>
        )}
        {totalReelsCount - 1 !== indexValue ? (
          <Col xs={12} className="flex items-end justify-end">
            <Button size="small" onClick={handleNext}>
              <img
                src={dropArrowIcon}
                alt="Down Arrow"
                className="object-cover w-12 h-12 rounded-full"
              />
            </Button>
          </Col>
        ) : (
          <Col xs={12} className="mb-5 flex items-end justify-end">
            <Button
              className="!text-black p-3 w-2/3 !block"
              size="small"
              onClick={handleRefreshReels}
            >
              <RefreshIcon style={{ fontSize: 48 }} />
              <p className="my-3 font-Mulish font-semibold text-sm text-lightTextColor capitalize">
                No more reels available at this moment. please refresh
              </p>
            </Button>
          </Col>
        )}
      </div>
      {openBottomSheet && (
        <BottomSheetModal
          isVisible={openBottomSheet}
          shareDataInfo={shareDataInfo}
          onClosedAction={() => setOpenBottomSheet(false)}
        />
      )}
    </div>
  );
};

const ReuseButtonIconText = ({
  iconType,
  colorType,
  onClickPress,
  btnText,
}) => {
  const formattedNumber = formatNumber(btnText);
  return (
    <div className=" mb-3">
      <button
        className="w-full flex items-center justify-center"
        onClick={onClickPress}
      >
        {iconType === 'ACTIVE_FAV' && (
          <img
            src={activeFavIcon}
            alt="Light Fav"
            className="object-contain w-6 h-6"
          />
        )}
        {iconType === 'NOT_ACTIVE_FAV' && (
          <img
            src={colorType === 'white' ? inActiveWhiteFavIcon : inActiveFavIcon}
            alt="Active Fav"
            className="object-contain w-6 h-6"
          />
        )}
        {iconType === 'ACTIVE_SHARE' && (
          <img
            src={colorType === 'white' ? inActiveWhiteShareIcon : shareIcon}
            alt="Share"
            className="object-contain w-6 h-6"
          />
        )}
        {iconType === 'ACTIVE_MORE_ICON' && <MoreHorizIcon fontSize="medium" />}
      </button>
      {!isEmpty(formattedNumber) && (
        <>
          <p className="mb-0 font-Mulish font-semibold text-sm text-white block md:hidden">
            {formattedNumber}
          </p>
          <p className="mb-0 font-Mulish font-semibold text-sm text-black hidden md:block">
            {formattedNumber}
          </p>
        </>
      )}
    </div>
  );
};

ReuseButtonIconText.propTypes = {
  iconType: PropTypes.string,
  colorType: PropTypes.string,
  btnText: PropTypes.string,
  onClickPress: PropTypes.func,
};

ReelsComponent.propTypes = {
  indexValue: PropTypes.number,
  isPlaying: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  muted: PropTypes.bool,
  handleMuteUnmute: PropTypes.func,
  onClickActionButton: PropTypes.func,
  onPlayPausedVideo: PropTypes.func,
  reel: PropTypes.object,
  isCurrent: PropTypes.bool,
  playbackRate: PropTypes.func,
  handleVideoError: PropTypes.func,
  onFullscreenView: PropTypes.func,
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
  onFavAction: PropTypes.func,
  favBoolValue: PropTypes.bool,
  handleRefreshReels: PropTypes.func,
  totalReelsCount: PropTypes.number,
  isOpenOffersModal: PropTypes.bool,
  onClickOpenOfferModal: PropTypes.func,
};

export default ReelsComponent;
