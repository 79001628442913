import React from "react";
// import { Card, CardContent } from '@/components/ui/card';
import { Card, Image } from "react-bootstrap";
import { Play, Timer, Utensils, Wifi, MapPin, Star } from "lucide-react";
import Icons from "../../../components/Icons";
import { assets } from "../../../assets/assets";

const AboutRestaurantCard = ({ restaurantData }) => {
  return (
    <Card
      className="rounded-4 p-3"
      style={{ borderColor: assets.colors.exp_card_border }}
    >
      <div className="p-0">
        <div className="flex flex-col md:flex-row gap-6">
          {/* Image Section */}
          <div className="container d-flex flex-column flex-lg-row justify-content-start gap-3">
            <div
              className="col-md-12 col-lg-5 rounded-3"
              // style={{ height: "auto", maxWidth: "100%", overflow: "hidden" }}
              style={{
                height: "auto",
                minHeight: "250px", // Example height
                objectFit: "cover",
              }}
            >
              <Image
                src={restaurantData?.logo_url}
                width={"100%"}
                style={{ height: "100%", aspectRatio: "16/9" }}
                className="object-contains w-100 rounded-4"
              />
            </div>
            <div className="col-md-12 col-lg-7">
              {/* Content Section */}
              <div className="flex-1 space-y-4">
                <div>
                  {/* <h3 className="text-xl font-medium">SKYE- Roottop By Eternia</h3> */}
                  <h5
                    className="mb-1"
                    style={{ fontWeight: "bold", color: "#000" }}
                  >
                    {restaurantData?.restaurant_name}
                  </h5>
                  <p className="text-gray-600 text-sm">
                    {restaurantData?.restaurant_description}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-center flex-column gap-3">
                  <div className="d-flex align-items-center justify-content-center col-12">
                    <div className="d-flex items-center gap-2 col-6">
                      <Image
                        src={require("../../../assets/images/IconsandImages/fastfood.png")}
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="text-sm">Fast food</span>
                    </div>
                    <div className="d-flex items-center gap-2 col-6">
                      <Icons type={"chicken_bowl"} />
                      <span className="text-sm">Chicken bowl +4 </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center col-12">
                    <div className="d-flex items-center gap-2 col-6">
                      <Icons type={"currency"} />
                      <span className="text-sm">
                        {restaurantData?.cost_for_two + " for two"}
                      </span>
                    </div>
                    <div className="d-flex items-center gap-2 col-6">
                      <Icons type={"wifi"} />
                      <span className="text-sm">Free wifi + 1</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center col-12">
                    <div className="d-flex items-center gap-2 col-6">
                      <Icons type={"route"} />
                      <span className="text-sm">4.5 kms</span>
                    </div>
                    <div className="d-flex items-center gap-2 col-6">
                      <Icons type={"clock"} />
                      <span className="text-sm">Open 24/7</span>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-1 mt-3">
                  {/* <Star className="w-4 h-4 fill-current text-green-500" /> */}
                  <Star
                    className="w-4 h-4 mr-2"
                    style={{ color: "green", fill: "green" }}
                  />
                  <span className="text-sm font-medium">
                    {restaurantData?.rating}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AboutRestaurantCard;
