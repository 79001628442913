import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
// import backImages from "../../../assets/images/Food/Screenshot_1735811956.png";
import AddButton from '../../ReusableComponent/Buttons';
import propTypes from 'prop-types';
import MenuItemsCard from '../../ReusableComponent/MenuItemsCard';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { NOTAVAILABLEIMAGE } from '../../../common/enums/GlobalEnums';
import moment from 'moment';
import { isEmpty } from '../../../common/utility/helperFunc';
import { Image } from 'react-bootstrap';

const ColorButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '48px',
  borderRadius: '10px',
  color: '#FFFFFF',
  border: '1px solid #FFFFFF',
  backgroundColor: '#EE3A23',
  '&:hover': {
    color: '#EE3A23',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EE3A23',
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '48px',
  borderRadius: '10px',
  color: '#EE3A23',
  border: '1px solid #EE3A23',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: '#EE3A23',
    border: '1px solid #FFFFFF',
  },
}));
const ComboModal = (props) => {
  const {
    item,
    storedItems,
    setStoredItems,
    orderSelected,
    restData,
    setPriceAlertModal,
    disabledAddCombo,
  } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [itemExits, setItemExits] = React.useState(false);
  const [itemExitsQuantity, setItemExitsQuantity] = React.useState({});

  useEffect(() => {
    const itemData = item;
    let getActualItems = storedItems.find(
      (itemInfo) => itemInfo?.itemId === itemData?._id
    );
    if (!isEmpty(getActualItems) && getActualItems?.quantity > 0) {
      setItemExits(true);
      setItemExitsQuantity(getActualItems);
    } else {
      setItemExits(false);
      setItemExitsQuantity({});
    }
  }, []);

  useEffect(() => {
    const itemData = item;
    let getActualItems = storedItems.find(
      (itemInfo) => itemInfo?.itemId === itemData?._id
    );
    if (!isEmpty(getActualItems) && getActualItems?.quantity > 0) {
      setItemExits(true);
      setItemExitsQuantity(getActualItems);
    } else {
      setItemExits(false);
      setItemExitsQuantity();
    }
  }, [storedItems, item]);

  const getTotalAmountWithTaxes = () => {
    let finalData = {
      totalItemsPrice: 0,
      totalItemsCount: 0,
    };
    let getActualItems = storedItems.find(
      (itemInfo) => itemInfo?.itemId === item?._id
    );
    let arr = getActualItems?.comboInfo?.comboItems;
    for (let i = 0; i < arr?.length; i++) {
      let count = 0;
      for (let j = 0; j < arr[i]?.items?.length; j++) {
        if (arr[i]?.items[j]?._id?.availability === 'AVAILABLE') {
          count = +arr[i]?.items?.length;
        }
      }
      finalData.totalItemsPrice =
        getActualItems?.quantity *
        (getActualItems?.finalPrice + getActualItems?.charges);
      finalData.totalItemsCount += getActualItems?.quantity * count;
    }
    return finalData;
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        size="small"
        onClick={handleOpen}
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          paddingX: '25px',
          paddingY: '2px',
        }}
        disabled={disabledAddCombo}
      >
        {itemExits ? `${itemExitsQuantity?.quantity} Combo Added` : 'Add Combo'}
      </Button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        className="food-menu-combo-modal"
      >
        <Modal.Header className={'sm:px-2 md:px-0 sm:py-3 md:py-0 border-0'}>
          <Image
            src={item?.images?.[0] || NOTAVAILABLEIMAGE}
            alt={'Item Image'}
            title={'Item Images'}
            className={
              'w-full h-[210px] md:h-[220px] rounded-t-lg md:rounded-t-3xl rounded-b-lg md:rounded-b-none bg-center bg-no-repeat object-cover'
            }
          />
          {/* <img
            // src={backImages}
            
          /> */}
        </Modal.Header>
        <Modal.Body className="h-auto pt-0 border-0 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start block md:hidden ">
              <p className="text-base font-Mulish font-bold text-primaryRed mr-3">
                Available:
              </p>
              <p className="text-base font-Mulish font-semibold text-lightTextColor mx-2">
                From
              </p>
              <p className="text-base font-Mulish font-semibold text-black">
                {moment(item?.date_of_availability?.start_date).format(
                  'Do MMM'
                )}
              </p>
              <p className="text-base font-Mulish font-semibold text-lightTextColor mx-2">
                to
              </p>
              <p className="text-base font-Mulish font-semibold text-black">
                {moment(item?.date_of_availability?.end_date).format('Do MMM')}
              </p>
            </div>
          </div>
          <div className="flex items-end justify-between">
            <div>
              <p className="text-xl font-extrabold font-Mulish text-black md:pt-4">
                {item?.comboName}
              </p>
              <div className="hidden md:block ">
                <div className="flex items-center justify-start">
                  <p className="text-base font-Mulish font-bold text-primaryRed mr-3">
                    Available:
                  </p>
                  <p className="text-base font-Mulish font-semibold text-lightTextColor mx-2">
                    From
                  </p>
                  <p className="text-base font-Mulish font-semibold text-black">
                    {moment(item?.date_of_availability?.start_date).format(
                      'Do MMM'
                    )}
                  </p>
                  <p className="text-base font-Mulish font-semibold text-lightTextColor mx-2">
                    to
                  </p>
                  <p className="text-base font-Mulish font-semibold text-black">
                    {moment(item?.date_of_availability?.end_date).format(
                      'Do MMM'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-baseline">
              <AddButton
                item={item}
                storedItems={storedItems}
                setStoredItems={setStoredItems}
                orderSelected={orderSelected}
                restData={restData}
                setPriceAlertModal={setPriceAlertModal}
                actionType={'COMBO'}
              />
              <p className="text-sm md:text-base font-Mulish font-bold text-black ml-4">
                Set
              </p>
            </div>
          </div>
          <div className="items-center justify-between gap-3 sm:columns-1 md:columns-2">
            {!isEmpty(item) &&
              !isEmpty(item?.comboItems) &&
              item?.comboItems.map((itemCombo) => {
                return (
                  <React.Fragment key={itemCombo?._id}>
                    {itemCombo?.items.map((itemsInfo) => {
                      return (
                        <React.Fragment key={itemsInfo?._id?._id}>
                          <MenuItemsCard itemData={itemsInfo?._id} />
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            {/* <MenuItemsCard />
            <MenuItemsCard />
            <MenuItemsCard />
            <MenuItemsCard /> */}
          </div>
          {/*** View Details Box ***/}
          <div className="sm:w-full md:w-1/2 md:float-end justify-end items-end">
            <div className="flex items-start justify-between mt-14 mb-3">
              <div>
                <p className="text-base text-[#00000065] font-Mulish font-bold leading-5 mb-2">
                  Total
                </p>
                <p className="text-2xl text-[#00000065] font-Mulish font-bold underline decoration-[#00000065] leading-7 mb-0">
                  {+getTotalAmountWithTaxes().totalItemsCount} items
                </p>
              </div>
              {/* <div>
                <p className="text-xs text-primaryRed font-Mulish font-semibold mb-0">
                  View Details
                </p>
              </div> */}
              <div className="mt-2">
                <p className="text-xl text-[#212121] font-Mulish font-bold leading-6 mb-0">
                  ₹ {+getTotalAmountWithTaxes().totalItemsPrice}
                </p>
                <p className="text-xs text-[#212121] font-Mulish font-medium leading-6 mb-0">
                  Plus Taxes
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="sm:w-full md:w-1/2 md:float-end justify-end items-end">
            <Stack
              spacing={2}
              direction="row"
              className="justify-end items-end"
            >
              {/* {!itemExits && ( */}
              <OutlinedButton
                variant="outlined"
                style={{ width: '50%' }}
                onClick={handleClose}
              >
                Cancel
              </OutlinedButton>
              {/* )} */}
              <ColorButton
                variant="contained"
                style={{ width: '50%' }}
                onClick={handleClose}
              >
                Submit
              </ColorButton>
            </Stack>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

ComboModal.propTypes = {
  item: propTypes.array || propTypes.object || propTypes.string,
  storedItems: propTypes.array || propTypes.object || propTypes.string,
  setStoredItems: propTypes.array || propTypes.object || propTypes.string,
  orderSelected: propTypes.array || propTypes.object || propTypes.string,
  restData: propTypes.array || propTypes.object || propTypes.string,
  setPriceAlertModal: propTypes.array || propTypes.object || propTypes.string,
};

export default ComboModal;
