import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { NOTAVAILABLEIMAGE } from "../../../common/enums/GlobalEnums";
import { CartPageAddButton } from "../../ReusableComponent/Buttons";
import { Col } from "react-bootstrap";


function CartMenuCard({
  index,
  item,
  storedItems,
  setStoredItems,
  fullItemData,
  favorites,
  onToggleFavorite,
}) {
  const incrementHander = () => {
    const updatedItem = {
      ...fullItemData,
      quantity: fullItemData.quantity + 1,
    };
    const updatedItems = [...storedItems];
    updatedItems[index] = updatedItem;
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setStoredItems(updatedItems);
  };



  const decrementHandler = () => {
    if (fullItemData.quantity === 1) {
      // If quantity is 1, remove the item from the array
      const updatedItems = storedItems.filter((item, i) => i !== index);

      sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
      setStoredItems(updatedItems);
    } else if (fullItemData.quantity > 1) {
      // If quantity is greater than 1, decrement the quantity
      const updatedItem = {
        ...fullItemData,
        quantity: fullItemData.quantity - 1,
      };

      const updatedItems = [...storedItems];
      updatedItems[index] = updatedItem;

      sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
      setStoredItems(updatedItems);
    }
  };

  // Function to check if item is a favorite
  //const isFavorite = (id) => favorites.has(id);

  // Function to toggle favorite state
  // const onToggleFavorite = (id) => {
  //   setFavorites((prevFavorites) => {
  //     const newFavorites = new Set(prevFavorites);
  //     if (newFavorites.has(id)) {
  //       newFavorites.delete(id);
  //     } else {
  //       newFavorites.add(id);
  //     }
  //     return newFavorites;
  //   });
  // };

  const isFavorite = (itemId) => favorites?.includes(itemId);

  return (
    <div>
      <div className="menu-card border rounded-xl p-2 mb-2 relative">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Image
              src={require(`../../../assets/images/IconsandImages/${item?.veg ? "vegexp.png" : "nonvegexp.png"
                }`)}
              alt="Image"
              width="18px"
              height="18px"
            />
            <span className="f15-700 mx-2">
              {" "}
              {item?.veg ? "Veg" : "Non-Veg"}{" "}
            </span>
          </div>
          <div className="d-flex">
            <Image
              src={require("../../../assets/images/IconsandImages/videocam.png")}
              alt="Favorite"
              width="20px"
              height={"20px"}
              className="mx-1"
            />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-4 p-1 flexCenter position-relative">
            <div
              //className="w-[111px] md:w-[100px] mb-20 md:mb-16 md:ml-10"
              style={{
                // aspectRatio: "1/1", // This creates a perfect square (change to 16/9, 4/3, etc. for other ratios)
                //position: "relative",
              }}
              className="p-3"
            >
              <Image
                src={item?.images?.[0] || NOTAVAILABLEIMAGE}
                alt="Image"
                className="rounded"
                style={{
                  objectFit: "cover",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: "-5px",
                right: "10px",
                width: "25px",
                height: "25px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "3px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                transform: "translate(35%, 15%)",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click event
                onToggleFavorite(item?.id);
              }}
            >
              <Image
                src={
                  isFavorite(item?.id)
                    ? require("../../../assets/images/IconsandImages/heartcoloured.png")
                    : require("../../../assets/images/IconsandImages/heart.png")
                }
                alt="heartIcon"
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
            </div>
          </div>
          <div className="col-8 d-flex flex-column justify-content-between">
            <div>
              <div className="f16-700 my-2">{item?.name}</div>
              <div className="text-muted f14-600 mb-2">
                Size:{" "}
                <span className="f16-800">
                  {fullItemData?.size?.size || "Standard"}
                </span>
              </div>
              <div className="text-muted f14-500 mb-2">
                {item?.description?.length > 40
                  ? item.description.substring(0, 40) + "..."
                  : item.description}
              </div>
              <div className="d-flex align-items-center mb-3">
                <Image
                  src={require("../../../assets/images/IconsandImages/timer.png")}
                  alt="Prep Time"
                  width="12px"
                  className="me-1"
                />
                <span className="f14-500">{item?.prepTime || 0} min</span>
              </div>
              <div className="d-flex my-2">
                <div className="d-flex ">
                  {item?.rating ? (
                    <div className="pr-1">
                      <span>
                        <Image
                          src={require("../../../assets/images/IconsandImages/warningstar.png")}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                      <span className="f14-500 opacity- mx-1">
                        {item?.rating || 0}
                      </span>
                    </div>
                  ) : null}
                  {item?.spice_level?.value && (
                    <div className="px-1 ">
                      <span className="f14-500 opacity-50 mx-1">
                        {item?.spice_level?.level}
                      </span>
                      <span>
                        <Image
                          src={require("../../../assets/images/IconsandImages/chili.png")}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="my-2 d-flex align-items-center justify-content-between">
                <div className="">
                  <CartPageAddButton
                    item={fullItemData}
                    incrementHander={incrementHander}
                    decrementHandler={decrementHandler}
                  />
                </div>
                <div className="f18-700 mr-2">₹ {fullItemData?.finalPrice}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartMenuCard;
