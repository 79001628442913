import { Heart, Phone, Share2, Star } from "lucide-react";
import Icons from "../../../components/Icons";
import { assets } from "../../../assets/assets";
import { useParams } from "react-router-dom";
import useApi from "../../../common/api/hooks/useApi";
import { URL } from "../../../common/api/urls";
import { useRef, useState } from "react";
import PhoneContactPopup from "./PhoneContactPopup";
const ExpRestaurantCard = ({ data, resData }) => {
  if (!resData) {
    console.warn("⚠️ No data passed to ExpRestaurantCard");
  } else {
    console.log("🚀 ExpRestaurantCard ~ resData", resData);
  }
  //console.log("🚀 RestaurantCard ~ resData", resData);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { restaurantId, experienceId } = useParams();
  const { handleRequest, loading } = useApi();
  const [isLoading, setIsLoading] = useState(true);

  const photos = data?.photos || [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef(null);
  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    const scrollLeft = scrollContainerRef.current.scrollLeft;
    const containerWidth = scrollContainerRef.current.offsetWidth;

    const index = Math.round(scrollLeft / containerWidth);
    setCurrentIndex(index);
  };
  const onClickFavorites = async () => {
    const isFav = userDetails?.experienceFav.includes(experienceId);

    let formData = {
      expId: experienceId,
      restaurantId: restaurantId,
    };

    const favType = isFav ? "remove" : "add";

    await handleRequest("post", `${URL.FAVORITES}?${favType}=true`, formData, {
      headers: { Authorization: userDetails.accessToken },
      successMessage: "Successfully updated favorites",
      errorMessage: "Favorites update failed",
      onSuccess: async (response) => {
        // ✅ Clone userDetails to modify
        const updatedUserDetails = { ...userDetails };

        // ✅ Update experienceFav array
        if (!isFav) {
          updatedUserDetails.experienceFav.push(experienceId);
        } else {
          updatedUserDetails.experienceFav =
            updatedUserDetails.experienceFav.filter(
              (id) => id !== experienceId
            );
        }

        // ✅ Save updated userDetails back to localStorage
        localStorage.setItem("userDetails", JSON.stringify(updatedUserDetails));

        setIsLoading(false);
      },
      onError: (error) => {
        console.log("🚀 ~ error:", error);
        setIsLoading(false);
      },
    });
  };

  return (
    <div className=" w-100" style={{ borderRadius: "12px" }}>
      {/* Header Section */}
      <div className="card-header bg-white border-0 d-flex align-items-center justify-content-between ">
        <h5 className="card-title mb-0" style={{ fontWeight: "bold" }}>
          {data?.restaurantName}
        </h5>
        <div className="d-flex align-items-center gap-3">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              padding: "6px 6px",
              cursor: "pointer",
            }}
            onClick={onClickFavorites}
          >
            <Icons
              type="heart"
              color={
                userDetails?.experienceFav.includes(experienceId) ? "red" : ""
              }
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              padding: "6px 6px",
              cursor: "pointer",
            }}
          >
            <Icons type="star" color={"green"} />
          </div>
          <div
            className="d-none d-md-flex  align-items-center justify-content-center"
            style={{
              padding: "6px 6px",
              cursor: "pointer",
              height: "2.5rem",
              width: "2.5rem",
            }}
            onClick={() => setOpenPhoneModal(true)}
          >
            <Icons type="phone" />
          </div>
          <PhoneContactPopup
            open={openPhoneModal}
            onClose={() => setOpenPhoneModal(false)}
            contacts={resData?.point_of_contact?.[0] || null}
          />
          <div
            className="d-none d-md-flex  align-items-center justify-content-center"
            style={{
              padding: "6px 6px",
              cursor: "pointer",
            }}
          >
            <Icons type="share" />
          </div>
        </div>
      </div>

      {/* Scrollable Image Section */}
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className="scrollContainerExp"
      >
        {photos.map((photoUrl, index) => (
          <img
            key={index}
            src={photoUrl}
            alt={`Restaurant ${index + 1}`}
            className="card-img-top"
            style={{
              objectFit: "cover",
              height: "300px",
              borderRadius: "12px",
              minWidth: "100%",
              scrollSnapAlign: "start",
            }}
          />
        ))}
      </div>

      {/* Dots Pagination */}
      <div
        className="d-flex justify-content-center align-items-center mt-2"
        style={{ gap: "8px" }}
      >
        {photos.map((_, index) => (
          <div
            key={index}
            style={{
              height: "8px",
              width: currentIndex === index ? "24px" : "8px",
              borderRadius: "8px",
              backgroundColor: currentIndex === index ? "#FF5733" : "#D3D3D3", // Replace with `assets.colors.orange` if needed
              transition: "all 0.3s ease",
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ExpRestaurantCard;
