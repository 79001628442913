import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: JSON.parse(localStorage.getItem("userDetails")) || null, // Rehydrate state
  isAuthenticated: !!localStorage.getItem("userDetails"),
  userSavedAddress: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userDetails = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem("userDetails", JSON.stringify(action.payload));
    },
    setUserSavedAddresses: (state, action) => {
      state.userSavedAddress = action.payload;
    },
    logoutUser: (state) => {
      sessionStorage.clear();
      //localStorage.removeItem("userLocation");
      localStorage.removeItem("userDetails");
      state.userDetails = null;
      state.isAuthenticated = false;
      window.location.replace("/");
      //state.location = {};
    },
  },
});

export const { setUser, logoutUser, setUserSavedAddresses } = userSlice.actions;
export const logoutUserAsync = () => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(logoutUser());
    resolve();
  });
};
export default userSlice.reducer;
