export const screenPadding = {
  xs: '2rem 2em',
  sm: '2rem 2rem',
  md: '2rem 5rem',
  lg: '2rem 5rem',
  xl: '2rem 6rem',
};

export const hideScrollBar = {

  "& .MuiDrawer-paper": {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
};
