import React, { useEffect, useState } from "react";
import TitleSection from "../ReusableComponent/TitleSection";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../common/api/urls";
import { useAxios } from "../../common/api/hooks/useAxios";
import { Image, Table } from "react-bootstrap";
import moment from "moment";

import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box, Button, debounce, TextField, Drawer } from "@mui/material";
import { assets } from "../../assets/assets";
import ExpsuccessModal from "../ordering/modals/ExpsuccessModal";
import { isEmpty } from "../../common/utility/helperFunc";
import TaxesDropDownComponent from "../experience/TaxDropComponent";
import ReuseCountPriceEdit from "../experience/ReuseCountPriceEdit";
import ExperienceSuccessModel from "./ExperienceSuccessModel";
import {
  PAY_LATER_ARR,
  PAY_WITH_ARR,
  UPI_ARR,
} from "../../common/constants/Payments";
import Icons from "../../components/Icons";
import useAlerts from "../../components/AlertMessages";
import { NEW_PAYMENT_TYPE_METHOD } from "../../common/enums/OrderEnums";
import { useDispatch, useSelector } from "react-redux";
import {
  setExperienceDetails,
  setExpRequest,
  setExpTransaction,
} from "../../store/slices/expSlice";
import useApi from "../../common/api/hooks/useApi";

function ExpCheckout() {
  const userAuth = useSelector((state) => state.user.userDetails);
  const orderData = JSON.parse(sessionStorage.getItem("orderPostData"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurantId, experienceId } = useParams();
  const [successModal, showSuccessModal] = useState(false);
  const [cancel, experienceData, error, loaded, reset, executeAPI] = useAxios();
  // eslint-disable-next-line
  const [cancelExp, DataExp, errorExp, loadedExp, resetExp, executeExpAPI] =
    useAxios();
  // eslint-disable-next-line
  const [
    cancelExpPatch,
    DataExpPatch,
    errorExpPatch,
    loadedExpPatch,
    resetExpPatch,
    executeExpPatchAPI,
  ] = useAxios();
  const [
    // eslint-disable-next-line
    transactioncancel,
    // eslint-disable-next-line
    transactionresponseData,
    // eslint-disable-next-line
    transactionerror,
    // eslint-disable-next-line
    transactionloaded,
    // eslint-disable-next-line
    transactionreset,
    transactionexecuteAPI,
  ] = useAxios();

  const [displayTaxes, setDisplayTaxes] = useState(false);
  const [displaySurCharges, setDisplaySurCharges] = useState(false);
  const [activeTab, setactivetab] = useState(1);
  const [getAddExperienceData, setBookExpData] = useState(
    JSON.parse(sessionStorage.getItem("expBookData")) || []
  );

  const [createExpData, setCreateExpData] = useState();

  const expRequestDetails = useSelector(
    (state) => state.experience?.expTrackDetails
  );

  const { handleRequest } = useApi();

  const [state, setState] = useState({
    isLoading: true,
    restData: undefined,
    // restId: ID,
    openBankingModal: false,
    walletDetailsFinal: {},
    savedCardListArr: [],
    savedUpiListArr: [],
    currentOrderData: {},
    currentRestaurantInfo: {},
    currentTotalAmount: 0,
    currentOrderType: "",
    currentOrderId: "",
    currentActionBy: "",
    //************//
    setCustomerId: "",
    selectedUsedIdState: "",
    cardCvv: "",
    selectedPaymentMethod: "",
    netBankingPaymentMethod: "",
    netBankingPaymentKey: "",
  });

  const {
    isLoading,
    restId,
    openBankingModal,
    currentOrderData,
    walletDetailsFinal,
    savedCardListArr,
    currentRestaurantInfo,
    currentTotalAmount,
    currentOrderType,
    currentOrderId,
    currentActionBy,
    setCustomerId,
    selectedUsedIdState,
    cardCvv,
    selectedPaymentMethod,
    netBankingPaymentMethod,
    netBankingPaymentKey,
  } = state;

  const [
    walletAmountCancel,
    walletAmountresponseData,
    walletAmounterror,
    walletAmountloaded,
    walletAmountreset,
    walletAmountexecuteAPI,
  ] = useAxios();

  const [
    orderPostcancel,
    orderPostresponseData,
    orderPosterror,
    orderPostloaded,
    orderPostreset,
    orderPostexecuteAPI,
  ] = useAxios();

  const [
    ordercancel,
    orderresponseData,
    ordererror,
    orderloaded,
    orderreset,
    orderexecuteAPI,
  ] = useAxios();

  const [
    razorpayOrderRecordCancel,
    razorpayOrderRecordresponseData,
    razorpayOrderRecorderror,
    razorpayOrderRecordloaded,
    razorpayOrderRecordreset,
    razorpayOrderRecordexecuteAPI,
  ] = useAxios();

  const [
    walletPaymentCancel,
    walletPaymentresponseData,
    walletPaymenterror,
    walletPaymentloaded,
    walletPaymentreset,
    walletPaymentexecuteAPI,
  ] = useAxios();

  const [
    razorpayPaymentCancel,
    razorpayPaymentresponseData,
    razorpayPaymenterror,
    razorpayPaymentloaded,
    razorpayPaymentreset,
    razorpayPaymentexecuteAPI,
  ] = useAxios();

  const { showSuccessAlert, showErrorAlert, AlertComponent } = useAlerts();
   const [OrderData, setOrderData] = useState(
      JSON.parse(sessionStorage.getItem("order_details")) || []
    );

  useEffect(() => {
    if (experienceId !== undefined && experienceId !== null) {
      executeAPI(`${URL.EXPERIENCE}/${experienceId}`, "GET");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId]);

  useEffect(() => {
    if (isEmpty(getAddExperienceData)) {
      navigate(`/restaurant/${restaurantId}/`);
    }
  }, [getAddExperienceData]);

  const getTotalAmount = (count, price) => {
    let totalPrice = price * count;
    return totalPrice;
  };
  const getExperienceTaxes = (amount, exp) => {
    let taxData = exp?.taxes || [];
    let formData = {
      taxes: {},
      taxesTotal: 0,
      surCharges: {},
      surChargesTotal: 0,
    };
    for (let i = 0; i < taxData.length; i++) {
      if (taxData[i].taxType === "TAX") {
        if (taxData[i].type === "PERCENTAGE") {
          formData["taxes"][taxData[i].code] =
            (amount * parseFloat(taxData[i].value)) / 100;
          formData["taxesTotal"] +=
            (amount * parseFloat(taxData[i].value)) / 100;
        } else {
          formData["taxes"][taxData[i].code] = parseFloat(taxData[i].value);
          formData["taxesTotal"] += parseFloat(taxData[i].value);
        }
      } else {
        if (taxData[i].type === "PERCENTAGE") {
          formData["surCharges"][taxData[i].code] =
            (amount * parseFloat(taxData[i].value)) / 100;
          formData["surChargesTotal"] +=
            (amount * parseFloat(taxData[i].value)) / 100;
        } else {
          formData["surCharges"][taxData[i].code] = parseFloat(
            taxData[i].value
          );
          formData["surChargesTotal"] += parseFloat(taxData[i].value);
        }
      }
    }
    return formData;
  };

  const PayLaterTrans = () => {
    transactionexecuteAPI(
      `${URL.UPDATE_TRANSACTION}`,
      "POST",
      {
        paid: false,
        method: "PAYLATER",
        amount: DataExp?.paymentData?.totalAmount,
        type: "PAYLATER",
        date: moment().valueOf(),
        paymentType: 6,
        id: DataExp?._id,
        experience: true,
      },
      {
        Authorization: userAuth.accessToken,
      }
    );
  };

  const postExp = () => {
    const orderPreparedDataNew = {
      ...getAddExperienceData,
      status: "INITIAL",
    };

    orderPreparedDataNew.paymentData.paymentMethod = 6;
    orderPreparedDataNew.paymentData.paymentPending = false;
    orderPreparedDataNew.paymentMethod = 6;
    orderPreparedDataNew.paymentPending = false;
    executeExpAPI(`${URL.USEREXP}`, "POST", orderPreparedDataNew, {
      Authorization: userAuth.accessToken,
    });
  };

  const UpdateExp = () => {
    const updateData = {
      paymentStatus: 0,
      status: "PENDING",
      pointOfEntry: "WEBAPP",
      waitTime: 5,
    };

    let orderId = sessionStorage.getItem("currentOrderID");

    executeExpPatchAPI(
      `${URL.USEREXP}/${DataExp?._id}`,
      "PATCH",
      updateData,
      {
        Authorization: userAuth.accessToken,
      },
      (res) => {
        dispatch(setExperienceDetails(res?.data));
      }
    );
  };

  useEffect(() => {
    if (DataExp && selectedPaymentMethod === "Pay At Site") {
      PayLaterTrans();
    }
  }, [DataExp]);

  useEffect(() => {
    if (transactionresponseData) {
      UpdateExp();
    }
  }, [transactionresponseData]);

  useEffect(() => {
    if (DataExpPatch) {
      showSuccessModal(true);

      // dispatch(setExperienceDetails(DataExpPatch))

      navigate(`/experience/${restaurantId}/${experienceId}/payment-success`, {
        state: DataExpPatch,
      });
    }
  });

  const handleSave = () => {
    if (activeTab === 1) {
      setactivetab(2);
    } else if (activeTab === 2) {
      onClickProceedAction();
    }
  };
  const handleCancel = () => {
    if (activeTab === 1) {
      navigate(`/experience/${restaurantId}/${experienceId}/booking-confirm`);
    } else if (activeTab === 2) {
      setactivetab(1);
    }
  };

  const [disable, setDisable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dialogBoxText, setDialogBoxText] = useState("");
  const [useWalletAmount, setUseWalletAmount] = useState(false);
  const [selectedSplitPaymentMethod, setSelectedSplitPaymentMethod] =
    useState(false);
  const [selectedSplitPaymentMethodText, setSelectedSplitPaymentMethodText] =
    useState("");

  const onButtonClickAction = (infoData) => {
    let upperTitle = infoData.title.split(" ").join("").trim().toUpperCase();
    if (
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) >=
        +currentTotalAmount.toFixed(2) &&
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) + +currentTotalAmount.toFixed(2) >
        10000
    ) {
      setVisible(true);
      setDialogBoxText(
        "You have sufficient wallet balance for this order, Please use your wallet for placing this order"
      );
    } else if (
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) <
        +currentTotalAmount.toFixed(2) &&
      selectedPaymentMethod.toLowerCase() === "wallet" &&
      (infoData?.title.toLowerCase() === "bhim upi" ||
        infoData?.title.toLowerCase() === "debit card" ||
        infoData?.title.toLowerCase() === "credit card" ||
        upperTitle.toLowerCase() === "netbanking" ||
        // infoData?.title.toLowerCase() === "pay at site" ||
        infoData?.title.toLowerCase() === "upi_default")
    ) {
      if (!isEmpty(userAuth.accessToken)) {
        setSelectedSplitPaymentMethod(true);
        onClickCheckSplitPayment(infoData);
      } else {
        // props.navigation.navigate('Login', {
        //   fromAppFlow: true,
        // });
      }
    } else {
      if (userAuth.accessToken)
        if (infoData.isOpenModal && infoData.linkType === "MODAL") {
          setUseWalletAmount(false);
          if (upperTitle === "NETBANKING") {
            setState({
              ...state,
              selectedPaymentMethod: upperTitle,
              openBankingModal: true,
            });
          } else {
            setState({
              ...state,
              selectedPaymentMethod: upperTitle,
              openBankingModal: false,
            });
          }
        } else {
          setUseWalletAmount(false);
          if (selectedPaymentMethod === infoData?.title) {
            setState({
              ...state,
              selectedPaymentMethod: "",
              netBankingPaymentMethod: "",
              netBankingPaymentKey: "",
            });
          } else {
            setState({
              ...state,
              selectedPaymentMethod: infoData?.title,
              netBankingPaymentMethod: "",
              netBankingPaymentKey: "",
            });
          }
        }
      else {
      }
    }
  };

  const onClickCheckSplitPayment = (selectedInfo) => {
    let upperTitle = selectedInfo.title
      .split(" ")
      .join("")
      .trim()
      .toUpperCase();
    if (
      selectedPaymentMethod === "WALLET" &&
      selectedSplitPaymentMethodText.toLowerCase() !==
        selectedInfo?.title.toLowerCase()
    ) {
      setSelectedSplitPaymentMethod(true);
      if (upperTitle.toLowerCase() === "netbanking") {
        setSelectedSplitPaymentMethodText("NETBANKING");
      } else {
        setSelectedSplitPaymentMethodText(selectedInfo?.title);
      }
    } else {
      setSelectedSplitPaymentMethod(false);
      setSelectedSplitPaymentMethodText("");
    }
  };

  const setDisableBtn = (e) => {
    setDisable(false);
  };
  const debouncedHandleInputChange = debounce(setDisableBtn, 4000);

  const onClickProceedAction = () => {
    // return ;
    setDisable(true);
    debouncedHandleInputChange();
    if (selectedPaymentMethod === "WALLET") {
      if (walletAmountresponseData?.balance > orderData?.total_amount) {
        onClickPayByWalletAmount();
      } else {
        showErrorAlert(
          "Insufficient wallet amount, please select different payment method"
        );
      }
    } else if (selectedPaymentMethod === "Pay At Site") {
      postExp();
    } else if (
      selectedPaymentMethod === "Debit Card" ||
      selectedPaymentMethod === "Credit Card" ||
      selectedPaymentMethod === "NETBANKING" ||
      selectedPaymentMethod === "BHIM UPI" ||
      selectedPaymentMethod === "UPI_DEFAULT"
    ) {
      createExperienceRequest();
      // onClickPayByWalletAmount()
    } else {
      setVisible(true);
      setDialogBoxText("Select at least one payment method");
    }
  };

  const createExperienceRequest = async () => {
    const orderPreparedDataNew = {
      ...getAddExperienceData,
      status: "INITIAL",
    };

    orderPreparedDataNew.paymentData.paymentMethod = 6;
    orderPreparedDataNew.paymentData.paymentPending = true;
    orderPreparedDataNew.paymentMethod = 6;
    orderPreparedDataNew.paymentPending = false;

    executeExpAPI(
      `${URL.USEREXP}`,
      "POST",
      orderPreparedDataNew,
      {
        Authorization: userAuth.accessToken,
      },
      (res) => {
        dispatch(setExperienceDetails(res?.data));
        // onClickPayByWalletAmount()
        orderCallbackSuccess(res?.data);
      }
    );
  };

  const getOrderData = () => {
    if (userAuth.accessToken) {
      let formData = JSON.parse(sessionStorage.getItem("orderPostData"));
      const order_details = JSON.parse(sessionStorage.getItem("order_details"));
      let user_details = userAuth;
      formData = { ...formData, ...order_details };
      formData.user_details = {
        first_name: user_details?.User?.first_name,
        last_name: user_details?.User?.last_name,
        country_code: user_details?.User?.country_code,
        mobile_number: user_details?.User?.mobile_number,
        user_id: user_details?.User?._id,
        email: user_details?.User?.email,
        token: user_details?.User?.FCMtoken,
      };

      formData.total_amount = orderData?.total_amount;
      formData.token = user_details?.User?.FCMtoken;
      formData.user_id = user_details?.User?._id;

      formData.user_location = {
        lat: user_details?.User?.lastLocation?.coordinates[1],
        long: user_details?.User?.lastLocation?.coordinates[0],
      };
      formData.totalDinePax = order_details?.totalDinePax;

      // formData['trackLink'] =
      //   window.location.protocol +
      //   '//' +
      //   window.location.hostname +
      //   `/restaurant/${ID}/ordertrack`;

      return formData;
    }
    return null;
  };

  const razorPayPaymentSuccess = (data) => {
    try {
      if (data?.status === 201 || data?.status === 200) {
        if (selectedPaymentMethod === "Pay At Site") {
          orderexecuteAPI(
            `${URL.USER_ORDER}/${data?.data?._id}`,
            "PATCH",
            {
              order_status: 0,
              currentStatusText: "Pending",
            },
            {
              Authorization: userAuth.accessToken,
            }
          );
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            "POST",
            {
              paid: false,
              method: "PAYLATER",
              amount: data?.data?.base_amount,
              type: "PAYLATER",
              date: moment().valueOf(),
              paymentType: 6,
              id: data?.data?._id,
            },
            {
              Authorization: userAuth.accessToken,
            },
            (res) => {
              dispatch(setExpTransaction(res?.data));
            }
          );
        } else if (
          selectedPaymentMethod === "Debit Card" ||
          selectedPaymentMethod === "Credit Card" ||
          selectedPaymentMethod === "NETBANKING" ||
          selectedPaymentMethod === "BHIM UPI" ||
          selectedPaymentMethod === "UPI_DEFAULT"
        ) {
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            "POST",
            {
              paid: true,
              method: "RAZORPAY",
              amount: orderPostresponseData?.base_amount,
              type: "PAYMENT",
              date: moment().valueOf(),
              paymentType: NEW_PAYMENT_TYPE_METHOD.indexOf(
                selectedPaymentMethod
              ),
              id: sessionStorage.getItem("currentOrderID"),
              transactionId:
                razorpayOrderRecordresponseData?.transactional?._id,
              txnId:
                razorpayOrderRecordresponseData?.transactional
                  ?.transactionNumber,
            },
            {
              Authorization: userAuth.accessToken,
            },
            (transactionData) => {
              // navigate(`/experience/${restaurantId}/${experienceId}/payment-success`, {
              //   state: DataExpPatch,
              // });
            }
          );
        } else if (selectedPaymentMethod === "WALLET") {
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            "POST",
            {
              paid: true,
              method: "WALLET",
              amount: orderPostresponseData?.base_amount,
              type: "PAYMENT",
              date: moment().valueOf(),
              paymentType: NEW_PAYMENT_TYPE_METHOD.indexOf(
                selectedPaymentMethod
              ),
              id: sessionStorage.getItem("currentOrderID"),
              transactionId: walletPaymentresponseData?._id,
              txnId: "",
            },
            {
              Authorization: userAuth.accessToken,
            },
            () => {}
          );
          orderexecuteAPI(
            `${URL.USER_ORDER}/${sessionStorage.getItem("currentOrderID")}`,
            "PATCH",
            {
              order_status: 0,
              currentStatusText: "Pending",
              payment_status: 1,
              transactional_id: JSON.parse(
                sessionStorage.getItem("razorpayOrderDetails")
              ).transactional?._id,
            },
            {
              Authorization: userAuth.accessToken,
            },
            () => {}
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickPayByWalletAmount = () => {
    setState({
      ...state,
      isLoading: true,
    });
    if (selectedPaymentMethod === "Pay At Site") {
      let formData = getOrderData();
      formData.payment_method = 6;
      formData.payment_pending = false;
      formData.payment_status = 0;

      orderPostexecuteAPI(
        `${URL.USER_ORDER}`,
        "POST",
        formData,
        {
          Authorization: userAuth.accessToken,
        },
        (resData) => razorPayPaymentSuccess(resData)
      );
    } else {
      let orderData = getOrderData();
      orderData.payment_method = NEW_PAYMENT_TYPE_METHOD.indexOf(
        selectedPaymentMethod
      );
      orderData.payment_status = 0;
      if (orderData) {
        orderCallbackSuccess(orderData);
      }
    }
  };

  const orderCallbackSuccess = (expRequestDetails) => {
    let orderID = expRequestDetails?._id;

    setState((existing) => {
      return {
        ...existing,
        currentOrderID: orderID,
      };
    });

    sessionStorage.setItem("currentOrderID", orderID);
    let formData = {
      amount: selectedSplitPaymentMethod
        ? expRequestDetails?.pendingAmount
        : expRequestDetails?.paymentData?.totalAmount,
      user_id: expRequestDetails?.userId,
      transaction_type: "RAZORPAY",
      refModel: "User",
      message: "",
      currency: "INR",
      isEvent: false,
      restaurant_id: expRequestDetails?.restaurant_id?._id,
      type: "razorpay",
    };
    dispatch(setExpTransaction(formData));
    if (!isEmpty(orderID)) {
      razorpayOrderRecordexecuteAPI(
        `${URL.RAZORPAY_ORDER_RECORD}`,
        "POST",
        formData,
        {
          Authorization: userAuth.accessToken,
        },
        (resData) => razorpayCallbackSuccess(resData.data)
      );
    }
  };

  const razorpayCallbackSuccess = (prevApiResponse) => {
    sessionStorage.setItem(
      "razorpayOrderDetails",
      JSON.stringify(prevApiResponse)
    );
    let encodedString =
      "Basic cnpwX2xpdmVfM210WVZPOG5ST2I1VXE6QWVibE1ac2xWTjdhVjg5SXRnZXpEWGxB";
    if (selectedPaymentMethod === "WALLET" && !selectedSplitPaymentMethod) {
      let formData = {
        user_id: prevApiResponse?.transactional?.user_id,
        transaction_type: "WALLET",
        amount: prevApiResponse?.transactional?.amount,
        restaurant_id: prevApiResponse?.transactional?.restaurant_id,
        orderId: sessionStorage.getItem("currentOrderID"),
        transactional_id: prevApiResponse?.transactional?._id,
      };
      walletPaymentexecuteAPI(
        `${URL.WALLET_PAYMENT}`,
        "POST",
        formData,
        {
          Authorization: userAuth.accessToken,
        },
        (resData) => razorPayPaymentSuccess(resData)
      );
    } else {
      let formData = {
        name: userAuth?.User?.first_name + " " + userAuth?.User?.last_name,
        contact: userAuth?.User?.mobile_number,
        email: userAuth?.User?.email,
        fail_existing: "0",
        notes: {
          notes_key_1: "",
          notes_key_2: "",
        },
      };

      razorpayCustomerSuccess(
        "cust_N3Ms9PumHZ9Gn2",
        prevApiResponse,
        sessionStorage.getItem("currentOrderID")
      );
    }
  };

  const razorpayCustomerSuccess = (
    customerInfo,
    prevResponse,
    orderID,
    razorPayPaymentApiSuccess,
    orderCallbackError
  ) => {
    const customerID = customerInfo;

    // Define options for the Razorpay modal
    const options = {
      key: process.env.RAZORPAY_KEY, // Replace with your Razorpay API key
      amount: prevResponse?.amount, // Amount in smallest currency unit (e.g., paise for INR)
      currency: "INR", // Currency code (e.g., 'INR')
      name: "Amealio",
      description: "Amealio Ordering Payment",
      image: "https://your-image-url.com/logo.png", // Optional: logo or image URL
      order_id: prevResponse?.transactional?.order_id,
      // customer_id: customerID,
      prefill: {
        name: userAuth?.User?.firstName,
        email: userAuth?.User?.email,
        contact: `${userAuth?.User?.country_code} ${userAuth?.User?.mobile_number}`,
      },
      method: {
        netbanking: selectedPaymentMethod === "NETBANKING",
        card:
          selectedPaymentMethod === "Debit Card" ||
          selectedPaymentMethod === "Credit Card",
        wallet: false,
        upi: selectedPaymentMethod === "BHIM UPI",
      },
      notes: {
        order_id: orderID,
      },

      handler: (response) => {
        if (response?.razorpay_payment_id) {
          const paymentDetails = {
            transactional_id: prevResponse?.transactional?._id,
            razorpay_payment_id: response?.razorpay_payment_id,
            isEvent: false,
            order_id: sessionStorage.getItem("currentOrderID"),
          };
          razorpayPaymentexecuteAPI(
            `${URL.RAZORpAY_PAYMENT_RECORD}`,
            "POST",
            paymentDetails,
            {
              Authorization: userAuth.accessToken,
            },
            (resData) => razorPayPaymentSuccess(resData)
          );
          // Call the success callback function directly
        }
      },
      prefill: {
        name: userAuth?.User?.firstName,
        email: userAuth?.User?.email,
        contact: `${userAuth?.User?.country_code}${userAuth?.User?.mobile_number}`,
      },
    };

    // Additional options for payment methods
    if (
      selectedPaymentMethod.toUpperCase() === "WALLET" &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText.toLowerCase() === "netbanking") {
        options.bank = netBankingPaymentKey;
      }
    } else if (selectedPaymentMethod.toLowerCase() === "netbanking") {
      options.bank = netBankingPaymentKey;
    }

    if (
      selectedPaymentMethod.toUpperCase() === "WALLET" &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText === "UPI_DEFAULT") {
        options.vpa = selectedUsedIdState;
      }
    } else if (selectedPaymentMethod === "UPI_DEFAULT") {
      options.vpa = selectedUsedIdState;
    }

    displayRazorpay(options);
  };

  const loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        console.log("razorpay loaded successfully");
        resolve(true);
      };
      script.onerror = () => {
        console.log("error in loading razorpay");
        resolve(false);
      };
      document.body.appendChild(script);
    });

  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      // paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      // paymentId.current = response.error.metadata.payment_id;
      console.log("payment failed ", response);
    });

    // to open razorpay checkout modal.
  };

  return (
    <div>
      <TitleSection
        bg={"#FFFFFF"}
        title={experienceData?.restaurantName || "Experience"}
        // backRoute={
        //   `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`
        // }
        backButton={() =>
          navigate(
            `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`,
            { state: { actionBy: "PAYMENT_SUMMARY" } }
          )
        }
        className={"box-shadow-down"}
        resticon={experienceData?.restaurantId?.logo_url_thumbnails}
        expName={experienceData?.name}
      />
      <div className="p-2">
        <div className="bg-indigo payment_screen my-2">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="f12-400">Total Amount To be paid</div>
            <div className="f12-700 primary-color">{`Experience ID: ${
              experienceData && experienceData?.expId
            }`}</div>
          </div>
          <div className="d-flex justify-content-between  align-items-centeras my-2">
            <div className="f24-700">
              {`₹ ${getAddExperienceData?.paymentData?.totalAmount?.toFixed(
                2
              )} ` || 0}
            </div>
            <div className="eperience_name f10-700">
              {experienceData?.subCategory?.title}
            </div>
          </div>
        </div>

        <div className="topDateTimeBox d-flex">
          <div className="f14-400">{`Date: ${moment(
            getAddExperienceData?.reservationDate
          ).format("Do MMM YYYY")}`}</div>
          <div className="f14-400">{`Time: ${getAddExperienceData?.reservationTime}`}</div>
        </div>

        <div className="f12-400 primary-color my-2">{`(Seat Selection is not available at this moment, will be assigned at the restaurant.)`}</div>
      </div>

      <div className="divider_line " style={{ paddingBottom: "30px" }}>
        {!getAddExperienceData?.isPackagesActive ? (
          <div>
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.adultCount} Adults`}
              onClickEdit={() =>
                navigate(
                  `/experience/${restaurantId}/${experienceId}/booking-confirm`,
                  { state: { actionBy: "PAYMENT_SUMMARY" } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.adultCount,
                getAddExperienceData?.adultPrice
              )}
            />
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.kidsCount} Kids`}
              onClickEdit={() =>
                navigate(
                  `/experience/${restaurantId}/${experienceId}/booking-confirm`,
                  { state: { actionBy: "PAYMENT_SUMMARY" } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.kidsCount,
                getAddExperienceData?.kidsPrice
              )}
            />
          </div>
        ) : (
          <div>
            <div className="f16-400" style={{ color: "#A28AE4" }}>
              {getAddExperienceData?.package?.name}
            </div>
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.package?.count} Package`}
              onClickEdit={() =>
                navigate(
                  `/experience/${restaurantId}/${experienceId}/booking-confirm`,
                  { state: { actionBy: "PAYMENT_SUMMARY" } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.package?.count,
                getAddExperienceData?.package?.price
              )}
            />
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.adultCount} Adults`}
              onClickEdit={() =>
                navigate(
                  `/experience/${restaurantId}/${experienceId}/booking-confirm`,
                  { state: { actionBy: "PAYMENT_SUMMARY" } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.adultCount,
                getAddExperienceData?.adultPrice
              )}
            />
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.kidsCount} Kids`}
              onClickEdit={() =>
                navigate(
                  `/experience/${restaurantId}/${experienceId}/booking-confirm`,
                  { state: { actionBy: "PAYMENT_SUMMARY" } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.kidsCount,
                getAddExperienceData?.kidsPrice
              )}
            />
          </div>
        )}
        {activeTab === 1 && (
          <DonateUdbhav
            //  OrderData={getAddExperienceData}
            setOrderData={setBookExpData}
            onClickProceedAction={onClickProceedAction}
            //  exp={true}
          />
        )}
        {activeTab === 1 && (
          <>
            <div className="f16-700 mb-4 mt-3">{`Order Summary`}</div>
            <div className="paymentSummary mb-4 d-flex">
              <div className="f16-400 my-2">{`₹ ${getAddExperienceData?.paymentData?.amount?.toFixed(
                2
              )}`}</div>
            </div>
          </>
        )}

        <div
          style={{
            borderBottomWidth: 1,
            borderStyle: "dashed",
            borderColor: "#00000029",
          }}
        ></div>

        <div className="f16-400 my-2">{`Original Item Value`}</div>

        <TaxesDropDownComponent
          openState={displayTaxes}
          onPressAction={() => setDisplayTaxes(!displayTaxes)}
          taxTitle={"Taxes"}
          taxPrice={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.taxesTotal
          }
          arrData={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.taxes
          }
        />
        <TaxesDropDownComponent
          openState={displaySurCharges}
          onPressAction={() => setDisplaySurCharges(!displaySurCharges)}
          taxTitle={"Surcharges"}
          taxPrice={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.surChargesTotal
          }
          arrData={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.surCharges
          }
        />
        <AddTip OrderData={orderData} setOrderData={setOrderData} />
        <DonateUdbhav OrderData={orderData} setOrderData={setOrderData} />
        {activeTab === 1 && (
          <>
            <div
              style={{
                borderBottomWidth: 1,
                borderStyle: "dashed",
                borderColor: "#00000029",
              }}
            ></div>
            <div
              className="paymentSummary d-flex "
              style={{ marginBottom: "20px" }}
            >
              <div className="f16-700 ">{"Grand Total"}</div>
              <div className="f16-700 ">{`₹ ${getAddExperienceData?.paymentData?.totalAmount?.toFixed(
                2
              )}`}</div>
            </div>
          </>
        )}
        {activeTab === 2 && (
          <div className="py-2">
            {/* <div className="f16-700">Pay With</div>
            <div className="my-3 p-2 shadow border-active border-radius flex-between">
              <div className="d-flex">
                <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/paylater.jpeg')}
                    width={24}
                  />
                </div>
                <div className="f14-700 flexCenter px-3">Pay At Site</div>
              </div>
              <div>
                <KeyboardArrowRightRoundedIcon />
              </div>
            </div> */}

            <div className="f16-700 my-2 pl-2">Pay With</div>
            <div className="p-2 shadow border-radius">
              {UPI_ARR.map((data) => (
                <>
                  <div
                    className={`my-3 p-2  border-radius flex-between ${
                      selectedPaymentMethod
                        .split(" ")
                        .join("")
                        .trim()
                        .toUpperCase() ===
                      data.title.split(" ").join("").trim().toUpperCase()
                        ? "border-active"
                        : ""
                    }`}
                    onClick={() => onButtonClickAction(data)}
                  >
                    <div className="d-flex">
                      <div>
                        <Image
                          src={require("../../assets/images/IconsandImages/Bhim_upi.png")}
                          width={35}
                        />
                      </div>
                      <div className="f14-700 flexCenter px-3">
                        {data.title}
                      </div>
                    </div>
                    <div>
                      <KeyboardArrowRightRoundedIcon />
                    </div>
                  </div>
                </>
              ))}

              {PAY_WITH_ARR.map((data) => (
                <>
                  <div
                    className={`my-3 p-2  border-radius flex-between ${
                      selectedPaymentMethod
                        .split(" ")
                        .join("")
                        .trim()
                        .toUpperCase() ===
                      data.title.split(" ").join("").trim().toUpperCase()
                        ? "border-active"
                        : ""
                    }`}
                    onClick={() => onButtonClickAction(data)}
                  >
                    <div className="d-flex">
                      <div>
                        <Icons type={data.title} />
                      </div>
                      <div className="f14-700 flexCenter px-3">
                        {data.title}
                      </div>
                    </div>
                    <div>
                      <KeyboardArrowRightRoundedIcon />
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div className="f16-700 pl-2 mt-4">Pay Later</div>
            <div className="p-2 pt-0  border-radius">
              {PAY_LATER_ARR.map((data) => (
                <>
                  <div
                    className={`my-3 p-3 shadow  border-radius flex-between ${
                      selectedPaymentMethod
                        .split(" ")
                        .join("")
                        .trim()
                        .toUpperCase() ===
                      data.title.split(" ").join("").trim().toUpperCase()
                        ? "border-active"
                        : ""
                    }`}
                    onClick={() => onButtonClickAction(data)}
                  >
                    <div className="d-flex">
                      <div>
                        {/* <Image
                          src={require('../../../assets/images/IconsandImages/paylater.jpeg')}
                          width={24}
                        /> */}
                        <Icons type={"Pay at site"} />
                      </div>
                      <div className="f14-700 flexCenter px-3">
                        {data.title}
                      </div>
                    </div>
                    <div>
                      <KeyboardArrowRightRoundedIcon />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        )}
      </div>
      <hr />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
        //  position: "fixed",
          bottom: 0,
          width: "-webkit-fill-available",
          backgroundColor: assets.colors.white,
        }}
        p={2}
        mt={3}
        pb={5}
        mb={3}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={() => handleCancel()}
          style={{
            borderRadius: "15px",
            color: "black",
            border: "1px solid #C4C4C4",
            textTransform: "capitalize",
          }}
          className="bg-white mr-2"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{
            borderRadius: "15px",
            border: "1px solid #C4C4C4",
            color: "white",
            backgroundColor: assets.colors.primary,
            textTransform: "capitalize",
          }}
          // disabled={!(tableNumber || paxNum || paxNumber)}
          fullWidth
          onClick={handleSave}
        >
          Submit
        </Button>
      </Box>
      {/* <ExperienceSuccessModel
        exp_id={DataExp?._id}
        successModal={successModal}
        showSuccessModal={(e) => showSuccessModal(e)}
        restaurantId={restaurantId}
        onHome={()=>{
            // navigate to home screen
            navigate(`/welcome`);
        }}
        onTrack={()=>{
            // navigate to experience track order screen
        }}
      /> */}
    </div>
  );
}
export default ExpCheckout;

export function DonateUdbhav({ setOrderData, onClickProceedAction }) {
  const [expandTip, setExpandTip] = useState(false);
  const [custom, setcustom] = useState(false);
  const expData = JSON.parse(sessionStorage.getItem("expBookData"));
  const [tiPAmount, setTipAmount] = useState(
    expData?.paymentData?.donationAmount || 0
  );
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);
  const [showDonateDrawer, setShowDonateDrawer] = useState(false);

  const handleAddTip = (e) => {
    setTipAmount(e.target.value);
    setShowTips(false);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = () => {
    setShowTips(true);
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    setTipAmount(customAmount);
    setShowTips(false);
    setcustom(false);
  };

  useEffect(() => {
    let numericTiPAmount = parseFloat(tiPAmount) || 0;
    const expData = JSON.parse(sessionStorage.getItem("expBookData"));
    let newOrderData = {
      ...expData,
      paymentData: {
        donationAmount: numericTiPAmount,
        totalAmount: expData?.paymentData?.totalAmount + numericTiPAmount,
        amount: expData.paymentData.amount,
        paymentMethod: 6,
      },
    };

    sessionStorage.setItem("expBookData", JSON.stringify(newOrderData));
    setOrderData(newOrderData);
  }, [tiPAmount]);

  const handleDonateLearnMore = () => {
    setShowDonateDrawer(true);
  };
  return (
    <>
      <div className="add-tip">
        <div>
          Donate to Udbhav{" "}
          {/* <span className="f12-500" style={{ color: "#2196f3" }}>
            Learn More
          </span> */}
          <span
            className="f12-500"
            style={{ color: "#2196f3", cursor: "pointer", fontWeight: "500" }}
            onClick={handleDonateLearnMore}
          >
            Learn More
          </span>
        </div>
        <div
          className="primary-color f12-700"
          onClick={handleChange}
          style={{ marginRight: "70px" }}
        >
          Change Amount
        </div>
        <div>₹ {tiPAmount}</div>
      </div>
      <Drawer
              anchor="bottom"
              open={showDonateDrawer}
              onClose={() => setShowDonateDrawer(false)}
              PaperProps={{
                style: {
                  width: "60vh",
                  margin: "auto",
                  marginBottom: "50px",
                  borderRadius: "16px ",
                  backgroundColor: "white",
                },
              }}
            >
              <div style={{ padding: "20px" }}>
                <h2 style={{ fontWeight: "bold" }}>Donate To Udbhav</h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../../assets/images/IconsandImages/udbhav.png")}
                    alt="Udbhav"
                    style={{ maxWidth: "150px", marginBottom: "15px" }}
                  />
                </div>
                <p style={{ fontSize: "16px", color: "#333", lineHeight: "1.6" }}>
                  Udbhav is a foundation focused on providing resources to the
                  underprivileged sectors while creating a lasting impact that
                  empowers communities. We cater to families in need of bare
                  necessities and motivate children to study by developing specialized
                  programs to improve their quality of life. Driven by the youth,
                  Udbhav willingly partners up with similar organizations to help
                  create change. We believe in creating a sustainable world by
                  participating in drives that address environmental concerns. A
                  recent partnership with the Akshaya Patra organization led to 500
                  food packets delivered to the families and people most affected by
                  the pandemic. A host of partnerships and events that give back to
                  society are on the horizon. Be a part of this and help us to change
                  the world!
                </p>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#fc5a47",
                    color: "white",
                    borderRadius: "25px",
                    textTransform: "capitalize",
                    width: "100%",
                  }}
                  onClick={() => window.open("https://visionudbhav.org/", "_blank")}
                >
                  Visit Udbhav
                </Button>
              </div>
            </Drawer>
      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                {/* <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button> */}
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={() => {
                      onClickProceedAction();
                      // handleSubmit()
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

function AddTip({ OrderData, setOrderData }) {
  const [showTipDrawer, setShowTipDrawer] = useState(false);
  const [expandTip, setExpandTip] = useState(false);
  const [custom, setcustom] = useState(false);
  const [tiPAmount, setTipAmount] = useState(OrderData?.tip?.amount || 0);
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const handleAddTip = (e) => {
    setTipAmount(e.target.value);
    setShowTips(false);
  };
  const handleTipLearnMore = () => {
    setShowTipDrawer(true);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = () => {
    setShowTips(true);
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    setTipAmount(customAmount);
    setShowTips(false);
    setcustom(false);
  };

  useEffect(() => {
    let numericTiPAmount = parseFloat(tiPAmount);
    let newOrderData = { ...OrderData, tip: { amount: numericTiPAmount } };
    sessionStorage.setItem("order_details", JSON.stringify(newOrderData));
    setOrderData(newOrderData);
  }, [tiPAmount]);
  return (
    <>
      <div className="add-tip relative flex items-center justify-between w-full px-4 py-2">
        <div className="flex items-center">
          {"Add Tip "}
          <span
            className="f12-500 ml-2 text-blue-500 cursor-pointer"
            onClick={handleTipLearnMore}
          >
            Learn More
          </span>
        </div>

        {/* Fixed positioning for amount and change button */}
        <div className="flex items-center">
          <span
            className="primary-color f12-700 cursor-pointer mr-3"
            onClick={handleChange}
          >
            Change Amount
          </span>
          <div>₹ {tiPAmount}</div>
        </div>
      </div>
      {/* Tip Modal */}
      <Drawer
        anchor="bottom"
        open={showTipDrawer}
        onClose={() => setShowTipDrawer(false)}
        PaperProps={{
          style: {
            width: "60vh",
            margin: "auto",
            marginBottom: "50px",
            borderRadius: "16px ",
            backgroundColor: "white",
          },
        }}
      >
        <div
          style={{
            padding: "20px",

            borderRadius: "16px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 style={{ fontWeight: "bold" }}> Add Tip</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../assets/images/IconsandImages/addtipNEW.png")}
              alt="Udbhav"
              style={{ maxWidth: "300px", marginBottom: "15px" }}
            />
          </div>
          <p style={{ fontSize: "14px", color: "#333", lineHeight: "1.6" }}>
            A Customer may at it's sole discretion add a certain amount as "Tip"
            for the services provided through the Amealio Platform.Any amount
            recieved towards Tips shall be non-refundable except in the
            following circumstances: A. Order is auto-cancelled before the
            acceptance of the order bythe Merchant. b. If the Order is cancelled
            by the Merchant due to such as non-availability of food items
            ordered by the customer, non-availability of the service, or any
            other reasons attributable to the Merchant. However, in case of
            partial cncellation or modifications in the order/ Booking, the
            amount contributed towards Tips shall be non-refundableAmealio
            reserves the right, at its sole discretion, to refuse the refund of
            the amount pay towards Tips for any reason what so ever, without
            assigning any reason to the customer and the customer accepts and
            undertakes to not raise any dispute on the same.
          </p>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#fc5a47",
              color: "white",
              borderRadius: "10px",
              textTransform: "capitalize",
              width: "50%",
            }}
            onClick={() => {
              setShowTipDrawer(false);
              setShowTips(true);
            }}
          >
            Add Tip
          </Button>
        </div>
      </Drawer>
      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      color: "black",
                      border: "1px solid #C4C4C4",
                      textTransform: "capitalize",
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "rgb(252, 90, 71) !important",
                      textTransform: "capitalize",
                      color: "whitesmoke",
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

