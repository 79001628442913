import React, { useEffect } from "react";
import { Box, Typography, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import MainSideBar from "../../components/MainsideBar";
import ScreenTemplate from "../../components/ScreenTemplate";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useAuth from "../../common/hooks/useAuth";
import { useState } from "react";
import { URL } from "../../common/api/urls";
import useApi from "../../common/api/hooks/useApi";
import MainHeader from "../../components/headers/Mainheader";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import "../../assets/scss/fonts.scss";;

const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));

const SavedAddresses = () => {
  const [selectedAddress, setSelectedAddress] = useState(0);

  const addresses = [
    {
      id: 0,
      title: "Flat 1502 Tower 5",
      description: "Amanora Park Town, Hadapsar",
      isDefault: true,
    },
    {
      id: 1,
      title: "Building 300, Space A",
      description: "Accenture, Baner, PDC4AC",
      isDefault: false,
    },
    {
      id: 2,
      title: "Building 300, Space A",
      description: "Accenture, Baner, PDC4AC",
      isDefault: false,
    },
    {
      id: 3,
      title: "Building 300, Space A",
      description: "Accenture, Baner, PDC4AC",
      isDefault: false,
    },
  ];
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { auth, persist } = useAuth();
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  const { handleRequest, loading } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [userDetailsInfo, setUserDetailsInfo] = useState({});

  const handleLogout = () => {
    sessionStorage.removeItem("userInformation");
    sessionStorage.removeItem("userOtpDetails");
    sessionStorage.removeItem("userOtpPayload");
    navigate("/");
  };

  useEffect(() => {
    (async () => {
      if (location.lat) {
        const userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
          const userDetailsInfo = JSON.parse(userDetails);
          setUserDetailsInfo(userDetailsInfo);
          // validate the token first
          await handleRequest("get", `${URL.VALIDATE_TOKEN}`, null, {
            headers: { Authorization: userDetailsInfo?.accessToken },
            successMessage: "Successfully validated the user authentication",
            errorMessage: "User authentication failed",
            onSuccess: async (response) => {



            },
            onError: (error) => {
              setIsLoading(false);
              handleLogout();
            },
          });
        }
      }
    })();
  }, [location]);

  const content = (
    <>
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "red",
          margin: "20px 0px 0px 20px",
        }}
      >
        My ddress
      </div>
      <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-lg font-bold mb-4">Saved Addresses</h2>

        {addresses.map((address, index) => (
          <div
            key={index}
            className={`flex items-center justify-between p-3 rounded-lg border ${selectedAddress === index ? "border-red-500" : "border-gray-300"
              } mb-3`}
          >
            {/* Radio Button */}
            <input
              type="radio"
              name="address"
              className="w-5 h-5 text-red-500 focus:ring-red-500"
              checked={selectedAddress === index}
              onChange={() => setSelectedAddress(index)}
            />

            {/* Address Details */}
            <div className="flex-1 ml-3">
              <div className="flex items-center gap-2">
                <h3 className="font-semibold">{address.title}</h3>
                {address.isDefault && (
                  <span className="text-xs font-bold text-white bg-yellow-500 px-2 py-1 rounded">
                    DEFAULT
                  </span>
                )}
              </div>
              <p className="text-gray-500 text-sm">{address.description}</p>
            </div>

            {/* Edit Button */}
            <button className="text-gray-500 hover:text-red-500">
              {/* <FaRegEdit size={18} /> */}
            </button>
          </div>
        ))}

        {/* Add New Address */}
        <div className="flex items-center text-red-500 font-semibold mt-4 cursor-pointer">
          {/* <IoMdAddCircleOutline size={20} /> */}
          <span className="ml-2">Add New Address</span>
        </div>

        {/* Cancel & Submit Buttons */}
        <div className="flex justify-between mt-6">
          <button className="border border-red-500 text-red-500 px-6 py-2 rounded-lg hover:bg-red-100">
            Cancel
          </button>
          <button className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600">
            Submit
          </button>
        </div>
      </div>
    </>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
    />
  );
};

export default SavedAddresses;
