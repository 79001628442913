import React, { useState, useEffect } from "react";
import useApi from "../../common/api/hooks/useApi";
import { URL } from "../../common/api/urls";

const FavoriteButton = ({ restaurantId }) => {
  const { handleRequest } = useApi();
  const [isFavorite, setIsFavorite] = useState(false);
  const [favorites, setFavorites] = useState([]);

  // Load user's favorite list from local storage
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.favourites) {
      setFavorites(userDetails.favourites);
      setIsFavorite(userDetails.favourites.includes(restaurantId));
    }
  }, [restaurantId]);

  // Toggle favorite function
  const toggleFavorite = async () => {
    const updatedFavorites = isFavorite
      ? favorites.filter((id) => id !== restaurantId)
      : [...favorites, restaurantId];

    setFavorites(updatedFavorites);
    setIsFavorite(!isFavorite);

    // Update local storage
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      userDetails.favourites = updatedFavorites;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    }

    // Send API request
    let formData = { restaurant_id: restaurantId };
    const favType = isFavorite ? "remove" : "add";

    await handleRequest("post", `${URL.FAVORITES}?${favType}=true`, formData, {
      headers: { Authorization: userDetails.accessToken },
      successMessage: "Successfully updated favorites",
      errorMessage: "Favorites update failed",
    });
  };

  return (
    <button onClick={toggleFavorite} style={{ background: "none", border: "none", cursor: "pointer" }}>
      {isFavorite ? <img src={require("../../assets/images/IconsandImages/likedheart.png")}></img> : <img src={require("../../assets/images/IconsandImages/fav-ash-bg.png")}></img>}
    </button>
  );
};

export default FavoriteButton;
