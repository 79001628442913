import moment from "moment";
import React, { useState } from "react";
import {
  NEW_PAYMENT_TYPE,
  ORDER_STATUS,
  PAYMENT_STATUS,
} from "../../../common/enums/OrderEnums";
import { Image } from "react-bootstrap";
import { NOTAVAILABLEIMAGE } from "../../../common/enums/GlobalEnums";
import { ArrowDropDown } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, Button, Drawer } from "@mui/material";
import { useAxios } from "../../../common/api/hooks/useAxios";
import { URL } from "../../../common/api/urls";
import { assets } from "../../../assets/assets";

function OrderDetails({ data, fontsize, open, setOpen , from=""}) {
  const [showcharges, setShowcharges] = useState(false);
  const [showTax, setShowtax] = useState(false);
  const [showsurCharges, SetShowcharges] = useState(false);
  const [selected, setselected] = useState(1);
  const [reason, setReason] = useState("");

  // eslint-disable-next-line
  const [
    // eslint-disable-next-line
    ordercancel,
    // eslint-disable-next-line
    orderresponseData,
    // eslint-disable-next-line
    ordererror,
    // eslint-disable-next-line
    orderloaded,
    // eslint-disable-next-line
    orderreset,
    orderexecuteAPI,
  ] = useAxios();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleProceed = () => {
    orderexecuteAPI(
      `${URL.USER_ORDER}/${data?._id}`,
      "PATCH",
      {
        order_status: 8,
        currentStatusText: "Cancelled",
        cancelReason:
          selected === 1
            ? "Not Available"
            : selected === 2
            ? "Dont Like the place"
            : reason,
      },
      {
        Authorization: sessionStorage.getItem("accessToken"),
      }
    );
    setOpen(false);
  };

  const getTotalSurcharges = (data) => {
    let totalCharges = 0;

    if(data?.surCharges){
      totalCharges = Object.values(data?.surCharges).reduce(
        (sum, value) => sum + value,
        0
      );
    }
    return totalCharges;
  };

  return (
    <div className="px-3">
      <div className="waitlist-card my-3">
        <div className="f16-700 my-3">Order Details</div>
        <div className="row mx-0">
          <div className="col">
            <div className="my-2">
              <div className="f14-700">
                <div>₹ {data?.total_amount?.toFixed(2) || 0}</div>
              </div>
              <div className="f12-700 opacity-50">Total Amount</div>
            </div>
            <div className="my-2">
              <div className="f14-700">{data?.order_id || ""}</div>
              <div className="f12-500 opacity-50">Order Id</div>
            </div>

            <div className="my-2">
              <div className="f14-700">
                {moment(data?.updatedAt).format("DD MMM YYYY")}
              </div>
              <div className="f12-500 opacity-50">Last change date</div>
            </div>
          </div>
          <div className="col">
            <div className="my-2">
              <div className="flex-between">
                <div className="f14-700">
                  {NEW_PAYMENT_TYPE[data?.payment_method] ?? data?.payment_method?.toUpperCase()}
                </div>
                <div
                  className="f12-500 px-2"
                  style={{
                    backgroundColor:
                      data?.payment_status === 1 || "CAPTURED" ? "#4caf50" : "#E9DB5D",
                    borderRadius: "6px",
                    color: "whitesmoke",
                  }}
                >
                  {PAYMENT_STATUS[data?.payment_status] ?? data?.payment_status}
                </div>
              </div>
              <div className="f12-500 opacity-50">Payment Type</div>
            </div>

            <div className="my-2">
              <div className="f14-700 nowrap">
                {data?.status}
              </div>
              <div className="f12-500 opacity-50 ">Status</div>
            </div>

            <div className="my-2">
              <div className="f14-700">
                {moment(data?.updatedAt).format("hh:mm A")}
              </div>
              <div className="f12-500 opacity-50">Last Change Time</div>
            </div>
          </div>
        </div>
        {data?.order_status === 0 ? (
          <div className="flexCenter">
            <div className="my-2 text-center p-3" onClick={() => setOpen(true)}>
              <CancelOutlinedIcon
                className="color-indigo  shadow p-1"
                sx={{ borderRadius: "50%", fontSize: 28 }}
              />
              <div className="f12-500">cancel</div>
            </div>
          </div>
        ) : null}

        <div className="my-4">
          {data?.order_items?.map((item) => (
            <React.Fragment key={item?.item?.name}>
              <div className="row m-0 border border-[#CCC] shadow-sm rounded-xl mb-3 py-2">
                <div className="col-8 flex items-center">
                  <div className="w-14 h-14 border rounded-xl">
                    <Image
                      src={item?.item?.images?.[0] || NOTAVAILABLEIMAGE}
                      alt="Image"
                      title={item?.item?.name}
                      className="w-full h-full object-cover rounded-xl"
                    />
                  </div>
                  <div className={`px-3 ${fontsize ? "f13-700" : "f13-700"}`}>
                    {item?.item?.name} x {item?.quantity}
                  </div>
                </div>
                <div className="col-4 flex items-center justify-end">
                  <div className="f14-700">
                    ₹ {parseFloat(item?.size?.price || 0).toFixed(2)}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        {!showcharges && (
          <div
            style={{ color: "#2196f3" }}
            className="f14-700 cursor-pointer"
            onClick={() => setShowcharges(true)}
          >
            View More
          </div>
        )}

        <div>
          {showcharges ? (
            <>
              <div className="p-3 f14-700">
                <div className="f16-700 my-3">Order Summary</div>
                <div className="flex-between my-2">
                  <div>Sub Total</div>
                  <div>₹{parseFloat(data?.base_amount || 0).toFixed(2)}</div>
                </div>
                {data?.discount?.amount ? (
                  <div className="my-2">
                    <div className="flex-between">
                      <div>Discount</div>
                      <div style={{ color: assets.colors.primary }}>
                        - ₹
                        {parseFloat(data?.discount?.amount || 0).toFixed(2) ||
                          0}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="my-2">
                  <div className="flex-between">
                    <div onClick={() => setShowtax(!showTax)}>
                      {" "}
                      {showTax ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                      Taxes
                    </div>
                    <div>
                      ₹{parseFloat(data?.gstTaxes || 0).toFixed(2) || 0}
                    </div>
                  </div>
                  {showTax && (
                    <div className="pl-4 py-1 f14-500">
                      {data?.gstTaxes
                        ? Object.entries(data?.gstAmount).map(
                            ([key, value]) => (
                              <div className="flex-between" key={key}>
                                <div>{key}</div>
                                <div>₹{parseFloat(value || 0).toFixed(2)}</div>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  )}
                </div>
                <div className="my-2">
                  <div className="flex-between">
                    <div onClick={() => SetShowcharges(!showsurCharges)}>
                      {" "}
                      {showsurCharges ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                      Surcharges
                    </div>
                    <div>
                      ₹ {parseFloat(getTotalSurcharges(data))?.toFixed(2)}
                    </div>
                  </div>
                  {showsurCharges && (
                    <div className="pl-4 py-1 f14-500">
                      {data?.surCharges
                        ? Object.entries(data?.surCharges).map(
                            ([key, value]) => (
                              <div className="flex-between" key={key}>
                                <div>{key}</div>
                                <div>₹{parseFloat(value || 0).toFixed(2)}</div>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  )}
                </div>
                {/* {data?.tip?.amount ? ( */}
                <div className="my-2">
                  <div className="flex-between">
                    <div>Tip</div>
                    <div>
                      ₹{parseFloat(data?.tip?.amount || 0).toFixed(2) || 0}
                    </div>
                  </div>
                </div>
                {/* ) : null} */}
                {/* {data?.donation?.amount ? ( */}
                <div className="my-2">
                  <div className="flex-between">
                    <div>Donation</div>
                    <div>
                      ₹{parseFloat(data?.donation?.amount || 0).toFixed(2) || 0}
                    </div>
                  </div>
                </div>
                {/* ) : null} */}

                <hr />
                <div className="flex-between f16-700">
                  <div> Total Amount Paid</div>
                  <div>₹{data?.total_amount || 0}</div>
                </div>
                <hr />
              </div>
              <div
                style={{ color: "#2196f3" }}
                onClick={() => setShowcharges(false)}
                className="f14-700"
              >
                View Less
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div>
        <Drawer anchor={"bottom"} open={open} sx={{ zIndex: "1400" }}>
          <div className="p-3">
            <div className="f18-700"> Reason for Cancellation</div>
            <div className="f14-500 text-grey mb-3">
              Select your reaso for cancellation
            </div>
            <div
              className={`f16-700 shadow p-3 border-radius my-3 ${
                selected === 1 ? "border-active" : ""
              }`}
              onClick={() => setselected(1)}
            >
              Not Available
            </div>
            <div
              className={`f16-700 shadow p-3 border-radius my-3 ${
                selected === 2 ? "border-active" : ""
              }`}
              onClick={() => setselected(2)}
            >
              Don't Like the place
            </div>
            <div
              className={`f16-700 shadow p-3 border-radius my-3 ${
                selected === 3 ? "border-active" : ""
              }`}
              onClick={() => setselected(3)}
            >
              Other
            </div>
          </div>
          <hr />
          <Box
            sx={{ display: "flex", justifyContent: "space-evenly" }}
            my={1}
            px={2}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={handleCancel}
              style={{
                borderRadius: "15px",
                color: "black",
                border: "1px solid #C4C4C4",
                textTransform: "capitalize",
              }}
              className="bg-white mr-2"
              fullWidth
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              size="large"
              style={{
                borderRadius: "15px",
                border: "1px solid #C4C4C4",
                color: "white",
                backgroundColor: "#fc5a47",
                textTransform: "capitalize",
              }}
              // disabled={!(tableNumber || paxNum || paxNumber)}
              fullWidth
              onClick={handleProceed}
            >
              Submit
            </Button>
          </Box>
        </Drawer>
      </div>
    </div>
  );
}

export default OrderDetails;
