import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  styled,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import RippleButton from "../../../../components/buttons/RippleButton";
import { Col, Image, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import propTypes from "prop-types";
import { Close } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  extractTimingData,
  initialCustomizationSelectCheck,
  toggleChecked,
  totalOndcItemPrice,
} from "../../ondcHelperFuction.ts";
import { isEmpty } from "../../../../common/utility/helperFunc";
import AddToCartOndcButton from "./AddToCartOndcButton";

const ColorButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#FFFFFF",
  border: "1px solid #FFFFFF",
  backgroundColor: "#EE3A23",
  "&:hover": {
    color: "#EE3A23",
    backgroundColor: "#FFFFFF",
    border: "1px solid #EE3A23",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#EE3A23",
  border: "1px solid #EE3A23",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#EE3A23",
    border: "1px solid #FFFFFF",
  },
}));
const OndcCustomizationModal = (props) => {
  const {
    modalOpen,
    onClickOpenModal,
    onClickClosedModal,
    item,
    quantity,
    storedItems,
    setStoredItems,
    orderSelected,
    restData,
    setPriceAlertModal,
    disabledButton,
    actionBy,
  } = props;
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [customizationArr, setCustomizationArr] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedCustomizationArr, setSelectedCustomizationArr] = useState([]);

  useEffect(() => {
    if (modalOpen) {
      let blankArr = [];
      if (Array.isArray(item?.custom_groups)) {
        let addedIds = new Set();
        restData?.customGroups.forEach((items) => {
          if (
            item?.custom_groups.includes(items?.id) &&
            !addedIds.has(items?.id)
          ) {
            blankArr.push(items);
            addedIds.add(items?.id);
          }
        });
      } else {
        console.error("data2 is not an array!");
      }
      let finalData = initialCustomizationSelectCheck(blankArr);
      let totalItemPrice = totalOndcItemPrice(finalData, +item?.price?.value);
      setTotalAmount(totalItemPrice);
      setCustomizationArr(finalData);
      if (!isEmpty(finalData)) {
        let blankArrTwo = [];
        finalData.forEach((group) => {
          group?.items.forEach((groupInfo) => {
            if (groupInfo.checked === true) {
              blankArrTwo.push({
                ...groupInfo,
                parent_item_id: item?.id,
                quantity: { count: 1 },
              });
            }
          });
        });
        setSelectedCustomizationArr(blankArrTwo);
      }
    }
  }, [modalOpen]);

  const onChangeSize = (arrData, catID, items) => () => {
    const updatedData = toggleChecked(arrData, catID, items?.id);
    let totalItemPrice = totalOndcItemPrice(updatedData, +item?.price?.value);
    setCustomizationArr(updatedData);
    setTotalAmount(totalItemPrice);
    if (!isEmpty(updatedData)) {
      let blankArrTwo = [];
      updatedData.forEach((group) => {
        group?.items.forEach((groupInfo) => {
          if (groupInfo.checked === true) {
            blankArrTwo.push({
              ...groupInfo,
              parent_item_id: item?.id,
              quantity: { count: 1 },
            });
          }
        });
      });
      setSelectedCustomizationArr(blankArrTwo);
    }
  };

  return (
    <React.Fragment>
      {/* {(open || !open) && ( */}
      <AddToCartOndcButton
        item={item}
        restData={restData}
        selectedCustomization={selectedCustomizationArr}
        actionBy={"CUSTOMIZED_ACTION"}
        modalState={modalOpen}
        onCallBackCustomize={onClickClosedModal}
        handleOpen={onClickOpenModal}
        disabledButton={disabledButton}
      />
      {/* )} */}
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalOpen}
        onHide={onClickClosedModal}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        className="food-menu-combo-modal"
      >
        <Modal.Header className={"border"}>
          <p className="mb-0 text-xl font-Mulish font-bold">Customize</p>
          <button title="closed" onClick={onClickClosedModal}>
            <Close fontSize="medium" />
          </button>
        </Modal.Header>
        <Modal.Body className="h-auto pt-0 border-0 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
          <Row className="py-2">
            <Col>
              <p className="text-lg font-Mulish font-medium mb-0">
                {item?.descriptor?.name}
              </p>
            </Col>
            <Col className="text-end">
              <p className="text-lg font-Mulish font-bold mb-0">
                ₹ {extractTimingData(item?.price?.tags, "range", "lower")} - ₹{" "}
                {extractTimingData(item?.price?.tags, "range", "upper")}
              </p>
            </Col>
          </Row>
          {!isEmpty(customizationArr) &&
            customizationArr?.length > 0 &&
            customizationArr.map((groupData) => {
              return (
                <Accordion
                  key={groupData?.id}
                  defaultExpanded={true}
                  id={groupData?.descriptor?.name}
                  className="mb-3"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1aCustomize-content"
                    id="panel1aCustomize-header"
                    className="m-0"
                  >
                    <Typography
                      component="span"
                      className="text-lg font-Mulish font-semibold"
                    >
                      {groupData?.descriptor?.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!isEmpty(groupData) &&
                      !isEmpty(groupData?.items) &&
                      groupData?.items?.length > 0 &&
                      groupData?.items.map((items, index) => {
                        return (
                          <Row key={items?.id} className="mb-2">
                            <Col
                              xs={10}
                              className="flex items-center justify-start"
                            >
                              <FormControlLabel
                                className="mr-3"
                                value={items?.price?.value}
                                control={
                                  <Checkbox
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "red",
                                      },
                                    }}
                                    className="py-0"
                                  />
                                }
                                checked={items?.checked}
                                onChange={onChangeSize(
                                  customizationArr,
                                  groupData?.id,
                                  items
                                )}
                              />
                              {/* {console.log("customizationSelect==>244", items)} */}
                              <Image
                                src={require(`../../../../assets/images/IconsandImages/${
                                  extractTimingData(
                                    items?.tags,
                                    "veg_nonveg",
                                    "veg"
                                  ) === "yes"
                                    ? "vegindicator.png"
                                    : "nonveg_indicator.png"
                                }`)}
                                alt="Veg-Nog Veg"
                                className="h-3 w-3 object-fill mr-2"
                              />
                              <p className="text-sm font-Mulish font-medium mb-0">
                                {items?.descriptor?.name}
                              </p>
                            </Col>
                            <Col
                              xs={2}
                              className="flex items-center justify-end"
                            >
                              <p className="text-sm font-Mulish font-bold mb-0">
                                ₹ {items?.price?.value}
                              </p>
                            </Col>
                          </Row>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          {/*sm:w-full md:w-1/2 md:float-end justify-end items-end */}
          <div className="w-full">
            <Stack
              spacing={2}
              direction="row"
              className="justify-between items-center"
            >
              <div className="flex items-center justify-start">
                <p className="mb-0 text-base font-Mulish font-bold text-lightTextColor">
                  Total Amount:{" "}
                  <span className="text-lg text-black">₹ {totalAmount}</span>
                </p>
              </div>
              <AddToCartOndcButton
                item={item}
                restData={restData}
                selectedCustomization={selectedCustomizationArr}
                actionBy={"CUSTOMIZED_BTN_DISPLAY"}
                modalState={modalOpen}
                onCallBackCustomize={onClickClosedModal}
                handleOpen={onClickOpenModal}
              />
            </Stack>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

OndcCustomizationModal.propTypes = {
  item: propTypes.array || propTypes.object || propTypes.string,
  disabledButton: propTypes.bool.isRequired,
  storedItems: propTypes.array || propTypes.object || propTypes.string,
  setStoredItems: propTypes.array || propTypes.object || propTypes.string,
  orderSelected: propTypes.array || propTypes.object || propTypes.string,
  restData: propTypes.array || propTypes.object || propTypes.string,
  setPriceAlertModal: propTypes.array || propTypes.object || propTypes.string,
  actionBy: propTypes.string,
};

export default OndcCustomizationModal;
