export const servicesImages = {
  TakeAway: require('./Food/services/takeaway-service.png'),
  CurbSide: require('./Food/services/curbside-service.png'),
  DineIn: require('./Food/services/dineinservice.png'),
  SkipTheLine: require('./Food/services/skiptheline-service.png'),
  Delivery: require('./Food/services/delivery-service.png'),
  WaitList: require('./Food/services/waitlist-service.png'),
  Reservation: require('./Food/services/reservation-service.png'),
  WalkIn: require('./Food/services/walkin-service.png'),
  TakeAwaySelected: require('./Food/services/takeAway.png'),
  CurbSideSelected: require('./Food/services/curbsideselected.png'),
  DineInSelected: require('./Food/services/dineinselected.png'),
  SkipTheLineSelected: require('./Food/services/skipthelineselected.png'),
  DeliverySelected: require('./Food/services/deliveryselected.png'),
  WaitListSelected: require('./Food/services/waitlistselected.png'),
  ReservationSelected: require('./Food/services/reservation.png'),
  WalkInSelected: require('./Food/services/walkinselected.png'),
};

export const seatingAssets = {
  celebrationCap: require('./IconsandImages/celebration-cap.png'),
  adults: require('./seating/adults.png'),
  kids: require('./seating/kids.png'),
  handicap: require('./seating/handicap.png'),
  highchair: require('./seating/highchair.png'),
  seatingpreferences: require('./seating/seatingpreferences.png'),
};

export const profilePageicon = {
   favorite  :require('./IconsandImages/favorite.png') ,
  bookmark   : require('./IconsandImages/bookmark.png') ,
    referralIcon   : require('./IconsandImages/motion_sensor_active.png') ,
    Wallet   : require('./IconsandImages/wallets.png'),
    referoffer   : require('./IconsandImages/referofer.png') ,
    award   : require('./IconsandImages/award_star.png') ,
    ref   : require('./IconsandImages/ref.png') ,
    kidstar   : require('./IconsandImages/kid_star.png') ,
    thumb   : require('./IconsandImages/thumb_up.png') ,
    mobile   : require('./IconsandImages/mobile.png') ,
    location   : require('./IconsandImages/location_on.png') ,
    nominationsIcon   : require('./IconsandImages/mingcute_location-3-line.png') ,
    scan   : require('./IconsandImages/scan.png') ,
    clinic   : require('./IconsandImages/clinical_notes.png') ,
    bsx   : require('./IconsandImages/bxs_offer.png'),
    mode   : require('./IconsandImages/mode_comment.png') ,
    help   : require('./IconsandImages/helpicons.png') ,
    localpolice   : require('./IconsandImages/local_police.png') ,
    privacy   : require('./IconsandImages/privacy_tip.png') ,
    refund   : require('./IconsandImages/iconoir_privacy-policy.png') ,
    proicons   : require('./IconsandImages/proicons_cookies.png') ,
    ic   : require('./IconsandImages/ic_outline-policy.png') ,
    editicon   : require('./IconsandImages/editicon.png'),
  
}

