import React, { useEffect, useState } from "react";
import { GoogleMap,  Marker, DirectionsRenderer } from "@react-google-maps/api";
 

const TrackWithMap = ({ startLocation, destination }) => {
  const [directions, setDirections] = useState(null);

  const mapStyles = {
    height: "500px",
    width: "100%",
  };

  const defaultCenter = {
    lat: (startLocation.lat + destination.lat) / 2,
    lng: (startLocation.lng + destination.lng) / 2,
  };

  useEffect(() => {
    if (startLocation && destination) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: startLocation,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error("Error fetching directions:", status);
          }
        }
      );
    }
  }, [startLocation, destination]);

  return (
   
      <GoogleMap mapContainerStyle={mapStyles} zoom={12} center={defaultCenter}>
        <Marker position={startLocation} label="S" />
        <Marker position={destination} label="D" />
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
   
  );
};

export default TrackWithMap;
