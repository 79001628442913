import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  SwipeableDrawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useApi from "../../common/api/hooks/useApi";
import { URL } from "../../common/api/urls";
import {
  clearNotifications,
  markAllAsRead,
  markAsRead,
  removeNotification,
  setNotifications,
} from "../../store/slices/notificationSlice";
import useNotificationsApi from "../../api/notificationsApis";

const NotificationsDrawer = ({ drawerOpen, toggleDrawer, isDesktop }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const userData = useSelector((state) => state.user.userDetails);
  const navigate = useNavigate();
  const { handleRequest } = useApi();
  const { fetchNotifications } = useNotificationsApi();

  useEffect(() => {
    if (drawerOpen) {
      fetchNotifications(userData?._id, dispatch);
    }
  }, [drawerOpen]);

  const handleNotificationClick = (notification) => {
    // navigate(notification.link);
  };

  const handleClearAll = () => {
    dispatch(clearNotifications());
  };

  const handleCloseNotification = (notificationId) => {
    dispatch(removeNotification(notificationId));
  };

  const handleMarkAsRead = () => {
    dispatch(markAllAsRead());
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer}
      sx={{
        width: isDesktop ? 500 : "100%",
         flexShrink: 0,
        // "& .MuiDrawer-paper": {
        //   width: isDesktop ? 500 : "100%",
        //   height: "100%",
        //   position: "relative",
        // },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography className="f18-800">Notifications</Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography
            className="f16-500 flexCenter mx-2"
            color="primary"
            onClick={handleClearAll}
            sx={{ cursor: "pointer" }}
          >
            Clear All
          </Typography>
          <Typography
            className="f16-500 flexCenter"
            color="primary"
            onClick={handleMarkAsRead}
            sx={{ cursor: "pointer" }}
          >
            Mark As Read
          </Typography>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Divider/>

      <Box
        sx={{ padding: 2, overflowY: "auto", maxHeight: "calc(100% - 160px)" }}
      >
        {loading ? (
          <CircularProgress />
        ) : notifications?.length === 0 ? (
          <Typography>No New Notifications</Typography>
        ) : (
          notifications.map((notification) => (
            <Box
              key={notification._id}
              sx={{
                marginBottom: 2,
                padding: 2,
                border: "1px solid #ddd",
                borderRadius: 1,
                backgroundColor: notification.read ? "#f4f4f4" : "#fff",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleNotificationClick(notification)}
            >
              <AccessTimeIcon sx={{ marginRight: 2 }} />
              <Typography variant="body2" sx={{ flexGrow: 1, color: "gray" }}>
                {notification.content}
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseNotification(notification._id);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ))
        )}
      </Box>
  </SwipeableDrawer>
  );
};

export default NotificationsDrawer;
