import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainSideBar from "../../components/MainsideBar";
import MainHeader from "../../components/headers/Mainheader";
import ScreenTemplate from "../../components/ScreenTemplate";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import { Row, Col, Image, Button } from "react-bootstrap";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import { SearchWithFilterAndSort } from "../experience/SearchWithFilterAndSort";
import RelatedExperiences from "./RelatedExperiences";
import useApi from "../../common/api/hooks/useApi";
import moment from "moment";
const ExperienceDetailsWeb = () => {
  // const { restaurantId, experienceId } = useParams();
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);

  const [search, setSearch] = useState("");
  const [experiences, setExperiences] = useState([]);
  const [filteredExperiences, setFilteredExperiences] = useState([]);

  const navigate = useNavigate();
  // getting the experiences id and restaurants id
  useEffect(() => {
    const storedData = sessionStorage.getItem("experienceData");
    if (storedData) {
      setExperienceData(JSON.parse(storedData));
      // console.log(
      //   "🚀 Retrieved Experience Data from Storage:",
      //   JSON.parse(storedData)
      // );
    }
  }, []);
  const [experienceData, setExperienceData] = useState(null);
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  const { handleRequest } = useApi();

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    if (searchValue) {
      const filtered = experiences.filter((exp) =>
        exp.name.toLowerCase().includes(searchValue)
      );
      setFilteredExperiences(filtered);
    } else {
      setFilteredExperiences(experiences);
    }
  };
  const [
    restaurantCancel,
    restaurantResponseData,
    restaurantError,
    restaurantLoaded,
    restaurantReset,
    restaurantExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (experienceData?.restaurantId) {
      restaurantExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${experienceData?.restaurantId}?lat=true&long=true`,
        "GET"
      );
    }
  }, [experienceData?.restaurantId]);

  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.experienceFav) {
      setFavorites(userDetails.experienceFav);
    }
  }, []);

  const toggleFavorite = async (restaurantId) => {
    // console.log("Toggling favorite for restaurant:", restaurantId);
    const updatedFavorites = favorites.includes(restaurantId)
      ? favorites.filter((id) => id !== restaurantId)
      : [...favorites, restaurantId];

    setFavorites(updatedFavorites);

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      userDetails.experienceFav = updatedFavorites;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    }

    let formData = { restaurant_id: restaurantId };
    const isFav = updatedFavorites.includes(restaurantId);
    const favType = isFav ? "add" : "remove";

    await handleRequest("post", `${URL.FAVORITES}?${favType}=true`, formData, {
      headers: { Authorization: userDetails.accessToken },
      successMessage: "Successfully updated favorites",
      errorMessage: "Favorites update failed",
      onSuccess: (response) => {
        console.log("Favorites updated successfully:", response);
      },
      onError: (error) => {
        console.error("Error updating favorites:", error);
      },
    });
  };

  //******** for the new experience api for showing the restaurants ********
  const [
    relatedCancel,
    relatedResponseData,
    relatedError,
    relatedLoaded,
    relatedReset,
    relatedExecuteAPI,
  ] = useAxios();
  useEffect(() => {
    if (experienceData) {
      const formData = {
        ids: experienceData.experienceIds,
        restaurants: experienceData.restaurantId,
      };

      relatedExecuteAPI(
        `${URL.RESTAURANT_EXPERIENCES}?pageType=EXPERIENCES`,
        "POST",
        formData
      );
    }
  }, [experienceData]);
  useEffect(() => {
    if (relatedLoaded && restaurantLoaded) {
      setLoading(false);
    }
  }, [relatedLoaded, restaurantLoaded]);

  useEffect(() => {
    if (relatedResponseData) {
      // console.log("🟢 Related Experiences API Response:", relatedResponseData);
    }
  }, [relatedResponseData]);
  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={
        <>
          <div style={{ padding: "10px 20px" }}>
            <h2
              style={{
                fontSize: "33px",
                color: "#F85032",
                fontWeight: "800",
                marginBottom: "5px",
              }}
            >
              {relatedResponseData?.[0]?.category?.title || ""} Experience
            </h2>
            <p style={{ fontSize: "17px", margin: 0 }}>
              Hosting Corporate events are an excellent
            </p>
          </div>
          {/* Search Bar Section */}
          <div style={{ marginBottom: "20px", padding: "10px 20px" }}>
            <SearchWithFilterAndSort
              onChange={handleSearchChange}
              search={search}
              placeHolderText={"Search for experiences"}
              micIcon={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginLeft: "20px",
            }}
          >
            <Image
              src={require("../../assets/images/IconsandImages/schedule.png")}
              alt="icon"
              width="25px"
              height="25x"
              style={{ maxWidth: "100%" }}
            />
            <span className="f15-500">
              <div className="f14-700">
                {moment().format("dddd Do MMM YYYY")}
              </div>
            </span>
          </div>
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {/* Related Experiences Section */}
              <RelatedExperiences
                relatedResponseData={relatedResponseData || []}
                toggleFavorite={toggleFavorite}
                favorites={favorites}
                restdata={restaurantResponseData || {}}
              />
            </>
          )}
        </>
      }
    />
  );
};

export default ExperienceDetailsWeb;
