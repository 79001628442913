import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ExpRestaurantDetails from "./ExpRestaurantDetails";
import MainSideBar from "../../components/MainsideBar";
import ScreenTemplate from "../../components/ScreenTemplate";
import MainHeader from "../../components/headers/Mainheader";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import useLocation from "../../common/hooks/useLocation";
import ExpPassDetails from "./ExpPassDetails";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import { useParams } from "react-router-dom";

import {
  setExperienceDetails,
  setExpRequest,
  setExpTransaction,
} from "../../store/slices/expSlice";
import { GOOGLE_API_KEY } from "../../config/keys";

function OrderStatus(props) {
  const windowWidth = useWindowWidth();

  const isDesktop = windowWidth > 1024;
  const userAuth = useSelector((state) => state.user.userDetails);
  const [loading, setLoading] = useState(true);
  const [restaurantData, setRestaurantData] = useState({});
  const [trackData, setTrackData] = useState([]);
  const [promoVideos, setPromoVideos] = useState([]);
  const [countDownTime, setCountDownTime] = useState(0);
  const [error, setError] = useState(null);
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  const { restaurantId, experienceId } = useParams();
  const expTrackDetails = useSelector(
    (state) => state.experience?.expTrackDetails
  ); // Use expTrackDetails
  const experienceDetails = useSelector(
    (state) => state.experience.experienceDetails
  );
  const expRequest = useSelector((state) => state.experience.expRequest);
  const expTransaction = useSelector(
    (state) => state.experience.expTransaction
  );

  const expRequestDetails = useSelector(
    (state) => state.experience?.expTrackDetails
  );

  // Fetch restaurantId from sessionStorage or another source
  // const restaurantId = sessionStorage.getItem('restaurantId');

  useEffect(() => {
    if (
      sessionStorage.getItem("restaurantData") &&
      restaurantId === JSON.parse(sessionStorage.getItem("restaurantData"))?._id
    ) {
      setRestaurantData(JSON.parse(sessionStorage.getItem("restaurantData")));
    } else {
      promotionalvideosExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${restaurantId}?lat=true&long=true`,
        "GET",
        null
      );
    }
  }, [restaurantId]);

  const [
    promotionalvideosCancel,
    promotionalvideosResponseData,
    promotionalvideosError,
    promotionalvideosLoaded,
    promotionalvideosReset,
    promotionalvideosExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (restaurantData) {
      promotionalvideosExecuteAPI(`${URL.PROMOTIONAL_VIDEOS}`, "GET", null, {
        Authorization: userAuth.accessToken,
      });
    }
  }, [restaurantData]);

  const [
    orderTrackcancel,
    ordertrackresponseData,
    orderTrackerror,
    orderTrackloaded,
    orderTrackreset,
    orderTrackexecuteAPI,
  ] = useAxios();

  const getOrderData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Hardcoded track ID
      // const hardcodedTrackId = "6777be0d1e48ef54b412be72";
      const hardcodedTrackId = expRequestDetails?._id;

      await orderTrackexecuteAPI(
        `${URL.USEREXP}/${hardcodedTrackId}`,
        "GET",
        null,
        {
          Authorization: userAuth.accessToken,
        }
      );
    } catch (err) {
      setError("Failed to fetch order data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getTimeData = () => {
    try {
      if (expTrackDetails?.max_time_date || expTrackDetails?.OrderAcceptTime) {
        const updatedTime = moment(
          expTrackDetails?.max_time_date || expTrackDetails?.OrderAcceptTime
        ).add(parseInt(expTrackDetails?.max_time), "minutes");

        let diff = updatedTime.diff(moment());
        return diff;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Error calculating time data:", error);
      return 0;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (restaurantData) await getOrderData();
      const timeData = getTimeData();
      setCountDownTime(timeData);
    };

    fetchData();
  }, [restaurantData]);

  useEffect(() => {
    if (ordertrackresponseData) {
      // Integrating the data from the API into the Redux store
      setTrackData(ordertrackresponseData);
      const timeData = getTimeData();
      setCountDownTime(timeData);
    }

    if (orderTrackerror) {
      setError("Failed to load track data. Please try again.");
    }
  }, [ordertrackresponseData, orderTrackerror]);

  const content = (
    <div style={{ padding: "20px" }}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div>
          <ExpRestaurantDetails
            trackData={expTrackDetails} // Use Redux's expTrackDetails here
            promoVideos={promoVideos}
            restaurantData={restaurantData}
            experienceDetails={experienceDetails}
            expRequest={expRequest}
            expTransaction={expTransaction}
          />
          <ExpPassDetails
            trackData={expTrackDetails}
            experienceDetails={ordertrackresponseData}
          />{" "}
          {/* Pass track data */}
        </div>
      )}
    </div>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
}

export default OrderStatus;
