import moment from 'moment';
import React from 'react';
// import ReadMoreReact from "read-more-react";
import { arrayToString } from '../../common/utility/helperFunc';
import TimingsAvailability from './TimingsAvailability';
import { getActivityTimings } from '../../common/utility/expFunctions';

const ExperienceDescription = ({ data }) => {
  return (
    <div className="p-3 experience-description">
      <div className="f18-500">{data?.name || ''}</div>
      <div className="f12-400 mt-1 text-indigo">
        {data?.classification?.map((e, index, array) => (
          <span key={e.title}>
            {' '}
            {e.title}
            {index < array.length - 1 && ','}
          </span>
        ))}
      </div>
      <hr />
      <div className="f12-500 text-subtitle">Description</div>
      <div className="f12-400">
        {/* <ReadMoreReact
          text={data?.description || ""}
          min={0}
          ideal={0}
          max={data?.description?.length}
          readMoreText={"Read More"}
        /> */}
        {data?.description || ''}{' '}
        {/* <span className="text-primary">Read More</span> */}
      </div>
      {data?.tags?.length ? (
        <div className="f12-500 mt-2">
          {data?.tags ? data?.tags?.join(' | ') : ''}
        </div>
      ) : null}
      {/* <hr />
      <div className="f12-500">Private Event | Socialize | Kids Friendly</div> */}
      {data?.foodItems && (
        <>
          <hr />
          <div className="f10-400">
            Food items included with this experience
          </div>
          <div className="f12-500">{data?.foodItems}</div>
        </>
      )}
      {data?.addOnDesc && (
        <>
          <hr />
          <div className="f10-400">Add Ons included with this experience</div>
          <div className="f12-500">{data?.addOnDesc}</div>
        </>
      )}

      <hr />
      <div className="row">
        <div className="col-4">
          <div className="f10-400">Availability</div>
        </div>
        <div className="col-5">
          <div className="f10-400">Timi</div>
        </div>
      </div>
      <div className="row">
        {data
          ? getActivityTimings(data)?.multipleHours?.map(
              (timingsData, index) => {
                if (timingsData?.open) {
                  return (
                    <React.Fragment key={timingsData?.description}>
                      <div className="col-4">
                        <div className="f12-700 text-indigo">
                          {timingsData?.description || 'N/A'}
                        </div>
                      </div>
                      <div className="col-5">
                        <div>
                          <span className="f12-700 text-indigo mr-2">
                            {moment(timingsData?.openingTime).format('hh:mm A')}{' '}
                            -{' '}
                            {moment(timingsData?.closingTime).format('hh:mm A')}
                          </span>{' '}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                } else return <>Timings Not Available</>;
              }
            )
          : null}
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: '-24px' }}
        >
          <span className="f12-500 text-danger">
            <TimingsAvailability exp={data} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ExperienceDescription;
