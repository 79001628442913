import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AmealioLogo from './reusable/AmealioLogo';

import HomeIcon from '../assets/images/ordericon/footerhomeblack.png';
import HomeIconActive from '../assets/images/ordericon/footerhomeblack.png';
import ExpIcon from '../assets/images/ordericon/ExpIcon.svg';
import ExpIconActive from '../assets/images/ordericon/ExpIcon.svg';
import CommunityIcon from '../assets/images/ordericon/footercommunityblack.png';
import CommunityIconActive from '../assets/images/ordericon/footercommunityblack.png';
import FoodIcon from '../assets/images/ordericon/footerfoodblack.png';
import FoodIconActive from '../assets/images/ordericon/footerfoodblack.png';
import BytesIcon from '../assets/images/ordericon/footerbytesblack.png';
import BytesIconActive from '../assets/images/ordericon/footerbytesblack.png';

// const iconMap = {
//   Home: (
//     <img src={HomeIcon} alt="Home" style={{ width: "24px", height: "24px" }} />
//   ),
//   Experience: (
//     <img
//       src={ExpIcon}
//       alt="Experience"
//       style={{ width: "24px", height: "24px" }}
//     />
//   ),
//   Community: (
//     <img
//       src={CommunityIcon}
//       alt="Community"
//       style={{ width: "24px", height: "24px" }}
//     />
//   ),
//   Food: (
//     <img src={FoodIcon} alt="Food" style={{ width: "24px", height: "24px" }} />
//   ),
//   Bytes: (
//     <img
//       src={BytesIcon}
//       alt="Bytes"
//       style={{ width: "24px", height: "24px" }}
//     />
//   ),
// };
const iconMap = {
  Home: { default: HomeIcon, active: HomeIconActive },
  Experience: { default: ExpIcon, active: ExpIconActive },
  Community: { default: CommunityIcon, active: CommunityIconActive },
  Food: { default: FoodIcon, active: FoodIconActive },
  Bytes: { default: BytesIcon, active: BytesIconActive },
};

const MainSideBar = ({ windowWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { label: 'Home', route: '/home', icon: 'Home' },
    { label: 'Experience', route: '/experience', icon: 'Experience' },
    { label: 'Community', route: '/community', icon: 'Community' },
    { label: 'Food', route: '/food', icon: 'Food' },
    { label: 'Ondc', route: '/ondc', icon: 'Food' },
    { label: 'Bytes', route: '/bytes', icon: 'Bytes' },
  ];

  const getActiveItem = () => {
    const activeItem = navItems.find((item) =>
      location.pathname.includes(item.route)
    );
    return activeItem ? activeItem.label : '';
  };

  const activeItem = getActiveItem();

  if (windowWidth > 1024) {
    return (
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: '#FFFFFF',
            color: '#000000',
            borderRight: '1px solid #e0e0e0',
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <AmealioLogo />
        </Box>
        <List>
          {navItems.map((item) => (
            <ListItem key={item.label}>
              <ListItemButton
                selected={activeItem === item.label}
                onClick={() => navigate(item.route)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#FFB240',
                    borderRadius: '12px',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#FF7043',
                    },
                  },
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                {/* <ListItemIcon
                  sx={{
                    color: activeItem === item.label ? "#FFFFFF" : "#757575",
                  }}
                >
                  {iconMap[item.icon]}
                </ListItemIcon> */}
                <ListItemIcon>
                  <img
                    src={
                      activeItem === item.label
                        ? iconMap[item.icon].active
                        : iconMap[item.icon].default
                    }
                    alt={item.label}
                    style={{ width: '24px', height: '24px' }} // Ensures proper sizing
                  />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#FFFFFF',
        borderTop: '1px solid #e0e0e0',
        zIndex: 1000,
      }}
    >
      <List
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          padding: '0 0',
          alignItems: 'center',
          minHeight: '50px',
        }}
      >
        {navItems.map((item) => (
          <ListItem
            key={item.label}
            sx={{ padding: '0px 0', minWidth: '50px' }}
          >
            <ListItemButton
              selected={activeItem === item.label}
              onClick={() => navigate(item.route)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  color: '#FFB240',
                },
                '&:hover': {
                  color: '#FF7043',
                },
              }}
            >
              <img
                src={
                  activeItem === item.label
                    ? iconMap[item.icon].active
                    : iconMap[item.icon].default
                }
                alt={item.label}
                style={{ width: '22px', height: '22px' }}
              />
              {/* <ListItemText
                className="f8-400"
                primary={item.label}
                sx={{
                  fontSize: "8px",
                  fontFamily: "mulish",
                  marginTop: "2px",
                  textAlign: "center",
                  width: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              /> */}
              <Typography className="f10-500">{item.label}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MainSideBar;
