import moment from "moment";

export const HorizontalScrollMenu = ({ data, onClick, selected }) => {
  const onClicked = (e) => {
    if (e === selected) {
      if (onClick) onClick("");
    } else {
      if (onClick) onClick(e);
    }
  };
  return (
    <div className="d-flex overflow-scroll hidescrollbar">
      {data?.map((e) => (
        <div className="p-1 " key={e?.title}>
          <div
            onClick={() => (e?._id ? onClicked(e?._id) : onClicked(e))}
            className={`py-2 flexcetner px-4 my-1 f14-400 badge-item text-center ${selected === (e?._id || e) ? "selected-indigo" : ""
              }`}
            style={{
              backgroundColor: e?.code || "#f8f9fa",
              color: e?.code ? "black" : "#333",
              borderRadius: "6px",
              border:
                selected === (e?._id || e)
                  ? "1px solid #EE3A23"
                  : "1px solid rgba(255, 255, 255, 0.8)",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
            }}
          >
            {e?.title || e?.toString() || ""}
          </div>
        </div>
      ))}
    </div>
  );
};

export const HorizontalScrollMenuTimings = ({ data, onClick, selected }) => {
  const onClicked = (e) => {
    if (e === selected) {
      if (onClick) onClick("");
    } else {
      if (onClick) onClick(e);
    }
  };

  return (
    <div className="d-flex overflow-scroll hidescrollbar">
      {data?.map((e) => (
        <div className="p-1" key={e}>
          <div
            onClick={() => onClicked(e)}
            className={`py-3 px-4 f14-400 badge-item bg-white text-center py-1 ${selected === e
                ? "badge-item-selected-border-focus text-black"
                : ""
              }`}
            style={{
              border: "1px solid rgba(255, 255, 255, 0.8)", // Thin white border
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)", // Very light shadow
              borderRadius: "6px",
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
            }}
          >
            {moment(e, "hh:mm a").format("hh:mm A") || ""}
          </div>
        </div>
      ))}
    </div>
  );
};
