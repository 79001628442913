// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { FacebookAuthProvider, getAuth } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDdFnMqK0NlhvCJ2ejR_MlTOPNGbu8BhYQ',
  authDomain: 'amealio-usa.firebaseapp.com',
  projectId: 'amealio-usa',
  storageBucket: 'amealio-usa.firebasestorage.app',
  messagingSenderId: '859203423831',
  appId: '1:859203423831:web:fccc7b6c9d446d69e8dd12',
  measurementId: 'G-K31WH3GH67',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const facebookProvider = new FacebookAuthProvider();
export const messaging = getMessaging(app);

export default app;

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey:
        'BL4Si7RWhCOBpSedGJN6PFfyAc5SAaUvsVPdIPqa0a13HYkW7gWOJtG21FEHSPKgHLXXKr631Tp7rnbS54fCW74',
    });

  }
};

export const getFcmToken = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission !== 'granted') {
      console.warn('Permission not granted for notifications.');
      return null;
    }

    const token = await getToken(messaging, {
      vapidKey:
        'BL4Si7RWhCOBpSedGJN6PFfyAc5SAaUvsVPdIPqa0a13HYkW7gWOJtG21FEHSPKgHLXXKr631Tp7rnbS54fCW74',
    });

    if (token) {
      return token;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error generating FCM token:', error);
    return null;
  }
};
