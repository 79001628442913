import React, { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Col, Image, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import RippleButton from "../../../../components/buttons/RippleButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { isEmpty } from "../../../../common/utility/helperFunc";
import { Close } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ondcInitialCartData,
  setViewCartInfo,
  extractTimingData,
  initialCustomizationSelectCheck,
  toggleChecked,
  totalOndcItemPrice,
} from "../../ondcHelperFuction.ts";
import {
  saveOndcApiGetCartItemsOndc,
  saveOndcApiGetOndc,
  saveOndcCartItems,
  saveOndcOrderInfo,
  saveOndcViewCartBottomInfo,
  updateOndcCheckItemAvailability,
} from "../../../../store/slices/ondcSlice.js";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../../common/api/hooks/useApi.js";
import { URL } from "../../../../common/api/urls.js";
import { useSnackbar } from "notistack";

const AddToCartOndcButton = (props) => {
  const { item, restData, actionPerform, menuData, currenItemCartData } = props;
  const dispatch = useDispatch();
  const { handleRequest, isDataLoading } = useApi();
  const path = window.location.pathname;
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const getOndcSavedCartItems = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcCartItems
  );
  const ondcOrderInfoStoreData = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcOrderInfo
  );

  const userData = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userDetails
  );

  const [state, setState] = useState({
    quantity: 0,
    alreadyInCartModal: false,
  });
  const { quantity } = state;
  const [apiLoading, setApiLoading] = useState(false);
  const [initialCartData, setInitialCartData] = useState(ondcInitialCartData());
  const [openCustomizationModal, setOpenCustomizationModal] = useState(false);
  const handleOpenCustomizationModal = () => setOpenCustomizationModal(true);
  const handleCloseCustomizationModal = () => setOpenCustomizationModal(false);

  const [customizationArr, setCustomizationArr] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedCustomizationArr, setSelectedCustomizationArr] = useState([]);

  useEffect(() => {
    let totalItems = 0;
    // if (
    //   Array.isArray(getOndcSavedCartItems) &&
    //   getOndcSavedCartItems?.length > 0
    // ) {
    //   totalItems = getOndcSavedCartItems.reduce((total, element) => {
    //     if (element?.id.includes(item.id)) {
    //       return total + (element?.quantity?.count || 0);
    //     }
    //     return total;
    //   }, 0);
    // }
    if (currenItemCartData && currenItemCartData?.cart_items?.length > 0) {
      totalItems = currenItemCartData?.cart_items.reduce((total, element) => {
        if (element?.itemDetails?.id.includes(item.id)) {
          return total + (element?.quantity || 0);
        }
        return total;
      }, 0);
    }
    setState({
      ...state,
      quantity: totalItems,
    });
    //****** MODAL INFO EFFECT ******//
    if (isEmpty(actionPerform) && actionPerform !== "ONDC_VIEW_CART") {
      let blankArr = [];
      if (Array.isArray(item?.custom_groups)) {
        let addedIds = new Set();
        !isEmpty(restData) &&
          restData?.customGroups.forEach((items) => {
            if (
              item?.custom_groups.includes(items?.id) &&
              !addedIds.has(items?.id)
            ) {
              blankArr.push(items);
              addedIds.add(items?.id);
            }
          });
      } else {
        console.error("data2 is not an array!");
      }
      let finalData = initialCustomizationSelectCheck(blankArr);
      let totalItemPrice = totalOndcItemPrice(finalData, +item?.price?.value);
      setTotalAmount(totalItemPrice);
      setCustomizationArr(finalData);
      if (!isEmpty(finalData)) {
        let blankArrTwo = [];
        !isEmpty(finalData) &&
          finalData.forEach((group) => {
            group?.items.forEach((groupInfo) => {
              if (groupInfo.checked === true) {
                blankArrTwo.push({
                  ...groupInfo,
                  parent_item_id: item?.id,
                  quantity: { count: 1 },
                });
              }
            });
          });
        setSelectedCustomizationArr(blankArrTwo);
      }
    }
  }, []);

  useEffect(() => {
    let totalItems = 0;
    if (currenItemCartData && currenItemCartData?.cart_items?.length > 0) {
      totalItems = currenItemCartData?.cart_items.reduce((total, element) => {
        if (element?.itemDetails?.id.includes(item.id)) {
          return total + (element?.quantity || 0);
        }
        return total;
      }, 0);
    }
    setState({
      ...state,
      quantity: totalItems,
    });
  }, [currenItemCartData]);

  useEffect(() => {
    if (isDataLoading) {
      setApiLoading(true);
    } else {
      setApiLoading(false);
    }
  }, [isDataLoading]);

  const onChangeSize = (arrData, catID, items) => () => {
    const updatedData = toggleChecked(arrData, catID, items?.id);
    let totalItemPrice = totalOndcItemPrice(updatedData, +item?.price?.value);
    setCustomizationArr(updatedData);
    setTotalAmount(totalItemPrice);
    if (!isEmpty(updatedData)) {
      let blankArrTwo = [];
      !isEmpty(updatedData) &&
        updatedData.forEach((group) => {
          group?.items.forEach((groupInfo) => {
            if (groupInfo.checked === true) {
              blankArrTwo.push({
                ...groupInfo,
                parent_item_id: item?.id,
                quantity: { count: 1 },
              });
            }
          });
        });
      setSelectedCustomizationArr(blankArrTwo);
    }
  };

  /*****************************
   * @DESC ADD MENU ITEM TO CART
   ****************************/
  const onClickAddToCart = useCallback(
    async (item, changeRestaurant, selectedCustomizationArrData) => {
      if (+item?.quantity?.maximum?.count <= 0) {
        console.log("You cannot add more than the item's maximum quantity");
      } else {
        // debugger;
        try {
          // const [orderData, cartData] = await Promise.all([
          //   localStorage.getItem("ondcOrderDetails"),
          //   localStorage.getItem("ondcCartDetails"),
          // ]);
          const parsedOrder = ondcOrderInfoStoreData;
          const parsedCart = getOndcSavedCartItems || [];
          let initialCartInfo = initialCartData;
          const singleCartItem = JSON.parse(JSON.stringify(item));
          singleCartItem["quantity"] = {
            count: 1,
          };
          if (item?.has_customization) {
            let newCartData = [...parsedCart, ...selectedCustomizationArrData];
            // setState((prevState) => ({
            //   ...prevState,
            //   quantity: 1,
            // }));
            newCartData.push(singleCartItem);
            let locationData = restData?.locations;
            locationData.forEach((item) => {
              if (!item.address.hasOwnProperty("building")) {
                item.address.building = "";
              }
            });
            initialCartInfo.context = restData?.context;
            initialCartInfo.message.order.restId = restData?.id;
            initialCartInfo.message.order.fulfillments = restData?.fulfillments;
            initialCartInfo.message.order.tags = restData?.tags;
            initialCartInfo.message.order.fssai_license_no =
              restData["@ondc/org/fssai_license_no"] || "";
            initialCartInfo.message.order.provider = {
              ...restData?.descriptor,
              customGroups: restData?.customGroups,
              id: restData?.id,
              locations: locationData,
            };
            initialCartInfo.message.order.items = newCartData;
            handleCloseCustomizationModal();
            onClickContinue(
              newCartData,
              initialCartData,
              item,
              selectedCustomizationArrData,
              "ADD_NEW_ITEM"
            );
          } else if (isEmpty(parsedCart) || parsedOrder?.id === restData?.id) {
            let newCartData = [...parsedCart];
            // setState((prevState) => ({
            //   ...prevState,
            //   quantity: 1,
            // }));
            newCartData.push(singleCartItem);
            let locationData = restData?.locations;
            locationData.forEach((item) => {
              if (!item.address.hasOwnProperty("building")) {
                item.address.building = "";
              }
            });

            initialCartInfo.context = restData?.context;
            initialCartInfo.message.order.restId = restData?.id;
            initialCartInfo.message.order.fulfillments = restData?.fulfillments;
            initialCartInfo.message.order.tags = restData?.tags;
            initialCartInfo.message.order.fssai_license_no =
              restData["@ondc/org/fssai_license_no"] || "";
            initialCartInfo.message.order.provider = {
              ...restData?.descriptor,
              id: restData?.id,
              locations: locationData,
            };
            initialCartInfo.message.order.items = newCartData;
            onClickContinue(
              newCartData,
              initialCartData,
              item,
              selectedCustomizationArrData,
              "ADD_NEW_ITEM"
            );
          } else {
            setState((prevState) => ({
              ...prevState,
              alreadyInCartModal: true,
            }));
            return;
          }
        } catch (error) {
          console.error("Error updating cart:", error);
        }
      }
    },
    [
      dispatch,
      restData,
      ondcOrderInfoStoreData,
      getOndcSavedCartItems,
      currenItemCartData,
    ]
  );

  /**
   * ITEM INCREMENT FUNC
   */
  const onClickAddMoreItem = useCallback(
    async (onItem, itemQuantity, selectedCustomizationArrData) => {
      if (+onItem?.quantity?.maximum?.count <= itemQuantity) {
        // showError("You cannot add more than the item's maximum quantity.");
        console.log("EnterThis===>90");
      } else {
        try {
          const [orderData, getOndcCartData] = await Promise.all([
            localStorage.getItem("ondcOrderDetails"),
            localStorage.getItem("ondcCartDetails"),
          ]);
          //ONDC LOCALSTORAGE Data
          const initialCartData = JSON.parse(orderData);
          const parsedCart = JSON.parse(getOndcCartData) || [];
          const itemInCart = parsedCart.find((item) => item?.id === onItem?.id);
          //ONDC Api Call Data
          const parsedCartTwo = currenItemCartData || {};
          const itemInCartTwo = parsedCartTwo?.cart_items.find(
            (item) => item?.itemDetails?.id === onItem?.id
          );
          if (itemInCart?.quantity?.count >= onItem?.quantity?.maximum?.count) {
            // showError(
            //   `You cannot add more than the item's maximum quantity of ${+onItem
            //     ?.quantity?.maximum?.count}.`
            // );
            console.log("EnterThis===>106");
          } else {
            if (
              // actionBy === "CUSTOMIZED_ACTION" ||
              // actionBy === "CUSTOMIZED_BTN_DISPLAY"
              item?.has_customization
            ) {
              !isEmpty(parsedCart) &&
                parsedCart.forEach((obj) => {
                  if (
                    obj.checked === true &&
                    obj.parent_item_id === onItem?.id
                  ) {
                    // Increase the quantity count by 2
                    obj.quantity.count += 1;
                  }
                });
              if (itemInCart || itemInCartTwo) {
                //ONDC LOCALSTORAGE Data
                if (!isEmpty(itemInCart)) {
                  let totalQuantity = itemInCart.quantity.count;
                  itemInCart.quantity.count = +totalQuantity + 1;
                }
                //ONDC Api Call Data
                let totalQuantityTwo = itemInCartTwo.quantity;
                totalQuantityTwo = +totalQuantityTwo + 1;
                setState((prevState) => ({
                  ...prevState,
                  quantity: totalQuantityTwo,
                }));
                onClickContinue(
                  parsedCart,
                  initialCartData,
                  onItem,
                  selectedCustomizationArrData,
                  "INCREMENT_ITEM_QTY",
                  totalQuantityTwo,
                  itemInCartTwo?._id
                );
              }
            } else if (itemInCart || itemInCartTwo) {
              //ONDC LOCALSTORAGE Data
              if (!isEmpty(itemInCart)) {
                let totalQuantity = itemInCart.quantity.count;
                itemInCart.quantity.count = +totalQuantity + 1;
              }
              //ONDC Api Call Data
              let totalQuantityTwo = itemInCartTwo.quantity;
              totalQuantityTwo = +totalQuantityTwo + 1;
              setState((prevState) => ({
                ...prevState,
                quantity: totalQuantityTwo,
              }));
              onClickContinue(
                parsedCart,
                initialCartData,
                onItem,
                selectedCustomizationArrData,
                "INCREMENT_ITEM_QTY",
                totalQuantityTwo,
                itemInCartTwo?._id
              );
            }
          }
        } catch (error) {
          console.error("Error updating cart:", error);
        }
      }
    },
    [
      dispatch,
      ondcOrderInfoStoreData,
      getOndcSavedCartItems,
      currenItemCartData,
    ]
  );

  /**
   * ITEM DECREMENT FUNC
   */
  const onClickRemoveItem = useCallback(
    async (onItem, quantity, selectedCustomizationArrData) => {
      try {
        // const [orderData, getOndcCartData] = await Promise.all([
        //   localStorage.getItem("ondcOrderDetails"),
        //   localStorage.getItem("ondcCartDetails"),
        // ]);
        //ONDC LOCALSTORAGE Data
        const initialCartData = ondcOrderInfoStoreData;
        const parsedCart =
          JSON.parse(JSON.stringify(getOndcSavedCartItems)) || [];
        const lastIndex = parsedCart.findIndex(
          (item) => item.id === onItem?.id
        );
        //ONDC Api Call Data
        const parsedCartTwo = currenItemCartData || {};
        const itemInCartTwo = parsedCartTwo?.cart_items.find(
          (item) => item?.itemDetails?.id === onItem?.id
        );
        if (lastIndex === -1) {
          //ONDC Api Call Data
          let totalQuantityTwo = itemInCartTwo.quantity;
          totalQuantityTwo = +totalQuantityTwo - 1;
          setState((prevState) => ({
            ...prevState,
            quantity: totalQuantityTwo,
          }));
          onClickContinue(
            parsedCart,
            initialCartData,
            onItem,
            selectedCustomizationArrData,
            "DECREMENT_ITEM_QTY",
            totalQuantityTwo,
            itemInCartTwo?._id
          );
        }
        const item = parsedCart[lastIndex];
        const currentCount = item?.quantity?.count || 0;
        if (currentCount <= 1) {
          parsedCart.splice(lastIndex, 1);
          for (let i = parsedCart?.length - 1; i >= 0; i--) {
            if (parsedCart[i].parent_item_id === onItem?.id) {
              parsedCart.splice(i, 1);
            }
          }
        } else {
          if (
            // actionBy === "CUSTOMIZED_ACTION" ||
            // actionBy === "CUSTOMIZED_BTN_DISPLAY"
            item?.has_customization
          ) {
            !isEmpty(parsedCart) &&
              parsedCart.forEach((obj) => {
                if (
                  obj.checked === true &&
                  obj.parent_item_id === onItem?.id &&
                  obj.quantity.count !== 1
                ) {
                  if (+obj.quantity.count !== 1) {
                    obj.quantity.count -= 1;
                  }
                }
              });
          }
          item.quantity.count -= 1;
        }
        const totalItems =
          !isEmpty(parsedCart) &&
          parsedCart.reduce((total, element) => {
            if (element.id === onItem?.id) {
              total += element?.quantity?.count || 0;
            }
            return total;
          }, 0);
        //ONDC Api Call Data
        let totalQuantityTwo = itemInCartTwo.quantity;
        totalQuantityTwo = +totalQuantityTwo - 1;
        setState((prevState) => ({
          ...prevState,
          quantity: totalQuantityTwo,
        }));
        onClickContinue(
          parsedCart,
          initialCartData,
          onItem,
          selectedCustomizationArrData,
          "DECREMENT_ITEM_QTY",
          totalQuantityTwo,
          itemInCartTwo?._id
        );
      } catch (error) {
        console.error("Error updating cart:", error);
      }
    },
    [
      dispatch,
      ondcOrderInfoStoreData,
      getOndcSavedCartItems,
      currenItemCartData,
    ]
  );

  /*****************************
   * @DESC CONTINUE MENU ITEM CART
   ****************************/
  const onClickContinue = async (
    exitingCartDataArr,
    initialCartOrderInfo,
    singleItemInfo,
    selectedCustomizationArrData,
    actionFrom,
    itemQuantity,
    itemId
  ) => {
    let orderInfoData = JSON.parse(JSON.stringify(initialCartOrderInfo));
    let cartItems = exitingCartDataArr;
    /*** VIEW CART ***/

    let finalData = setViewCartInfo(cartItems, orderInfoData);
    if (!isEmpty(orderInfoData)) {
      orderInfoData.message.order.items = cartItems;
      orderInfoData.message.order.base_amount = +finalData?.totalPrice;
      orderInfoData.message.order.total_amount = +finalData?.totalAmount;
      dispatch(saveOndcOrderInfo(orderInfoData));
      dispatch(saveOndcCartItems(cartItems));
    }
    const selectedCustomization = selectedCustomizationArrData.map(
      (item) => item.id
    );
    if (actionFrom === "ADD_NEW_ITEM") {
      const finalAddToCartDataObj = {
        transaction_id: orderInfoData?.context?.transaction_id,
        restaurant: {
          id: orderInfoData?.message?.order?.provider?.id,
          bpp_id: orderInfoData?.context?.bpp_id,
          bpp_uri: orderInfoData?.context?.bpp_uri,
          fssai_license_no: orderInfoData?.message?.order?.fssai_license_no,
          tags: orderInfoData?.message?.order?.tags,
          fulfillments: orderInfoData?.message?.order?.fulfillments,
          descriptor: orderInfoData?.message?.order?.provider,
          locations: orderInfoData?.message?.order?.provider?.locations,
          menu: {
            id: menuData?.id,
            parent_category_id: menuData?.parent_category_id,
            descriptor: menuData?.descriptor,
            tags: menuData.tags,
            item: {
              ...singleItemInfo,
              selected_custom_items: selectedCustomization,
            },
          },
          customGroups: orderInfoData?.message?.order?.provider?.customGroups,
        },
      };
      // API Call to add the items to cart in the backend
      onClickAddTocartApiCall(userData?.accessToken, finalAddToCartDataObj);
    }
    if (actionFrom === "INCREMENT_ITEM_QTY") {
      const formData = {
        type: "QUANTITY_CHANGE", // QUANTITY_CHANGE, REMOVE_ITEM
        quantity: +itemQuantity,
      };
      incrementDecrementItemsApiCall(userData?.accessToken, formData, itemId);
    }
    if (actionFrom === "DECREMENT_ITEM_QTY") {
      const formData = {
        type: +itemQuantity === 0 ? "REMOVE_ITEM" : "QUANTITY_CHANGE", // QUANTITY_CHANGE, REMOVE_ITEM
        quantity: +itemQuantity,
      };
      incrementDecrementItemsApiCall(userData?.accessToken, formData, itemId);
    }

    // API Call to add the items to cart in the backend
    await Promise.all([
      localStorage.setItem("ondcOrderDetails", JSON.stringify(orderInfoData)),
      localStorage.setItem("ondcCartDetails", JSON.stringify(cartItems)),
    ]);
    setViewCartInfoAction(cartItems, orderInfoData);
  };

  const setViewCartInfoAction = (parsedItems, orderInfoData) => {
    let finalData = setViewCartInfo(parsedItems, orderInfoData);
    localStorage.setItem("ondcViewCartBottomInfo", JSON.stringify(finalData));
    dispatch(saveOndcViewCartBottomInfo(finalData));
  };

  const onClickAddTocartApiCall = async (token, orderInfo) => {
    let headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
    await handleRequest("post", `${URL.ONDC_USER_CART}`, orderInfo, {
      headers: headers,
      onSuccess: async (data) => {
        showNotification(`${data?.message}`, "success");
        getOndcCartDataFromApiRequest(token);
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
        // setError("Failed to load experiences. Please try again later.");
      },
    });
    // setLoading(false);
  };

  const incrementDecrementItemsApiCall = async (token, orderInfo, itemId) => {
    const isOndcCartPage = path.includes("ondccart");
    let headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
    await handleRequest("patch", `${URL.ONDC_USER_CART}/${itemId}`, orderInfo, {
      headers: headers,
      onSuccess: async (data) => {
        showNotification(`${data?.message}`, "success");
        getOndcCartDataFromApiRequest(token);
        if (isOndcCartPage && orderInfo?.type !== "REMOVE_ITEM") {
          onClickAvailabilityCheck();
        }
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
      },
    });
    // setLoading(false);
  };

  const getOndcCartDataFromApiRequest = async (token) => {
    let headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
    await handleRequest("get", `${URL.ONDC_USER_CART}`, null, {
      headers: headers,
      onSuccess: async (data) => {
        dispatch(saveOndcApiGetOndc(data?.data));
        dispatch(saveOndcApiGetCartItemsOndc(data?.data?.cart_items));
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
      },
    });
  };

  const onClickAvailabilityCheck = async () => {
    await handleRequest("get", `${URL.ONDC_USER_CART_DELIVERY}`, null, {
      headers: { Authorization: userData.accessToken },
      successMessage: "",
      errorMessage: "Something went wrong! Please try again",
      onSuccess: async (response) => {
        // fetch all communities from this location
        if (response.status) {
          // setCheckItemAvailability(true);
          dispatch(updateOndcCheckItemAvailability(true));
        } else {
          dispatch(updateOndcCheckItemAvailability(false));
        }
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
      },
    });
  };

  return (
    <div className="flex items-center justify-center">
      {!isEmpty(quantity) && quantity > 0 ? (
        <div className="inline-flex rounded-md bg-white" role="group">
          <RippleButton
            minWidth={"30px"}
            btnText={<RemoveIcon fontSize="20px" />}
            borderRight={"none"}
            borderTopRightRadius={"0px"}
            borderBottomRightRadius={"0px"}
            borderTopLeftRadius={"10px"}
            borderBottomLeftRadius={"10px"}
            onClickAction={() =>
              onClickRemoveItem(
                item,
                quantity,
                selectedCustomizationArr,
                ondcOrderInfoStoreData,
                getOndcSavedCartItems
              )
            }
          />
          <RippleButton
            minWidth={"20px"}
            btnText={quantity}
            hoverBgColor={"#FFFFFF"}
            hoverColor={"#1976d2"}
            borderRight={"0px solid #C0C0C0"}
            borderLeft={"0px solid #C0C0C0"}
            borderTopLeftRadius={"0px"}
            borderTopRightRadius={"0px"}
            borderBottomLeftRadius={"0px"}
            borderBottomRightRadius={"0px"}
          />
          <RippleButton
            minWidth={"30px"}
            btnText={<AddIcon fontSize="20px" />}
            borderLeft={"none"}
            borderTopLeftRadius={"0px"}
            borderBottomLeftRadius={"0px"}
            borderTopRightRadius={"10px"}
            borderBottomRightRadius={"10px"}
            onClickAction={() =>
              onClickAddMoreItem(
                item,
                quantity,
                selectedCustomizationArr,
                ondcOrderInfoStoreData,
                getOndcSavedCartItems
              )
            }
          />
        </div>
      ) : (
        <div className="text-center">
          <RippleButton
            btnText="Add"
            minWidth={"64px"}
            onClickAction={() => {
              if (
                item?.has_customization &&
                !isEmpty(quantity) &&
                quantity <= 0
              ) {
                handleOpenCustomizationModal();
              } else {
                onClickAddToCart(item, quantity, selectedCustomizationArr);
              }
            }}
          />
          {item?.has_customization && (
            <p className="text-sm font-Mulish font-medium mb-0 text-green-500 text-center">
              Customize
            </p>
          )}
        </div>
      )}
      {openCustomizationModal && (
        <OndcCustomizationModal
          modalOpen={openCustomizationModal}
          onClickOpenModal={handleOpenCustomizationModal}
          onClickClosedModal={handleCloseCustomizationModal}
          onClickAddCustomizationAction={() =>
            onClickAddToCart(item, quantity, selectedCustomizationArr)
          }
          item={item}
          restData={restData}
          onChangeSize={onChangeSize}
          customizationArr={customizationArr}
          totalAmount={totalAmount}
        />
      )}
    </div>
  );
};

AddToCartOndcButton.propTypes = {
  item: PropTypes.object.isRequired,
  restData: PropTypes.object.isRequired,
  selectedCustomizationArr: PropTypes.array,
  actionPerform: PropTypes.string,
};

export default memo(AddToCartOndcButton);

export const OndcCustomizationModal = (props) => {
  const {
    modalOpen,
    onClickClosedModal,
    item,
    onClickAddCustomizationAction,
    onChangeSize,
    customizationArr,
    totalAmount,
  } = props;

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={modalOpen}
      onHide={onClickClosedModal}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      className="food-menu-combo-modal"
    >
      <Modal.Header className={"border"}>
        <p className="mb-0 text-xl font-Mulish font-bold">Customize</p>
        <button title="closed" onClick={onClickClosedModal}>
          <Close fontSize="medium" />
        </button>
      </Modal.Header>
      <Modal.Body className="h-auto pt-0 border-0 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
        <Row className="py-2">
          <Col>
            <p className="text-lg font-Mulish font-medium mb-0">
              {item?.descriptor?.name}
            </p>
          </Col>
          <Col className="text-end">
            <p className="text-lg font-Mulish font-bold mb-0">
              ₹ {extractTimingData(item?.price?.tags, "range", "lower")} - ₹{" "}
              {extractTimingData(item?.price?.tags, "range", "upper")}
            </p>
          </Col>
        </Row>
        {!isEmpty(customizationArr) &&
          customizationArr?.length > 0 &&
          customizationArr.map((groupData) => {
            return (
              <Accordion
                key={groupData?.id}
                defaultExpanded={true}
                id={groupData?.descriptor?.name}
                className="mb-3"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1aCustomize-content"
                  id="panel1aCustomize-header"
                  className="m-0"
                >
                  <Typography
                    component="span"
                    className="text-lg font-Mulish font-semibold"
                  >
                    {groupData?.descriptor?.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!isEmpty(groupData) &&
                    !isEmpty(groupData?.items) &&
                    groupData?.items?.length > 0 &&
                    groupData?.items.map((items, index) => {
                      return (
                        <Row key={items?.id} className="mb-2">
                          <Col
                            xs={10}
                            className="flex items-center justify-start"
                          >
                            <FormControlLabel
                              className="mr-3"
                              value={items?.price?.value}
                              control={
                                <Checkbox
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "red",
                                    },
                                  }}
                                  className="py-0"
                                />
                              }
                              checked={items?.checked}
                              onChange={onChangeSize(
                                customizationArr,
                                groupData?.id,
                                items
                              )}
                            />
                            <Image
                              src={require(`../../../../assets/images/IconsandImages/${
                                extractTimingData(
                                  items?.tags,
                                  "veg_nonveg",
                                  "veg"
                                ) === "yes"
                                  ? "vegindicator.png"
                                  : "nonveg_indicator.png"
                              }`)}
                              alt="Veg-Nog Veg"
                              className="h-3 w-3 object-fill mr-2"
                            />
                            <p className="text-sm font-Mulish font-medium mb-0">
                              {items?.descriptor?.name}
                            </p>
                          </Col>
                          <Col xs={2} className="flex items-center justify-end">
                            <p className="text-sm font-Mulish font-bold mb-0">
                              ₹ {items?.price?.value}
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        {/*sm:w-full md:w-1/2 md:float-end justify-end items-end */}
        <div className="w-full">
          <Stack
            spacing={2}
            direction="row"
            className="justify-between items-center"
          >
            <div className="flex items-center justify-start">
              <p className="mb-0 text-base font-Mulish font-bold text-lightTextColor">
                Total Amount:{" "}
                <span className="text-lg text-black">₹ {totalAmount}</span>
              </p>
            </div>
            <RippleButton
              btnText="Add"
              minWidth={"64px"}
              onClickAction={onClickAddCustomizationAction}
            />
          </Stack>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

OndcCustomizationModal.propTypes = {
  item: PropTypes.array || PropTypes.object || PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onClickClosedModal: PropTypes.func,
  onClickAddCustomizationAction: PropTypes.func,
  onChangeSize: PropTypes.func,
  customizationArr: PropTypes.array,
  totalAmount: PropTypes.number || PropTypes.string,
  currenItemCartData: PropTypes.object,
};
