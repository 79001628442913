import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { logoutUserAsync } from "../../store/slices/userSlice";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { profilePageicon } from "../../assets/images";
import { Avatar, Divider, Typography, IconButton } from "@mui/material";
import Image from "react-bootstrap/Image";
import { hideScrollBar } from "../../assets/muiClasses";
import { assets } from "../../assets/assets";
import CloseIcon from '@mui/icons-material/Close';

export default function ProfilePage({ anchor, open, toggleDrawer }) {
  const mystuff = [
    { name: "Favorites", icon: profilePageicon.favorite, route: "/Profile/favoritesPage" },
    { name: "Track Order", icon: profilePageicon.referralIcon, route: "/Profile/track-order" },
    { name: "Saved Addresses", icon: profilePageicon.bookmark, route: "/Profile/saved-addresses" },
  ]
  const wallet = [
    { name: "Wallet", icon: profilePageicon.Wallet, route: "/wallet" },
  ];

  const menuItems = [
    { name: "Your Referral Code", icon: profilePageicon.referoffer, route: "/referral-code" },
    { name: "Offer & Rewards", icon: profilePageicon.award, path: "/offers" },
    { name: "Referral Programs", icon: profilePageicon.ref, path: "/referral-programs" },
    { name: "Refer & Earn", icon: profilePageicon.kidstar, route: "/refer-earn" },
    { name: "Rating & Reviews", icon: profilePageicon.thumb, route: "/reviews" },
    { name: "Rate Us On App Store", icon: profilePageicon.mobile, route: "/rate-us" },
    { name: "Location", icon: profilePageicon.location, route: "/location" },
    { name: "Nominations", icon: profilePageicon.nominationsIcon, route: "/nominations" },
  ];

  const history = [
    { name: "Scan & Pay History", icon: profilePageicon.scan, route: "/scan-history" },
    { name: "Seating & Ordering History", icon: profilePageicon.clinic, route: "/order-history" },
    { name: "Offer & Event History", icon: profilePageicon.bsx, route: "/event-history" },
  ];

  const helpsupport = [
    { name: "Chats", icon: profilePageicon.mode, route: "/chats" },
    { name: "Help/Ticket/FAQs", icon: profilePageicon.help, route: "/help" },
    { name: "Terms & Conditions", icon: profilePageicon.localpolice, route: "/terms" },
    { name: "Privacy Policy", icon: profilePageicon.privacy, route: "/privacy-policy" },
    { name: "Refund & Cancellation Policy", icon: profilePageicon.refund, route: "/refund-policy" },
    { name: "Cookies Policy", icon: profilePageicon.proicons, route: "/cookies-policy" },
    { name: "Other Policies", icon: profilePageicon.ic, route: "/other-policies" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user) || {};
  const firstLetter = data.userDetails.first_name.charAt(0).toUpperCase();

  const handleLogout = async () => {
    try {
      dispatch(logoutUserAsync());
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };



  const list = () => (
    <Box
      sx={{ width: { xs: "99vw", sm: "90vw", md: "440px" } }}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="hidescrollbar pl-[4%] sm:pl-[5%] md:pl-[6%] lg:pl-[8%] px-4"
    >
      <Box className="mt-3 flex justify-content-between" >
        <Box className="f22-800 text-red-500">
          Profile
        </Box>
        <IconButton aria-label="close" onClick={toggleDrawer(false)} >
          <CloseIcon color={assets.colors.primary} />
        </IconButton>
      </Box>
      <Box className="flex justify-between mt-3">
        <Box>
          <Typography className="f18-500">Hello</Typography>
          <Typography className="f22-800 mt-1 "> {`${data?.userDetails?.first_name} ${data?.userDetails?.last_name}`}</Typography>
          <Typography color={assets.colors.greySubText2} className="f16-500"> {`${data?.userDetails?.mobile_number}`}</Typography>
        </Box>
        <Box className="flexCenter">
          {/* {data?.userDetails?.profile_photo?.[0] ? (
            <img
              src={data.userDetails.profile_photo[0]}
              alt="Profile Pic"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          ) : ( */}

          <Avatar sx={{ width: 64, height: 64 }}  >{firstLetter}</Avatar>
          {/* )} */}
        </Box>
      </Box>

      <Box mt={3}>
        <Typography className="f20-800 mb-2">My Stuff</Typography>
        <List disablePadding sx={{ border: '1px solid lightgrey', borderRadius: '10px', mb: 2 }} >
          {mystuff.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ borderBottom: '1px solid lightgrey' }}>
              <ListItemButton className="h-14" onClick={() => navigate(item.route)}>
                <ListItemIcon>
                  <Image src={item.icon} alt={item.name} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Typography className="f20-800 mt-4">Amealio Wallet</Typography>
        <List>
          {wallet.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ border: '1px solid lightgrey', borderRadius: '10px' }} >
              <ListItemButton >
                <ListItemIcon>
                  <Image src={item.icon} alt={item.name} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Typography className="f20-800 mt-4">Refferals & Ratings</Typography>
        <List disablePadding sx={{ border: '1px solid lightgrey', borderRadius: '10px', mb: 2 }}>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ mt: 1, borderBottom: '1px solid lightgrey' }}>
              <ListItemButton >
                <ListItemIcon>
                  <Image src={item.icon} alt={item.name} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Typography className="f20-800 mt-4">History</Typography>
        <List disablePadding sx={{ border: '1px solid lightgrey', borderRadius: '10px', mb: 2 }}>
          {history.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ mt: 1, borderBottom: '1px solid lightgrey' }}>
              <ListItemButton >
                <ListItemIcon>
                  <Image src={item.icon} alt={item.name} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Typography className="f20-800 mt-4">Help & Support</Typography>
        <List disablePadding sx={{ border: '1px solid lightgrey', borderRadius: '10px', mb: 2 }}>
          {helpsupport.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ mt: 1, borderBottom: '1px solid lightgrey' }}>
              <ListItemButton >
                <ListItemIcon>
                  <Image src={item.icon} alt={item.name} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <PrimaryButton variant="contained" onClick={handleLogout} fullWidth className="mb-4 mt-3">
          Logout
        </PrimaryButton>
        <Divider />
        <Typography className="mb-5 text-center">Version 1.9.578</Typography>
      </Box>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={hideScrollBar}
    >
      {list()}
    </SwipeableDrawer>
  );
}
