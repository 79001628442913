import { useEffect, useState } from "react";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useApi from "../../common/api/hooks/useApi";
import { URL } from "../../common/api/urls";
import MainHeader from "../../components/headers/Mainheader";
import ScreenTemplate from "../../components/ScreenTemplate";
import MainSideBar from "../../components/MainsideBar";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import ExpCheckout from "./ExpCheckout";
import { useSelector } from "react-redux";

const ExpMainCheckout = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  const userAuth = useSelector((state) => state.user.userDetails);
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  const { handleRequest, loading } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (location?.lat) {
        // validate the token first
        await handleRequest("get", `${URL.VALIDATE_TOKEN}`, null, {
          headers: { Authorization: userAuth.accessToken },
          successMessage: "Successfully validated the user authentication",
          errorMessage: "User authentication failed",
          onSuccess: async (response) => {
            // fetch all communities from this location
            setIsLoading(false);
          },
          onError: (error) => {
            setIsLoading(false);
            handleLogout();
          },
        });
      }
    })();
  }, [location]);

  const handleLogout = () => {
    sessionStorage.removeItem("userInformation");
    sessionStorage.removeItem("userOtpDetails");
    sessionStorage.removeItem("userOtpPayload");
    navigate("/");
  };

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={<ExpCheckout />}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
    />
  );
};
export default ExpMainCheckout;
