import React from "react";
import {
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

const ExperienceTimingsPopup = ({ open, onClose, timings, info }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          p: 4,
          borderRadius: 3,
          boxShadow: 5,
          width: { xs: "90%", sm: "80%", md: "400px" },
          maxWidth: "400px",
          textAlign: "left",
          overflow: "auto",
          maxHeight: "80vh",
        }}
      >
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Experience Date and Time
        </Typography>

        {/* Experience ID (Placeholder) */}
        <Box mb={1} p={1} bgcolor="#E0E0E0" borderRadius={1}>
          <Typography variant="body1" fontWeight="bold">
            Experience ID : {info?.expId}
          </Typography>
        </Box>
        <Box mb={2} p={1} bgcolor="#E0E0E0" borderRadius={1}>
          <Typography variant="body1" fontWeight="bold">
            {info?.name}
          </Typography>
        </Box>

        {/* Days with Timings */}
        {timings?.days?.map((day) => (
          <Accordion key={day}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight="bold">{day}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {timings[day]?.open ? (
                timings[day]?.multipleHours?.map((slot, index) => (
                  <Typography key={index} variant="body2" color="textSecondary">
                    {moment(slot.openingTime).format("hh:mm A")} -{" "}
                    {moment(slot.closingTime).format("hh:mm A")}
                    <Typography>{slot.description}</Typography>
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Closed
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Modal>
  );
};

export default ExperienceTimingsPopup;
