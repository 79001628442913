import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import MainSideBar from "../../components/MainsideBar";
import ScreenTemplate from "../../components/ScreenTemplate";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useAuth from "../../common/hooks/useAuth";
import { useState } from "react";
import Loader from "../../components/loader";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import useApi from "../../common/api/hooks/useApi";
import MainHeader from "../../components/headers/Mainheader";
import useWindowWidth from "../../common/hooks/useWindowWidth";

const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));

const TrackOrders = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { auth, persist } = useAuth();
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  const { handleRequest, loading } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [communityRes, setCommunityRes] = useState();
  const [userDetailsInfo, setUserDetailsInfo] = useState({});

  const handleLogout = () => {
    sessionStorage.removeItem("userInformation");
    sessionStorage.removeItem("userOtpDetails");
    sessionStorage.removeItem("userOtpPayload");
    navigate("/");
  };
  const favorites = [
    { name: "Seating" },
    { name: "Orders" },
    { name: "Experience" },
  ];

  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    (async () => {
      if (location.lat) {
        const userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
          const userDetailsInfo = JSON.parse(userDetails);
          setUserDetailsInfo(userDetailsInfo);
          // validate the token first
          await handleRequest("get", `${URL.VALIDATE_TOKEN}`, null, {
            headers: { Authorization: userDetailsInfo?.accessToken },
            successMessage: "Successfully validated the user authentication",
            errorMessage: "User authentication failed",
            onSuccess: async (response) => {
              // fetch all communities from this location
              await handleRequest(
                "get",
                `${URL.GET_USER_COMMUNITITES}?lat=${location.lat}&long=${location.lng}`,
                null,
                {
                  successMessage: "Successfully fetched communites",
                  errorMessage: "Unable to fetch communites",
                  onSuccess: (response) => {
                    setIsLoading(false);
                    setCommunityRes(response);
                  },
                  onError: (error) => {
                    setIsLoading(false);
                    setCommunityRes({
                      status: false,
                      message: "Unable to fetch communites",
                    });
                  },
                }
              );
            },
            onError: (error) => {
              setIsLoading(false);
              handleLogout();
            },
          });
        }
      }
    })();
  }, [location]);

  const content = (
    <div>
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "red",
          margin: "20px 0px 0px 20px",
        }}
      >
        Track-Orders
      </div>
      <span
        style={{
          color: "black",
          display: "flex",
          justifyContent: "space-evenly",
          borderBottom: "4px solid gray",
          margin: "30px 0px 0px 10px",
        }}
      >
        {favorites.map((item, index) => (
          <p key={index}>
            <button
              onClick={() => setIsActive(index)}
              className={
                isActive === index
                  ? "text-red-500 border-red-500"
                  : "text-black-500 border-gray-500"
              }
            >
              <b>{item.name} </b>
            </button>
          </p>
        ))}
      </span>
    </div>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
    />
  );
};

export default TrackOrders;
