import React, { useState } from "react";
import { Image, Form } from "react-bootstrap";
import { RWebShare } from "react-web-share";
import {
  checkRestaurantClosedOrOpen,
  extractTimingData,
  getOndcRestaurantAddress,
} from "../ONDC/ondcHelperFuction.ts";
import moment from "moment";

function CartSectionRestaurantDetails(props) {
  const { displayType, ondcRestInfo } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [callOpen, setCallOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (props?.onclicksearch) {
      props.onclicksearch(searchQuery);
    }
  };

  return (
    <div
      className={`title-section-with-search d-flex align-items-center${
        props.className || ""
      }`}
      style={{ backgroundColor: props.bg || "#FFFFFF" }}
    >
      <div className="flex flex-col w-100 m-3">
        <div
          className={`col-8 d-flex ${
            displayType === "ONDC" ? "align-items-start" : "align-items-center"
          }`}
        >
          {/* Restaurant Logo */}
          {props?.resticon && (
            <div className="w-16 h-16 rounded-lg bg-white">
              <Image
                src={props.resticon}
                alt="Restaurant Logo"
                className="w-full h-full object-center rounded-lg shadow-md"
              />
            </div>
          )}
          <div className="mx-2">
            <div className="f20-700 px-1 mb-2">{props?.title}</div>
            {displayType === "ONDC" && (
              <>
                <div className="flex items-center justify-start f20-700 px-1">
                  <p className="text-sm font-Mulish mb-0 font-black">
                    Time:{" "}
                    <span className="font-bold">
                      {moment(
                        extractTimingData(
                          ondcRestInfo?.tags,
                          "timing",
                          "time_from"
                        ),
                        "HHmm"
                      ).format("HH:mmA")}{" "}
                      -{" "}
                      {moment(
                        extractTimingData(
                          ondcRestInfo?.tags,
                          "timing",
                          "time_to"
                        ),
                        "HHmm"
                      ).format("HH:mmA")}
                    </span>
                  </p>
                  <p
                    className={`text-base font-Mulish font-bold mb-0 px-2 ${
                      checkRestaurantClosedOrOpen(ondcRestInfo?.tags)
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {checkRestaurantClosedOrOpen(ondcRestInfo?.tags)
                      ? "Open now"
                      : "Closed"}
                  </p>
                </div>
                <div className="px-1">
                  {getOndcRestaurantAddress(ondcRestInfo?.locations).map(
                    (line, index) => (
                      <span
                        className="text-sm font-Mulish font-semibold mb-0"
                        key={index}
                      >
                        {line}
                        {index === 3 && <br />}{" "}
                      </span>
                    )
                  )}
                </div>
              </>
            )}
            <div className="d-flex align-items-center justify-content-between w-100">
              {/* Time & Distance Section */}
              {props.time && props.distance && (
                <div className="d-flex ml-1 align-items-center">
                  {/* Timer Icon */}
                  <Image
                    src={require("../../assets/images/IconsandImages/FoodPrepTimeIcon.png")}
                    alt="Time Icon"
                    width="14px"
                    height="14px"
                    style={{ marginRight: "5px" }}
                  />
                  {/* Time Text */}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "black ",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {props.time} min
                  </span>
                  {/* Distance Text */}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "black",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div className="flex flex-row ml-2">
                      <Image
                        src={require("../../assets/images/IconsandImages/LocationIconCart.png")}
                        className="mr-2"
                      />
                      {props.distance} {"  "} Kms
                    </div>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Order References section - with Tailwind responsive classes */}
        <div className="flex flex-row mt-3 items-center whitespace-nowrap overflow-x-auto">
          <span className="text-xs sm:text-sm md:text-base font-extrabold">
            {`Order References : ${props.orderCategory} |`}
          </span>{" "}
          <div className="flex flex-row">
            <div className="text-center flex flex-row w-100 gap-1 sm:gap-2">
              <Image
                src={require("../../assets/images/IconsandImages/calexp.png")}
                alt="icon"
                width="18px"
                height="18px"
                className="ml-2 w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
              />
              <span className="text-xs sm:text-sm md:text-base font-bold">
                {props.date}
              </span>
              <Image
                src={require("../../assets/images/IconsandImages/clockexp2.png")}
                alt="icon"
                width="18px"
                height="18px"
                className="ml-1 w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
              />
              <span className="text-xs sm:text-sm md:text-base font-bold">
                {props.currentTime}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartSectionRestaurantDetails;
