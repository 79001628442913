import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";
import { Image } from "react-bootstrap";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserRegistration from "../ReusableComponent/UserRegistration";
import TitleSection from "../ReusableComponent/TitleSection";
import VideoPlayer from "../seating/VideoPlayer";
import { useAxios } from "../../common/api/hooks/useAxios";
import { getStatusMessage } from "../../common/utility/helperFunc";
import { URL } from "../../common/api/urls";
import ExpTrackDetails from "./ExpTrackDetails";
import { screenSetup } from "../experience-details/ScreenSetup";
import ExpTrackScreenQr from "../ordering/trackScreen/ExpTrackScreenQr";
import SocketContext from "../../setup/socket/SocketContext";
import CountDown from "../ReusableComponent/countdownTimer";

function ExpTrackScreenPage(props) {
  const { restaurantId, trackId } = useParams();
  const [loading, setLoading] = useState(true);
  const [restaurantData, setRestaurantData] = useState({});
  const [promoVideos, setPromoVidos] = useState([]);
  const [recFood, setRecFood] = useState([]);
  const [trackData, setTrackData] = useState([]);
  const [openQr, setQrmodal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [countDownTime, setCountDownTime] = useState(0);
  const handleClickOpenQr = () => {
    setQrmodal(true);
  };
  const handleCloseQr = () => {
    setQrmodal(false);
  };

  const [
    // eslint-disable-next-line
    restCancel,
    restResponseData,
    // eslint-disable-next-line
    restError,
    // eslint-disable-next-line
    restLoaded,
    // eslint-disable-next-line
    restReset,
    restExecuteAPI,
  ] = useAxios();

  const [
    // eslint-disable-next-line
    recommendFoodCancel,
    recommendFoodResponseData,
    // eslint-disable-next-line
    recommendFoodError,
    // eslint-disable-next-line
    recommendFoodLoaded,
    // eslint-disable-next-line
    recommendFoodReset,
    recommendFoodExecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    orderTrackcancel,
    ordertrackresponseData,
    orderTrackerror,
    orderTrackloaded,
    orderTrackreset,
    orderTrackexecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (
      sessionStorage.getItem("restaurantData") &&
      restaurantId == JSON.parse(sessionStorage.getItem("restaurantData"))?._id
    ) {
      setRestaurantData(JSON.parse(sessionStorage.getItem("restaurantData")));
    } else {
      restExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${restaurantId}?lat=true&long=true`,
        "GET",
        null
      );
    }
  }, []);

  const [
    // eslint-disable-next-line
    promotionalvideosCancel,
    promotionalvideosResponseData,
    // eslint-disable-next-line
    promotionalvideosError,
    // eslint-disable-next-line
    promotionalvideosLoaded,
    // eslint-disable-next-line
    promotionalvideosReset,
    promotionalvideosExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (restaurantData)
      promotionalvideosExecuteAPI(`${URL.PROMOTIONAL_VIDEOS}`, "GET", null, {
        Authorization: sessionStorage.getItem("accessToken"),
      });
  }, [restaurantData]);

  useEffect(() => {
    if (promotionalvideosResponseData) setLoading(false);
    setPromoVidos(promotionalvideosResponseData);
  }, [promotionalvideosResponseData]);

  useEffect(() => {
    if (restaurantData.vendorId)
      recommendFoodExecuteAPI(
        `${URL.RECOMMENDED_FOOD}?vendor_id=${restaurantData.vendorId}`,
        "GET",
        null,
        {
          Authorization: sessionStorage.getItem("accessToken"),
        }
      );
  }, [restaurantData.vendorId]);

  useEffect(() => {
    if (restaurantData.vendorId && recommendFoodResponseData)
      setRecFood(recommendFoodResponseData);
  }, [recommendFoodResponseData]);

  const getOrderData = async () => {
    orderTrackexecuteAPI(`${URL.USEREXP}/${trackId}`, "GET", null, {
      Authorization: sessionStorage.getItem("accessToken"),
    });
  };
  const [showOtpModal, setshowOtpModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (restaurantData) await getOrderData();
      // Calculate countdown time after getOrderData completes
      const timeData = getTimeData();
      setCountDownTime(timeData);
    };

    fetchData();
  }, [restaurantData]);

  useEffect(() => {
    if (ordertrackresponseData) {
      setTrackData(ordertrackresponseData);
      const timeData = getTimeData();
      setCountDownTime(timeData);
    }
  }, [ordertrackresponseData]);

  useEffect(() => {
    if (props?.socket) {
      props.socket.authentication.app.authentication.setAccessToken(
        sessionStorage.getItem("accessToken")
      );
      props.socket.authentication.app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log("Socket Re-Authenticated");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    props?.socket?.service("expRequest").on("requestUpdate", (_data) => {
      setTrackData(_data);
      //}
    });
  }, []);

  const message = getStatusMessage(trackData, trackData?.order_status);

  const status = trackData?.order_status;

  // const getTimeData = () => {
  //   // try {
  //   if (trackData?.max_time_date || trackData?.OrderAcceptTime) {
  //     const updatedTime = moment(
  //       trackData?.max_time_date || trackData?.OrderAcceptTime
  //     ).add(parseInt(trackData?.max_time), 'minutes');

  //     let diff = updatedTime.diff(moment());

  //     return diff;
  //   } else {
  //     return 0;
  //   }
  //   // } catch (error) {
  //   //   return 0;
  //   // }
  // };
  console.log(trackData);
  const getTimeData = () => {
    if (trackData?.reservationTime || trackData?.updatedAt) {
      const updatedTime = new Date(trackData?.reservationTime);
      const currentTimeTwo = new Date().getTime();
      const updatedTimeNew = updatedTime.getTime();
      const waitTime =
        Math.abs(trackData?.waitTime < 0 ? 0 : trackData?.waitTime) * 60 * 1000;
      let updateWaitTime = updatedTimeNew + waitTime;
      let diff = updateWaitTime - currentTimeTwo;

      return diff;
    } else {
      return 0;
    }
  };

  return (
    <Box>
      {showOtpModal ? (
        <UserRegistration
          open={showOtpModal}
          setshowOtpModal={(val) => {
            // if (!val && sessionStorage.getItem("accessToken")) {
            //   getOrderData();
            // }
            setshowOtpModal(val);
          }}
          noNavigationOnSubmit={true}
        />
      ) : null}
      {loading && !promoVideos && !trackData ? (
        <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
          <CircularProgress sx={{ marginTop: "20%" }} />
        </div>
      ) : (
        <>
          <div>
            <TitleSection
              title={restaurantData?.restaurant_name || ""}
              subtitle={`REQUEST ID: ${trackData?.requestId || ""}`}
              resticon={restaurantData?.logo_url_thumbnails}
              className={"box-shadow-down"}
              backRoute={`/restaurant/${restaurantId}/food/menu`}
              callicon={true}
            />
            <div className="row mx-0 bg-indigo py-2">
              <div className="col-5 d-flex justify-content-between">
                <div className="text-center">
                  <Image
                    src={require("../../assets/images/IconsandImages/schedule.png")}
                    alt="icon"
                    width="20px"
                    height="20x"
                    style={{ maxWidth: "100%" }}
                  />
                  <div className="f10-700">
                    {moment(trackData?.reservationTime).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="text-center ">
                  <Image
                    src={require("../../assets/images/IconsandImages/clock.png")}
                    alt="icon"
                    width="20px"
                    height="20x"
                    style={{ maxWidth: "100%" }}
                  />
                  <div className="f10-700">
                    {moment(trackData?.reservationTime).format("hh:mm A")}
                  </div>
                </div>
              </div>
              <div className="col-7 d-flex justify-content-between">
                <div className="f14-700 text-indigo text-center mt-3 mr-1">
                  {trackData?.status === "NOTSEATED" ||
                  trackData.status === "SEATED" ? (
                    <>
                      <CountDown waitNew={countDownTime ? countDownTime : 0} />
                    </>
                  ) : null}

                  {/* <TrackCountDown untill={getTimeData()} /> */}
                </div>
                <div onClick={handleClickOpenQr}>
                  <Image
                    src={require("../../assets/images/IconsandImages/viewqr.png")}
                    alt="icon"
                    width="52px"
                    // height="25x"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="row mx-0 bg-p2 py-2">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <div className="f14-700 opacity-50">Order References:</div>
                  <div className="f14-700">{trackData?.experienceId?.name}</div>
                </div>

                {/* <div>
                <Image
                  src={require('../../../assets/images/IconsandImages/viewqr.png')}
                  alt="icon"
                  width="52px"
                  style={{ maxWidth: '100%' }}
                />
              </div> */}
              </div>
            </div>
            <div className="m-3">
              <VideoPlayer videosData={promoVideos} />
            </div>
            <div className="p-2">
              <div className="f14-700  my-2"> Experience Pass details</div>
              {/* <div className="p-2"> */}
              <div
                className="pl-4  py-2 my-2 package-pass-card"
                style={{
                  backgroundImage: `url('${screenSetup.CURATED?.PASS?.BACKGROUND}')`,
                }}
              >
                <div className="d-flex mt-2 ">
                  <div className="col-8">
                    <div className="f14-500 text-indigo">
                      {trackData?.package?.count
                        ? trackData?.package?.count + trackData?.package?.name
                        : trackData?.experienceId?.name}
                    </div>
                    {trackData?.adultCount ? (
                      <div className="f14-500">
                        {trackData?.adultCount} Adults{" "}
                      </div>
                    ) : null}
                    {trackData?.kidsCount ? (
                      <div className="f14-500">
                        {trackData?.kidsCount} Kids{" "}
                      </div>
                    ) : null}
                    {trackData?.package?.count ? (
                      <div className="f12-500 d-flex text-indigo">
                        <div>
                          {"("}
                          {trackData?.package?.adults
                            ? trackData?.package?.adults + "Adults"
                            : null}{" "}
                          {", "}
                          {trackData?.package?.kids
                            ? trackData?.package?.kids + "Kids"
                            : null}
                          {")"}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="col-1 p-0"
                    style={{ maxHeight: "110px", width: "10px" }}
                  >
                    <Image
                      src={require("../../assets/images/ExperiencePageAssets/indigo-divider.png")}
                      height={"100%"}
                    />
                  </div>
                  <div className="col-3 justify-content-end pl-4">
                    {trackData?.package?.count ? (
                      <div className="f14-700">
                        {" "}
                        ₹ {trackData?.package?.price}
                      </div>
                    ) : (
                      <div style={{ visibility: "hidden" }}> {"hello"}</div>
                    )}
                    {trackData?.adultCount ? (
                      <div className="f14-700">₹ {trackData?.adultPrice}</div>
                    ) : null}

                    {trackData?.kidsCount ? (
                      <div className="f14-700">₹ {trackData?.kidsPrice}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>

            <ExpTrackDetails
              data={trackData}
              fontsize={false}
              open={cancelModal}
              setOpen={setCancelModal}
            />
            <div className="mt-3" style={{ marginBottom: "10rem" }}>
              {" "}
              <Image
                src={require("../../assets/images/IconsandImages/referearn.png")}
                fluid
                width="100%"
                style={{ maxWidth: "100%" }}
              />
              <div className="m-4"></div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                position: "fixed",
                bottom: 0,
                width: "100%",
                zIndex: "100",
              }}
            >
              <div className="col-12 col-md-6 col-lg-4 m-0">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="p-2 primary-bg-color d-flex justify-content-between nestedjustify-between"
                    style={{
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    <div className="row m-0 w-100">
                      <div className="col-8">
                        <div className="d-flex justify-content-start align-items-center">
                          <span className="f14-700 text-white mx-2 ">
                            {trackData?.status}
                          </span>
                        </div>
                      </div>
                      <div className="col-4 nowrap flexCenter">
                        <div
                          className="f12-500 text-white"
                          style={{ textDecoration: "underline" }}
                        >
                          {" "}
                          View Updates
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ maxHeight: "30vh", overflowY: "scroll" }}
                  >
                    {trackData?.auditLogs ? (
                      <>
                        <Timeline
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0,
                            },
                          }}
                        >
                          {trackData?.auditLogs.map((data) => {
                            return (
                              <TimelineItem key={data._id}>
                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <div className="f12-400">
                                    {data?.initiator}
                                  </div>
                                  <div className="f14-700">{data?.message}</div>
                                  <div className="f10-400">
                                    {" "}
                                    {moment(data?.timestamp).format(
                                      "DD MMM YYYY"
                                    )}{" "}
                                    {"|"}{" "}
                                    {moment(data?.timestamp).format("hh:mm A")}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          })}
                        </Timeline>
                      </>
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
          {/* <TrackScreenQr
            open={openQr}
            handleCloseQr={handleCloseQr}
            data={trackData}
          /> */}
          <ExpTrackScreenQr
            open={openQr}
            handleCloseQr={handleCloseQr}
            data={trackData}
          />
        </>
      )}
    </Box>
  );
}

const ExpTrackScreen = (props) => (
  <SocketContext.Consumer>
    {(socket) => <ExpTrackScreenPage {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default ExpTrackScreen;
