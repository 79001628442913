import React, { useState } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PhoneContactPopup from '../ExperienceBooking/Components/PhoneContactPopup';
import useWindowWidth from '../../common/hooks/useWindowWidth';
const RelatedExperiences = ({
  relatedResponseData,
  toggleFavorite,
  restdata,
  favorites,
}) => {
  const navigate = useNavigate();
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  const buttons = [
    { icon: 'crossExp.png', alt: 'Cancel', text: 'Cancel' },
    { icon: 'chatExp.png', alt: 'Chat', text: 'Chat' },
    { icon: 'helpExp.png', alt: 'Help', text: 'Help' },
    { icon: 'addItemsExp.png', alt: 'Add Items', text: 'Add Items' },
    { icon: 'shareExp.png', alt: 'Share', text: 'Share' },
  ];
  if (!relatedResponseData || relatedResponseData.length === 0)
    return (
      <div
        className="f18-700"
        style={{ textAlign: 'center', marginTop: '20px' }}
      >
        No restaurants available
      </div>
    );
  console.log('restdata', restdata);
  return (
    <>
      {relatedResponseData.map((experience) => {
        const isLiked = favorites.includes(experience._id);

        return (
          <div
            key={experience._id}
            style={{ padding: '10px 20px', marginBottom: '40px' }}
          >
            {/* Experience Title */}
            <Row className="mt-3 align-items-center">
              {/* Restaurant Name - Separate Row for Mobile */}
              {/* <Col xs={12} className="text-center d-md-none mb-2">
                <div
                  className="fw-bold"
                  style={{ fontSize: "30px", color: "#000" }}
                >
                  {experience.restaurantName || "Restaurant Name"}
                </div>
              </Col> */}

              {/* Favorite, Rating, and Call - Separate Row for Mobile */}
              {/* <Col
                xs={12}
                className="d-flex justify-content-center gap-2 d-md-none"
              >
                <Image
                  src={
                    isLiked
                      ? require("../../assets/images/ExperiencePageAssets/heart.png")
                      : require("../../assets/images/IconsandImages/heart.png")
                  }
                  alt="Like"
                  style={{ width: "20px", height: "20px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFavorite(experience._id);
                  }}
                />

                <Image
                  className="icon"
                  src={require("../../assets/images/ExperiencePageAssets/startgreen.png")}
                  alt="Rating"
                  style={{ maxWidth: "40px", height: "22px" }}
                />
                <span
                  className="fw-bold"
                  style={{ fontSize: "16px", color: "#333" }}
                >
                  {experience.expReviewed?.toFixed(1) || "N/A"}
                </span>

                <Button
                  variant="light"
                  style={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                    border: "1px solid #ddd",
                    marginTop: "7px",
                  }}
                >
                  <Image
                    src={require("../../assets/images/ExperiencePageAssets/calls.png")}
                    alt="Call"
                  />
                </Button>
              </Col> */}
              {/* <PhoneContactPopup
                open={openPhoneModal}
                onClose={() => setOpenPhoneModal(false)}
                contacts={restdata?.point_of_contact?.[0] || null}
              /> */}
              {/* Desktop Layout - Everything in One Row */}
              <Col
                md={12}
                className=" d-flex align-items-center justify-content-between"
              >
                {/* Restaurant Name on the Left */}
                <div
                  className="fw-bold"
                  style={{ fontSize: '28px', color: '#000' }}
                >
                  {experience.restaurantName || 'Restaurant Name'}
                </div>

                {/* Centered Rating and Favorite */}
                <div className="d-flex align-items-center gap-2">
                  <Image
                    src={
                      isLiked
                        ? require('../../assets/images/ExperiencePageAssets/heartexpcard.png')
                        : require('../../assets/images/IconsandImages/heart.png')
                    }
                    alt="Like"
                    style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleFavorite(experience._id);
                    }}
                    className="mx-1"
                  />
                  <Image
                    src={require('../../assets/images/landingPageAssets/star.png')}
                    alt="Rating"
                    style={{ width: '18px', height: '18px' }}
                  />
                  <span
                    className="fw-bold"
                    style={{ fontSize: '16px', color: '#333' }}
                  >
                    {experience.expReviewed?.toFixed(1) || 'N/A'}
                  </span>
                  <Button
                    variant="light"
                    className="d-flex align-items-center justify-content-center rounded-circle border mx-1 "
                    style={{ width: '35px', height: '35px', padding: 0 }}
                    onClick={() => setOpenPhoneModal(true)}
                  >
                    <Image
                      src={require('../../assets/images/IconsandImages/call.png')}
                      alt="Call"
                      style={{ width: '18px', height: '18px' }}
                    />
                  </Button>
                </div>

                {/* Call Button on the Right */}
              </Col>
            </Row>

            {/* Cuisines */}
            <Row className="mt-3">
              <Col xs={12} md={5}>
                <div className="f15-500 text-break">
                  {experience.restCuisines
                    ? experience.restCuisines.length > 100
                      ? `${experience.restCuisines.substring(0, 100)}...`
                      : experience.restCuisines
                    : 'Cuisines not available'}
                </div>
              </Col>
            </Row>

            {/* Address */}
            <div className="f14-500" style={{ marginTop: '5px' }}>
              {experience.venueAddress}
            </div>

            {/* Status and Price */}
            <div className="f14-400" style={{ marginTop: '10px' }}>
              <span style={{ color: '#ffa500' }}>Open now</span> - 11a.m - 12p.m
              | ₹{experience.adultPrice || 'N/A'} for two
            </div>

            {/* Image Section */}
            <Row className="mt-3">
              {/* For Small Screens: Full Width Images in Separate Rows */}
              <Col xs={12} className="d-md-none mb-3">
                {experience.photos?.length > 0 ? (
                  <Image
                    src={experience.photos[0]}
                    alt="Event"
                    className="w-100 rounded"
                    style={{ height: '280px' }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </Col>

              {/* For Larger Screens: Keep Original Layout */}
              <Col md={6} className="d-none d-md-block">
                {experience.photos?.length > 0 ? (
                  <Image
                    src={experience.photos[0]}
                    alt="Event"
                    className="w-100 rounded"
                    style={{ height: '280px' }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </Col>

              <Col
                md={5}
                className="d-none d-md-flex flex-column justify-content-between"
              >
                {experience.photos?.length > 1 && (
                  <>
                    <Image
                      src={experience.photos[1]}
                      alt="Secondary Event"
                      className="w-100 rounded mb-2"
                      style={{ height: '140px' }}
                    />
                    {experience.photos.length > 2 && (
                      <Image
                        src={experience.photos[2]}
                        alt="Tertiary Event"
                        className="w-100 rounded"
                        style={{ height: '140px' }}
                      />
                    )}
                  </>
                )}
              </Col>
            </Row>

            {/* Action Buttons */}
            <Row className="justify-content-center mt-1">
              {/* Buttons Row */}
              {isDesktop ? (
                <>
                  <Col
                    xs={12}
                    className="d-flex flex-wrap justify-content-center gap-3 d-md-none"
                  >
                    {buttons.map((btn, index) => (
                      <Button
                        key={index}
                        className="d-flex align-items-center justify-content-center p-2"
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          borderColor: '#F85032',
                          backgroundColor: '#ffffff',
                        }}
                      >
                        <Image
                          src={require(`../../assets/images/IconsandImages/${btn.icon}`)}
                          style={{ width: '18px', height: '18px' }}
                        />
                      </Button>
                    ))}
                  </Col>
                </>
              ) : null}

              <Col
                xs={12}
                className="d-md-none mt-1 d-flex justify-content-end"
              >
                <Button
                  variant="danger"
                  className="px-4 py-2 fw-bold"
                  style={{
                    width: '200px',
                    backgroundColor: '#EE3A23',
                    border: 'none',
                  }}
                  onClick={() =>
                    navigate(
                      `/experience/${experience.restaurantId._id}/${experience._id}/booking`
                    )
                  }
                >
                  Book Now
                </Button>
              </Col>

              {/* Desktop View - Full Buttons */}
              <Col
                md={12}
                className="d-none d-md-flex justify-content-center flex-wrap gap-3 mt-1"
              >
                {buttons.map((btn, index) => (
                  <Button
                    key={index}
                    className="d-flex align-items-center gap-2 px-3 py-2"
                    style={{
                      borderRadius: '8px',
                      borderColor: '#F85032',
                      backgroundColor: '#ffffff',
                      color: '#F85032',
                    }}
                  >
                    <Image
                      src={require(`../../assets/images/IconsandImages/${btn.icon}`)}
                      alt={btn.alt}
                      style={{ width: '16px', height: '16px' }}
                    />
                    {btn.text}
                  </Button>
                ))}
                <Button
                  variant="danger"
                  className="px-4 py-2  fw-bold"
                  style={{
                    width: '200px',
                    backgroundColor: '#EE3A23',
                    border: 'none',
                  }}
                  onClick={() =>
                    navigate(
                      `/experience/${experience.restaurantId._id}/${experience._id}/booking`
                    )
                  }
                >
                  Book Now
                </Button>
              </Col>
            </Row>
            <PhoneContactPopup
              open={openPhoneModal}
              onClose={() => setOpenPhoneModal(false)}
              contacts={restdata?.point_of_contact?.[0] || null}
            />
          </div>
        );
      })}
    </>
  );
};

export default RelatedExperiences;
