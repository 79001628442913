import React from "react";
import { Card } from "react-bootstrap";
import Icons from "../../../components/Icons";
import { Image } from "react-bootstrap";
const TicketPackage = ({ responseData }) => {
  //console.log("response data in ticket ", responseData);
  return (
    <div className="col-lg-5 col-md-12">
      <Card
        className="col-12 w-10 rounded-3xl p-6 rounded-4"
        style={{ borderColor: "#A28AE4", backgroundColor: "#FFFBFA" }}
      >
        <div className="p-0 space-y-4">
          <div className="d-flex align-items-center gap-3">
            <h2 className="text-xl fw-bold">Single Ticket</h2>
          </div>

          <div className="d-flex justify-content-between align-items-center position-relative">
            {/* Adult Section */}
            <div className="d-flex flex-column align-items-center flex-grow-1">
              <Image
                src={require("../../../assets/images/IconsandImages/ticketexp.png")}
                style={{ width: "40px", height: "40px", marginBottom: "8px" }}
              />
              <p className="text-lg m-0">
                ₹{responseData?.adultPrice} / per adult
              </p>
            </div>

            {/* Vertical Line Separator */}
            <div
              className="vertical-line d-none d-md-block"
              style={{
                height: "150%",
                width: "1px",
                backgroundColor: "#A28AE4",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                marginBottom: "50px",
              }}
            ></div>

            {/* Child Section */}
            <div className="d-flex flex-column align-items-center flex-grow-1">
              <Image
                src={require("../../../assets/images/IconsandImages/kidsexp.png")}
                style={{ width: "40px", height: "40px", marginBottom: "8px" }}
              />
              <p className="text-lg m-0">
                ₹{responseData?.kidsPrice} / per child
              </p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TicketPackage;
