import React, { useState, useRef, useCallback, useEffect } from 'react';
import { URL } from '../../common/api/urls';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Col, Row } from 'react-bootstrap';
import { CircularProgress, Divider, Typography } from '@mui/material';
import ScreenTemplate from '../../components/ScreenTemplate';
import { useAxios } from '../../common/api/hooks/useAxios';
import MainSideBar from '../../components/MainsideBar';
import MainHeader from '../../../src/components/headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import useApi from '../../common/api/hooks/useApi';
import { GOOGLE_API_KEY } from '../../config/keys';
import '../../assets/scss/fonts.scss';
import '../../assets/scss/colors.scss';
import VideosPlayer from '../seatingwebview/VideosPlayer';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import OrderingServices from '../../components/food/OrderingServices';
import ReferEarnHelp from './ReferEarnHelp';
import FavoriteButton from '../homePage/FavoriteButton';
import useLocation from '../../common/hooks/useLocation';

function RestaurantDetailsPage({ from }) {
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { ID, experienceId } = useParams();
  const { handleRequest } = useApi();
  const userAuth = JSON.parse(localStorage.getItem('userDetails'))?.accessToken;
  const [state, setState] = useState({
    isLoading: true,
    menuData: undefined,
    restData: undefined,
    restId: ID,
    loadingMenu: true,
  });

  const [selectedFilters, setSelectedFilters] = useState({
    RESTAURANT_TYPE: [],
    FOOD_TYPE: [],
    LIQUORS: [],
    SORT_BY: [],
    subscribedServices: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleFilterChange = (category, values) => {
    setSelectedFilters((prev) => ({ ...prev, [category]: values }));
  };
  const { restId, restData } = state;

  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  const [isActive, setIsActive] = useState(null);
  const favorites = [
    { name: 'Images' },
    { name: 'Videos' },
    { name: 'About' },
    { name: 'Reviews' },
    { name: 'Services' },
    { name: 'Things to do' },
  ];

  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  //   iconSrc = ''; // Replace with your Icon A image path
  // } else if (rating >= 3 && rating < 4) {
  //   iconSrc = '/src/assets/images/IconsandImages/starnew.png'; // Replace with your Icon B image path
  // } else if (rating >= 4 && rating <= 5) {
  //   iconSrc = '/src/assets/images/IconsandImages/star 1.png'; // Replace with your Icon C image path

  useEffect(() => {
    if (textRef.current)
      setIsOverflowing(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
  }, [restData]);
  useEffect(() => {
    if (restId !== undefined && restId !== null) {
      executeAPI(
        `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,
        null
      );
    }
  }, []);
  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset();
    }
  }, [loaded, responseData]);

  const [reelsData, setReelsData] = useState([]);
  const fetchReels = useCallback(
    async (restaurantId, page = 1, limit = 10, parsedLocation) => {
      let headers = {
        'Content-Type': 'application/json',
        authorization: userAuth,
      };
      await handleRequest(
        'get',
        `/reels?restaurantId=${restaurantId}&limit=${limit}&page=${page}`,
        null,
        {
          headers: headers,
          onSuccess: async (response) => {
            console.log('Reels Response:', response);
            setReelsData(response);
          },
          onError: (error) => {},
        }
      );
    },
    []
  );
  useEffect(() => {
    if (ID) fetchReels(ID);
  }, [ID, fetchReels]);
  console.log(restData, 'data');

  const content = (
    <>
      {isLoading ? (
        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
          <CircularProgress sx={{ marginTop: '20%' }} />
        </div>
      ) : (
        <>
          <Box
            sx={{
              padding: '10px',
              fontFamily: 'Arial, sans-serif',
              display: { xs: 'none', lg: 'block' },
            }}
            className="d-flex justify-between"
          >
            <Box>
              <Box
                sx={{
                  fontSize: '24px',
                  fontWeight: '800',
                  color: '#EE3A23',
                  fontFamily: 'mulish',
                }}
              >
                Food
              </Box>
              <Box
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginTop: '5px',
                  fontFamily: 'mulish',
                }}
              >
                Food / Restaurant Details
              </Box>
            </Box>

            <Box>
              <Box className="d-flex justify-between m-4">
                <Box className="d-flex justify-evenly items-center h-12 gap-2">
                  <img
                    className="h-12 w-12"
                    src={require('../../assets/images/IconsandImages/callicon.png')}
                    alt="call"
                  />
                  <FavoriteButton restaurantId={restData?._id} />
                  <img
                    className="h-12 w-12"
                    src={require('../../assets/images/IconsandImages/shareicon.png')}
                    alt="share"
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="flex flex-wrap lg:justify-between mx-24">
            <Box className="flex flex-col items-center  text-center">
              <Box>
                {restData ? (
                  <img
                    className="rounded-full h-40 w-40 object-cover"
                    src={state?.restData.logo_url_thumbnails}
                    alt="Restaurant Logo"
                  />
                ) : (
                  'No data available'
                )}
              </Box>

              <Box className="f24-600">{state?.restData?.restaurant_name}</Box>

              <PrimaryButton className="rounded-md w-64 h-12 f20-600">
                + Follow
              </PrimaryButton>
            </Box>

            <Box className="flex flex-col gap-3 h-72 w-3/5">
              <Box className="d-flex justify-around !border-2 border-gray-200 h-20 items-center rounded-xl">
                <Box>
                  {' '}
                  <Box className="f20-700">12</Box>
                  <Box className="f16-400">Posts</Box>
                </Box>
                <Box>
                  {' '}
                  <Box className="f20-700">300</Box>
                  <Box className="f16-400">Followers</Box>
                </Box>
                <Box>
                  {' '}
                  <Box className="f20-700">800</Box>
                  <Box className="f16-400">Following</Box>
                </Box>
              </Box>

              <Box className="d-flex justify-between">
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                  <Box>
                    <Box className="inline-flex w-max  h-9 rounded-3xl items-center f16-600">
                      {state?.restData?.rating ? (
                        state.restData.rating >= 1 &&
                        state.restData.rating < 3 ? (
                          <img
                            src={require('../../assets/images/IconsandImages/star.png')}
                            alt="Rating Icon A"
                            style={{ width: '24px', height: '24px' }}
                          />
                        ) : state.restData.rating >= 3 &&
                          state.restData.rating < 4 ? (
                          <img
                            src={require('../../assets/images/IconsandImages/star221.png')}
                            alt="Rating Icon B"
                            style={{ width: '24px', height: '24px' }}
                          />
                        ) : state.restData.rating >= 4 &&
                          state.restData.rating <= 5 ? (
                          <img
                            src={require('../../assets/images/IconsandImages/star 1.png')}
                            alt="Rating Icon C"
                            style={{ width: '24px', height: '24px' }}
                          />
                        ) : (
                          <img
                            src={require('../../assets/images/IconsandImages/star.png')}
                            alt="Rating Icon A"
                            style={{ width: '24px', height: '24px' }}
                          />
                        )
                      ) : (
                        <img
                          src={require('../../assets/images/IconsandImages/star.png')}
                          alt="Rating Icon A"
                          style={{ width: '24px', height: '24px' }}
                        />
                      )}
                      &nbsp; {state?.restData?.rating}
                      <span className="text-blue-500">
                        ( {state?.restData?.reviews} reviews)
                      </span>
                    </Box>
                  </Box>
                  <Box className="flex justify-center items-center f16-600">
                    <img
                      className="h-6 w-6"
                      src={require('../../assets/images/IconsandImages/time.png')}
                    ></img>
                    {state?.restData?.openStatus?.open && (
                      <span> &nbsp;Open</span>
                    )}
                    ( Closes at 11:00 PM)
                  </Box>
                  <Box className="d-flex w-max h-9 justify-center items-center border-gray-400">
                    <Row className="d-flex f16-600">
                      <Box
                        xs={9}
                        className="d-flex align-items-center justify-content-center gap-2"
                      >
                        {/* Only Veg */}
                        {restData?.vegOnly && (
                          <>
                            <img
                              src={require('../../assets/images/IconsandImages/vegexp.png')}
                              alt="Veg"
                            />
                            <span>Veg</span>
                          </>
                        )}

                        {/* Only Non-Veg */}
                        {restData?.isOnlyNonVeg && (
                          <>
                            <img
                              src={require('../../assets/images/IconsandImages/nonvegexp.png')}
                              alt="Non-Veg"
                            />
                            <span sx={{ whiteSpace: 'nowrap' }}>Non-Veg</span>
                          </>
                        )}

                        {/* Both Veg & Non-Veg */}
                        {!restData?.vegOnly && !restData?.isOnlyNonVeg && (
                          <>
                            <img
                              src={require('../../assets/images/IconsandImages/vegexp.png')}
                              alt="Veg"
                            />
                            <img
                              src={require('../../assets/images/IconsandImages/nonvegexp.png')}
                              alt="Non-Veg"
                            />
                            <span>Veg</span>

                            <span>Non-Veg</span>
                          </>
                        )}
                      </Box>
                    </Row>
                  </Box>
                </Box>
              </Box>
              <Box className="f16-600 d-flex gap-1">
                <img
                  className="h-5 w-6 "
                  src={require('../../assets/images/IconsandImages/location(1).png')}
                ></img>

                <Box>{state?.restData?.restaurant_address}</Box>
              </Box>
              <Box className="flex flex-col justify-between gap-2">
                {' '}
                <Box className="flex justify-between items-center f16-600">
                  <Box className="d-flex gap-3">
                    <img
                      src={require('../../assets/images/IconsandImages/lunch_dining.png')}
                    ></img>
                    {(state?.restData?.fastfood_listing_services?.open && (
                      <span>Fast food restaurant</span>
                    )) || <span>No fast food services</span>}
                  </Box>
                  <Box>
                    <img
                      src={require('../../assets/images/IconsandImages/rice_bowl.png')}
                    ></img>
                  </Box>
                </Box>
                <Box className="flex justify-between items-center f16-600">
                  <Box className="d-flex gap-3">
                    <img
                      src={require('../../assets/images/IconsandImages/currency_rupee.png')}
                    ></img>
                    {state?.restData?.cost_for_two && (
                      <span> {state?.restData?.cost_for_two} for two</span>
                    )}
                  </Box>
                  <Box>
                    <img
                      src={require('../../assets/images/IconsandImages/nest_remote_comfort_sensor.png')}
                    ></img>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <OrderingServices
            className="m-4 bg-slate-400"
            title={
              <span className="text-black/200">What would you like to do?</span>
            }
            hanldeClick={(e) => {
              if (from === 'ONDC') {
                navigate(`/ondc/${state?.restData?._id}/menu`);
              } else {
                navigate(`/restaurant/${state?.restData?._id}/food/menu`);
              }
            }}
            handleFilterChang={handleFilterChange}
            selectedFilters={selectedFilters}
          />

          <Box className="mt-4">
            {state?.restData?.primaryOffer && (
              <>
                <Typography className="f24-800 ml-4">Offers</Typography>
                <Box className="flex flex-wrap gap-2 p-4 f14-600 ">
                  <Box className="d-flex gap-2 bg-pink-200 p-4 rounded-md items-center justify-center h-16 w-52">
                    <img
                      className="h-6 w-6"
                      src={require('../../assets/images/IconsandImages/offers.png')}
                    />
                    <Box>
                      {' '}
                      Get {state.restData.primaryOffer.discount}% Off Offer Code
                      - {state.restData.primaryOffer.coupon_code}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box className=" d-flex justify-between gap-10 m-4 f20-600">
            <Button
              className="f20-600 !border-2 !border-red-600"
              variant="white"
              onClick={() => {
                navigate(`/restaurant/${ID}/food/menu`);
              }}
            >
              View Menu
            </Button>
            <Button
              className="f20-600 !border-2 !border-red-600"
              variant="white"
              onClick={() => {
                navigate(`/restaurant/${ID}/experienceweb/:experienceId`);
              }}
            >
              View Experience
            </Button>
            <Button
              className="f20-600 !border-2 !border-red-600"
              variant="white"
              onClick={() => {
                navigate(`/community`);
              }}
            >
              View communities
            </Button>
          </Box>

          <Box className="d-flex justify-between shadow-[0px_5px_10px_rgba(0,0.1,0,0.1)]  m-4 items-center  h-16">
            {favorites.map((item, index) => (
              <Typography key={index}>
                <button
                  onClick={() => setIsActive(index)}
                  className={
                    isActive === index
                      ? 'text-red-500 border-red-500 border-b-4'
                      : 'text-black-500 border-gray-500'
                  }
                >
                  <Typography>{item.name} </Typography>
                </button>
              </Typography>
            ))}
          </Box>
          <Box>
            <Box className="d-flex justify-between m-4">
              <Typography className="f18-700">Gallery</Typography>
              <Typography className="f16-500 text-blue-600 border-b border-blue-600">
                View more
              </Typography>
            </Box>
            <Box className="m-4 row">
              {state?.restData?.restaurant_pictures
                ?.slice(0, 4)
                .map((image, index) => (
                  <Box className="col-3">
                    <img
                      key={index}
                      src={image}
                      alt={`Restaurant Image ${index + 1}`}
                      className="object-cover rounded-lg"
                    />
                  </Box>
                ))}
            </Box>
            <Box className="m-4 row ">
              {reelsData?.records?.map((video, index) => (
                <Box key={index} className="col-2 p-1 overflow-auto">
                  <video
                    className="object-cover rounded-lg"
                    poster={video.thumbnailUrl}
                    autoplay
                  >
                    <source src={video?.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              ))}
            </Box>

            <Divider />
          </Box>
          <Box className="m-4">
            <Typography className="f20-700">About Restaurant</Typography>
            <div className="m-2">
              <Typography
                ref={textRef}
                className={`transition-all ${expanded ? '' : 'line-clamp-3'}`}
              >
                {state?.restData?.restaurant_description}
              </Typography>
              {isOverflowing && (
                <button
                  onClick={() => setExpanded(!expanded)}
                  style={{ color: '#EE3A23', fontWeight: 'bold' }}
                >
                  {expanded ? 'Read Less' : 'Read More'}
                </button>
              )}
            </div>
          </Box>
          <Divider />
          <Box>
            <Typography className="f24-800 m-4">Available Cuisines</Typography>
            <Box
              sx={{ d: 'flex', flexWrap: 'wrap' }}
              className="d-flex gap-3 m-4"
            >
              {state?.restData?.selected_cusine.map((cuisine, index) => (
                <Box className="f20-500 bg-gray-200 rounded-lg px-4 h-10 d-flex  inline-flex justify-between items-center shadow-md">
                  {cuisine.title}
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
          <Box>
            <Box className="d-flex justify-between m-4">
              <Typography className="f24-800">Health & Hygiene</Typography>
              <Typography className="f16-500 d-flex flex-wrap gap-1 text-blue-600">
                <img
                  className="w-6 h-6"
                  src={require('../../assets/images/IconsandImages/info.png')}
                ></img>
                click for more info
              </Typography>
            </Box>
            <Box className="gap-y-6">
              <Box
                sx={{ d: 'flex', flexWrap: 'wrap' }}
                className="d-flex gap-3 m-4"
              >
                {state?.restData?.selected_health_and_sanitization_facility.map(
                  (sanitization, index) => (
                    <Box
                      key={index}
                      className="f20-500 bg-[#E6F9F1] text-[#4CAF50] rounded-lg px-4 h-10 d-flex gap-[10px] inline-flex justify-between items-center shadow-md"
                    >
                      <img
                        src={require('../../assets/images/IconsandImages/tick-green.png')}
                      ></img>
                      {sanitization.title}
                    </Box>
                  )
                )}
              </Box>

              <Box
                sx={{ display: 'flex', flexWrap: 'wrap', m: '24px' }}
                className="gap-3"
              >
                {[
                  ...(state?.restData?.selected_dress_code || []),
                  ...(state?.restData?.selected_pet_allowance || []),
                ].map((item, index) => (
                  <Box
                    key={index}
                    className="bg-gray-200 f20-600 rounded-lg px-4 h-10 flex gap-2 items-center shadow-md"
                  >
                    <img className="w-6 h-6" src={item.icon} alt="" />
                    {item.title}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box className="bg-[#EFEFEF] pb-4">
            <Typography className="f24-800 m-4">Food Type</Typography>
            <Box className=" d-flex gap-3 flex-wrap m-4">
              {state?.restData?.selected_food_type.map((FoodType, index) => (
                <Box className="flex flex-col items-center justify-center">
                  <Box className="w-16 h-16 bg-white rounded-full p-3">
                    <img src={FoodType.icon}></img>
                  </Box>
                  <Box
                    className="f20-600  px-4 h-10 items-center gap-[10px] "
                    key={index}
                  >
                    {FoodType.title}
                  </Box>
                </Box>
              ))}
            </Box>
            <Typography className="f24-800 m-4"> Liquor</Typography>
            <Box className="flex flex-wrap gap-3 m-4">
              {state?.restData?.selected_liquor_category?.length ? (
                state.restData.selected_liquor_category.map((liquor, index) => (
                  <Box
                    key={index}
                    className="flex flex-col items-center space-y-2"
                  >
                    <Box className="w-16 h-16 bg-white rounded-full flex items-center justify-center p-3 shadow-md">
                      <img
                        src={liquor.icon}
                        alt={liquor.title}
                        className="w-10 h-10"
                      />
                    </Box>
                    <Box className="text-lg font-semibold text-gray-700 text-center px-4">
                      {liquor.title}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className="text-gray-500 text-lg">
                  No Liquor Category Selected
                </Box>
              )}
            </Box>
            <Divider sx={{ borderBottom: '1px solid gray' }} />
            <Box>
              <Box className="f18-800 d-flex items-center m-4">
                <img
                  src={require('../../assets/images/IconsandImages/tick-green.png')}
                ></img>
                <Typography className="f18-800">Allergy Info</Typography>
              </Box>
              <Typography className="m-4">
                {state?.restData?.allergicInfoText}
              </Typography>
            </Box>
            <Divider sx={{ borderBottom: '1px solid gray' }} />
            <Box>
              <Box className="f18-800 d-flex items-center m-4">
                <img
                  src={require('../../assets/images/IconsandImages/tick-green.png')}
                ></img>
                <Typography className="f18-800">Nutrition Info </Typography>
              </Box>
              <Typography className="m-4">
                {state?.restData?.nutritionInfoText}
              </Typography>
            </Box>
            <Divider sx={{ borderBottom: '1px solid gray' }} />
            <Box>
              <Box className="f18-800 d-flex items-center m-4">
                <img
                  src={require('../../assets/images/IconsandImages/tick-green.png')}
                ></img>
                <Typography className="f18-800">
                  Personalisation Info{' '}
                </Typography>
              </Box>
              <Typography className="m-4">
                {state?.restData?.personalization_information_description}
              </Typography>
            </Box>
          </Box>
          {/* <Box>
            <Typography className="f24-800 ml-4">Reviews & Ratings</Typography>
            {/* <ExpReviewRating
                          vendorId={restaurantId}
                          data={responseData}
                          count={count}
                        /> */}
          {/* </Box> */}
          <Box>
            <Typography className="f24-800 m-4"> Available features</Typography>
            <Box>
              <Typography className="f18-800 m-4">Payments</Typography>
              <Box className="m-4">
                <Box className="flex gap-1 flex-nowrap lg:grid lg:grid-cols-6 lg:gap-4 overflow-x-auto lg:overflow-x-visible hidescrollbar">
                  {state?.restData?.selected_payment_methods.map(
                    (Payments, index) => (
                      <Box
                        key={index}
                        className="flex flex-col items-center justify-center min-w-[120px]"
                      >
                        {/* Icon container */}
                        <Box className="lg:w-16 lg:h-16 h-6 w-6 object-cover bg-[#EFEFEF] border-gray-400 rounded-full lg:p-3">
                          <img src={Payments.icon} alt={Payments.title} />
                        </Box>
                        {/* Title - Hidden on small screens, visible on large screens */}
                        <Box className="f20-600 px-4 h-10 items-center gap-[10px] hidden lg:flex">
                          {Payments.title}
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            </Box>
            <Divider sx={{ borderBottom: '1px solid gray' }} />
            <Box>
              <Box className="d-flex justify-between m-4">
                <Typography className="f20-800">Parking</Typography>
                <Typography className="f16-500 d-flex flex-wrap gap-1 text-blue-600">
                  <img
                    className="w-6 h-6"
                    src={require('../../assets/images/IconsandImages/info.png')}
                  ></img>
                  click for more info
                </Typography>
              </Box>
              <Box className="f18-700">{state?.restData?.parking_desc}</Box>
              <Box className=" flex gap-1 flex-nowrap lg:grid lg:grid-cols-6 lg:gap-4 overflow-x-auto lg:overflow-x-visible hidescrollbar">
                {state?.restData?.selected_parking_type.map(
                  (Payments, index) => (
                    <Box className="flex flex-col items-center justify-center m-2 ">
                      <Box className="w-16 h-16 bg-[#EFEFEF] border-gray-400 rounded-full p-3">
                        <img src={Payments.icon}></img>
                      </Box>
                      <Box
                        className="f20-600  px-4 h-10 items-center gap-[10px] "
                        key={index}
                      >
                        {Payments.title}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>
            <Divider sx={{ borderBottom: '1px solid gray' }} />
            <Box>
              <Typography className="f20-800 m-4">Accessibility</Typography>
              <Box className="m-4 flex gap-1 flex-nowrap lg:grid lg:grid-cols-6 lg:gap-4 overflow-x-auto lg:overflow-x-visible hidescrollbar">
                {state?.restData?.selected_accessibilty.map(
                  (accessibility, index) => (
                    <Box className="flex flex-col items-center justify-center">
                      <Box className="w-16 h-16 bg-[#EFEFEF] border-gray-400 rounded-full p-3">
                        <img src={accessibility.icon}></img>
                      </Box>
                      <Box
                        className="f20-600  px-4 h-10 items-center gap-[10px] "
                        key={index}
                      >
                        {accessibility.title}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>

            <Box>
              <Typography className="f20-700 m-4">
                Things To Do Around
              </Typography>
              <Box className="m-4">
                <Typography
                  ref={textRef}
                  className={`transition-all ${expanded || 'line-clamp-3'}`}
                >
                  {state?.restData?.things_nearby}
                </Typography>
                {isOverflowing && (
                  <button
                    onClick={() => setExpanded(!expanded)}
                    className="text-blue-500 font-bold"
                  >
                    {expanded ? 'Read Less' : 'Read More'}
                  </button>
                )}
              </Box>
            </Box>
            <ReferEarnHelp />
            <Box className="mt-2">
              <img
                src={require('../../assets/images/IconsandImages/Fssai-ash.png')}
              ></img>
              <Typography className="mt-3 f19-500 text-gray-400">
                Lic.No {state?.restData?.fssai_code}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
    />
  );
}

export default RestaurantDetailsPage;
