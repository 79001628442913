import React, { useState } from "react";
import ScreenTemplate from "../../../components/ScreenTemplate";
import MainSideBar from "../../../components/MainsideBar";
import MainHeader from "../../../components/headers/Mainheader";
import Typography from "@mui/material/Typography";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import useCustomLocation from "../../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../../config/keys";
import { useNavigate, useLocation } from "react-router-dom";
import IncDecButtons from "./IncDecButtons";
import { assets } from "../../../assets/assets";
import { Box, Button, Divider, Switch, TextField } from "@mui/material";
import { Image } from "react-bootstrap";
import { seatingAssets } from "../../../assets/images";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { seatingSvgs } from "../../../assets/svgs";
import Counter from "../../../components/buttons/Counter";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import moment from "moment";

const reservationValidationSchema = yup.object({
  reservationTime: yup.string().required("Reservation Time is required"),
  adults: yup
    .number()
    .required("Minimum 1 adult is required.")
    .min(1, "Minimum 1 adult is required."),
});
const seatingValidationSchema = yup.object({
  adults: yup
    .number()
    .required("Minimum 1 adult is required.")
    .min(1, "Minimum 1 adult is required."),
});

const WaitlistBooking = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const [data, setData] = useState(null); // State to store fetched data
  const [error, setError] = useState(null); // State to store any errors
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const locationPath = useLocation();
  const requestType = locationPath?.pathname?.includes("reservation")
    ? "RESERVATION"
    : "SEATING";

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useCustomLocation(GOOGLE_API_KEY);

  const UserData = useSelector((state) => state.user.userDetails);

  const [stage, setStage] = React.useState(3);

  const formik = useFormik({
    initialValues: {
      country_code: "+91",
      mobile_number: "",
      first_name: "",
      last_name: "",
      otp: "",

      adults: 1,
      kids: 0,
      HandicapSwitch: false,
      handicap: 0,
      highchair: 0,
      seating_preference: "N/A",
      occasion: "",
      roomNumber: "",

      reservationDate: moment().format("YYYY-MM-DD"),
      timings: [],
      reservationTime: "",
    },
    validationSchema:
      requestType === "SEATING"
        ? seatingValidationSchema
        : requestType === "RESERVATION"
        ? reservationValidationSchema
        : null,
    onSubmit: async (value) => {
      var formData = {
        country_code: value.country_code,
        mobile_number: value.mobile_number,
        first_name: value.first_name,
        last_name: value.last_name,
        email: value.email,
      };

      if (UserData) {
        formData.country_code = UserData?.country_code || "";
        formData.mobile_number = UserData?.mobile_number || "";
        formData.first_name = UserData?.first_name || "";
        formData.last_name = UserData?.last_name || "";
        formData.email = UserData?.email || "";
      }
    },
  });

  const content = (
    <Box>
      <Typography className="f24-800" color={assets.colors.primary}>
        Walk In Ordering
      </Typography>
      <Divider className="my-3" />
      <Box sx={{ display: "flex" }}>
        <Image src={seatingAssets.celebrationCap} style={{ width: "40px" }} />
        <Typography className="f22-700">Party Size</Typography>
      </Box>
      <Box className="f14-500 d-flex align-items-center mt-2">
        <InfoOutlinedIcon style={{ height: "15px", color: "#5C5C5C" }} />
        The kids ranging below 6 years will be kept under the kids category.
      </Box>

      {/* //card component// */}
      <Box sx={{ display: "flex" }} my={2}>
        <Box sx={{ display: "flex", marginRight: "20px" }}>
          <Image
            src={seatingAssets.adults}
            style={{ width: "60px" }}
            className="mx-4"
          />
          <Box>
            <Typography className="f18-700">Adults</Typography>
            <Counter />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Image
            src={seatingAssets.kids}
            style={{ width: "60px" }}
            className="mx-4"
          />
          <Box>
            <Typography className="f18-700">Kids</Typography>
            <Counter />
          </Box>
        </Box>
      </Box>
      <Divider className="my-3" />
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "24px" }}>
        <Typography className="f18-700 me-3">
          Need Handicap Access or a Highchair?
        </Typography>
        <Switch
          //checked={formik.values.HandicapSwitch}

          color="primary"
        />
      </Box>
      <Box sx={{ display: "flex" }} my={2}>
        <Box sx={{ display: "flex", marginRight: "20px" }}>
          <Image
            src={seatingAssets.handicap}
            style={{ width: "60px" }}
            className="mx-4"
          />
          <Box>
            <Typography className="f18-700">Handicap</Typography>
            <Counter />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Image
            src={seatingAssets.highchair}
            style={{ width: "60px" }}
            className="mx-4"
          />
          <Box>
            <Typography className="f18-700">Highchair</Typography>
            <Counter />
          </Box>
        </Box>
      </Box>
      <Divider className="my-3" />
      <Box sx={{ display: "flex" }}>
        <Image
          src={seatingAssets.seatingpreferences}
          style={{ width: "30px" }}
          className="mr-2"
        />
        <Typography className="f22-700">Seating Preferences</Typography>
      </Box>
      <Box className="f14-500 d-flex align-items-center mt-2">
        Your preference will be considered. Seating is a subject to
        availability.*{" "}
      </Box>
      <Divider className="my-3" />
      <Typography className="f20-700 my-2">Special Occassion</Typography>
      <TextField
        multiline
        rows={3}
        label=""
        name="occasion"
        // value={values?.occasion}
        //onChange={handleChange}
        placeholder="Write Here e.g. Wedding Anniversary"
        fullWidth
      />
      <Divider className="my-3" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",

          width: "-webkit-fill-available",
          backgroundColor: assets.colors.white,
        }}
        p={2}
        mt={3}
      >
        <Button
          variant="outlined"
          size="large"
          // onClick={() => handleCancel()}
          style={{
            borderRadius: "15px",
            color: "black",
            border: "1px solid #C4C4C4",
            textTransform: "capitalize",
          }}
          className="bg-white mr-2"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{
            borderRadius: "15px",
            border: "1px solid #C4C4C4",
            color: "white",
            backgroundColor: assets.colors.primary,
            textTransform: "capitalize",
          }}
          // disabled={!(tableNumber || paxNum || paxNumber)}
          fullWidth
          //onClick={handleSave}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
};

export default WaitlistBooking;
