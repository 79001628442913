import React from 'react';
import { Image } from 'react-bootstrap';
import stripTop from '../../assets/images/ordermodalassets/darkPurpal.svg';

const WithAmealioLove = () => {
  return (
    <div className="bg-[#F2F2FF] w-full h-auto relative mt-12">
      <Image
        src={stripTop}
        className="w-full h-8 object-fill absolute -top-5 md:-top-8"
        alt={'Amealio Love'}
        title={'Amealio Love'}
      />
      <div
        className="d-flex justify-content-center align-items-center font-Mulish text-xl md:text-3xl font-semibold"
        style={{ gap: '1px' }}
      >
        <span>With</span>
        <Image
          src={require('../../assets/images/ExperiencePageAssets/love-location.png')}
          alt="Love"
          title="Love"
          //   width={300}
          className="-mx-10 md:-mx-16 w-48 md:w-72 h-auto object-fill"
        />
        <span>Amealio</span>
      </div>
    </div>
  );
};

export default WithAmealioLove;
