import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";

const PhoneContactPopup = ({ open, onClose, contacts }) => {
  // console.log(contacts);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          p: 4,
          borderRadius: 3,
          boxShadow: 5,
          width: { xs: "90%", sm: "80%", md: "400px" },
          maxWidth: "400px",
          textAlign: "left",
          overflow: "auto",
          maxHeight: "80vh",
        }}
      >
        <Typography variant="h5" fontWeight="bold" mb={1}>
          Contact Information
        </Typography>
        <Typography variant="body2" color="gray" mb={3}>
          You can reach us anytime
        </Typography>

        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
          Name of the Restaurant
        </Typography>
        <Box display="flex" gap={1} mb={2}>
          <TextField
            fullWidth
            variant="outlined"
            value={contacts?.firstName || ""}
            InputProps={{ readOnly: true }}
          />
        </Box>
        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
          Contact info
        </Typography>
        <Box display="flex" gap={1} mb={2}>
          <Select
            value={contacts?.phone_numbers[0]?.country_code || "+91"}
            sx={{ width: 90 }}
          >
            <MenuItem value="+91">+91</MenuItem>
          </Select>
          <TextField
            fullWidth
            variant="outlined"
            value={contacts?.phone_numbers[0]?.mobile_no || ""}
            InputProps={{ readOnly: true }}
          />
        </Box>

        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
          Email ID
        </Typography>
        <Box display="flex" gap={1} mb={2}>
          <TextField
            fullWidth
            variant="outlined"
            value={contacts?.enquiry_emails?.[0] || ""}
            InputProps={{ readOnly: true }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PhoneContactPopup;
