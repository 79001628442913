import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const Cuisine = () => {
  const [cuisines, setCuisines] = useState([]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.cuisine) {
      //console.log("Fetched cuisines from localStorage:", userDetails.cuisine);
      setCuisines(userDetails.cuisine);
    } else {
      console.warn("No cuisines found in localStorage.");
    }
  }, []);

  // Static cuisine data
  const staticCuisines = [
    {
      name: "Indian",
      image: require("../../assets/images/IconsandImages/indian.png"),
    },
    {
      name: "Chinese",
      image: require("../../assets/images/IconsandImages/chinise.png"),
    },
    {
      name: "Italian",
      image: require("../../assets/images/IconsandImages/italian.png"),
    },
    {
      name: "South Indian",
      image: require("../../assets/images/IconsandImages/southindia.jpg"),
    },
    {
      name: "Asian",
      image: require("../../assets/images/IconsandImages/asian.png"),
    },
    {
      name: "Desserts",
      image: require("../../assets/images/IconsandImages/Desserts.png"),
    },
    {
      name: "American",
      image: require("../../assets/images/IconsandImages/american.png"),
    },
  ];

  return (
    <div className="mt-2">
      {/* Title aligned to top-left */}
      <div className="f22-700 ml-1 mb-4">Cuisine You'll Like</div>
      <div className="cuisine-scroll d-md-none">
        {staticCuisines.map((cuisine, index) => (
          <div key={index} className="cuisine-item">
            <Image
              src={cuisine.image}
              rounded
              fluid
              className="cuisine-image"
            />
            <p className="mt-1 fs-6">{cuisine.name}</p>
          </div>
        ))}
      </div>

      <Row className="g-5 ml-1 justify-content-start d-none d-md-flex">
        {staticCuisines.map((cuisine, index) => (
          <Col key={index} xs="auto" className="text-center">
            <Image
              src={cuisine.image}
              rounded
              fluid
              className="cuisine-image"
            />
            <p className="mt-1 fs-6">{cuisine.name}</p>
          </Col>
        ))}
      </Row>

      <style jsx>{`
        .cuisine-scroll {
          display: flex;
          overflow-x: auto;
          gap: 16px;
          padding-bottom: 10px;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none; /* Hides scrollbar in Firefox */
        }

        .cuisine-scroll::-webkit-scrollbar {
          display: none; /* Hides scrollbar in WebKit browsers */
        }

        .cuisine-item {
          flex: 0 0 auto;
          text-align: center;
        }

        .cuisine-image {
          width: 90px;
          height: 90px;
          object-fit: cover;
        }
      `}</style>
    </div>
  );
};

export default Cuisine;
