import React, { useState, useEffect } from 'react';
import { URL } from '../../common/api/urls';
import { useAxios } from '../../common/api/hooks/useAxios';
import TitleSection from '../ReusableComponent/TitleSection';
import ImageSlider from '../ReusableComponent/imageslider';
import { Col, Row, Image } from 'react-bootstrap';
import Services from '../ReusableComponent/services';
import FoodSection from './foodsection';
import RestaurantGallery from './restaurantgallery';
import Experiencecards from './experiencecards';
import ReadMoreReact from 'read-more-react';
import { Link, useParams } from 'react-router-dom';
import RatingsAndReviews from '../experience-details/RatingsAndReviews';
import { arrayToString } from '../../common/utility/helperFunc';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import WithAmealioLove from '../../components/reusable/WithAmealioLove';
//import { RWebShare } from "react-web-share";

const RestaurantDetails = (props) => {
  // const location = useLocation();
  const { ID } = useParams();
  // const restdata = location?.state?.data;
  const [dateFilter, setDateFilter] = useState(moment().format('YYYY-MM-DD'));
  const [experiences, setExperiences] = useState([]);
  const [state, setState] = useState({
    isLoading: true,
    restData: undefined,
    restId: ID,
  });

  const { isLoading, restId, restData } = state;

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  console.log('Data: ', responseData);

  // eslint-disable-next-line
  const [
    expcancel,
    expresponseData,
    experror,
    exploaded,
    expreset,
    expexecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (restId !== undefined && restId !== null) {
      executeAPI(
        `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,

        null
      );
    }
  }, []);

  //api response handler for get rest details
  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  useEffect(() => {
    expexecuteAPI(
      `${URL.RESTAURANT_EXPERIENCES}?pageType=EXPERIENCES&  =${dateFilter}`,
      'POST',
      {
        restaurants: [ID],
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  useEffect(() => {
    if (exploaded) {
      if (expresponseData !== null) {
        setExperiences(expresponseData);
      } else if (error !== null) {
        setExperiences([]);
      }
      reset(); //this is required to make any further api call to get rest details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploaded, expresponseData]);

  const shareData = {
    title: 'Here is a best place I found on Amealio - Download and Explore',
    text: `I found this great place ${restData?.restaurant_name} on Amealio, with this app I save time and money at my convenience. You can Discover, explore, engage order and much more on this platform seamlessly. `,
    url: `${restData?.vendor_id?.shareLink?.userShortLink}`,
  };

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
          <CircularProgress sx={{ marginTop: '20%' }} />
        </div>
      ) : (
        <div className="restaurant-details">
          <TitleSection
            bg={'#E9E7FD'}
            backRoute={`/restaurant/${ID}/food/menu`}
            title={restData?.restaurant_name}
            // icon={require("../../assets/images/IconsandImages/share.png")}
            shareIcon={require('../../assets/images/IconsandImages/share.png')}
            shareObj={shareData}
          />
          <div>
            <ImageSlider
              shapedivider={true}
              images={restData?.restaurant_pictures || []}
            />
          </div>
          <div className="my-4 px-1">
            <Row className="m-0 py-3">
              <Col xs={5} className="px-2 border-right">
                <div className="d-flex">
                  <div>
                    <Image
                      src={require('../../assets/images/IconsandImages/timer.png')}
                      alt="Image"
                      width="20px"
                      fluid
                    />
                  </div>
                  <div className="mx-2">
                    <div className="f12-500">
                      {' '}
                      {restData?.openStatus?.open
                        ? restData?.openStatus?.time
                          ? `Closes at ${restData?.openStatus?.time}`
                          : 'Open 24x7'
                        : 'Closed'}
                    </div>
                    <div className="f12-400 text-indigo">Business hours</div>
                  </div>
                </div>
              </Col>
              <Col xs={3} className="px-2 border-right">
                <div className="d-flex">
                  <div>
                    <Image
                      src={require('../../assets/images/IconsandImages/smile.png')}
                      alt="Image"
                      width="24px"
                    />
                  </div>
                  <div className="mx-2">
                    <div className="f14-500">{restData?.orderCount} +</div>
                    <div className="f10-400 text-grey">Ordered</div>
                  </div>
                </div>
              </Col>
              <Col xs={4} className="px-2">
                <div className="d-flex">
                  <div>
                    <Image
                      src={require('../../assets/images/IconsandImages/serves.png')}
                      alt="Image"
                      width="20px"
                    />
                  </div>
                  <div className="mx-2">
                    <div className="f14-500">₹ {restData?.cost_for_two}</div>
                    <div className="f10-400 text-grey">Serves 2</div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="my-3 mx-3">
              <div className="d-flex align-items-center my-3">
                <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/diner.png')}
                    alt="Image"
                    width="24px"
                  />
                </div>
                <div className="mx-2">
                  <div className="f12-500">
                    {arrayToString(restData?.selected_cusine, ',', 'title')}
                    {/* North Indian, Maharashtrian, South Indian, Gujrathi, Marwadi, Konkani, Punjabi, Jain, Maharashtrian, South Indian. */}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center my-3">
                <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/cafehut.png')}
                    alt="Image"
                    width="22px"
                  />
                </div>
                <div className="mx-2">
                  <div className="f12-500">
                    {arrayToString(
                      restData?.selected_restaurant_type,
                      ',',
                      'title'
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex  align-items-center my-3">
                <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/rightdirection.png')}
                    alt="Image"
                    width="24px"
                  />
                </div>
                <div className="mx-2">
                  <div className="f12-500">{restData?.restaurant_address}</div>
                </div>
              </div>
              <div className="d-flex my-2 align-items-center">
                <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/eye.png')}
                    alt="Image"
                    width="24px"
                    fluid
                  />
                </div>
                <div className="mx-2">
                  <div className="f12-500">{restData?.view_count}+ views</div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-2">
            <Services id={restData?._id} />
          </div>
          {experiences?.length ? (
            <Experiencecards id={restData?._id} data={experiences} />
          ) : null}

          <div className="my-2 mx-2">
            <div className="f20-700 py-2">About Restaurant</div>
            <div className="f12-500" id="read-more-text">
              <ReadMoreReact
                text={restData?.restaurant_description || 'no data'}
                min={0}
                ideal={0}
                max={restData?.restaurant_description?.length}
                readMoreText={'Read more'}
              />
            </div>
          </div>
          <hr className="mx-2 opacity-25" />
          <div className="my-1 px-2 ">
            <div className="f20-700 py-2 d-flex justify-content-between">
              Health & Hygiene
              <span className="text-center">
                <Image
                  src={require('../../assets/images/IconsandImages/bluetriangle.png')}
                  alt="Image"
                  width="16px"
                  fluid
                />
                <Link
                  to={`/restaurant/${ID}/healthandsanitation`}
                  state={{ data: restData }}
                >
                  <p className="f10-400 m-0" style={{ color: '#2196f3' }}>
                    more info
                  </p>
                </Link>
              </span>
            </div>
            <div className="row mx-2">
              <div className="col-6 my-2">
                <div className="d-flex">
                  <div>
                    <Image
                      src={require('../../assets/images/IconsandImages/greenright.png')}
                      alt="Image"
                      width="16px"
                      fluid
                    />
                  </div>
                  <div className="f12-500 mx-2">Hand Soap</div>
                </div>
              </div>
              <div className="col-6 my-2">
                <div className="d-flex">
                  <div>
                    <Image
                      src={require('../../assets/images/IconsandImages/greenright.png')}
                      alt="Image"
                      width="16px"
                      fluid
                    />
                  </div>
                  <div className="f12-500 mx-2">Sanitizer</div>
                </div>
              </div>
              <div className="col-6 my-2">
                <div className="d-flex">
                  <div>
                    <Image
                      src={require('../../assets/images/IconsandImages/greenright.png')}
                      alt="Image"
                      width="16px"
                      fluid
                    />
                  </div>
                  <div className="f12-500 mx-2">Mask</div>
                </div>
              </div>
              <div className="col-6 my-2">
                <div className="d-flex">
                  <div>
                    <Image
                      src={require('../../assets/images/IconsandImages/greenright.png')}
                      alt="Image"
                      width="16px"
                      fluid
                    />
                  </div>
                  <div className="f12-500 mx-2">
                    Sanitation <br /> Station at Entry
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mx-2 opacity-25" />
          <div className="m-2">
            <div className="row m-0 py-2">
              <div className="col-6 d-flex">
                <div className="mx-1">
                  <Image
                    src={restData?.primary_dress_code?.icon}
                    alt="Image"
                    width="34px"
                    fluid
                  />
                </div>
                <div className="mx-1">
                  <div className="f16-500">Dress Code </div>
                  <div className="f12-400">
                    {restData?.primary_dress_code?.title}
                  </div>
                </div>
              </div>
              <div className="col-6 d-flex">
                <div className="mx-1">
                  <Image
                    src={restData?.primary_pet_allowance?.icon}
                    alt="Image"
                    width="34px"
                    fluid
                  />
                </div>
                <div className="mx-1">
                  <div className="f16-500">Pets Allowed</div>
                  <div className="f12-400">
                    {restData?.primary_pet_allowance?.title}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FoodSection restdata={restData} />
          <div className="my-1 px-2 ">
            <div className="f20-700 py-2 d-flex justify-content-between">
              Available Features
              <span className="text-center">
                <Image
                  src={require('../../assets/images/IconsandImages/bluetriangle.png')}
                  alt="Image"
                  width="16px"
                  fluid
                />
                <p className="f10-400 m-0" style={{ color: '#2196f3' }}>
                  more info
                </p>
              </span>
            </div>
            <div className="f14-700">Payment Method</div>
            <div className="d-flex align-items-center overflow-scroll hidescrollbar my-4 text-center m-0">
              {restData?.selected_payment_methods?.map((data) => {
                return (
                  <div className="col-3" key={data?._id}>
                    <Image
                      src={data?.icon}
                      alt="Image"
                      width="50px"
                      height="50px"
                      className="rounndborder"
                    />
                    <div className="f12-500 my-2">{data?.title} </div>
                  </div>
                );
              })}
            </div>
            <hr className="mx-2 opacity-25" />
            <div className="f14-700">Accessibility</div>
            <div className="row m-0 my-4">
              {restData?.selected_accessibilty?.map((data) => {
                return (
                  <div className="col-3 text-center" key={data?._id}>
                    <Image
                      src={data?.icon}
                      alt="Image"
                      width="50px"
                      fluid
                      className="rounndborder"
                    />
                    <div className="f12-500 my-2">{data?.title} </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="p-1" style={{ backgroundColor: '#E5D7D53B' }}>
            <div className="f20-700 ">Other Features</div>
            <div className="d-flex justify-content-start row m-0 px-2 py-4 ">
              {restData?.selected_other_features?.length &&
                restData?.selected_other_features?.map((data) => {
                  return (
                    <React.Fragment key={data?._id}>
                      <div className="col-4 p-2">
                        <div className="f12-500 nowrap">
                          <span className="indigodot"></span> {data?.title}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <RestaurantGallery data={restData} />
          <div className="pt-4 px-2">
            <div className="d-flex align-items-center justify-content-between pb-2">
              <div className="f18-700 m-2">Ratings & Reviews</div>
              <div className="f12-500 text-grey">Write A Review </div>
            </div>
            <RatingsAndReviews vendorId={restData?.vendorId} />
          </div>
          <div className="my-2">
            <Image
              src={require('../../assets/images/IconsandImages/referearn.png')}
              fluid
              width="100%"
              style={{ maxWidth: '100%' }}
            />
            <div className="my-4">
              <div className="f18-700 p-3">Things To Do Around</div>
              <div className="f14-500 px-3">{restData?.things_nearby}</div>
            </div>
            <div className="m-3">
              <Image
                src={require('../../assets/images/IconsandImages/fssai.png')}
                fluid
                width="200px"
                style={{ maxWidth: '100%' }}
              />
              <div className="f16-700 opacity-50">
                Lic No:{restData?.fssai_code}{' '}
              </div>
            </div>
          </div>
          <WithAmealioLove />
        </div>
      )}
    </>
  );
};
export default RestaurantDetails;
