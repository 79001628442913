import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, TextField } from '@mui/material';

import facebookShare from '../../assets/images/socialmedia/facebookShare.png';
import instagramShare from '../../assets/images/socialmedia/social.png';
import whatsappShare from '../../assets/images/socialmedia/whatsapp.png';
import gmailShare from '../../assets/images/socialmedia/gmail.png';
import twitterShare from '../../assets/images/socialmedia/twitter.png';
import linkedInShare from '../../assets/images/socialmedia/linkedin.png';

const buttonArray = [
  {
    id: 0,
    title: 'whatsapp',
    img: whatsappShare,
  },
  {
    id: 1,
    title: 'facebook',
    img: facebookShare,
  },
  {
    id: 2,
    title: 'linkedin',
    img: linkedInShare,
  },
  {
    id: 3,
    title: 'instagram',
    img: instagramShare,
  },
  {
    id: 4,
    title: 'gmail',
    img: gmailShare,
  },
  // {
  //   id: 5,
  //   title: "twitter",
  //   img: twitterShare,
  // },
];

const BottomSheetModal = ({ isVisible, onClosedAction, shareDataInfo }) => {
  const { title, message, subject, url } = shareDataInfo;
  const modalRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        // Escape key
        onClosedAction();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleShare = useCallback(
    (platform) => {
      let shareUrl = '';
      let encodedTitle = encodeURIComponent(title);
      let encodedText = encodeURIComponent(message);
      let encodedUrl = encodeURIComponent(url);
      switch (platform) {
        case 'whatsapp':
          shareUrl = `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`;
          break;
        case 'facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
          break;
        case 'linkedin':
          shareUrl = `https://www.linkedin.com/shareArticle?url=${encodedUrl}&title=${encodedTitle}&summary=${encodedText}`;
          break;
        case 'twitter':
          shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`;
          break;
        case 'gmail':
          shareUrl = `mailto:?subject=${encodedTitle}&body=${encodedText}%0A%0A${encodedUrl}`;
          break;
        case 'instagram':
          if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/iPhone|iPad|iPod/i)
          ) {
            // Mobile devices
            shareUrl = `instagram://library?AssetPath=${encodedUrl}`; //This will open the instagram app, but might not paste the URL.
          } else {
            // Desktop
            shareUrl = `https://www.instagram.com/`; // Opens instagram on the web.
          }
          break;
        default:
          return;
      }
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    },
    [title, message, url]
  );

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      textAreaRef.current.value = url;
      textAreaRef.current.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (innerErr) {
        console.error('Could not copy text: ', innerErr);
        alert('Could not copy text. Please copy manually.');
      }
    }
  };

  return (
    <div className="absolute bottom-0 w-full h-full">
      {isVisible && (
        <div className="share-reels-overlay">
          <div ref={modalRef} className="share-reels-bottom-sheet">
            <div className="w-full h-auto flex items-end justify-end">
              <Button onClick={onClosedAction} className="!min-w-8 p-0">
                <CloseIcon style={{ fontSize: 28 }} htmlColor="black" />
              </Button>
            </div>
            <p className="font-Mulish font-semibold text-base mb-3 text-black">
              Hey! Just wanted to share this awesome Vibe with you on Amealio.
              Check it out!
            </p>
            <div className="w-full my-3">
              <ButtonGrid
                buttons={buttonArray}
                numberOfRows={6}
                onButtonAction={handleShare}
              />
            </div>
            <div className="w-full min-h-7 bg-black rounded-md border flex items-center justify-between p-2 mb-4">
              <div className="w-3/4 flex justify-start break-words">
                <p
                  ref={textAreaRef}
                  className="text-white font-Mulish font-medium text-base mb-0 overflow-auto"
                >
                  {url}
                </p>
              </div>
              <div className="w-1/4 flex justify-end">
                <Button
                  onClick={handleCopy}
                  className="text-[#0C66FF] font-Mulish font-bold text-lg"
                >
                  {copied ? 'Copied!' : 'Copy'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ButtonGrid = ({ buttons, numberOfRows, onButtonAction }) => {
  const buttonsPerRow = numberOfRows;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {Array.from({ length: Math.ceil(buttons?.length / buttonsPerRow) }).map(
        (_, rowIndex) => (
          <div
            key={rowIndex}
            style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}
          >
            {buttons
              .slice(rowIndex * buttonsPerRow, (rowIndex + 1) * buttonsPerRow)
              .map((button, buttonIndex) => (
                <Button
                  key={buttonIndex}
                  onClick={() => onButtonAction(button?.title)}
                  className={`border !rounded-full bg-white border-lightTextColor shadow-md !min-w-14 !min-h-14 p-0`}
                >
                  <img
                    src={button?.img}
                    className={`object-cover w-12 h-12 ${
                      button?.title === 'gmail' && 'p-1'
                    }`}
                    alt={button?.title}
                    title={button?.title}
                  />
                </Button>
              ))}
          </div>
        )
      )}
    </div>
  );
};

ButtonGrid.propTypes = {
  numberOfRows: PropTypes.number,
  buttons: PropTypes.array,
  onButtonAction: PropTypes.func,
};

BottomSheetModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClosedAction: PropTypes.func.isRequired,
  shareDataInfo: PropTypes.object,
};

export default BottomSheetModal;
