import React from "react";
import { Check } from "lucide-react";
import { Button, Image } from "react-bootstrap";
import ExpOrderCard from "./ExperienceOrderCard";
import { assets } from "../../../assets/assets";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ExpPaymentPage = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const { state } = useLocation();
  const navigate = useNavigate();

  const paymentDetails = useSelector(
    (state) => state.experience?.expTransaction
  );
  const expRequestDetails = useSelector(
    (state) => state.experience?.expTrackDetails
  );

  const { restaurantId, experienceId } = useParams();

  const inputDate = expRequestDetails?.reservationTime;

  // Convert to Date object
  const date = new Date(inputDate);

  // Format the date and time
  const formattedTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const formattedDate = date
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, " "); // Removes any extra spaces

  // Combine the formatted values
  const finalFormat = `${formattedTime} | ${formattedDate}`;

  return (
    <div className="max-w-6xl mx-auto bg-white">
      {/* Header */}
      <div className="mb-1">
        <h4 className="text-danger" style={{ fontWeight: "bold" }}>
          Order Details
        </h4>
      </div>

      {/* Payment Success Message */}
      <div className="text-center py-8 bg-green-50 rounded-lg mb-8">
        <div className="d-flex justify-content-center mb-3">
          <div
            className="rounded-circle p-1 d-flex align-items-center justify-content-center"
            style={{ width: "5rem", height: "5rem" }}
          >
            <Image
              src={
                "https://sellwase-production.s3.amazonaws.com/6777a01dd9da3191da17e878.png"
              }
              width={"100%"}
              className="object-contains w-100 rounded-4"
            />
          </div>
        </div>

        <p
          className="p-0 m-3"
          style={{ fontWeight: "bold", fontSize: "1.1em", color: "#4CAF50" }}
        >
          Payment Success
        </p>
        <p
          className="p-0 m-1 f15-700"
          style={{ fontWeight: "bold", fontSize: "0.9em" }}
        >
          Your payment is successful
        </p>
        <p className="p-0 m-1" style={{ fontSize: "0.8em" }}>
          Transaction ID: {expRequestDetails?._id}
        </p>
        <p className="p-0 m-1" style={{ fontSize: "0.8em" }}>
          {finalFormat}
        </p>
      </div>

      {/* Order Details Section */}
      <div className="mb-3">
        <h5 className="" style={{ fontWeight: "bold" }}>
          Order Details
        </h5>
      </div>
      <ExpOrderCard />

      <div className="w-100 mt-4 d-flex justify-content-end">
        <div className="h-100" style={{ width: isDesktop ? "40%" : "100%" }}>
          <h5 className="" style={{ fontWeight: "bold" }}>
            Payment Details
          </h5>

          <div className="d-flex align-items-center justify-content-between">
            <p className="p-0 m-0" style={{ fontSize: "0.9em" }}>
              {"Grand Total"}
            </p>
            <h5 className="" style={{ fontWeight: "bold" }}>
              ₹ {expRequestDetails?.paymentData?.totalAmount}
            </h5>
          </div>

          <div className="d-flex align-items-center gap-4">
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {}}
              style={{
                borderRadius: "8px",
                color: "black",
                textTransform: "capitalize",
                //   backgroundColor: assets.colors.white_400,
                color: assets.colors.primary,
                border: `1px solid #EE3A23`,
                width: "50%",
              }}
              className="my-2"
            >
              Rate Us
            </Button>

            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                // formik.handleSubmit();
                navigate(
                  `/experience/${restaurantId}/${experienceId}/orderstatus`,
                  {
                    state: state?.transactionDetails[0],
                  }
                );
              }}
              style={{
                borderRadius: "8px",
                color: "black",
                textTransform: "capitalize",
                backgroundColor: "#EE3A23",
                color: "white",
                border: `1px solid #EE3A23`,
                width: "50%",
              }}
              className="my-2 px-5"
            >
              Track
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpPaymentPage;
