import { SearchWithFilterAndSort } from './SearchWithFilterAndSort';
import { TopTitle } from './TopTitle';
import { HorizontalScrollMenu } from './HorizontalScrollMenu';
import { ExperienceGrid } from './ExperienceGrid';
import { Image } from 'react-bootstrap';

import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DatePicker from './DatePicker';
import moment from 'moment';
import CustomSingleDatePicker from '../../components/datePickers/CustomDatePicker';
import WithAmealioLove from '../../components/reusable/WithAmealioLove';

export default function Experiences() {
  const { restaurantId } = useParams();
  const [experiences, setExperiences] = useState({
    sourceData: [],
    displayData: [],
  });

  const [quickLinks, setQuickLinks] = useState({
    categories: [],
    subCategories: [],
    sourceSubCategories: [],
  });

  const [subCategory, setSubCategory] = useState('');
  const [category, setCategory] = useState('');

  const setSelectedCategory = (e) => {
    if (!e) {
      setQuickLinks({
        ...quickLinks,
        subCategories: quickLinks?.sourceSubCategories,
      });
      setExperiences({
        ...experiences,
        displayData: experiences?.sourceData,
      });
      setCategory('');
    } else {
      setCategory(e);
      setSubCategory('');
    }
  };

  const [dateFilter, setDateFilter] = useState(moment());
  const [dateFilterExp, setDateFilterExp] = useState(moment());
  const [search, setSearch] = useState('');
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value) {
      let displayData = experiences?.sourceData;
      displayData = displayData.filter((item) =>
        item?.name
          ?.toString()
          ?.toLowerCase()
          .includes(e.target.value?.toString()?.toLowerCase())
      );
      setExperiences({
        ...experiences,
        displayData,
      });
    } else {
      setExperiences({
        ...experiences,
        displayData: experiences?.sourceData,
      });
    }
  };
  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  const [
    // eslint-disable-next-line
    cancelQL,
    responseDataQL,
    // eslint-disable-next-line
    errorQL,
    loadedQL,
    resetQL,
    executeQuickLinksAPI,
  ] = useAxios();
  useEffect(() => {
    executeAPI(
      `${URL.RESTAURANT_EXPERIENCES}?pageType=EXPERIENCES&dateFilter=${dateFilter}`,
      'POST',
      {
        restaurants: [restaurantId],
      }
    );
    console.log(
      'Executing API Request:',
      `${URL.RESTAURANT_EXPERIENCES}?pageType=EXPERIENCES&dateFilter=${dateFilter}`
    );

    executeQuickLinksAPI(`${URL.QUICK_LINKS}?type=QUICK_LINKS`, 'GET');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  useEffect(() => {
    if (loaded) {
      if (responseData !== null) {
        //do any preprocessing if required using preprocessor.js functions
        console.log('API Response Data:', responseData);
        setCategory('');
        setSubCategory('');
        setExperiences({
          sourceData: responseData,
          displayData: responseData,
        });
      } else if (error !== null) {
        setExperiences({
          sourceData: [],
          displayData: [],
        });
      }
      reset(); //this is required to make any further api call to get rest details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  useEffect(() => {
    if (loadedQL) {
      if (responseDataQL !== null) {
        //do any preprocessing if required using preprocessor.js functions
        setQuickLinks({
          categories: responseDataQL?.categories,
          sourceSubCategories: responseDataQL?.subCategories,
          subCategories: responseDataQL?.subCategories,
        });
      }
      resetQL(); //this is required to make any further api call to get rest details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedQL, responseDataQL]);

  useEffect(() => {
    if (category || subCategory) {
      let displayData = experiences?.sourceData;
      if (category) {
        displayData = displayData.filter((item) => {
          return item?.category?._id === category;
        });
      }
      if (subCategory) {
        displayData = displayData.filter((item) => {
          return item?.subCategory?._id === subCategory;
        });
      }
      setExperiences({
        ...experiences,
        displayData,
      });
    }

    if (category) {
      let subCategories = quickLinks?.sourceSubCategories;
      subCategories = subCategories.filter((item) => {
        return item?.selected_category === category;
      });
      setQuickLinks({
        ...quickLinks,
        subCategories,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, subCategory]);

  const handleChange = (newValue) => {
    setDateFilter(moment(newValue).format('YYYY-MM-DD'));
    setDateFilterExp(newValue);
  };

  return (
    <div className="experiences-list">
      <TopTitle
        title={experiences?.sourceData?.[0]?.restaurantName || 'Experiences'}
        subTitle={'Create your own event & dining experiences'}
        backRoute={`/restaurant/${restaurantId}`}
      />
      <div className="px-3">
        <div className="p-2">
          <SearchWithFilterAndSort
            onChange={onChangeSearch}
            search={search}
            placeHolderText={'Search by experiences'}
            micIcon={true}
          />
        </div>
        <div className="d-flex align-items-center hide-mui-texfeild-border f14-700">
          <CustomSingleDatePicker
            value={dateFilterExp}
            defaultValue={moment()}
            onChange={(val) => handleChange(val)}
            borderRadius={2}
            // label={todat}
            restStyle={{ width: '100%' }}
          />
          {/* <DatePicker value={dateFilter} onChange={(e) => setDateFilter(e)} /> */}
        </div>
        <hr className="mx-0 my-2" />
        <div>
          <HorizontalScrollMenu
            data={quickLinks?.categories}
            onClick={(e) => setSelectedCategory(e)}
            selected={category}
          />
        </div>
        <div className="mt-2">
          <div className="f14-500">Quick Options</div>
          <HorizontalScrollMenu
            data={quickLinks?.subCategories}
            onClick={(e) => {
              setSubCategory(e);
              if (!e) {
                setExperiences({
                  ...experiences,
                  displayData: experiences?.sourceData,
                });
              }
            }}
            selected={subCategory}
          />
        </div>
        <div className="mt-4">
          <div className="f14-500 mb-3">
            Enjoy Great Experiences in just few steps!
          </div>
          {experiences?.displayData?.map((e) => (
            <ExperienceGrid key={e?._id} data={e} />
          ))}
        </div>
      </div>
      <WithAmealioLove />
    </div>
  );
}
