export const ITEM_TAGS = [
  { id: 1, title: "All" },
  { id: 2, title: "Best Seller" },
  { id: 3, title: "Chef's Special" },
  { id: 4, title: "Recommended" },
  { id: 5, title: "Most Popular" },
  { id: 6, title: "Popular" },
  { id: 7, title: "Newly Added" },
  { id: 8, title: "Pocket Friendly" },
  { id: 9, title: "Seasonal" },
];

export const AnalyticPage = {
  QR_SCAN: "QR_SCAN",
  MENU_VIEW: "MENU_VIEW",
  EXP_VIEW: "EXP_VIEW",
  RESTAURANT_VIEW: "RESTAURANT_VIEW",
  APP_DOWNLOAD: "APP_DOWNLOAD",
  PREFERENCE_SKIP: "PREFERENCE_SKIP",
  REGISTER_WEBAPP: "REGISTER_WEBAPP",
};

export const ONDC_SOCKET_DATA = [
  "ondc_on_search",
  "ondc_on_select",
  "ondc_on_init",
  "ondc_on_confirm",
  "ondc_on_status",
  "ondc_on_track",
];

export const DEFAULT_ITEM_IMAGE =
  "https://iconslist.s3.amazonaws.com/imagenotavailable.png";

export const NOTAVAILABLEIMAGE =
  "https://amealia.s3.amazonaws.com/60b7435e6dcadb0291ef7868TheLotusSutraHyattRegencyPune-Group%2070774.svg";

export const CALL_TO_ACTION_ENUMS = [
  "RESTAURANT_PAGE",
  "ITEM_PAGE",
  "EXPERIENCE_BOOKING_PAGE",
  "OFFER_PAGE",
];
