import { orange } from "@mui/material/colors";

const assets = {
  images: {
    PAYLATER: "./images/IconsandImages/paylater.jpeg",
  },
  colors: {
    secondary_blue: "#2196F3",
    special_occassions_border: "#C8C8F8",
    white_400: "#FBFBFB",
    border_clr: "#EFEFEF",
    ratings_card_bg: "#FBFBFB",
    exp_card_border: "#C0BDFF",
    ratings_type_bg: "#FFB240",
    primary: "#fc5a47",
    secondary: "#43BFEB",
    accent: "#82D1ED",
    accent1: "#43BEEB6D",
    accent3: "#46B2D9",
    white: "#FFFFFF",
    divider: "#CBCBCB",
    black: "#000000",
    lightGreen: "#9DDB75",
    grey: "#505050",
    disabled: "#d3d3d3",
    disabledLight: "#e4e4e4",
    red: "#DB6447",
    green: "#9CCB5C",
    orange: "#FF8566",
    orangeSecondary: "#E62E00",
    lightGrey: "#80808030",
    greySubtext: "#595959",
    greySubText2: "#828282",
    lightBlue: "#e1f5fe",
    background: "#F8F8F8",
    borderColor: "#CBCBCB",
    //new//
    defaultBlue: "#1976d2",
    borderColorLight: "#CBCBCB",
    backgroundIndigo: "#E7E7FF",
    backgroundPeach: "#FFF5F3",
    textIndigo: "#8771DF",
    borderIndigo: "#A28AE4",
    backgrounddarkSkyBlue: "#D9DEFF40",
    hyperlink: "#1976d2",
    expstar: "#4DB14F",
    orange: "#EE3A23",
    hyginegreen: "#E6F9F1"
  },
};

export { assets };
