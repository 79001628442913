import React, { useRef, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Avatar, Button, Chip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import TruncatedText from '../BytesScreen/TruncatedText';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isEmpty } from '../../common/utility/helperFunc';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  borderRadius: 4,
  padding: 0,
};

const OffersModal = (props) => {
  const {
    openModal,
    onClosedModal,
    offerData,
    currentReelsInfo,
    onActionFavClick,
  } = props;
  const [copied, setCopied] = useState(false);
  const textAreaRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(offerData?.coupon_code);
      setCopied(true);
      showNotification(`Coupon Code Copy Successfully`, 'success');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      textAreaRef.current.value = offerData?.coupon_code;
      textAreaRef.current.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        showNotification(`Coupon Code Copy Successfully`, 'success');
        setTimeout(() => setCopied(false), 2000);
      } catch (innerErr) {
        console.error('Could not copy text: ', innerErr);
        alert('Could not copy text. Please copy manually.');
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={onClosedModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openModal}>
        <Box
          sx={style}
          className={'overflow-hidden border-0 bg-white w-[90%] md:w-[800px]'}
        >
          <div className="w-full h-60 border-0 bg-red-700 relative">
            <img
              src={offerData?.photos?.[0]}
              alt={offerData?.name}
              title={offerData?.name}
              className="object-fill w-full h-full"
            />
            <Button
              onClick={onClosedModal}
              className="!absolute top-3 right-3 !min-w-8 !h-8 rounded-full p-0 m-0"
            >
              <Avatar
                className={`!bg-[#FFFFFF] !shadow-md !w-8 !h-8 text-black border border-black`}
              >
                <CloseIcon sx={{ fontSize: 22 }} />
              </Avatar>
            </Button>
          </div>
          <div className="px-4 pt-3 pb-0">
            <Row>
              <Col sm={6}>
                <Chip
                  label={offerData?.isOnline ? 'Online' : 'Offline'}
                  color="success"
                  className="text-white !rounded-2xl !bg-[#4CAF50] uppercase !text-[8px] !font-Mulish !font-bold !h-auto py-1"
                />
              </Col>
              <Col sm={6} className="text-end">
                <Chip
                  icon={<VisibilityIcon sx={{ fontSize: 12 }} />}
                  label={offerData?.offerViews || 0}
                  className="bg-white text-black !rounded-2xl shadow-md !text-[8px] !font-Mulish !font-bold !h-auto py-1"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col xs={12} md={10}>
                <h4 className="text-black text-xl font-Mulish font-extrabold m-0 p-0 capitalize">
                  {offerData?.name}
                </h4>
                <TruncatedText
                  textString={offerData?.description}
                  textCount={125}
                  textColorType={'text-black'}
                />
              </Col>
              <Col xs={12} md={2} className="flex items-center justify-end">
                <Button onClick={onActionFavClick} className="!min-w-8 !h-8">
                  <Avatar className={`!bg-[#F7F7F7] !w-8 !h-8 !text-[#F85032]`}>
                    {currentReelsInfo?.isLiked ? (
                      <FavoriteIcon />
                    ) : (
                      <FavoriteBorderIcon />
                    )}
                  </Avatar>
                </Button>
              </Col>
            </Row>
          </div>
          <Row className="border-t border-t-borderLightColor px-4 py-3">
            <Col>
              <h4 className="text-black font-Mulish text-sm font-bold m-0 p-0">
                {moment(offerData?.start_date).format('DD MMM YYYY')} |{' '}
                {moment(offerData?.end_date).format('DD MMM YYYY')}
              </h4>
              <p className="text-[#9E9E9E] font-Mulish text-xs font-normal mb-0">
                Offer Duration
              </p>
            </Col>
            <Col className="text-end flex items-center justify-end">
              <p
                to={'#'}
                target="_blank"
                className={
                  '!text-[#7CA2E3] font-Mulish text-xs font-normal mb-0'
                }
              >
                View Terms & Conditions
              </p>
            </Col>
          </Row>
          <Row className="border-t border-t-borderLightColor px-4 py-3 items-center justify-start mb-6">
            <Col className="py-2">
              <div className="flex items-center justify-start">
                {!isEmpty(offerData?.service_type) &&
                  offerData?.service_type.map((data, index) => {
                    return (
                      <h4
                        className="text-black font-Mulish text-sm font-bold m-0 p-0 "
                        key={data + index}
                      >
                        {offerData?.service_type?.length - 1 === index
                          ? `${data}`
                          : `${data},`}
                        &nbsp;
                      </h4>
                    );
                  })}
              </div>
              <p className="text-[#9E9E9E] font-Mulish text-xs font-normal mb-0">
                Applicable Services
              </p>
            </Col>
            <Col className="border-l-0 md:border-l border-borderLightColor flex items-center justify-between py-2">
              <div>
                <h4
                  className="text-black font-Mulish text-sm font-bold m-0 p-0"
                  ref={textAreaRef}
                >
                  {offerData?.coupon_code}
                </h4>
                <p className="text-[#9E9E9E] font-Mulish text-xs font-normal mb-0">
                  Coupon Code
                </p>
              </div>
              <Button
                onClick={handleCopy}
                aria-label="Copy"
                title="Copy Coupon Code"
              >
                <Avatar
                  className={`!bg-[#FFFFFF] !shadow-md !w-8 !h-8 !p-1 ${
                    copied ? '!text-[#F85032]' : 'text-black'
                  }`}
                >
                  <ContentCopyIcon sx={{ fontSize: 18 }} />
                </Avatar>
              </Button>
            </Col>
          </Row>
        </Box>
      </Fade>
    </Modal>
  );
};

OffersModal.propTypes = {
  openModal: PropTypes.bool,
  onClosedModal: PropTypes.func,
  offerData: PropTypes.object,
  currentReelsInfo: PropTypes.object,
  onActionFavClick: PropTypes.func,
};

export default OffersModal;
