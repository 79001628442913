import React, { useState, useEffect } from 'react';
import RestaurantCard from '../ReusableComponent/restaurantCard';
import ScreenTemplate from '../../components/ScreenTemplate';
import MainSideBar from '../../components/MainsideBar';
import MainHeader from '../../components/headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import useLocation from '../../common/hooks/useLocation';
import { GOOGLE_API_KEY } from '../../config/keys';
import { URL } from '../../common/api/urls';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useAxios } from '../../common/api/hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import SocketContext from '../../setup/socket/SocketContext';
import {
  clearOndcRestaurants,
  saveOndcApiGetCartItemsOndc,
  saveOndcApiGetOndc,
  saveOndcCartItems,
  saveOndcOrderInfo,
  saveOndcRestaurants,
  saveOndcViewCartBottomInfo,
} from '../../store/slices/ondcSlice';
import OndcSeachBar from './Components/ondcSearchBar';
import moment from 'moment/moment';
import OndcRestaurantCards from '../ReusableComponent/OndcRestaurantCards';
import { ondcRestaurantSearch, setViewCartInfo } from './ondcHelperFuction.ts';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from '../../common/utility/helperFunc.js';
import CartBar from '../ReusableComponent/CartBar.js';
import { getItemsAddedToCart } from './ondcCart.js';
import { ONDC_SOCKET_DATA } from '../../common/enums/GlobalEnums.js';
import useApi from '../../common/api/hooks/useApi.js';
import WithAmealioLove from '../../components/reusable/WithAmealioLove.jsx';

const ALL_CATEGORY_KEYS = {
  RESTAURANT_TYPE: '5f562c9094ac7fdf47ea1d0a',
  FOOD_TYPE: '5f562c9094ac7fdf47ea1d1d',
  LIQUORS: '5f562c9094ac7fdf47ea1d1c',
};

const OndcRestaurantsList = (props) => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { handleRequest, isDataLoading } = useApi();

  const {
    coordinates,
    currentLocation,
    setCurrentLocation,
    updateLocation,
    location,
    setLocationState,
    selectedSavedAddress,
  } = useLocation(GOOGLE_API_KEY);

  const dispatch = useDispatch();

  const userAuth = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userDetails
  );
  const ondcRestaurants = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcRestaurants
  );
  const getOndcSavedOrderInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcOrderInfo
  );
  const getOndcSavedCartItems = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcCartItems
  );
  const getOndcBottomViewCartInfo = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcViewCartBottomInfo
  );
  const getOndcApiOrderCartData = useSelector(
    (state) => !isEmpty(state?.ondc) && state?.ondc?.ondcApiGetOndcData
  );

  const [cancel, ondcRestData, error, loaded, reset, executeOndcRestApi] =
    useAxios();

  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bottomViewCartInfo, setBottomViewCartInfo] = useState({});

  useEffect(() => {
    setRestaurants(ondcRestaurants);
  }, [ondcRestaurants]);

  useEffect(() => {
    if (!isEmpty(getOndcBottomViewCartInfo)) {
      setBottomViewCartInfo(getOndcBottomViewCartInfo);
    }
  }, [getOndcBottomViewCartInfo]);

  const fetchOndcRestaurants = async () => {
    try {
      dispatch(clearOndcRestaurants());
      const currentLatitude = location?.lat;
      const currentLongitude = location?.lng;
      const todayData = moment().toISOString();

      let formData = {
        context: {
          city: 'std:06755',
        },
        intent: {
          fulfillment: {
            type: 'Delivery',
            end: {
              location: {
                gps: `${currentLatitude}, ${currentLongitude}`,
                address: {
                  area_code: '500082',
                },
              },
            },
          },
          payment: {
            ['@ondc/org/buyer_app_finder_fee_type']: 'percent',
            ['@ondc/org/buyer_app_finder_fee_amount']: '3',
          },
          tags: [
            {
              code: 'catalog_full',
              list: [
                {
                  code: 'static_terms',
                  value: '',
                },
                {
                  code: 'static_terms_new',
                  value:
                    'https://github.com/ONDC-Official/NP-Static-Terms/buyerNP_BNP/1.0/tc.pdf',
                },
                {
                  code: 'effective_date',
                  value: todayData,
                },
              ],
            },
          ],
        },
      };

      const headers = {
        'Content-Type': 'application/json',
        _id: userAuth.User?._id ? userAuth.User?._id : userAuth?._id,
      };

      await executeOndcRestApi(
        `${URL.GET_ONDC_RESTAURNATS}`,
        'POST',
        formData,
        headers,
        (response) => {
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.log('ONDC-RESTAURANTS-LIST-ERROR', error);
    }
  };

  useEffect(() => {
    fetchOndcRestaurants();
    // !isEmpty(getOndcSavedOrderInfo) &&
    if (!isEmpty(getOndcSavedCartItems)) {
      dispatch(saveOndcOrderInfo(getOndcSavedOrderInfo));
      dispatch(saveOndcCartItems(getOndcSavedCartItems));
      dispatch(saveOndcViewCartBottomInfo(getOndcBottomViewCartInfo));
    } else {
      dispatch(saveOndcOrderInfo({}));
      dispatch(saveOndcCartItems([]));
      dispatch(saveOndcViewCartBottomInfo({}));
    }
  }, [location]);

  const getOndcGetToCartData = async () => {
    let headers = {
      'Content-Type': 'application/json',
      authorization: userAuth?.accessToken,
    };
    await handleRequest('get', `${URL.ONDC_USER_CART}`, null, {
      headers: headers,
      onSuccess: async (data) => {
        dispatch(saveOndcApiGetOndc(data?.data));
        dispatch(saveOndcApiGetCartItemsOndc(data?.data?.cart_items));
      },
      onError: (error) => {
        console.error(
          'API Error:',
          error?.response ? error?.response?.data : error
        );
        // setError("Failed to load experiences. Please try again later.");
      },
    });
  };

  useEffect(() => {
    if (props?.socket) {
      props.socket.authentication.app.authentication.setAccessToken(
        userAuth.accessToken
      );
      props.socket.authentication.app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log('Socket Re-Authenticated');
        })
        .catch((e) => {
          console.log('error', e);
        });
    }
    const ondcSocket = props.socket.service('ondc');
    ondcSocket.on(ONDC_SOCKET_DATA[0], (data) => {
      // console.log("ONDC-RESTAURANTS-LIST", data);
      const restaurants = data;

      if (restaurants?.message?.catalog) {
        dispatch(saveOndcRestaurants(restaurants));
      }
    });
    getOndcGetToCartData();
  }, []);

  const onSearch = (value) => {
    const searchResult = ondcRestaurantSearch(ondcRestaurants, value);
    setRestaurants(searchResult);
  };

  const ondcFavoriteAction = (favData) => {
    console.log('favorite', favData);
  };

  const ondcRestaurantsClick = (wholeRestData, providerData) => {
    const paramsData = {
      restContext: wholeRestData,
      restaurantsInfo: providerData,
    };
    navigate(`/ondc/${providerData?.id}/menu`, { state: paramsData });
  };

  const content = (
    <>
      <OndcSeachBar onChange={onSearch} />
      <div
        className={`d-flex ${isDesktop ? 'flex-row' : 'flex-column'} ${
          isDesktop ? '' : 'justify-content-center align-items-center'
        }`}
        style={{
          gap: '20px',
          marginTop: '15px',
        }}
      >
        <div>
          <div className="f22-700 ml-1 mb-4">Neighboring Restaurants</div>
          {/* <RestaurantCard restaurants={restaurantsToRender} from="ONDC" /> */}
          {isLoading && <div>Loading more restaurants...</div>}
        </div>
      </div>
      <Container fluid className="p-0">
        <Row>
          {restaurants.flatMap((item) =>
            item.message.catalog['bpp/providers'].map((provider) => {
              return (
                <React.Fragment key={provider?.id}>
                  <Col sm={12} md={4} lg={4}>
                    <OndcRestaurantCards
                      restaurantInfo={provider}
                      onPressCardClick={() =>
                        ondcRestaurantsClick(item, provider)
                      }
                      favType={false}
                      onClickFavoriteRestaurant={ondcFavoriteAction}
                    />
                  </Col>
                </React.Fragment>
              );
            })
          )}
        </Row>
      </Container>
      <WithAmealioLove />
      <div className="col-12 p-0">
        {/* !isEmpty(getOndcSavedOrderInfo) && */}
        {!isEmpty(getOndcApiOrderCartData) &&
          getOndcApiOrderCartData?.cart_items?.length > 0 && (
            <CartBar
              // setshowOtpModal={setshowOtpModal}
              from="ONDC"
              viewCartInfo={getOndcApiOrderCartData}
              ondcCheckAvailability={true}
              onClickCheckAvailabilityAction={() => {}}
            />
          )}
      </div>
    </>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
          selectedSavedAddress={selectedSavedAddress}
        />
      )}
      ContentComponent={content}
    />
  );
};

const OndcRestaurantsMain = (props) => (
  <SocketContext.Consumer>
    {(socket) => <OndcRestaurantsList {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default OndcRestaurantsMain;
