import { Box, Typography } from '@mui/material';
import React from 'react';


function ReferEarnHelp() {
  const referItems = [
    {
      title: "Refer A Friend",
      icon: require("../../assets/images/IconsandImages/referIcon.png"),
      mobileIcon: require("../../assets/images/IconsandImages/referFriendMobile.png"),
    },
    {
      title: "Earn With Us",
      icon: require("../../assets/images/IconsandImages/earnIcon.png"),
      mobileIcon: require("../../assets/images/IconsandImages/earn-mobile.png"),
    },
    {
      title: "Help & Feedback",
      icon: require("../../assets/images/IconsandImages/helpFeedback.png"),
      mobileIcon: require("../../assets/images/IconsandImages/helpFeedbackmobile.png"),
    },
  ];
  return (
    <Box className="bg-[#EFEFEF] p-6 ">
    <Typography className= "f20-800 mb-4">Refer & Earn</Typography>
    <Box className="d-flex  gap-6 gap-20">
      {referItems.map((item, index) => (
        <Box key={index} className="flex flex-col items-center">
          <Box className="bg-white border border-gray-300  rounded-full  shadow-md">
          <img
              src={item.icon}
              alt={item.title}
              className="w-16 h-16 hidden lg:block object-contain"
            />
            <img
              src={item.mobileIcon}
              alt={item.title}
              className="w-11 h-11 lg:hidden"
            />
          </Box>
          <Typography className="f16-700 mt-2 text-center text-gray-600">{item.title}</Typography>
        </Box>
      ))}
    </Box>
  </Box>
  );
}

export default ReferEarnHelp;
