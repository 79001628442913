import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@react-google-maps/api";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ScheduleIcon from "@mui/icons-material/Schedule";
import useApi from "../../../common/api/hooks/useApi";
import { URL } from "../../../common/api/urls";
import { useSnackbar } from "notistack";
import { isEmpty } from "../../../common/utility/helperFunc";
import { setUserSavedAddresses } from "../../../store/slices/userSlice";
import { Col, Row } from "react-bootstrap";

import HomeImg from "../../../assets/images/IconsandImages/homeblack.png";
import WorkImg from "../../../assets/images/IconsandImages/portfolioBlack.png";
import HotelImg from "../../../assets/images/IconsandImages/hotelBlack.png";
import OthersImg from "../../../assets/images/IconsandImages/locationBlack.png";

const MapLocationDrawer = (props) => {
  const {
    mapDrawerOpen,
    toggleDrawer,
    isDesktop,
    handleSavedLocationSelect,
    handleRecentSearch,
    getUserLatLngInfo,
  } = props;
  const dispatch = useDispatch();
  const { handleRequest, loading } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const showNotification = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  /**** @DESC REDUCER  ****/
  const userAuth = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userDetails
  );
  const getSavedUserAddress = useSelector(
    (state) => !isEmpty(state?.user) && state?.user?.userSavedAddress
  );

  const [autocomplete, setAutocomplete] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const MAX_RECENT_SEARCHES = 5; // Limit the number of recent searches

  useEffect(() => {
    getUserSavedAddress(userAuth.accessToken);
    const savedSearches =
      JSON.parse(localStorage.getItem("recentSearches")) || [];
    setRecentSearches(savedSearches);
  }, []);

  // Store the recent searches to localStorage
  const saveRecentSearches = (newSearches) => {
    setRecentSearches(newSearches);
    localStorage.setItem("recentSearches", JSON.stringify(newSearches));
  };

  const extractMainText = (addressComponents) => {
    return (
      addressComponents.find((component) =>
        component.types.includes("sublocality_level_1")
      )?.long_name ||
      addressComponents.find((component) =>
        component.types.includes("sublocality_level_2")
      )?.long_name ||
      addressComponents.find((component) =>
        component.types.includes("sublocality")
      )?.long_name ||
      addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name
    );
  };

  // Handle place change
  const handlePlaceChanged = () => {
    try {
      if (autocomplete) {
        const place = autocomplete.getPlace();
        if (
          !place ||
          !place.place_id ||
          !place.geometry ||
          !place.geometry.location
        ) {
          showNotification("Invalid place selected");
          return;
        }
        const placeService = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        placeService.getDetails(
          { placeId: place.place_id },
          (placeDetails, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const lat = placeDetails.geometry.location.lat();
              const lng = placeDetails.geometry.location.lng();
              let locationNameData = extractMainText(
                placeDetails?.address_components
              );
              let selectedPlace = {
                lat: lat,
                lng: lng,
                place_id: placeDetails.place_id,
                locationName: locationNameData,
                locationFullAddress: placeDetails.formatted_address,
              };
              const updatedSearches = [selectedPlace, ...recentSearches];
              const limitedSearches = updatedSearches.slice(
                0,
                MAX_RECENT_SEARCHES
              );
              saveRecentSearches(limitedSearches);
              handleRecentSearch(selectedPlace);
            } else {
              showNotification("Failed to get place details");
            }
          }
        );
      }
    } catch (error) {
      showNotification(error.message || "Invalid place selected");
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // Clear recent searches
  const clearSearchHistory = () => {
    setRecentSearches([]);
    localStorage.removeItem("recentSearches");
  };

  /***********************************
     @DESC GET USER SAVED ADDRESSES
  ***********************************/
  const getUserSavedAddress = async (token) => {
    let headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
    await handleRequest("get", `${URL.GET_USER_SAVED_ADDRESS}`, null, {
      headers: headers,
      onSuccess: async (response) => {
        dispatch(setUserSavedAddresses(response?.addressLocations));
      },
      onError: (error) => {
        showNotification(
          `${error?.response ? error?.response?.data : error}`,
          "error"
        );
      },
    });
  };

  const refineDeliveryAddress = (address) => {
    const completeAddress = [
      address?.name,
      address?.address1,
      address?.address2,
      address?.locality,
      address?.area,
      address?.city,
      address?.state,
      address?.country,
      address?.pincode,
    ]
      .filter(Boolean)
      .join(" ");
    return completeAddress;
  };

  const getUserCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          let coordinates = {
            lat: latitude,
            lng: longitude,
          };
          getUserLatLngInfo(coordinates);
        },
        (err) => {
          showNotification(
            `Error Code: ${err?.code} - ${err?.message || "Unknown error"}`,
            "error"
          );
        }
      );
    } else {
      showNotification(
        "Geolocation is not supported by this browser.",
        "error"
      );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={mapDrawerOpen}
      onClose={toggleDrawer}
      sx={{
        width: isDesktop ? 500 : "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isDesktop ? 500 : "100%",
          height: "100%",
          position: "relative",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography className="f18-800">Location Map</Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{ padding: 2, overflowY: "auto", maxHeight: "calc(100% - 160px)" }}
      >
        <Autocomplete
          onLoad={setAutocomplete}
          onPlaceChanged={handlePlaceChanged}
          options={{
            componentRestrictions: { country: "IN" }, // Restrict to India
            types: ["establishment"],
          }}
        >
          <TextField
            fullWidth
            type="text"
            placeholder="Search area, locality, city..."
            value={searchInput}
            onChange={handleInputChange}
            sx={{ mb: 2, height: 42 }}
            size="small"
          />
        </Autocomplete>
        <Box className={"border border-black h-auto w-full px-3 py-2 mb-3"}>
          <Button
            className="flex items-start justify-start hover:text-primaryRed w-full"
            onClick={getUserCurrentLocation}
          >
            <div className="w-auto p-2 self-start">
              <GpsFixedIcon />
            </div>
            <div className="w-full text-left p-1">
              <Row>
                <Col xs={12}>
                  <p className="text-base font-Mulish font-extrabold mb-0 text-black capitalize">
                    Get Current Location
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-xs font-Mulish font-medium text-lightTextColor mb-0 capitalize">
                    using gps
                  </p>
                </Col>
              </Row>
            </div>
          </Button>
        </Box>
        {!isEmpty(recentSearches) && recentSearches?.length > 0 && (
          <Box
            className={"border border-black h-auto w-full px-3 py-2 mb-3"}
            sx={{ overflowY: "auto", maxHeight: "calc(100% - 160px)" }}
          >
            <div className="flex items-center justify-between mb-2">
              <p className="text-lightTextColor text-left text-base font-Mulish font-bold px-3 capitalize mb-0">
                Recent Searches
              </p>
              <button
                onClick={clearSearchHistory}
                className="cursor-pointer text-sm font-Mulish font-semibold text-primaryRed"
              >
                Clear
              </button>
            </div>
            {!isEmpty(recentSearches) &&
              recentSearches.map((search) => {
                return (
                  <Button
                    key={search?.place_id}
                    className="flex items-start justify-start hover:text-primaryRed w-full"
                    onClick={() => handleRecentSearch(search)}
                  >
                    <div className="w-auto p-2 self-start">
                      <ScheduleIcon />
                    </div>
                    <div className="w-full text-left p-1">
                      <Row>
                        <Col xs={12}>
                          <p className="text-base font-Mulish font-extrabold mb-0 text-black capitalize">
                            {search?.locationName}
                          </p>
                        </Col>
                        <Col xs={12}>
                          <p className="text-xs font-Mulish font-medium text-lightTextColor mb-0 capitalize">
                            {search?.locationFullAddress}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Button>
                );
              })}
          </Box>
        )}
        <Box className={"border border-black h-auto w-full px-3 py-2"}>
          <p className="text-lightTextColor text-left text-base font-Mulish font-bold px-3 capitalize">
            saved addresses
          </p>
          {!isEmpty(getSavedUserAddress) &&
            getSavedUserAddress.map((data, index) => (
              <Button
                key={data?._id}
                className="flex items-start justify-start hover:text-primaryRed"
                onClick={() => handleSavedLocationSelect(data)}
              >
                <div className="w-auto p-2 self-start">
                  <img
                    className="w-6 h-6 rounded-sm"
                    src={
                      data?.type.toLowerCase() === "home"
                        ? HomeImg
                        : data?.type.toLowerCase() === "work"
                        ? WorkImg
                        : data?.type.toLowerCase() === "hotel"
                        ? HotelImg
                        : OthersImg
                    }
                    alt="Medium"
                  />
                </div>
                <div className="w-full text-left p-1">
                  <Row>
                    <Col xs={12}>
                      <p className="text-base font-Mulish font-extrabold mb-0 text-black capitalize">
                        {data?.type}
                      </p>
                    </Col>
                    <Col xs={12}>
                      <p className="text-xs font-Mulish font-medium text-lightTextColor mb-0 capitalize">
                        {refineDeliveryAddress(data)}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Button>
            ))}
          {getSavedUserAddress?.length === 0 && (
            <Row>
              <Col xs={12}>
                <p className="text-lightTextColor text-center text-sm font-Mulish font-bold px-3 capitalize mb-0">
                  no saved address found
                </p>
              </Col>
            </Row>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

MapLocationDrawer.propTypes = {
  mapDrawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  isDesktop: PropTypes.string || PropTypes.number,
  handleSavedLocationSelect: PropTypes.func.isRequired,
  handleRecentSearch: PropTypes.func.isRequired,
  getUserLatLngInfo: PropTypes.func.isRequired,
};

export default MapLocationDrawer;
