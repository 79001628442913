import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const BackIconComponent = ({ route, backButton }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        try {
          if (sessionStorage.getItem("TrackScreen")) {
            sessionStorage.removeItem("TrackScreen");
          }
          if (backButton) backButton();
          else navigate(route || -1);
        } catch (e) {
          console.log(e);
        }
      }}
    >
      <ChevronLeftIcon fontSize="medium" />
    </div>
  );
};
